import { Controller } from "@hotwired/stimulus"
import CustomerSubAccountSearchController from './customer_sub_account_search_controller.js'

// Connects to data-controller="organization-quick-search"
export default class extends CustomerSubAccountSearchController {
  connect() {
    document.account_search_input_id = "organization-quick-search-input";
  }
  refreshSearch(event){
    let curval = $("#organization-quick-search-input").val();
    if(curval != "")
    {
      $("#recent-customers .list-group-links").slideUp('fast');
      $("#organization-search-holder .list-group-links").slideDown('fast');
    }
    else
    {
      $("#organization-search-holder .list-group-links").slideUp('fast');
      $("#recent-customers .list-group-links").slideDown('fast');
    }
    super.refreshSearch(event);
  }
  searchInputBlur(event){
    if($("#organization-quick-search-container").data("hover_active") != "yes")
    {
      $("#recent-customers .list-group-links").slideUp('fast');
    }
  }
  searchInputFocus(event){
    var curval = $("#organization-quick-search-input").val();
    if(curval == "")
    {
      $("#recent-customers .list-group-links").slideDown('fast');
    }
    else
    {
      $("#recent-customers .list-group-links").hide();
    }
  }
  hoverOver(event){
    console.log("hoverOver");
    var curval = $("#organization-quick-search-input").val();
    if(curval == "")
    {
      $("#recent-customers .list-group-links").slideDown('fast');
    }
    else
    {
      $("#recent-customers .list-group-links").hide();
    }
    $("#organization-quick-search-container").data("hover_active","yes");
  }
  hoverOut(event){
    console.log("hoverOut");
    if(!$("#organization-quick-search-input").is(":focus"))
    {
      $("#recent-customers .list-group-links").slideUp('fast');
    }
    $("#organization-quick-search-container").data("hover_active","no");
  }
}
