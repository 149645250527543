import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="add-association"
export default class extends Controller {
  static targets = [ "search", "error", "custom_input" ]

  connect() {
    // Add modal form listener on my account page
    if (this.hasSearchTarget) {
      let searchTarget = this.searchTarget;
      if (this.element.dataset && this.element.dataset.location == "account" && this.element.tagName == "FORM") {
        this.element.addEventListener("submit", function (event) {
          if (!(searchTarget.selectedOptions && searchTarget.selectedOptions.length > 0)){
            event.preventDefault();
          }
        });
      }
    }
  }
  process_add(event){
    if (this.hasSearchTarget && this.hasCustom_inputTarget) {
      let val = this.custom_inputTarget.value;
      if (val != "") {
        this.custom_inputTarget.value = "";
        let newOption = new Option(val, "custom:"+val, false, true);
        this.searchTarget.options.add(newOption);
        event.currentTarget.blur(); // Deselect button
      }
    }
  }
}
