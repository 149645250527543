window.advanced_report_editor_setup = function(advanced_report_data_combo_url) {
  document.advanced_report_data_combo_url = advanced_report_data_combo_url;
  document.selected_option_groups = [];
  document.question_id_to_option_group_map = {};
  document.option_group_combinations = {};
//+
  document.add_group_modal_quest_id = undefined;
  document.add_group_modal_options = undefined;
  document.add_group_modal_custom = undefined;

  $(".question-option-select").off("change").on("change", function (e) {
    var quest_id = $(this).data("quest_id");
    var custom = $(this).data("custom");
    custom = custom ? custom : ""; // Make sure if undefined we set to empty string
    if ($("#" + custom + "question_add_group_btn_" + quest_id).is(":visible")) {
      if ($(this).val().length > 1) {
        $("#" + custom + "question_add_group_btn_" + quest_id).removeClass("disabled");
      } else {
        $("#" + custom + "question_add_group_btn_" + quest_id).addClass("disabled");
      }
    }
    if ($(this).val().length > 0) {
      $("#" + custom + "question_add_btn_" + quest_id).removeClass("disabled");
    } else {
      $("#" + custom + "question_add_btn_" + quest_id).addClass("disabled");
    }
  });
  $("#adv_report_data_type").off('select2:unselecting').on('select2:unselecting',
      function (e) {
        if ($(this).select2('data').length <= 1) {
          e.preventDefault();
          return false;
        }
      }
  );
  $(".signup-question").off("click").on("click", function (e) {
    var quest_id = $(this).data("quest_id");
    var div_id = ($(this).data("custom") ? 'custom_signup_question_div_' : 'signup_question_div_');
    var remove_class = ($(this).data("custom") ? "list-item-custom-" : 'list-item-signup-') + quest_id;
    if (this.checked) {
      $("#" + div_id + quest_id).slideDown();
    } else {
      // Remove all selected groups with this question
      // -- this will remove all combinations as well
      $("." + remove_class).find(".remove-option-group").click();

      $("#" + div_id + quest_id).slideUp();
    }
  });
  $(".question-option-add-btn").off("click").on("click", function (e) {
    e.preventDefault();
    $(this).blur();
    var quest_id = $(this).data("quest_id");
    var custom = $(this).data("custom");
    custom = custom ? custom : ""; // Make sure if undefined we set to empty string
    // Get all selected options
    // Add each one as a separate option
    var oplist = $("#" + custom + "question_options_" + quest_id).val();

    for (var z = 0; z < oplist.length; z++) {

      // Check if this question allows user to select multile answers
      var is_multi = false;
      if ($("#" + custom + "question_options_" + quest_id).data("multi") == "yes") {
        is_multi = true;
      }
      if (custom != "") {
        addQuestionOption(quest_id, true, oplist[z], is_multi, false, '');
      } else {
        addQuestionOption(quest_id, false, oplist[z], is_multi, false, '');
      }
    }
    // Disable options in select2 dropdown
    $("#" + custom + "question_options_" + quest_id).val('').change();
  });
  $(".question-option-add-group-btn").off("click").on("click", function (e) {
    e.preventDefault();
    $(this).blur();
    var custom = $(this).data("custom");
    custom = custom ? custom : ""; // Make sure if undefined we set to empty string
    // Get all selected options
    document.add_group_modal_quest_id = $(this).data("quest_id");
    document.add_group_modal_options = $("#" + custom + "question_options_" + document.add_group_modal_quest_id).val();
    document.add_group_modal_custom = custom;
    // Prompt for "Group Name"
    $("#group-name-modal").modal("show");
  });
  $('#group-name-modal').off("shown.bs.modal").on('shown.bs.modal', function (e) {
    $("#group_name_input").focus();
  });
  $('#group-name-modal').off("hidden.bs.modal").on('hidden.bs.modal', function (e) {
    document.add_group_modal_quest_id = undefined;
    document.add_group_modal_options = undefined;
    document.add_group_modal_custom = undefined;
    $("#group_name_input").val("");
  });
  $("#add_group_name_button").off("click").on("click", function (e) {
    var custom = document.add_group_modal_custom;
    var group_name = $("#group_name_input").val();

    if (group_name != "" && document.add_group_modal_quest_id && document.add_group_modal_options) {
      var is_multi = false;
      if ($("#" + custom + "question_options_" + document.add_group_modal_quest_id).data("multi") == "yes") {
        is_multi = true;
      }
      // Add as a group
      if (custom != "") {
        addQuestionOption(document.add_group_modal_quest_id, true, document.add_group_modal_options, is_multi, true, group_name);
      } else {
        addQuestionOption(document.add_group_modal_quest_id, false, document.add_group_modal_options, is_multi, true, group_name);
      }

      $("#" + custom + "question_options_" + document.add_group_modal_quest_id).val('').change();
      // Close modal
      $('#group-name-modal').modal("hide");
      document.add_group_modal_quest_id = undefined;
      document.add_group_modal_options = undefined;
      document.add_group_modal_custom = undefined;
    }
  });
  $(".question-option-add-ranged-btn").off("click").on("click", function (e) {
    e.preventDefault();
    $(this).blur();
    var quest_id = $(this).data("quest_id");
    var custom = $(this).data("custom");
    custom = custom ? custom : ""; // Make sure if undefined we set to empty string
    var range_start = $("#" + custom + "question_range_start_" + quest_id).val();
    var range_end = $("#" + custom + "question_range_end_" + quest_id).val();
    var range_text = $(this).data("range_text");
    if (range_start != "" && range_end != "") {
      if (custom != "") {
        addQuestionOption(quest_id, true, {
          range: true,
          start: range_start,
          end: range_end,
          range_text: range_text
        }, false, false, "");
      } else {
        addQuestionOption(quest_id, false, {
          range: true,
          start: range_start,
          end: range_end,
          range_text: range_text
        }, false, false, "");
      }
      $("#" + custom + "question_range_start_" + quest_id).val("");
      $("#" + custom + "question_range_end_" + quest_id).val("");
      $("#" + custom + "question_range_start_" + quest_id).change();
    }
    // // Get all selected options
    // // Add each one as a separate option
    // var oplist = $("#"+custom+"question_options_"+quest_id).val();

    // for(var z=0;z<oplist.length;z++)
    // {
    //   // Check if this question allows user to select multile answers
    //   var is_multi = false;
    //   if($("#"+custom+"question_options_"+quest_id).data("multi") == "yes")
    //   {
    //     is_multi = true;
    //   }
    //   if(custom != "")
    //   {
    //     addQuestionOption(quest_id,true,oplist[z],is_multi,false,'');
    //   }
    //   else
    //   {
    //     addQuestionOption(quest_id,false,oplist[z],is_multi,false,'');
    //   }
    // }
  });
  $(".question-range-input").off("keyup change").on("keyup change", function (e) {
    var quest_id = $(this).data("quest_id");
    if ($("#question_range_start_" + quest_id).val() != "" && $("#question_range_end_" + quest_id).val() != "") {
      $("#question_add_range_btn_" + quest_id).removeClass("disabled");
    } else {
      $("#question_add_range_btn_" + quest_id).addClass("disabled");
    }
  });
  $("#question-option-add-ranged-btn").off("click").on("click", function (e) {
    e.preventDefault();
    $(this).blur();
    var quest_id = $(this).data("quest_id");
    var custom = $(this).data("custom");
    custom = custom ? custom : ""; // Make sure if undefined we set to empty string
    // Get ranage values
    var range_start = $("#question_range_start_" + quest_id).val();
    var range_end = $("#question_range_end_" + quest_id).val();
    // Add range to filter options
    addQuestionOption(quest_id, false, {
      range: true,
      start: range_start,
      end: range_end
    }, false, false, '');
    // Clear
    $("#question_range_start_" + quest_id).val("");
    $("#question_range_end_" + quest_id).val("");
    $("#question_range_start_" + quest_id).change();
  });

// ZZZ


  $(document).off("click", ".remove-option-group").on("click", ".remove-option-group", function (e) {
    e.preventDefault();
    var remove_index = $(this).data("option_group_index");
    if (document.selected_option_groups[remove_index] && !document.selected_option_groups[remove_index].removed) {
      // Return the selected options to enabled in the dropdowns
      var quest_id = document.selected_option_groups[remove_index].quest_id;

      var oplist = document.selected_option_groups[remove_index].options;

      var custom = document.selected_option_groups[remove_index].is_custom ? "custom_" : "";

      $("#" + custom + "question_options_" + quest_id).val('').change();
      // Remove any combinations that use this
      if (document.option_group_combinations) {
        var combo_ids = Object.keys(document.option_group_combinations);
        for (var i = 0; i < combo_ids.length; i++) {
          var index_list = document.option_group_combinations[combo_ids[i]];
          if (index_list) {
            var remove_combo = false;
            for (var x = 0; x < index_list.length; x++) {
              if (parseInt(index_list[x]) === parseInt(remove_index)) {
                remove_combo = true;
                break;
              }
            }
            if (remove_combo) {
              $(".remove-combo-btn[data-combo_id='" + combo_ids[i] + "']").click();
            }
          }
        }
      }

      // Remove from mapping
      var map_id = (document.selected_option_groups[remove_index].is_custom ? "custom-" : "signup-") + quest_id;
      if (document.question_id_to_option_group_map[map_id]) {
        var unmapIndex = document.question_id_to_option_group_map[map_id].indexOf(remove_index);
        document.question_id_to_option_group_map[map_id].splice(unmapIndex, 1);
      }

      // Unset
      document.selected_option_groups[remove_index] = {removed: true};
      // Can not set to "undefined" either
      // Can not splice -- document.selected_option_groups.splice(remove_index, 1);
    }
    $(this).closest("li").find("input").prop("checked", false);
    // Disable the add combo button if there are not enough options selected anymore
    if ($(".verify-group-combo:checked").length > 0) {
      $("#add-combo-btn").removeClass("disabled");
    } else {
      $("#add-combo-btn").addClass("disabled");
    }
    $(this).closest("li").hide();

    setTimeout(function () {
      checkIfAutoCombineCanRun();
    }, 50);
  });

  $(document).off("click", ".verify-group-combo").on("click", ".verify-group-combo", function (e) {
    var option_group_index = $(this).data("option_group_index");
    if (this.checked) {
      // Make sure this quest_id is not already selected in aother option group (unless custom question allows multiple selection)
      var my_uid = document.selected_option_groups[option_group_index].uid;
      var unique_question_part = my_uid.split("!").slice(0, -1).join("!") + "!";
      var have_matching_question = false;
      if (!document.selected_option_groups[option_group_index].is_multi) {
        for (var z = 0; z < document.selected_option_groups.length; z++) {
          if (z != option_group_index && document.selected_option_groups[z] && !document.selected_option_groups[z].removed) {
            if (document.selected_option_groups[z].uid.indexOf(unique_question_part) != -1 && !document.selected_option_groups[z].is_multi) {
              if ($(".verify-group-combo[data-option_group_index='" + z + "']").prop("checked")) {
                have_matching_question = true;
                break;
              }
            }

          }
        }
      }
      if (!have_matching_question) {
        // -- added when button is pressed
      } else {
        e.preventDefault();
        document.comboExistsErrorCount++;
        document.comboExistsErrorTimestamp = (new Date()).getTime();
        var error_message = i18n_string("advanced_reports.you_can_no_have_combo_from_same_question", "You can not have combinations from the same question. You should instead group them together in the first step.");
        $("#add-combination-error-message").html(error_message).fadeIn('fast');
        setTimeout(function () {
          if ($("#add-combination-error-message").is(":visible")) {
            var timeDiff = (new Date()).getTime() - document.comboExistsErrorTimestamp;
            if (timeDiff > 7000) {
              $("#add-combination-error-message").fadeOut('fast');
              document.comboExistsErrorCount = 0;
            }
          }
        }, 8000);
      }
    } else {
      // Nothing to do here!
    }
    if (!e.isDefaultPrevented()) {
      if ($(".verify-group-combo:checked").length > 0) {
        $("#add-combo-btn").removeClass("disabled");
      } else {
        $("#add-combo-btn").addClass("disabled");
      }
    }

  });

  window.sort_option_last_clicks = function(a, b) {
    return ($(b).data('last_click')) < ($(a).data('last_click')) ? 1 : -1;
  }

  window.sort_option_order_data = function(a, b) {
    return ($(b).data('order')) < ($(a).data('order')) ? 1 : -1;
  }

  document.addComboNetworkCheck = [];
  document.addComboNetworkCheckIndex = 0;
  document.comboExistsErrorCount = 0;
  document.comboExistsErrorTimestamp = 0;
  $("#add-combo-btn").off("click").on("click", function (e) {
    e.preventDefault();
    // Add to document.option_group_combinations array
    if ($(".verify-group-combo:checked").length > 0) {
      var index_list = [];
      var combo_option_names = [];
      $(".verify-group-combo:checked").sort(sort_option_last_clicks).each(function (e) {
        var option_group_index = $(this).data("option_group_index");
        var option_group_name = $(this).data("option_group_name");
        index_list.push(option_group_index);
        combo_option_names.push('<span class="display_option_name_' + option_group_index + '">' + escapeHtml(option_group_name) + '</span>');
      });
      // TODO: find way to speed up this - Future Enhancement
      // Make sure this exact list of indexes is not used - before adding to document.option_group_combinations
      var index_list_for_id = index_list.slice().sort();
      var combo_id = "c" + index_list_for_id.join("-");
      if (document.option_group_combinations[combo_id]) {
        $(this).blur();
        document.comboExistsErrorCount++;
        document.comboExistsErrorTimestamp = (new Date()).getTime();
        var countText = "";
        if (document.comboExistsErrorCount > 1) {
          countText = " (" + document.comboExistsErrorCount + ")";
        }
        var error_message = i18n_string("advanced_reports.that_combo_has_already_been_added","That combination has already been added");
        $("#add-combination-error-message").html(error_message + countText).fadeIn('fast');
        setTimeout(function () {
          if ($("#add-combination-error-message").is(":visible")) {
            var timeDiff = (new Date()).getTime() - document.comboExistsErrorTimestamp;
            if (timeDiff > 7000) {
              $("#add-combination-error-message").fadeOut('fast');
              document.comboExistsErrorCount = 0;
            }
          }
        }, 8000);
        return false;
      }
      document.option_group_combinations[combo_id] = index_list;

      // Ajax call to get sample size + mean score
      var combo_json = JSON.stringify(index_list);
      var option_group_json = JSON.stringify(document.selected_option_groups);
      if (document.addComboNetworkCheck.length < 40) {
        document.addComboNetworkCheck[document.addComboNetworkCheckIndex] = (new Date()).getTime();
        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          myNetworkCheckIndex: document.addComboNetworkCheckIndex,
          type: "POST",
          url: document.advanced_report_data_combo_url,
          data: {
            combo_array: combo_json,
            option_groups: option_group_json
          },
          success: function (ret_data) {
            // var ret_data = JSON.parse(data);
            // Add row to table
            if (ret_data && ret_data.sample_size !== undefined) {
              processNewComboSetup(combo_id, combo_option_names, index_list, ret_data, this.myNetworkCheckIndex);
            } else {
              // No data/incorrect data
            }
            $("#add-combo-btn").addClass("disabled");
            // Uncheck previous option groups
            $(".verify-group-combo").each(function () {
              this.checked = false;
            });
          }
        });
        document.addComboNetworkCheckIndex++;
      } else {
        addComboWithoutSampleData(combo_id, combo_option_names, index_list);
        $("#add-combo-btn").addClass("disabled");
        // Uncheck previous option groups
        $(".verify-group-combo").each(function () {
          this.checked = false;
        });
      }
    }
    updateForSampleData();
    $(this).blur();
  });
  $(document).off("click", ".remove-combo-btn").on("click", ".remove-combo-btn", function (e) {
    e.preventDefault();
    var combo_id = $(this).data("combo_id");
    if (combo_id) {
      document.option_group_combinations[combo_id] = undefined;
      $("#combo-row-" + combo_id).hide();
    }
    updateForSampleData();
  });

  $("#edit-advanced-report-form").off("submit").on("submit", function (e) {
    var error_msg = "";
    // There should be a name set
    if ($("#adv_report_name").val() == "") {
      error_msg += i18n_string("advanced_reports.please_enter_a_name_for_the_report", "Please enter a name for the report.")+"<br />";
    }
    // There should be at least one combo
    var count_combos = 0;
    if (document.option_group_combinations) {
      var combo_ids = Object.keys(document.option_group_combinations);
      for (var i = 0; i < combo_ids.length; i++) {
        if (document.option_group_combinations[combo_ids[i]]) {
          count_combos++;
        }
      }
    }
    if (count_combos == 0) {
      error_msg += i18n_string("advanced_reports.select_at_least_one_combo", "You must select at least one option combination to save the report.")+"<br />";
    }

    if (error_msg != "") {
      e.preventDefault();
      error_msg = error_msg.substr(0, error_msg.length - 6);
      dojsalert(error_msg);
      dojsalert(error_msg);
    } else {
      // Everything is ok
      // Save the data arrays as JSON and save to the hidden variables
      var adv_option_combos_json = JSON.stringify(document.option_group_combinations);
      var adv_option_groups_json = JSON.stringify(document.selected_option_groups);
      $("#adv_report_option_combos").val(adv_option_combos_json);
      $("#adv_report_option_groups").val(adv_option_groups_json);
    }
  });

////////////////////////////////////////////////////////////////////////////////
// INIT these arrays if editing -- check adv_report_id > 0
////////////////////////////////////////////////////////////////////////////////

  document.loading_combo_list = {};
  document.loading_combo_list_index = 0;
  document.current_loading_combo = undefined;
  if ($("#adv_report_id").val() > 0) {
    document.temp_selected_option_groups = JSON.parse($("#adv_report_option_groups").val());
    document.temp_option_group_combinations = JSON.parse($("#adv_report_option_combos").val());
  }
  window.loadReportFromHiddenInputs = function() {
    document.option_group_objects = undefined;
    if ($("#adv_report_id").val() > 0) {
      // var temp_selected_option_groups = JSON.parse($("#adv_report_option_groups").val());
      // var temp_option_group_combinations = JSON.parse($("#adv_report_option_combos").val());

      for (var x = 0; x < document.temp_selected_option_groups.length; x++) {
        if (document.temp_selected_option_groups[x] && !document.temp_selected_option_groups[x].removed) {
          var oplist = document.temp_selected_option_groups[x].options;
          var custom = document.temp_selected_option_groups[x].is_custom ? "custom_" : "";
          var quest_id = document.temp_selected_option_groups[x].quest_id;
          $("#" + custom + "signup_question_check_" + quest_id).prop("checked", true);
          $("#" + custom + "signup_question_div_" + quest_id).show();

          $("#" + custom + "question_options_" + quest_id).val('').change();
          var send_option = oplist;
          if (!document.temp_selected_option_groups[x].is_group && !document.temp_selected_option_groups[x].options.range) {
            send_option = oplist[0];
          }
          // Validate that this question still exists
          var quest_check_error = "";
          var quest_search_id = "";
          if (document.temp_selected_option_groups[x].is_custom) {
            quest_search_id = "#" + custom + "question_options_" + quest_id;
          } else {
            quest_search_id = "#question_options_" + quest_id;
          }
          if (!document.temp_selected_option_groups[x].options.range) {
            if (quest_search_id.length > 0 && $(quest_search_id).length > 0) {
              // Make sure options are still valid
              var missing_option = false;
              if (document.temp_selected_option_groups[x].is_group) {
                for (var z = 0; z < send_option.length; z++) {
                  if ($(quest_search_id).find("option[value='" + send_option[z] + "']").length == 0) {
                    missing_option = true;
                    break;
                  }
                }
              } else {
                if ($(quest_search_id).find("option[value='" + send_option + "']").length == 0) {
                  missing_option = true;
                }
              }
              if (missing_option) {
                quest_check_error = i18n_string("advanced_reports.error_one_or_more_options_removed", "One or more selected options that this report used were removed or are not available from the account, and you can no longer edit this report. You can continue to run the report or create a new report.");
              }
            } else {
              quest_check_error = i18n_string("advanced_reports.error_one_or_more_options_removed", "One or more selected options that this report used were removed or are not available from the account, and you can no longer edit this report. You can continue to run the report or create a new report.");
            }
          }

          if (quest_check_error.length > 0) {
            if ($("#is_duplicate_report").val()) {
              document.selected_option_groups.push({removed: true});
            } else {
              clearTimeout(checkComboLoadingStatus);
              $("#advanced-creation-tools-btn").hide();
              $("#advanced-creation-tools-div").hide();
              $("#adv-editor-container-div").hide();
              $("#adv_report_loader").data("has_load_error", "yes");
              $("#adv_report_loader").css("background", "#FFFFFF");
              var heading_text = i18n_string("advanced_reports.unable_to_edit_report", "Unable to edit report.");
              var button_text = i18n_string("advanced_reports.create_new_reports", "Create New Report");
              $("#adv_report_loader").html('<div class="alert alert-danger text-left" style="height:auto;padding:75px 50px;"><h2 class="text-danger">'+heading_text+'</h2>' + quest_check_error + '<br><br><br><a class="btn btn-success" href="?edit=new">'+button_text+'</a></div>');
            }
          } else {
            addQuestionOption(quest_id, document.temp_selected_option_groups[x].is_custom, send_option, document.temp_selected_option_groups[x].is_multi, document.temp_selected_option_groups[x].is_group, document.temp_selected_option_groups[x].group_name);
          }
        } else if (document.temp_selected_option_groups[x] && document.temp_selected_option_groups[x].removed) {
          document.selected_option_groups.push({removed: true});
        }
      }
      // Need to change to only load one combo at a time ------------
      var combo_ids = Object.keys(document.temp_option_group_combinations);
      var final_temp_option_group_combinations = {};
      var did_conversion = false;
      for (var i = 0; i < combo_ids.length; i++) {
        // Update combo ids to all be strings
        var combo_id_final = combo_ids[i];
        if (combo_id_final.charAt(0) != "c") {
          final_temp_option_group_combinations["c" + combo_id_final] = document.temp_option_group_combinations[combo_id_final];
          combo_id_final = "c" + combo_id_final;
          did_conversion = true;
        }
        document.loading_combo_list[combo_id_final] = "loading";
      }
      if (did_conversion) {
        document.temp_option_group_combinations = final_temp_option_group_combinations;
      }

      // Remove any that are missing an option
      var final2_temp_option_group_combinations = {};
      var combo_ids2 = Object.keys(document.temp_option_group_combinations);
      for (var i = 0; i < combo_ids2.length; i++) {
        var index_list = document.temp_option_group_combinations[combo_ids2[i]];
        var missing = false;
        if (index_list) {
          for (var x = 0; x < index_list.length; x++) {
            if (!$(".verify-group-combo[data-option_group_index='" + index_list[x] + "']").length) {
              missing = true;
              break;
            }
          }
        }
        if (!missing) {
          final2_temp_option_group_combinations[combo_ids2[i]] = document.temp_option_group_combinations[combo_ids2[i]];
        }
      }
      document.temp_option_group_combinations = final2_temp_option_group_combinations;

      // Start check to wait for combos to finish loading
      $("#adv-editor-container-div").addClass("blur-element");
      setTimeout(checkComboLoadingStatus, 500);
    }
  }

  window.checkComboLoadingStatus = function() {
    if (!document.option_group_objects) {
      document.option_group_objects = {};
      $(".verify-group-combo").each(function () {
        var $thisObj = $(this);
        var group_index = parseInt($thisObj.data("option_group_index"));
        document.option_group_objects[group_index] = $thisObj;
      });
    }
    // Finish this check all and then hide loader once complete
    var combo_ids = Object.keys(document.loading_combo_list);
    var still_loading = false;
    var load_new = false;
    if (!document.current_loading_combo || document.loading_combo_list[document.current_loading_combo] != "loading") {
      load_new = true;
    }

    var p_total_combos = combo_ids.length;
    var p_completed_combos = 0;
    for (var zz = 0; zz < combo_ids.length; zz++) {
      if (document.loading_combo_list[combo_ids[zz]] != "loading") {
        p_completed_combos++;
      } else {
        still_loading = true;
      }
    }
    var percent_done = ((p_completed_combos / p_total_combos) * 100).toFixed(0);
    $("#adv_report_percent_loaded").html(percent_done + "%");

    if (load_new) {
      //for (var i = 0; i < combo_ids.length; i++) {
      var num_loaded_this_round = 0;
      for (; document.loading_combo_list_index < combo_ids.length; document.loading_combo_list_index++) {
        var i = document.loading_combo_list_index;
        if (document.loading_combo_list[combo_ids[i]] == "loading") {
          document.current_loading_combo = combo_ids[i];
          // Do loading
          // $(".verify-group-combo").prop("checked", false);
          var index_list = document.temp_option_group_combinations[document.current_loading_combo];
          var combo_option_names = [];
          var num_option_groups_checked = 0;
          if (index_list) {
            for (var x = 0; x < index_list.length; x++) {
              var $el = document.option_group_objects[index_list[x]];//$(".verify-group-combo[data-option_group_index='" + index_list[x] + "']");
              if ($el != undefined && $el.length) {
                // $(".verify-group-combo[data-option_group_index='" + index_list[x] + "']").prop("checked", true).change();
                var option_group_name = $el.data("option_group_name");
                combo_option_names.push('<span class="display_option_name_' + index_list[x] + '">' + escapeHtml(option_group_name) + '</span>');

                num_option_groups_checked++;
              }

            }
          }
          if (num_option_groups_checked > 0) {
            // $("#add-combo-btn").click();

            var index_list_for_id = index_list.slice().sort();
            var combo_id = "c" + index_list_for_id.join("-");
            if (!document.option_group_combinations[combo_id]) {
              document.option_group_combinations[combo_id] = index_list;
              // addComboWithoutSampleData(combo_id, combo_option_names, index_list);
              var load_text = i18n_string("advanced_reports.load", "(load)");
              var ret_data_no_sample = {
                sample_size: '<span class="data-content-not-loaded-sample" id="data-content-not-loaded-sample-' + combo_id + '" data-combo_id="' + combo_id + '">Not loaded <a href="#" class="data-content-not-loaded-btn" data-combo_id="' + combo_id + '">'+load_text+'</a></span>',
                sample_too_small: '<span class="data-content-not-loaded-score" id="data-content-not-loaded-score-' + combo_id + '" data-combo_id="' + combo_id + '">Not loaded <a href="#" class="data-content-not-loaded-btn" data-combo_id="' + combo_id + '">'+load_text+'</a></span>'
              };
              // var row_html = createComboRowHTML(combo_id, combo_option_names, index_list, ret_data_no_sample);
              // $("#group_combo_table_body").append(row_html);
              var row_dom = createComboRowDOM(combo_id, combo_option_names, index_list, ret_data_no_sample);
              document.getElementById("group_combo_table_body").appendChild(row_dom);
              document.loading_combo_list[combo_id] = "completed";
            }
            // Make sure not to load anymore until this one finishes
            num_loaded_this_round++;
            if (num_loaded_this_round > 50) {
              load_new = false;
              break; // No need to keep looping here
            }
          } else {
            document.loading_combo_list[combo_ids[i]] = "removed";
          }

        }
      }
    }

    if (!still_loading) {
      if ($("#adv_report_loader").data("has_load_error") != "yes") {
        doPostComboLoadingInit();
        $("#adv-editor-container-div").removeClass("blur-element");
        $("#adv_report_loader").hide();
      }
    } else {
      var timeout_ms = 5;
      // var timeout_ms = 200;
      // if(document.addComboNetworkCheck && document.addComboNetworkCheck.length >= 40) {
      //   timeout_ms = 10;
      // }
      setTimeout(checkComboLoadingStatus, timeout_ms);
    }
  }

  $(document).off("click", ".edit-group-name-btn").on("click", ".edit-group-name-btn", function (e) {
    e.preventDefault();
    var $parent = $(this).closest(".list-group-item");
    var current_name = $parent.find(".verify-group-combo").data("option_group_name");
    $parent.find(".group-name-edit-input").val(current_name);
    $parent.find(".group-name-display").hide();
    $parent.find(".group-name-edit").show();
  });
  $(document).off("click", ".group-name-edit-cancel-btn").on("click", ".group-name-edit-cancel-btn", function (e) {
    e.preventDefault();
    var $parent = $(this).closest(".list-group-item");
    $parent.find(".group-name-edit").hide();
    $parent.find(".group-name-display").show();
  });
  $(document).off("click", ".group-name-edit-confirm-btn").on("click", ".group-name-edit-confirm-btn", function (e) {
    e.preventDefault();
    var $parent = $(this).closest(".list-group-item");
    // Change Name
    var new_val = $parent.find(".group-name-edit-input").val();

    var checkboxObj = $parent.find(".verify-group-combo");
    var option_index = checkboxObj.data("option_group_index");

    if (new_val != "" && option_index != undefined) {
      document.selected_option_groups[option_index].group_name = new_val;
      checkboxObj.data("option_group_name", new_val);
      $parent.find(".group-name-display-text").html(escapeHtml(new_val));
    }
    $parent.find(".group-name-edit").hide();
    $parent.find(".group-name-display").show();
  });
  $(document).off("click", ".adv-report-delete-button").on("click", ".adv-report-delete-button", function (e) {
    if (!confirm(i18n_string("advanced_reports.confirm_advanced_report_delete", "Are you sure you want to delete this report? It will be removed from all folders assigned as well."))) {
      e.preventDefault();
    }
  });
  $(document).off("click", ".advanced-report-demo-btn").on("click", ".advanced-report-demo-btn", function (e) {
    e.preventDefault();
    $("#advanced_report_upgrade_modal").modal("show");
  });
  $("#additional_schools_select2").select2();
  $(document).off("click", ".edit-option-group").on("click", ".edit-option-group", function (e) {
    e.preventDefault();
    var option_group_index = $(this).data("option_group_index");
    var option_type = $(this).data("option_type");
    if (option_group_index >= 0) {
      $("#edit_option_group_type_group").hide();
      $("#edit_option_group_type_range").hide();
      $("#edit_option_group_type_single").hide();
      $("#edit_option_group_modal_option_group_index").val(option_group_index);
      $("#edit_option_group_modal_option_group_type").val(option_type);
      $("#edit_option_group_type_" + option_type).show();
      var option_data = document.selected_option_groups[option_group_index];
      if (option_data) {
        var quest_object;
        if (option_data.is_custom) {
          quest_object = $("#custom_question_options_" + option_data.quest_id);
        } else {
          quest_object = $("#question_options_" + option_data.quest_id);
        }
        var quest_name = quest_object.data("quest_name_short");
        $("#edit_option_group_field_label").html(quest_name);
        $("#edit_option_group_type_group_name").val(option_data.group_name);
        if (option_type == "group") {
          var modal_select_object = $("#edit_option_group_type_group_select");
          modal_select_object.empty().change();
          quest_object.find("option").each(function () {
            var is_selected = false;
            var this_value = $(this).val();
            if (option_data && option_data.options && option_data.options.indexOf(this_value) > -1) {
              is_selected = true;
            }
            modal_select_object.append($('<option>', {
              value: this_value,
              text: $(this).text(),
              selected: is_selected
            }));
          });
        } else if (option_type == "range") {
          $("#edit_option_group_type_range_start").val(option_data.options.start);
          $("#edit_option_group_type_range_end").val(option_data.options.end);
        } else {

        }

        $("#edit_option_group_modal").modal("show");
      }
    }
  });
  $("#edit_option_group_modal").off("shown.bs.modal").on("shown.bs.modal", function (e) {
    init_select2("#edit_option_group_type_group_select", {});
  });
  $("#edit_option_group_modal_save_btn").off("click").on("click", function (e) {
    e.preventDefault();
    var option_group_index = $("#edit_option_group_modal_option_group_index").val();
    var option_type = $("#edit_option_group_modal_option_group_type").val();
    if (option_group_index != "" && option_group_index != -1) {
      var option_data = document.selected_option_groups[option_group_index];
      if (option_data) {

        if (option_type == "group") {
          var modal_select_object = $("#edit_option_group_type_group_select");
          var group_name = $("#edit_option_group_type_group_name").val();
          var error_msg = "";
          if (modal_select_object.find("option:selected").length < 2) {
            error_msg = i18n_string('advanced_reports.you_must_select_at_least_two_options_for_a_group', "You must select at least two options for a group.");
          } else if (group_name == "") {
            error_msg = i18n_string('advanced_reports.the_group_name_can_not_be_blank', "The group name can not be blank.");
          }
          if (error_msg == "") {
            $("#edit_option_group_modal_msg").html("");
            // Save changes
            document.selected_option_groups[option_group_index].group_name = group_name;
            document.selected_option_groups[option_group_index].options = modal_select_object.val();
            // Update Displayed Name
            updateDisplayedNameForOptionGrouop(option_group_index);
            // Update sample data and close modal
            updateSampleDataForAnyComboUsingOption(option_group_index);
            $("#edit_option_group_modal_option_group_index").val(-1);
            $("#edit_option_group_modal").modal("hide");
          } else {
            $("#edit_option_group_modal_msg").html('<div class="alert alert-danger">' + error_msg + '</div>');
          }
        } else if (option_type == "range") {
          var range_start = $("#edit_option_group_type_range_start").val();
          var range_end = $("#edit_option_group_type_range_end").val();
          var error_msg = "";
          if (range_start > range_end) {
            error_msg = i18n_string('advanced_reports.the_range_start_can_not_be_larger_then_thd_the_end', "The range start can not be larger then thd the end value.");
          }
          if (error_msg == "") {
            $("#edit_option_group_modal_msg").html("");
            // Save changes
            document.selected_option_groups[option_group_index].options.start = range_start;
            document.selected_option_groups[option_group_index].options.end = range_end;
            // Update Displayed Name
            // <input type="checkbox" data-option_group_index="2" data-option_group_name="Years range 1 - 20" class="verify-group-combo mr-1">
            var display_name = $(".verify-group-combo[data-option_group_index='" + option_group_index + "']").data("option_group_name");
            display_name_parts = display_name.split(" range ");
            display_name = display_name_parts[0] + " range " + range_start + " - " + range_end;
            $(".display_option_name_" + option_group_index).html(escapeHtml(display_name));
            $(".verify-group-combo[data-option_group_index='" + option_group_index + "']").data("option_group_name", display_name);
            // Update sample data and close modal
            updateSampleDataForAnyComboUsingOption(option_group_index);
            $("#edit_option_group_modal").modal("hide");
          } else {
            $("#edit_option_group_modal_msg").html('<div class="alert alert-danger">' + error_msg + '</div>');
          }
        } else {

        }
      }
    }
  });
  document.last_click_increment = 0;
  $(document).off("change", "[type=checkbox]").on("change", "[type=checkbox]", function () { // always use change event
    if (this.checked) {
      document.last_click_increment++;
      $(this).data("last_click", document.last_click_increment);
    } else {
      $(this).data("last_click", -1);
    }
  });
  $(".multi-combiner-signup-question").off("click").on("click", function (e) {
    var order_wrapper_obj = $(this).closest("div").find(".order-input-wrapper");
    if (this.checked) {
      var max_val = 0;
      $(".multi-combiner-order-box:visible").each(function () {
        if ($(this).val() > max_val) {
          max_val = $(this).val();
        }
      });
      max_val++;
      order_wrapper_obj.find(".multi-combiner-order-box").val(max_val).change();
      order_wrapper_obj.show();
    } else {
      order_wrapper_obj.hide();
    }
  });
  $(".multi-combiner-order-box").off("change").on("change", function (e) {
    var checkbox_id = $(this).data("checkbox_id");
    $("#" + checkbox_id).data("order", $(this).val());
  });
  $(document).off("click", ".change-display-order-options-single").on("click", ".change-display-order-options-single", function (e) {
    e.preventDefault();
    var combo_id = $(this).data("combo_id");
    if (document.option_group_combinations[combo_id]) {
      $("#edit_option_display_order_single_combo_id").val(combo_id);
      var order_item_html = "";
      for (var i = 0; i < document.option_group_combinations[combo_id].length; i++) {
        var option_id = document.option_group_combinations[combo_id][i];
        if (document.selected_option_groups[option_id]) {
          var display_name = $(".verify-group-combo[data-option_group_index='" + option_id + "']").data("option_group_name");

          order_item_html += '<li data-option_id="' + option_id + '" class="sortable list-group-item"><span class="text-muted mr-2"><em class="fa fa-arrows"></em></span> ' + escapeHtml(display_name) + '</li>';
        }
      }
      $("#edit_option_display_order_single_sortable_area").html(order_item_html);
      $("#edit_option_display_order_single_sortable_area").sortable({
        // update: function (event, ui) {
        //   var option_id_order = [];
        //   $('#group_combo_table_body tr.allow-sort').each(function(idx) {
        //     var item_id= $(this).attr("id");
        //     item_id = item_id.replace("combo-row-","");
        //     combo_id_order.push(item_id);
        //   });
        //   var new_option_group_combinations = {};
        //   for(var i=0;i<combo_id_order.length;i++)
        //   {
        //     new_option_group_combinations[combo_id_order[i]] = document.option_group_combinations[combo_id_order[i]];
        //   }
        //   document.option_group_combinations = new_option_group_combinations;
        //
        // },
        items: '.sortable'
      });
      $("#edit_option_display_order_single_modal").modal("show");
    }
  });
  $("#edit_option_display_order_single_modal_save_btn").off("click").on("click", function (e) {
    e.preventDefault();
    var combo_id = $("#edit_option_display_order_single_combo_id").val();
    if (document.option_group_combinations[combo_id]) {
      var index_list = [];
      var combo_option_names = [];
      $('#edit_option_display_order_single_modal .sortable').each(function (idx) {
        var option_id = $(this).data("option_id");
        var display_name = $(".verify-group-combo[data-option_group_index='" + option_id + "']").data("option_group_name");
        index_list.push(option_id);
        combo_option_names.push('<span class="display_option_name_' + option_id + '">' + escapeHtml(display_name) + '</span>');
      });
      document.option_group_combinations[combo_id] = index_list;
      $("#display_combo_text_all_" + combo_id).html(combo_option_names.join(", "));
      $("#edit_option_display_order_single_modal").modal("hide");
    }
  });

  window.addQuestionOption = function(quest_id, is_custom, options, is_multi, is_group, group_name) {
    var quest_name = "";
    var display_option_name = "";
    var display_option_name_text = "";
    var remove_class = "";
    var custom = is_custom ? "custom_" : "";
    var option_type = "";
    if (is_custom) {
      quest_name = $("#" + custom + "question_options_" + quest_id).data("quest_name_short");
      remove_class = "list-item-custom-" + quest_id;
    } else {
      quest_name = $("#question_options_" + quest_id).data("quest_name_short");
      remove_class = "list-item-signup-" + quest_id;
    }
    var option_name_for_uid = "";
    if (is_group) {
      // display_option_name = '<span class="group-name-display ml-1"><span class="group-name-display-text">'+escapeHtml(group_name)+'</span>';
      // display_option_name += ' <a href="" class="edit-group-name-btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit group name"><em class="fa fa-pencil-square-o text-success" style=""></em></a>';
      // display_option_name += '</span>';
      // // Add rename widgets
      // display_option_name += '<span class="group-name-edit" style="display:none;">';
      // display_option_name += '<input type="text" value="'+escapeHtml(group_name)+'" class="group-name-edit-input form-control form-control-sm mr-1 ml-1" style="width:100px;" />';
      // display_option_name += ' <a href="" class="group-name-edit-confirm-btn mr-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Save name change"><em class="fa fa-check-square-o text-success" style=""></em></a>';
      // display_option_name += ' <a href="" class="group-name-edit-cancel-btn mr-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel"><em class="fa fa-times text-danger" style=""></em></a>';
      // display_option_name += '</span>';
      // display_option_name_text = escapeHtml(group_name);
      // // In tooltip have number and list them (X)
      // var option_tooltip_array = [];
      // for(var z=0;z<options.length;z++)
      // {
      //   option_tooltip_array.push($("#"+custom+"question_options_"+quest_id).find("option[value='"+options[z]+"']").text());
      // }
      // var option_str = option_tooltip_array.join(", ");
      // option_str = escapeHtml(option_str); //option_str.replace('"','&quot;');
      //
      // display_option_name += ' <a href="javascript:;" data-bs-toggle="tooltip" data-bs-placement="top" title="'+option_str+'">('+option_tooltip_array.length+')</a>';
      display_option_name = group_name;
      display_option_name_text = escapeHtml(group_name);
      option_type = "group";
      option_name_for_uid = options[0];
    } else if (options && options.range) {
      display_option_name = options.range_text + " range " + options.start + " - " + options.end;
      display_option_name_text = display_option_name;
      option_type = "range";
      option_name_for_uid = "range:" + options.start + ":" + options.end;
    } else {
      display_option_name = escapeHtml($("#" + custom + "question_options_" + quest_id).find("option[value='" + options + "']").text());
      display_option_name_text = display_option_name;
      options = [options];
      option_type = "single";
      option_name_for_uid = options[0];
    }
    // First value is either quest_id for signup_question or custom_question
    // Add to javascript data array
    var new_length = document.selected_option_groups.push({
      uid: (is_custom ? "custom" : "signup") + "!" + quest_id + "!" + option_name_for_uid,
      is_multi: is_multi,
      is_custom: is_custom,
      is_group: is_group,
      quest_id: quest_id,
      options: options,
      group_name: group_name
    });
    var last_index = new_length - 1;
    var map_id = (is_custom ? "custom-" : "signup-") + quest_id;
    if (!document.question_id_to_option_group_map[map_id]) {
      document.question_id_to_option_group_map[map_id] = []; // Make sure it is not undefined
    }
    document.question_id_to_option_group_map[map_id].push(last_index);

    display_option_name_text = display_option_name_text.replace('"', '&quot;');

    // Add HTML element to list
    var html_elem = '<li class="list-group-item justify-content-between ' + remove_class + '">';
    // display_option_name is already escaped for HTML
    html_elem += '  <span class="form-inline"><input type="checkbox" data-option_group_index="' + last_index + '" data-option_group_name="' + display_option_name_text + '" class="verify-group-combo mr-1" /> ' + escapeHtml(quest_name) + '&nbsp;<strong class="text-info">&gt;</strong>&nbsp;<span class="display_option_name_' + last_index + '">' + (display_option_name) + '</span></span>';
    html_elem += '  <span class="pull-right">';
    if (option_type == "group" || option_type == "range") {
      html_elem += '    <a href="" data-option_group_index="' + last_index + '" data-option_type="' + option_type + '" class="btn btn-sm btn-success edit-option-group"><span class="fa fa-pencil" aria-hidden="true"></span></a>';
    }
    html_elem += '    <a href="" data-option_group_index="' + last_index + '" class="btn btn-sm btn-danger remove-option-group"><span class="fa fa-times" aria-hidden="true"></span></a></span>';
    html_elem += '</li>';
    $("#selected_question_options_list").append(html_elem);
    initTooltips();

    // See if the auto-combine if possible
    setTimeout(function () {
      checkIfAutoCombineCanRun();
    }, 50);
  }

  window.updateForSampleData = function() {
    if ($("#adv_report_disabled").length) {
      var validcombo = 0;
      if (document.option_group_combinations) {
        var combo_ids = Object.keys(document.option_group_combinations);
        for (var i = 0; i < combo_ids.length; i++) {
          var index_list = document.option_group_combinations[combo_ids[i]];
          if (index_list) {
            validcombo++;
          }
        }
      }
      if (validcombo > 0) {
        $("#group_combo_table").addClass("promoter-dash-sample-chart");
      } else {
        $("#group_combo_table").removeClass("promoter-dash-sample-chart");
      }
    }
  }

//////////// ADV TOOLS
  $("#adv-multi-combiner-tool-start").off("click").on("click", function (e) {
    e.preventDefault();
    $("#adv-multi-combiner-tool").blur();
    var sizeNeeded = $("#multi_combiner_size").val();
    if ($(".multi-combiner-signup-question:checked").length == sizeNeeded || (sizeNeeded == "23" && $(".multi-combiner-signup-question:checked").length == 3)) {
      $("#adv-multi-combiner-tool-modal-error-msg").hide();
      var multi_combiner_size = $("#multi_combiner_size").val();
      $("#adv-multi-combiner-tool-start").prop("disabled", "disabled");
      $("#adv-multi-combiner-tool-modal #adv-multi-combiner-tool-checkboxes").hide();
      $("#adv-multi-combiner-tool-modal #adv-multi-combiner-tool-running").show();
      $(".multi-combiner-signup-question:checked").sort(sort_option_order_data).each(function () {
        var quest_id = $(this).data("quest_id");
        var custom_prefix = $(this).data("custom");
        if (!custom_prefix) {
          custom_prefix = "";
        }

        // Check question boxes
        if (!$("#" + custom_prefix + "signup_question_check_" + quest_id).is(":checked")) {
          $("#" + custom_prefix + "signup_question_check_" + quest_id).trigger("click");
        }

        // Select all options that are not already disabled
        $("#" + custom_prefix + "question_options_" + quest_id + " > option:enabled").prop("selected", "selected");
        init_select2("#" + custom_prefix + "question_options_" + quest_id, {});
        $("#" + custom_prefix + "question_options_" + quest_id).change();

        // Click on Add button for each thing
        $("#" + custom_prefix + "question_add_btn_" + quest_id).click();
      });

      // Do the combing fun
      var groups_temp = [];
      var index_list = [];
      var index_list2 = [];
      var index_list3 = [];
      var index_list_for_question = {};
      var last_index_number_for_question = 0;
      var combos_done_temp = {};
      $(".verify-group-combo").each(function () {
        var group_index = parseInt($(this).data("option_group_index"));
        var my_uid = document.selected_option_groups[group_index].uid;
        var unique_question_part = my_uid.split("!").slice(0, -1).join("!") + "!";
        var indexListNumber = 0;
        if (index_list_for_question[unique_question_part]) {
          indexListNumber = index_list_for_question[unique_question_part];
        } else {
          last_index_number_for_question++;
          indexListNumber = last_index_number_for_question;
          index_list_for_question[unique_question_part] = last_index_number_for_question;
        }
        if (indexListNumber == 1) {
          index_list.push(group_index);
        } else if (indexListNumber == 2) {
          index_list2.push(group_index);
        } else if (indexListNumber == 3) {
          index_list3.push(group_index);
        }
        groups_temp[group_index] = this;
      });

      document.bg_multicombine_z1 = 0;
      document.bg_multicombine_z2 = 0;
      document.bg_multicombine_z3 = 0;
      document.bg_index_list = index_list;
      document.bg_index_list2 = index_list2;
      document.bg_index_list3 = index_list3;
      document.bg_groups_temp = groups_temp;
      document.bg_combos_done_temp = combos_done_temp;
      document.bg_multi_combiner_size = multi_combiner_size;
      document.bg_multi_combiner_running = true;

      setTimeout(doMultiCombinerInBackground, 20);

      $("#adv-multi-combiner-tool-modal").modal("hide");
      $("#adv-editor-container-div").addClass("blur-element");
      $("#adv_report_multi_tool_inprogress").show();
      $("#adv-multi-combiner-tool").addClass("disabled");
      setTimeout(waitForCombosToFinishForMultiCombiner, 1000);
    } else {
      var error_string = i18n_string("advanced_reports.you_must_select_x_questions", "You must select 99 questions")
      var errorMsg = error_string.replace("99", "<strong>" + sizeNeeded + "</strong>");
      if (sizeNeeded == "23") {
        errorMsg = error_string.replace("99", "<strong>3</strong>");
      }
      $("#adv-multi-combiner-tool-modal-error-msg").html(errorMsg).fadeIn();
    }
  });
  // $("#advanced-creation-tools-btn").off("click").on("click", function (e) {
  //   e.preventDefault();
  //   $("#advanced-creation-tools-btn").blur();
  //   $("#advanced-creation-tools-div").slideToggle();
  // });

  window.waitForCombosToFinishForMultiCombiner = function() {
    if (document.addComboNetworkCheck) {
      var still_waiting = false;
      var currentTime = (new Date()).getTime();
      for (var i = 0; i < document.addComboNetworkCheckIndex; i++) {
        if (document.addComboNetworkCheck[i] > 0) {
          if (currentTime - document.addComboNetworkCheck[i] < 120000) // 2 minute timeout
          {
            still_waiting = true;
            break;
          }
        }
      }
      if (document.bg_multi_combiner_running) {
        still_waiting = true;
      }

      if (!still_waiting) {
        $("#adv-editor-container-div").removeClass("blur-element");
        $("#adv_report_multi_tool_inprogress").hide();
      } else {
        setTimeout(waitForCombosToFinishForMultiCombiner, 500);
      }
    }
  }

  window.updateDisplayedNameForOptionGrouop = function(option_group_index) {
    var option_data = document.selected_option_groups[option_group_index];
    $(".display_option_name_" + option_group_index).html(escapeHtml(option_data.group_name));
    $(".verify-group-combo[data-option_group_index='" + option_group_index + "']").data("option_group_name", option_data.group_name);
  }

  window.updateSampleDataForCombo = function(combo_id) {
    if (document.option_group_combinations[combo_id]) {
      var index_list = document.option_group_combinations[combo_id];
      var combo_json = JSON.stringify(index_list);
      var option_group_json = JSON.stringify(document.selected_option_groups);
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        url: document.advanced_report_data_combo_url,
        data: {
          combo_array: combo_json,
          option_groups: option_group_json
        },
        success: function (ret_data) {
          console.dir(ret_data);
          // var ret_data = JSON.parse(data);
          // Add row to table
          if (ret_data && ret_data.sample_size !== undefined) {
            var sampleCell = ret_data.sample_size;
            var meanCell = parseFloat(ret_data.average_total).toFixed(2);
            if (ret_data.sample_too_small) {
              meanCell = ret_data.sample_too_small;
            }
            $("#combo-row-" + combo_id + " td:nth-child(3)").html(sampleCell);
            $("#combo-row-" + combo_id + " td:nth-child(4)").html(meanCell);
          } else {
            var error_string = i18n_string('advanced_reports.unable_to_load', 'Unable to load');
            $("#combo-row-" + combo_id + " td:nth-child(3)").html(error_string);
            $("#combo-row-" + combo_id + " td:nth-child(4)").html(error_string);
          }

        }
      });
    }
  }

  window.updateSampleDataForAnyComboUsingOption = function(option_group_index) {
    if (document.option_group_combinations) {
      var combo_ids = Object.keys(document.option_group_combinations);
      for (var i = 0; i < combo_ids.length; i++) {
        var index_list = document.option_group_combinations[combo_ids[i]];
        if (index_list) {
          var updateCombo = false;
          for (var x = 0; x < index_list.length; x++) {
            if (parseInt(index_list[x]) === parseInt(option_group_index)) {
              updateCombo = true;
              break;
            }
          }
          if (updateCombo) {
            updateSampleDataForCombo(combo_ids[i]);
          }
        }
      }
    }
  }

  window.addComboWithoutSampleData = function(combo_id, combo_option_names, index_list) {
    var load_text = i18n_string("advanced_reports.load", "(load)");
    var ret_data_no_sample = {
      sample_size: '<span class="data-content-not-loaded-sample" id="data-content-not-loaded-sample-' + combo_id + '" data-combo_id="' + combo_id + '">Not loaded <a href="#" class="data-content-not-loaded-btn" data-combo_id="' + combo_id + '">'+load_text+'</a></span>',
      sample_too_small: '<span class="data-content-not-loaded-score" id="data-content-not-loaded-score-' + combo_id + '" data-combo_id="' + combo_id + '">Not loaded <a href="#" class="data-content-not-loaded-btn" data-combo_id="' + combo_id + '">'+load_text+'</a></span>'
    };
    processNewComboSetup(combo_id, combo_option_names, index_list, ret_data_no_sample, undefined);
  }

  window.processNewComboSetup = function(combo_id, combo_option_names, index_list, ret_data, myNetworkCheckIndex) {
    var row_html = createComboRowHTML(combo_id, combo_option_names, index_list, ret_data);

    var total_rows = $('#group_combo_table_body tr.combo-table-row').length;
    var combo_ids = Object.keys(document.option_group_combinations);
    var myIndex = combo_ids.indexOf(combo_id);
    if (total_rows == 0) {
      $("#group_combo_table_body").append(row_html);
    } else {
      var combo_before = combo_ids[myIndex - 1];
      if ($("#group_combo_table_body #combo-row-" + combo_before).length) {
        $("#group_combo_table_body #combo-row-" + combo_before).after(row_html);
      } else {
        var found_row_loc = false;
        for (z = myIndex - 2; z >= 0; z--) {
          combo_before = combo_ids[z];
          if ($("#group_combo_table_body #combo-row-" + combo_before).length) {
            $("#group_combo_table_body #combo-row-" + combo_before).after(row_html);
            found_row_loc = true;
            break;
          }
        }
        if (!found_row_loc) {
          $("#group_combo_table_body").append(row_html);
        }
      }
    }
    // if(myIndex < total_rows) {
    //   if(myIndex == 0){ myIndex = 1; }
    //   $("#group_combo_table_body tr.combo-table-row").eq(myIndex-1).after(row_html);
    // } else {
    //   $("#group_combo_table_body").append(row_html);
    // }
    if (!document.bg_multi_combiner_running) {
      doPostComboLoadingInit();
    }

    document.loading_combo_list[combo_id] = "completed";
    if (myNetworkCheckIndex !== undefined && document.addComboNetworkCheck[myNetworkCheckIndex]) {
      document.addComboNetworkCheck[myNetworkCheckIndex] = -1;
    }
  }

  window.createComboRowHTML = function(combo_id, combo_option_names, index_list, ret_data) {
    var row_html = '<tr class="combo-table-row allow-sort" id="combo-row-' + combo_id + '">';
    row_html += createComboRowHTMLCells(combo_id, combo_option_names, index_list, ret_data);
    row_html += '</tr>';
    return row_html;
  }

  window.createComboRowDOM = function(combo_id, combo_option_names, index_list, ret_data) {
    var cell_html = createComboRowHTMLCells(combo_id, combo_option_names, index_list, ret_data);
    // var row_html = '<tr class="combo-table-row allow-sort" id="combo-row-' + combo_id + '">';
    var row_dom = document.createElement("tr");
    row_dom.className = "combo-table-row allow-sort";
    row_dom.id = 'combo-row-' + combo_id + '';
    row_dom.innerHTML = cell_html;
    return row_dom;
  }

  window.createComboRowHTMLCells = function(combo_id, combo_option_names, index_list, ret_data) {
    var row_html = '<td align="center" valign="center" style="vertical-align: inherit;">';
    row_html += '  <em class="fa fa-arrows" style="font-size:20px;color:#cccccc;"></em>';
    row_html += '</td>';
    row_html += '<td>';
    row_html += '<span id="display_combo_text_all_' + combo_id + '">' + combo_option_names.join(", ") + '</span>';
    if (index_list.length > 1) {
      row_html += ' <a href="" class="change-display-order-options-single btn btn-sm btn-success" style="padding: 0px 4px;" data-combo_id="' + combo_id + '" data-bs-toggle="tooltip" data-bs-placement="top" title="Change display order of options"><em class="fa fa-arrows-h text-white" style=""></em></a>';
    }
    row_html += '</td>';
    row_html += '<td align="center">';
    row_html += ret_data.sample_size;
    row_html += '</td>';
    row_html += '<td align="center">';
    if (ret_data.sample_too_small) {
      row_html += ret_data.sample_too_small;
    } else {
      row_html += parseFloat(ret_data.average_total).toFixed(2);
    }
    row_html += '</td>';
    row_html += '<td align="center" width="35">';
    row_html += '<a href="" data-combo_id="' + combo_id + '" class="btn btn-sm btn-danger remove-combo-btn"><span class="fa fa-times" aria-hidden="true"></span></a>';
    row_html += '</td>';
    return row_html;
  }

  window.doPostComboLoadingInit = function() {
    // Enable sort on table rows
    // console.log("doPostComboLoadingInit:1");
    // Do not allow sorting if there are 1500 or more rows -- will lock up most browsers
    if ($("#group_combo_table_body tr").length < 1500) {
      $("#group_combo_table_body").sortable({
        update: function (event, ui) {
          var combo_id_order = [];
          $('#group_combo_table_body tr.allow-sort').each(function (idx) {
            var item_id = $(this).attr("id");
            item_id = item_id.replace("combo-row-", "");
            combo_id_order.push(item_id);
          });
          var new_option_group_combinations = {};
          for (var i = 0; i < combo_id_order.length; i++) {
            new_option_group_combinations[combo_id_order[i]] = document.option_group_combinations[combo_id_order[i]];
          }
          document.option_group_combinations = new_option_group_combinations;
        },
        items: 'tr.allow-sort'
      });
      // console.log("doPostComboLoadingInit:2");
      // Do not do the drag and drop cell size fix if there are 500 or more rows
      if ($("#group_combo_table_body tr").length < 500) {
        $("#group_combo_table_body td").each(function () {
          $(this).css("width", $(this).width() + 24); // Width + padding
        });
      }
    } else {
      // Disable Sorting Icon
      $("#group_combo_table_body .fa-arrows").hide();
    }
    // console.log("doPostComboLoadingInit:3");
    initTooltips();
    // console.log("doPostComboLoadingInit:4");
    if ($("#group_combo_table_body tr").length > 1) {
      $("#data-cleanup-btn").show();
    } else {
      $("#data-cleanup-btn").hide();
    }
  }

  window.doMultiCombinerInBackground = function() {
    var this_interation_count = 0;
    var this_interation_max = 15;
    var this_interation_count_nomatch = 0;
    var this_interation_max_nomatch = 100;
    var this_timeout_ms = 40;
    var index_list = document.bg_index_list;
    var index_list2 = document.bg_index_list2;
    var index_list3 = document.bg_index_list3;
    var groups_temp = document.bg_groups_temp;
    // var document.bg_combos_done_temp = document.bg_document.bg_combos_done_temp;
    var multi_combiner_size = document.bg_multi_combiner_size;

    if (multi_combiner_size == "2" || multi_combiner_size == "23") {
      this_interation_max = this_interation_max * 2;
      this_timeout_ms = 4;
    }
    if (!index_list) {
      index_list = [];
    }
    if (!index_list2) {
      index_list2 = [];
    }
    if (!index_list3) {
      index_list3 = [];
    }

    var percent = 0;
    if (multi_combiner_size == "2") {
      var z1_progress = document.bg_multicombine_z1 / index_list.length;
      var z2_progress = document.bg_multicombine_z2 / (index_list.length * index_list2.length);
      percent = ((z1_progress + z2_progress) * 100).toFixed(0);
    } else if (multi_combiner_size == "3") {
      var z1_progress = document.bg_multicombine_z1 / index_list.length;
      var z2_progress = document.bg_multicombine_z2 / (index_list.length * index_list2.length);
      var z3_progress = document.bg_multicombine_z3 / (index_list.length * index_list2.length * index_list3.length);
      percent = ((z1_progress + z2_progress + z3_progress) * 100).toFixed(0);
    }
    if (percent > 0) {
      $("#adv_report_multi_tool_inprogress_percent_loaded").html(percent + "%");
    }

    var z2_start = document.bg_multicombine_z2;
    var z3_start = document.bg_multicombine_z3;

    for (var z = document.bg_multicombine_z1; z < index_list.length; z++) {
      var i = index_list[z];
      var my_uid = document.selected_option_groups[i].uid;
      var unique_question_part = my_uid.split("!").slice(0, -1).join("!") + "!";
      for (var z2 = z2_start; z2 < index_list2.length; z2++) {
        var i2 = index_list2[z2];
        var my_uid2 = document.selected_option_groups[i2].uid;
        var unique_question_part2 = my_uid2.split("!").slice(0, -1).join("!") + "!";
        if (multi_combiner_size == "2" || multi_combiner_size == "23") {
          //console.log("unique_question_part="+unique_question_part+" != "+unique_question_part2); //

          if (unique_question_part != unique_question_part2 || (unique_question_part == unique_question_part2 && document.selected_option_groups[i].is_multi && my_uid != my_uid2)) {
            if (!document.bg_combos_done_temp[my_uid + ":" + my_uid2] && !document.bg_combos_done_temp[my_uid2 + ":" + my_uid]) {
              document.bg_combos_done_temp[my_uid + ":" + my_uid2] = 1;
              document.bg_combos_done_temp[my_uid2 + ":" + my_uid] = 1;

              // $(".verify-group-combo").each(function(){
              //   this.checked = false;
              // });
              // $(groups_temp[i]).click();
              // $(groups_temp[i2]).click();
              // $("#add-combo-btn").click();
              // BEGIN -- INSERT WITHOUT EVENTS
              var index_list_temp = [];
              var combo_option_names = [];

              var option_group_index = $(groups_temp[i]).data("option_group_index");
              var option_group_name = $(groups_temp[i]).data("option_group_name");
              index_list_temp.push(option_group_index);
              combo_option_names.push('<span class="display_option_name_' + option_group_index + '">' + escapeHtml(option_group_name) + '</span>');

              option_group_index = $(groups_temp[i2]).data("option_group_index");
              option_group_name = $(groups_temp[i2]).data("option_group_name");
              index_list_temp.push(option_group_index);
              combo_option_names.push('<span class="display_option_name_' + option_group_index + '">' + escapeHtml(option_group_name) + '</span>');

              var index_list_for_id = index_list_temp.slice().sort();
              var combo_id = "c" + index_list_for_id.join("-");
              if (!document.option_group_combinations[combo_id]) {
                document.option_group_combinations[combo_id] = index_list_temp;
                // addComboWithoutSampleData(combo_id, combo_option_names, index_list_temp);
                var load_text = i18n_string("advanced_reports.load", "(load)");
                var ret_data_no_sample = {
                  sample_size: '<span class="data-content-not-loaded-sample" id="data-content-not-loaded-sample-' + combo_id + '" data-combo_id="' + combo_id + '">Not loaded <a href="#" class="data-content-not-loaded-btn" data-combo_id="' + combo_id + '">'+load_text+'</a></span>',
                  sample_too_small: '<span class="data-content-not-loaded-score" id="data-content-not-loaded-score-' + combo_id + '" data-combo_id="' + combo_id + '">Not loaded <a href="#" class="data-content-not-loaded-btn" data-combo_id="' + combo_id + '">'+load_text+'</a></span>'
                };
                var row_html = createComboRowHTML(combo_id, combo_option_names, index_list_temp, ret_data_no_sample);
                $("#group_combo_table_body").append(row_html);
              }
              // END -- INSERT WITHOUT EVENTS

              this_interation_count++;
              if (this_interation_count > this_interation_max) {
                document.bg_multicombine_z3 = 0;
                document.bg_multicombine_z2 = z2 + 1;
                document.bg_multicombine_z1 = z;
                if (document.bg_multicombine_z2 >= index_list2.length) {
                  document.bg_multicombine_z2 = 0;
                  document.bg_multicombine_z1 = z + 1;
                }
                setTimeout(doMultiCombinerInBackground, this_timeout_ms);
                return 0;
              }
            }
          }
        }
        if (multi_combiner_size == "3" || multi_combiner_size == "23") {
          for (var z3 = z3_start; z3 < index_list3.length; z3++) {
            var i3 = index_list3[z3];
            var my_uid3 = document.selected_option_groups[i3].uid;
            var unique_question_part3 = my_uid3.split("!").slice(0, -1).join("!") + "!";
            if (multi_combiner_size == "3" || multi_combiner_size == "23") {
              // console.log("UIDS="+my_uid+":"+my_uid2+":"+my_uid3);
              if ((unique_question_part != unique_question_part2 && unique_question_part != unique_question_part3 && unique_question_part2 != unique_question_part3)
                  || (unique_question_part == unique_question_part2 && document.selected_option_groups[i].is_multi && my_uid != my_uid2 && unique_question_part != unique_question_part3 && unique_question_part2 != unique_question_part3)
                  || (unique_question_part == unique_question_part3 && document.selected_option_groups[i].is_multi && my_uid != my_uid3 && unique_question_part != unique_question_part2 && unique_question_part2 != unique_question_part3)
                  || (unique_question_part2 == unique_question_part3 && document.selected_option_groups[i2].is_multi && my_uid2 != my_uid3 && unique_question_part != unique_question_part2 && unique_question_part != unique_question_part3)
              ) {
                if (!document.bg_combos_done_temp[my_uid + ":" + my_uid2 + ":" + my_uid3] &&
                    !document.bg_combos_done_temp[my_uid + ":" + my_uid3 + ":" + my_uid2] &&
                    !document.bg_combos_done_temp[my_uid2 + ":" + my_uid + ":" + my_uid3] &&
                    !document.bg_combos_done_temp[my_uid2 + ":" + my_uid3 + ":" + my_uid] &&
                    !document.bg_combos_done_temp[my_uid3 + ":" + my_uid + ":" + my_uid2] &&
                    !document.bg_combos_done_temp[my_uid3 + ":" + my_uid2 + ":" + my_uid]
                ) {
                  document.bg_combos_done_temp[my_uid + ":" + my_uid2 + ":" + my_uid3] = 1;
                  document.bg_combos_done_temp[my_uid + ":" + my_uid3 + ":" + my_uid2] = 1;
                  document.bg_combos_done_temp[my_uid2 + ":" + my_uid + ":" + my_uid3] = 1;
                  document.bg_combos_done_temp[my_uid2 + ":" + my_uid3 + ":" + my_uid] = 1;
                  document.bg_combos_done_temp[my_uid3 + ":" + my_uid + ":" + my_uid2] = 1;
                  document.bg_combos_done_temp[my_uid3 + ":" + my_uid2 + ":" + my_uid] = 1;

                  // $(".verify-group-combo").each(function(){
                  //   this.checked = false;
                  // });
                  // $(groups_temp[i]).click();
                  // $(groups_temp[i2]).click();
                  // $(groups_temp[i3]).click();
                  // $("#add-combo-btn").click();

                  // BEGIN -- INSERT WITHOUT EVENTS
                  var index_list_temp = [];
                  var combo_option_names = [];

                  var option_group_index = $(groups_temp[i]).data("option_group_index");
                  var option_group_name = $(groups_temp[i]).data("option_group_name");
                  index_list_temp.push(option_group_index);
                  combo_option_names.push('<span class="display_option_name_' + option_group_index + '">' + escapeHtml(option_group_name) + '</span>');

                  option_group_index = $(groups_temp[i2]).data("option_group_index");
                  option_group_name = $(groups_temp[i2]).data("option_group_name");
                  index_list_temp.push(option_group_index);
                  combo_option_names.push('<span class="display_option_name_' + option_group_index + '">' + escapeHtml(option_group_name) + '</span>');

                  option_group_index = $(groups_temp[i3]).data("option_group_index");
                  option_group_name = $(groups_temp[i3]).data("option_group_name");
                  index_list_temp.push(option_group_index);
                  combo_option_names.push('<span class="display_option_name_' + option_group_index + '">' + escapeHtml(option_group_name) + '</span>');

                  var index_list_for_id = index_list_temp.slice().sort();
                  var combo_id = "c" + index_list_for_id.join("-");
                  if (!document.option_group_combinations[combo_id]) {
                    document.option_group_combinations[combo_id] = index_list_temp;
                    // addComboWithoutSampleData(combo_id, combo_option_names, index_list_temp);
                    var load_text = i18n_string("advanced_reports.load", "(load)");
                    var ret_data_no_sample = {
                      sample_size: '<span class="data-content-not-loaded-sample" id="data-content-not-loaded-sample-' + combo_id + '" data-combo_id="' + combo_id + '">Not loaded <a href="#" class="data-content-not-loaded-btn" data-combo_id="' + combo_id + '">'+load_text+'</a></span>',
                      sample_too_small: '<span class="data-content-not-loaded-score" id="data-content-not-loaded-score-' + combo_id + '" data-combo_id="' + combo_id + '">Not loaded <a href="#" class="data-content-not-loaded-btn" data-combo_id="' + combo_id + '">'+load_text+'</a></span>'
                    };
                    var row_html = createComboRowHTML(combo_id, combo_option_names, index_list_temp, ret_data_no_sample);
                    $("#group_combo_table_body").append(row_html);
                  }
                  // END -- INSERT WITHOUT EVENTS

                  this_interation_count++;
                  if (this_interation_count > this_interation_max) {
                    // document.bg_multicombine_z3 = z3+1;
                    // if(document.bg_multicombine_z3 >= index_list.length){
                    //   document.bg_multicombine_z3 = 0;
                    //   z2++;
                    // }
                    // document.bg_multicombine_z2 = z2;
                    // if(document.bg_multicombine_z2 >= index_list.length){
                    //   document.bg_multicombine_z2 = 0;
                    //   z++;
                    // }
                    // document.bg_multicombine_z1 = z;
                    document.bg_multicombine_z3 = z3 + 1;
                    document.bg_multicombine_z2 = z2;
                    document.bg_multicombine_z1 = z;
                    if (document.bg_multicombine_z3 >= index_list3.length) {
                      document.bg_multicombine_z3 = 0;
                      document.bg_multicombine_z2 = z2 + 1;
                    }
                    if (document.bg_multicombine_z2 >= index_list2.length) {
                      document.bg_multicombine_z2 = 0;
                      document.bg_multicombine_z1 = z + 1;
                    }
                    // console.log("z1="+document.bg_multicombine_z1+",z2="+document.bg_multicombine_z2+",z3="+document.bg_multicombine_z3+" / "+index_list.length);

                    setTimeout(doMultiCombinerInBackground, this_timeout_ms);
                    return 0;
                  }
                }
              }
            }
            this_interation_count_nomatch++;
            if (this_interation_count_nomatch > this_interation_max_nomatch) {
              document.bg_multicombine_z3 = z3 + 1;
              document.bg_multicombine_z2 = z2;
              document.bg_multicombine_z1 = z;
              if (document.bg_multicombine_z3 >= index_list3.length) {
                document.bg_multicombine_z3 = 0;
                document.bg_multicombine_z2 = z2 + 1;
              }
              if (document.bg_multicombine_z2 >= index_list2.length) {
                document.bg_multicombine_z2 = 0;
                document.bg_multicombine_z1 = z + 1;
              }
              // console.log("z1="+document.bg_multicombine_z1+",z2="+document.bg_multicombine_z2+",z3="+document.bg_multicombine_z3+" / "+index_list.length);

              setTimeout(doMultiCombinerInBackground, this_timeout_ms);
              return 0;
            }

          }
          z3_start = 0;
        }
      }
      z2_start = 0;
    }
    // console.log("What are the z="+z+",z2="+z2+",z3="+z3);

    document.bg_multi_combiner_running = false;
    doPostComboLoadingInit();
    return 1;
  }

  $(document).off("click", ".data-content-not-loaded-btn").on("click", ".data-content-not-loaded-btn", function (e) {
    e.preventDefault();
    var combo_id = $(this).data("combo_id");
    updateSampleDataForCombo(combo_id);
  });
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Combine existing options
  $("#adv-multi-combine-existing-options-confirm").off("click").on("click", function (e) {
    e.preventDefault();
    $("#confirm-auto-combine-modal").modal("hide");
    setTimeout(function () {
      combine_existing_options();
    }, 20);
  });

  window.combine_existing_options = function() {
    var multi_combiner_size = 0;
    $("#adv-multi-combiner-tool-modal #adv-multi-combiner-tool-checkboxes").hide();
    $("#adv-multi-combiner-tool-modal #adv-multi-combiner-tool-running").show();

    // Do the combing fun
    var groups_temp = [];
    var index_list = [];
    var index_list2 = [];
    var index_list3 = [];
    var index_list_for_question = {};
    var last_index_number_for_question = 0;
    var combos_done_temp = {};
    $(".verify-group-combo").each(function () {
      var group_index = parseInt($(this).data("option_group_index"));
      if (document.selected_option_groups[group_index] && document.selected_option_groups[group_index].uid) {
        var my_uid = document.selected_option_groups[group_index].uid;
        var unique_question_part = my_uid.split("!").slice(0, -1).join("!") + "!";
        var indexListNumber = 0;
        if (index_list_for_question[unique_question_part]) {
          indexListNumber = index_list_for_question[unique_question_part];
        } else {
          last_index_number_for_question++;
          indexListNumber = last_index_number_for_question;
          index_list_for_question[unique_question_part] = last_index_number_for_question;
        }
        if (indexListNumber == 1) {
          index_list.push(group_index);
          multi_combiner_size = ""+Math.max(Number(multi_combiner_size), 1);
        } else if (indexListNumber == 2) {
          index_list2.push(group_index);
          multi_combiner_size = ""+Math.max(Number(multi_combiner_size), 2);
        } else if (indexListNumber == 3) {
          index_list3.push(group_index);
          multi_combiner_size = ""+Math.max(Number(multi_combiner_size), 23);
        }
        groups_temp[group_index] = this;
      }
    });

    // index_list.get(0) is multi check
    if (multi_combiner_size == "1") {
      if (document.selected_option_groups[index_list[0]].is_multi) {
        index_list2 = index_list;
        multi_combiner_size = 2;
        //console.log("combine_existing_options::1");
      }
    } else if (multi_combiner_size == "2") {
      if (document.selected_option_groups[index_list[0]].is_multi) {
        index_list3 = index_list2;
        index_list2 = index_list;
        multi_combiner_size = "23";
        //console.log("combine_existing_options::2");
      } else if (document.selected_option_groups[index_list2[0]].is_multi) {
        index_list3 = index_list2;
        multi_combiner_size = "23";
        //console.log("combine_existing_options::3");
      }
    }

    if (parseInt(multi_combiner_size) < 2) {
      return;
    }

    document.bg_multicombine_z1 = 0;
    document.bg_multicombine_z2 = 0;
    document.bg_multicombine_z3 = 0;
    document.bg_index_list = index_list;
    document.bg_index_list2 = index_list2;
    document.bg_index_list3 = index_list3;
    document.bg_groups_temp = groups_temp;
    document.bg_combos_done_temp = combos_done_temp;
    document.bg_multi_combiner_size = multi_combiner_size;
    document.bg_multi_combiner_running = true;

    setTimeout(doMultiCombinerInBackground, 20);

    if ($('#adv-multi-combiner-tool-modal').hasClass('in')) {
      $("#adv-multi-combiner-tool-modal").modal("hide");
    }
    $("#adv-editor-container-div").addClass("blur-element");
    $("#adv_report_multi_tool_inprogress").show();
    $("#adv-multi-combiner-tool").addClass("disabled");
    setTimeout(waitForCombosToFinishForMultiCombiner, 1000);
  }

  window.checkIfAutoCombineCanRun = function() {
    var have_multi_question = false;
    var quest_list = [];
    $(".verify-group-combo").each(function () {
      var option_group_index = $(this).data("option_group_index");
      if (document.selected_option_groups[option_group_index] && document.selected_option_groups[option_group_index].uid) {
        var my_uid = document.selected_option_groups[option_group_index].uid;
        var unique_question_part = my_uid.split("!").slice(0, -1).join("!") + "!";
        if (quest_list.indexOf(unique_question_part) == -1) {
          quest_list.push(unique_question_part);
        }
        if (document.selected_option_groups[option_group_index].is_multi) {
          have_multi_question = true;
        }
      }
    });
    // Can at-most have 3 questions -- if a question is a multi-select it counts as two :)
    var can_autocombine = false;
    if (quest_list.length == 1 && have_multi_question) {
      can_autocombine = true;
    } else if (quest_list.length == 2) {
      can_autocombine = true;
    } else if (quest_list.length == 3 && !have_multi_question) {
      can_autocombine = true;
    }

    if (can_autocombine) {
      $("#adv-multi-combine-existing-options").removeClass("disabled");
    } else {
      $("#adv-multi-combine-existing-options").addClass("disabled");
    }
  }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Combination cleanup code ////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  document.nextCleanupRow = 0;
  document.cleanupSizeRequirement = 0;
  document.cleanRowCount = 0;
  document.processCombinationCleanupTimeout = undefined;
  $("#data-cleanup-start-btn").off("click").on("click", function (e) {
    e.preventDefault();
    document.cleanupSizeRequirement = parseInt($("#combination-size-requirement").val());
    document.nextCleanupRow = $('#group_combo_table_body tr.combo-table-row:visible').length;
    document.cleanRowCount = document.nextCleanupRow;
    // close modal
    $("#data-cleanup-modal").modal("hide");
    // show processing overlay
    $("#adv-editor-container-div").addClass("blur-element");
    $("#adv_report_multi_tool_inprogress").show();
    $("#adv_report_multi_tool_cancel_btn").show();
    // setTimeout - runs in the background
    document.processCombinationCleanupTimeout = setTimeout(processCombinationCleanup, 50);
    setTimeout(centerInProgressLoader, 100);
  });
  $("#adv_report_multi_tool_cancel_btn").off("click").on("click", function (e) {
    e.preventDefault();

    if (document.processCombinationCleanupTimeout) {
      clearTimeout(document.processCombinationCleanupTimeout);
      document.nextCleanupRow = 0;
      document.cleanRowCount = 0;
      $("#adv-editor-container-div").removeClass("blur-element");
      $("#adv_report_multi_tool_inprogress").hide();
      $("#adv_report_multi_tool_cancel_btn").hide();
    }
  });

  window.processCombinationCleanup = function() {
    if (document.nextCleanupRow > 0) {

      // Update progress display - div id= adv_report_multi_tool_inprogress_percent_loaded
      var progressString = "Checking " + (document.cleanRowCount - document.nextCleanupRow + 1) + "/" + document.cleanRowCount;
      $("#adv_report_multi_tool_inprogress_percent_loaded").html(progressString);

      $row = $('#group_combo_table_body tr.combo-table-row:visible').eq(document.nextCleanupRow - 1); // :nth-child('+document.nextCleanupRow+')
      if ($row.length) {
        var $buttonCheck = $row.find(".data-content-not-loaded-btn");
        if ($buttonCheck.length) {
          if (!$buttonCheck.data("cleanup_sample_lookup")) {
            $buttonCheck.data("cleanup_sample_lookup", "yes");
            $buttonCheck.click();
            document.processCombinationCleanupTimeout = setTimeout(processCombinationCleanup, 1000);
          } else {
            // Still waiting for the processing
            document.processCombinationCleanupTimeout = setTimeout(processCombinationCleanup, 700);
          }
        } else {
          // Get value from cell and filter
          var rowSampleSize = parseInt($row.find("td:nth-child(3)").html());
          if (rowSampleSize < document.cleanupSizeRequirement) {
            $row.find(".remove-combo-btn").click();
          }
          document.nextCleanupRow--;
          document.processCombinationCleanupTimeout = setTimeout(processCombinationCleanup, 200);
        }

      }
    } else {
      // Cleanup -- done processing
      $("#adv-editor-container-div").removeClass("blur-element");
      $("#adv_report_multi_tool_inprogress").hide();
      $("#adv_report_multi_tool_cancel_btn").hide();
    }
  }

// To recenter the progress bar on the page.  The progress animation would not be visible otherwise when doing combine-all or cleanup
  window.centerInProgressLoader = function() {
    if ($("#adv_report_multi_tool_inprogress:visible").length) {
      var parent = window;//$("#adv_report_multi_tool_inprogress");

      var loaderObj = $("#adv_report_multi_tool_inprogress").find(".loader");
      var topOffset = $("#adv_report_multi_tool_inprogress").offset().top;
      var newPostion = $(parent).height() / 2 + $(parent).scrollTop() - topOffset - 90 - 50; // 90 is half the width of the circle thing - 50 is to bump it up a little

      newPostion = Math.max(newPostion, 100);
      loaderObj.css({
        "margin-top": (newPostion + "px")
      });
    }
  }

  $(window).scroll(function () {
    clearTimeout($.data(this, 'scrollTimer'));
    $.data(this, 'scrollTimer', setTimeout(function () {
      centerInProgressLoader();
    }, 250));
  });

  // Load saved report
  loadReportFromHiddenInputs();
}