import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="animate"
export default class extends Controller {
  connect() {
    this.element[this.identifier] = this

    let elementId = this.element.id;

    if (!this.element.dataset || !this.element.dataset.animation) {
      //console.log("I'm missing something");
      return;
    }
    let animation = this.element.dataset.animation;
    let delay = 0;
    if (this.element.dataset.delay && Number(this.element.dataset.delay) > 0) {
      delay = Number(this.element.dataset.delay);
    }
    let element = this.element;
    if (animation == 'slideDown') {
      setTimeout(function(){
        $(element).slideDown();
      }, delay);
    } else if (animation == 'slideUp') {
      setTimeout(function(){
        $(element).slideUp();
      }, delay);
    }
  }

}
