import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manage-administrator"
export default class extends Controller {

  connect() {
    $("#invite_administrator_button").prop("disabled",true);
    $("#invite_folder_administrator_button").prop("disabled",true);
    $("#add_new_administrator_form").data("add_new_administrator_email_ok",0);
  }

  additional_sub_account_dropdown_change(e) {
    // .additional-customer-sub-account-permission-div
    let sub_account_ids = $("#add_additional_org1").val();
    sub_account_ids = sub_account_ids.map(function (x) {
      return parseInt(x, 10);
    });
    $(".additional-customer-sub-account-permission-div").each(function(){
      // console.log($(this).data("id"));
      let customer_sub_account_id = parseInt($(this).data("id"));
      if (sub_account_ids.indexOf(customer_sub_account_id) != -1) {
        $(this).show();
      } else {
        $(this).find("select").each(function(){
          $(this).val("").change();
        });
        $(this).hide();
      }
    });
  }

  admin_email_change(e) {
    $("#add_new_administrator_form").data("add_new_administrator_email_ok",0);
    $("#invite_administrator_button").prop("disabled",true);
    $("#invite_folder_administrator_button").prop("disabled",true);
    var user_email_val = $(e.currentTarget).val();
    if(user_email_val){
      $("#email_for_admin_invite_error_div").html('<div class="alert alert-warning mb-0 py-1 px-2 small"><em class="fal fa-circle-notch fa-spin"></em> Checking email address</div>').show();
      var send_data = {
        email: user_email_val,
        user_type: $("#permission_level").val(),
        associate_message: 1
      };
      $.ajax({
        url: '/users/check_email_avail',
        data: send_data,
        success: function(dataObj) {
          // The dataObj.same_user_type check is needed because this is set to allow associating existing accounts to a new admin assignment - neil
          if ( (dataObj.available && dataObj.available == "yes") || (dataObj.same_user_kind && dataObj.same_user_kind == "yes") ) {
            // show_confirm_user_convert();
            $("#add_new_administrator_form").data("add_new_administrator_email_ok",1);
            $("#invite_administrator_button").prop("disabled",false);
            $("#invite_folder_administrator_button").prop("disabled",false);
            $("#email_for_admin_invite_error_div").html('<div class="alert alert-success mb-0 py-1 px-2 small"><em class="fa fa-check-circle"></em> This email is good</div>').show();
          } else {
            if(dataObj.message != '') {
              $("#email_for_admin_invite_error_div").html('<div class="alert alert-danger mb-0 py-1 px-2 small">' + dataObj.message + '</div>').show();
            } else {
              $("#email_for_admin_invite_error_div").html('<div class="alert alert-danger mb-0 py-1 px-2 small">This email belongs to NON-admin user</div>').show();
            }
          }
        },
        type: 'POST'
      });
    } else {
      $("#email_for_admin_invite_error_div").html("").hide();
    }
  }

  adv_perm_checkbox_clicked(e) {
    if (e.currentTarget.checked) {
      $("#advanced_permissions_list_new").removeClass("hidden");
    } else {
      $("#advanced_permissions_list_new").addClass("hidden");
    }
  }

  check_data_type_option_selection(e) {
    let $selectObj = $(e.currentTarget);
    let val_list = $selectObj.val();
    // console.dir(val_list);
    var all_selected = false;
    if(val_list && val_list.length)
    {
      for(var i=0;i<val_list.length;i++)
      {
        if(val_list[i] == "all")
        {
          all_selected = true;
        }
      }
    }
    if(all_selected && val_list.length > 1)
    {
      // Deselect everything except for "All"
      $selectObj.val('all').change();
    }

    // See if this dropdown has subspecialties that we need to check
    var this_id = $selectObj.data("questionId");
    if(this_id) {
      var parentContainer = $selectObj.closest(".customer-sub-account-data-type-permission-div");
      // console.log("------------======== "+parentContainer.length);
      // console.log("------------======== "+parentContainer.find("[data-parent_question_id='"+this_id+"']").length);
      // console.log("this_id========"+this_id);
      if(parentContainer.find("[data-parent_question_id='"+this_id+"']").length) {
        // This dropdown does have children -- now check to see if any of them need to be hidden or shown :)
        if(all_selected) {
          // Show all
          parentContainer.find("[data-parent_question_id='"+this_id+"']").show();
        } else if (val_list.length > 0) {
          // Loop through and check selected options
          parentContainer.find("[data-parent_question_id='"+this_id+"']").each(function(){
            var compareVal = $(this).data("parent_option_id");
            var isSelectedInParent = false;
            if(val_list && val_list.length)
            {
              for(var i=0;i<val_list.length;i++)
              {
                if(val_list[i] == compareVal) {
                  isSelectedInParent = true;
                }

              }
            }
            if(isSelectedInParent) {
              $(this).show();
            } else {
              $(this).find("select").each(function() {
                $(this).val("").change();
              });
              $(this).hide();
            }
          });
        } else {
          parentContainer.find("[data-parent_question_id='"+this_id+"']").find("select").each(function() {
            $(this).val("").change();
          });
          parentContainer.find("[data-parent_question_id='"+this_id+"']").hide();
        }
      }
    }
  }

  confirm_delete_invite(e) {
    if (!confirm("Are you sure you want to delete this invite?")) {
      e.preventDefault();
    }
  }

  edit_permission_checkbox_checked(e) {
    if (e.currentTarget.value == "reports") {
      if (e.currentTarget.checked) {
        $("#advanced-permission-new-user").show();
      } else {
        $("#advanced-permission-new-user").find("input[type=checkbox]").prop('checked',false);
        $("#advanced-permission-new-user").find("select").val("").change();
        $("#advanced_permissions_list_new").hide();
        $("#specialty-list-div-new").hide();
        $("#advanced-permission-new-user").hide();
      }
    }
  }

  limited_report_type_clicked(e) {
    var selected_val = $("input[name='limited_report_type']:checked").val();
    if (selected_val == "report") {
      $("#limited_report_type_data").hide();
      $("#limited_report_type_report").show();
    } else if (selected_val == "data") {
      $("#limited_report_type_report").hide();
      $("#limited_report_type_data").show();
    }
  }

  permission_select_change(e) {
    var val_list = $(e.currentTarget).val();
    var have_reports = false;
    if(val_list && val_list.length)
    {
      for(var i=0;i<val_list.length;i++)
      {
        if(val_list[i] == "reports")
        {
          have_reports = true;
        }
      }
    }
    if(have_reports)
    {
      $("#advanced-permission-new-user").show();
    }
    else
    {
      $("#advanced-permission-new-user").hide();
      $("#advanced_permissions_checkbox_new").prop("checked",false);
      $("#advanced_permissions_list_new").hide();
    }
  }

  specialty_report_clicked(e) {
    if (e.currentTarget.checked) {
      $("#specialty-list-div-new").show();
    } else {
      $("#specialty-list-div-new").hide();
    }
  }

  submit_add_form(e) {
    if (!$("#add_new_administrator_form").data("add_new_administrator_email_ok")) {
      e.preventDefault();
    }
  }

  submit_add_from_folder_permissions(e) {
    if (!$("#add_new_administrator_form").data("add_new_administrator_email_ok")) {
      e.preventDefault();
      return;
    }
    let kind = $("#add_new_administrator_form").data("kind");
    let url = $("#add_new_administrator_form").data("url");
    let folder_id = $("#invite_administrator_folder_id").val();
    let email = $("#add_administrator").val();
    let sub_account = $("#invite_administrator_customer_sub_account_id").val();

    //console.log("folder_id="+folder_id+" email="+email+" url="+url+"");
    if (folder_id && email && url && sub_account) {
      var adv_report_arr = {};
      var wellness_snapshot_arr = {};
      var wellness_journey_arr = {};
      if(kind == 'advanced_report') {
        adv_report_arr[sub_account] = "1";
      }
      if(kind == 'wellness_snapshot') {
        wellness_snapshot_arr[sub_account] = [folder_id.toString()];
        adv_report_arr[sub_account] = "1";

        // {"adv_report_folder":{"675":"1"},"snapshot_folders":{"675":["678"]}}
      }
      if (kind == 'wellness_journey') {
        wellness_journey_arr[sub_account] = [folder_id.toString()];
        adv_report_arr[sub_account] = "1";
      }
      var folder_arr = {};
      folder_arr[sub_account] = [folder_id];

      var postData = {
        add_administrator: email,
        advanced_permissions: 1,
        adv_report_folder: adv_report_arr,
        wellness_snapshot_folder: wellness_snapshot_arr,
        wellness_journey_folder: wellness_journey_arr,
        advanced_permissions_for_multiple: 1,
        limited_report_type: 'report',
        permissions: ['reports'],
        action: 'add_admin',
        kind: "modal_created",
        add_customer_sub_account_id: [],
        folder_id: folder_arr
      }

      $.ajax({
        url: url,
        type: 'post',
        data: postData,
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        dataType: 'json',
        success: function (json) {
          if(json.status == 200) {
            $('#add_administrator').val('');
            $('#invite_folder_administrator_button').attr('disabled', true);
            $('#email_for_admin_invite_error_div').html('').hide();
            $('#folder_id').val('');

            $('#system_modal_popup').modal('hide');

            alert( json.message );
          } else {
            // Show error message if set, otherwise show general error
            if (json.message) {
              alert(json.message);
            } else {
              alert("Error. Please try again later.");
            }
          }
        }
      });

    } else {
      e.preventDefault();
      return;
    }
  }


  toggleChildFolders(e) {
    var item = e.currentTarget

    

    if(item.checked) {
      $(item).parent().parent().parent().find('.dd-list').find("input[type=checkbox]").each(function(index, item){
        item.checked = true
        item.disabled = true
      })
      // $(item).parent().parent().parent().find('.dd-list').find("input[type=checkbox]").attr('checked', true).attr('disabled', true)
    } else {
      $(item).parent().parent().parent().find('.dd-list').find("input[type=checkbox]").each(function(index, item){
        item.checked = false
        item.disabled = false
      })
      // $(item).parent().parent().parent().find('.dd-list').find("input[type=checkbox]").attr('checked', false).attr('disabled', false)
    }
  }

  togglePermissionsNav(e) {
    var item = e.currentTarget
    $('#myTab .nav-link').removeClass('active');
    $(item).addClass('active')
  }
}
