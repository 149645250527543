import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="reports"
export default class extends Controller {
  connect() {
    this.setTableWidth()
  }


  createInvoice(e) {
    e.preventDefault()
    let display_row = this.displayRow
    var invoice_id = $('#invoice_co_invoice_id').val()   
    
    if(invoice_id == '') {
       alert("Invoice cannot be blank");
    } else {

      this.modal = new Modal(document.getElementById('system_modal_popup'), {
        keyboard: false
      });
      this.modal.show();
      $("#system_modal").html('<div class="modal-body text-center p-5"><em class="far fa-spin fa-spinner fa-3x"></em></div>');

      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: $('#new_invoice').attr('action'), 
        data: { chargeover_id: invoice_id },
        type: 'POST', 
        success(data) {
          if(data.status == "success") {
            $('#system_modal').html('<div class="modal-body text-center p-5"><h3 class="text-success">Invoice Loaded Successfully.</h3></div>');
            
            var html = display_row(data)
            
            $('#sortable-table tbody').prepend(html);

            $('[data-bs-toggle="tooltip"]').tooltip('dispose');
            $('[data-bs-toggle="tooltip"]').tooltip(); 

          } else {
            $('#system_modal').html('<div class="modal-body text-center p-5"><h3 class="text-danger">Error Loading Invoice</h3><p>' + data.message + '</p></div>');
          }
        }
      });
    }

  }

  displayRow(data, display_tr = true) {
    if(display_tr) {
      var html  = '<tr id="row_' + data.invoice.id + '" role="row">';
    } else {
      var html = '';
    }
    
                html +=   '<td style="white-space: nowrap;">';
                html +=     '<a data-action="click->modal#loadModal" data-turbo="true" data-turbo-frame="system_modal" href="/reports/' + data.invoice.id + '/sa_edit_chargeover_invoice">' + data.invoice.co_invoice_id + '</a> ';
                html +=     '<a target="_blank" href="https://billing.cws.net/admin/r/invoice/view/' + data.invoice.co_invoice_id + '"><em class="far fa-eye ms-2" data-bs-toggle="tooltip" title="View in ChargeOver"></em></a> ';
                html +=     '<em class="far fa-sync-alt ms-2 text-primary" data-bs-toggle="tooltip" title="Reload Invoice from ChargeOver" data-action="click->reports#reloadFromChargeOver" data-id="' + data.invoice.co_invoice_id + '"></em>'
                html +=   '</td>';
                html +=   '<td>' + data.invoice.customer_name + '</td>';
                html +=   '<td>' + data.invoice.description + '</td>';
                html +=   '<td>' + data.invoice.amount + '</td>';
                html +=   '<td>' + data.invoice.license_total + '</td>';
                html +=   '<td>' + data.invoice.setup_total + '</td>';
                html +=   '<td>' + data.invoice.license_royalty + '</td>';
                html +=   '<td>' + data.invoice.setup_royalty + '</td>';
                html +=   '<td>' + data.invoice.logo_royalty + '</td>';
                html +=   '<td>' + data.invoice.royalty_total + '</td>';
                html +=   '<td>' + data.invoice.royalty_paid + '</td>';
                html +=   '<td>' + data.invoice.invoice_status + '</td>';
                html +=   '<td style="white-space: nowrap;">' + data.invoice.invoice_date + '</td>';
                html +=   '<td style="white-space: nowrap;">' + data.invoice.paid_date + '</td>';
                html +=   '<td><a data-action="click->modal#loadModal" data-turbo="true" data-turbo-frame="system_modal" href="/reports/' + data.invoice.id + '/sa_edit_chargeover_invoice"><em class="far fa-pencil text-primary"  data-bs-toggle="tooltip" title="Edit"></em></td>';
    if(display_tr) {                
                html += '</tr>';
    }
    
    return(html)

  }

  exportRoyaltyReport(e) {
    e.preventDefault();
    var item = e.target
    var current_form = $(item).closest('form').serializeArray();

    var form = document.createElement('form')
    document.body.appendChild(form)
    form.target = "_blank"
    form.method = "GET"
    form.action = "/reports/sa_royalty_export"
    
    for( var i = 0; i < current_form.length; i++) {
      if(current_form[i].name != 'authenticity_token' ){
        var field = document.createElement('input')
        field.setAttribute('type', 'hidden')
        field.setAttribute('name', current_form[i].name)
        field.setAttribute('value', current_form[i].value)
        form.appendChild(field);
      }
    }

    var field = document.createElement('input')
    field.setAttribute('type', 'hidden')
    field.setAttribute('name', 'download_format')
    field.setAttribute('value', $(item).data("format"))
    form.appendChild(field);

    form.submit()

    form.remove()
  }


  
  recalculateRoyalties(e) {
    e.preventDefault();
    var inv_amount = parseFloat($("#invoice_amount").val());
    var inv_license_total = parseFloat($("#invoice_license_total").val());
    var inv_setup_total = parseFloat($("#invoice_setup_total").val());
    var inv_pharmacist_license_total = parseFloat($("#invoice_pharmacist_license_total").val());
    var inv_dentist_license_total = parseFloat($("#invoice_dentist_license_total").val());
    var inv_leadership_license_total = parseFloat($("#invoice_leadership_license_total").val());
    var inv_logo_royalty = 0;
    var inv_license_royalty = 0;
    var inv_pharmacist_royalty = 0;
    var inv_dentist_royalty = 0;
    var inv_leadership_license_royalty = 0;
    var inv_setup_royalty = 0;
    if(inv_amount > 0)
    {
      // After 2024-08-02 there is noo more logo royalty
      if($("#invoice_date").val() <= '2024-08-02') {
        if (inv_leadership_license_total > 0) {
          // Exclude leadership impact index from logo royalty
          if (inv_amount - inv_leadership_license_total > 0) {
            inv_logo_royalty = Math.round(((inv_amount - inv_leadership_license_total) * 0.02) * 100) / 100;
          }
        } else {
          inv_logo_royalty = Math.round((inv_amount * 0.02) * 100) / 100;
        }
      }
    }
    if(inv_license_total > 0)
    {
      // If invoice date < 2023-01-04 use 55%
      if($("#invoice_date").val() < '2023-01-04') {
        inv_license_royalty = Math.round((inv_license_total*0.55)*100)/100;
      } else {
        inv_license_royalty = Math.round((inv_license_total*0.45)*100)/100;
      }
    }
    if(inv_setup_total > 0)
    {
      inv_setup_royalty = Math.round((inv_setup_total*0.05)*100)/100;
    }
    if (inv_leadership_license_total > 0) {
      inv_leadership_license_royalty = Math.round((inv_leadership_license_total*0.45)*100)/100;
    }
    var inv_date = $("#invoice_date").val();
    var t = inv_date.split(/[- :]/);
    var d = new Date(t[0], t[1]-1, t[2]);
    var inv_date_ts = d.getTime();
    if(inv_pharmacist_license_total > 0)
    {
      if (inv_date_ts >= (new Date(2021, 10-1, 7)).getTime() && inv_date_ts < (new Date(2022, 10-1, 7)).getTime())
      {
        // 10%
        inv_pharmacist_royalty = Math.round((inv_pharmacist_license_total*0.1)*100)/100;
      }
      else if (inv_date_ts >= (new Date(2022, 10-1, 7)).getTime() && inv_date_ts < (new Date(2023, 10-1, 7)).getTime())
      {
        // 15%
        inv_pharmacist_royalty = Math.round((inv_pharmacist_license_total*0.15)*100)/100
      }
      else if (inv_date_ts >= (new Date(2023, 10-1, 7)).getTime())
      {
        // 55%
        inv_pharmacist_royalty = Math.round((inv_pharmacist_license_total*0.55)*100)/100
      }
    }
    if(inv_dentist_license_total > 0)
    {
      if (inv_date_ts >= (new Date(2020, 11-1, 2)).getTime() && inv_date_ts < (new Date(2022, 11-1, 2)).getTime())
      {
        // 30%
        inv_dentist_royalty = Math.round((inv_dentist_license_total*0.30)*100)/100
      }
      else if (inv_date_ts >= (new Date(2022, 11-1, 2)).getTime())
      {
        // 55%
        inv_dentist_royalty = Math.round((inv_dentist_license_total*0.55)*100)/100
      }
    }

    var inv_royalty_total = inv_logo_royalty+inv_license_royalty+inv_setup_royalty+inv_pharmacist_royalty+inv_dentist_royalty+inv_leadership_license_royalty;
    $("#invoice_license_royalty").val(inv_license_royalty.toFixed(2));
    $("#invoice_pharmacist_royalty").val(inv_pharmacist_royalty.toFixed(2));
    $("#invoice_dentist_royalty").val(inv_dentist_royalty.toFixed(2));
    $("#invoice_leadership_license_royalty").val(inv_leadership_license_royalty.toFixed(2));
    $("#invoice_setup_royalty").val(inv_setup_royalty.toFixed(2));
    $("#invoice_logo_royalty").val(inv_logo_royalty.toFixed(2));
    $("#invoice_royalty_total").val(inv_royalty_total.toFixed(2));
  }

  reloadFromChargeOver(e) {
    e.preventDefault();
    var display_row = this.displayRow;
    var item = e.target

    $(item).addClass("fa-spin text-success");

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: $('#new_invoice').attr('action'), 
      data: { chargeover_id: item.dataset.id },
      type: 'POST',
      success(data) {
        if(data.status == "success") {
          
          $('[data-bs-toggle="tooltip"]').tooltip('dispose');
          $('[data-bs-toggle="tooltip"]').tooltip(); 

          var html = display_row(data, false)
          $('#row_' + item.dataset.id).html(html)
        } else {
          this.modal.show();
          $('#system_modal').html('<div class="modal-body text-center p-5"><h3 class="text-danger">Error Refreshing Invoice</h3><p>' + data.message + '</p></div>');
        }
      }
    });



  }

  setTableWidth() {
    if($("#sortable-table-container").length && !$("#sortable-table-container").data("widthset")){
      $("#sortable-table-container").data("widthset", "yes");
      var width = $('#sortable-table-container').width();
      var w = 0;
      
      if($('body').hasClass("nav-toggled")) {
        w = width - 80;
      } else {
        w = width - 250;
      }

      $('.table-responsive').css("width", w )
    }
  }

  toggleCustomDates(e) {
    e.preventDefault()
    var item = e.target;

    if(parseInt(item.value) == 0) {
      $('#custom_date_range').removeClass("hidden").show()
    } else {
      $("#custom_date_range").addClass("hidden").hide()
    }
  }

  togglePaidInvoiceRoyalty(e) {
    e.preventDefault()
    var item = e.target;

    if(item.value == "paid") {
      $("#invoice_paid_date_checkbox").removeClass("hidden").show()
    } else {
      $("#invoice_paid_date_checkbox").addClass("hidden").hide()
    }
  }
  
}
