$(function(){
  // Ensure we have the admin support panel loaded
  // if($("#admin_support_ticket").length) {
    $(document).on('click', '.admin-support-item', function(e){
      e.preventDefault();
      $('.admin-support-item').removeClass("active");
      $(this).addClass("active");

      $('#support_form_holder').removeClass("hidden");
      $('#support_ticket_kind').val( $(this).data("kind") );

      $("#support_ticket_browser").val( navigator.userAgent );
      $("#support_ticket_link").val(document.location.href);
    });

    $(document).on('submit', '#new_support_panel_ticket', function(){
      $('#support_status').show();
    });
  // }

  if($("#new_support_ticket").length) {
    $("#support_ticket_browser").val( navigator.userAgent );
    $("#support_ticket_link").val(document.location.href);
  }
});