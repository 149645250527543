import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="advanced-report-editor"
export default class extends Controller {
  initialize() {
    super.initialize();

  }

  connect() {
    advanced_report_editor_setup(this.element.dataset.comboUrl);
  }

  disconnect() {
    super.disconnect();
  }

  // question_checkbox_click(event) {
  //   $this_element = $(event.currentTarget);
  // }
}
