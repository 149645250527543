import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="login"
export default class extends Controller {
  initialize() {
    this.login_button_processing = false;
  }

  connect() {
    if ($('#login_name').length) {
      /* Added polling because if a browser login manager autofills in input 
       it does not always fire event and then requires user to click in and 
       then out of input - neil 2/27/2020 */
      setInterval((function(controllerObj){
        controllerObj.loginInputChange();
      })(this), 200);
    }
    // Verification page
    if ($('.login-submit-button').length) {
      setInterval((function(controllerObj){
        controllerObj.validationInputChange();
      })(this), 200);
    }
  }

  loginInputChange() {
    if($('#login_name').val().length > 0) {
      $('button[type="submit"]').removeAttr("disabled");
    } else {
      $('button[type="submit"]').attr("disabled", "disabled");
    }
  }

  validationInputChange() {
    if($('.login-verification-input').val().length > 0) {
      $('.login-submit-button').prop("disabled", false);
    } else {
      $('.login-submit-button').prop("disabled", true);
    }
  }

  processLogin(e) {
    if(!this.login_button_processing) {
      var buttonClicked = $(e.currentTarget);
      e.preventDefault();
      this.login_button_processing = true;
      var type = buttonClicked.val();
      $('button').attr('disabled', "disabled");
      $("<input />").attr("type", "hidden").attr("name", "login_type").attr("value", type).appendTo('#login_form');
      $('#login_form')[0].submit();
    }
  }
}
