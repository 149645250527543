import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard"
export default class extends Controller {
  connect() {
  }

  loadAdditionalOptions(e) {
    var item = e.currentTarget;

    $("#sub-account-other-options").html("").hide();

    $.ajax({
      url: "/customers/" + item.value + "/get_customer_sub_account_other_options",
      type: "GET",
      success: function(html) {
        if(html) {
          $("#sub-account-other-options").html(html).show();
        }
      }
    });
  }

  selectSubAccount(e) {
    var item = e.currentTarget

    $('#sub-account-div').hide()

    $.ajax({
      url: "/customers/" + e.target.value + "/get_customer_sub_accounts",
      type: "GET",
      success: function(e) {
        if(e.status == 200) {
          $('#sub-account-div').show()
          var data = '<option value>Select...</option>'
          $(e.accounts).each(function(index, item){
            data += '<option value="' + item.id + '">' + item.name + ' (' + item.group_content + ')</option>';
          })
          
          $('#customer_sub_account_id').html(data);
        }
      }
    });
  }

}
