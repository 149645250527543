import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tools"
export default class extends Controller {
  connect() {
  }

  changePrimaryAssocClicked(e) {
    var item = e.currentTarget;
    if (item.checked) {

    } else {
      if (document.getElementById("deactivate_old_account_checkbox").checked){
        item.checked = true;
      }
    }

  }

  confirmDataMigration(e) {
    $("#confirmDataMigrationStartButton").prop('disabled', false);
    $("#confirmDataMigrationModal").modal("show");
  }

  confirmUserDataMigration(e) {
    $("#confirmDataMigrationStartButton").prop('disabled', false);
    $("#confirmDataMigrationModal").modal("show");
  }

  customerSelectChanged(e) {
    var item = e.currentTarget;
    var form_id = item.form.id;
    if (item && item.dataset && item.dataset.altformid) {
      form_id = item.dataset.altformid;
    }
    console.log("Bless this form id: '"+form_id+"'");

    if (form_id == 'map_demographic_answers_form') {
      $('#sub-account-div').hide();
      $("#from-question-div").hide();
      $("#to-question-div").hide();
      $('#from_question_id').val('');
      $('#to_question_id').val('');
      $("#map_demographics_continue_step1").prop("disabled", true);
    } else if (form_id == 'migrate_user_accounts_form') {
      $('#sub-account-div').hide();
      $("#migrate_user_accounts_step1").prop("disabled", true);
      $('#customer-id-new-div').hide();
      $('#customer_id_new').val('');
    } else if (form_id == 'customer_sub_account_id_new') {
      $('#sub-account-div-new').hide();
      $("#migrate_user_accounts_step1").prop("disabled", true);
    }

    $.ajax({
      url: "/customers/" + item.value + "/get_customer_sub_accounts",
      type: "GET",
      success: function(returnData) {
        if(returnData.status == 200) {
          var data = '<option value>Select...</option>'
          $(returnData.accounts).each(function(index, item){
            data += '<option value="' + item.id + '">' + item.name + ' (' + item.group_content + ')</option>';
          })

          if (form_id == 'map_demographic_answers_form') {
            $('#customer_sub_account_id').html(data);
            $('#sub-account-div').show();
          } else if (form_id == 'migrate_user_accounts_form') {
            $('#customer_sub_account_id').html(data);
            $('#sub-account-div').show();
          } else if (form_id == 'customer_sub_account_id_new') {
            $('#customer_sub_account_id_new').html(data);
            $('#sub-account-div-new').show();
          }
        }
      }
    })
  }

  deactivateOldAssocClicked(e) {
    var item = e.currentTarget;
    if (item.checked) {
      document.getElementById("change_primary_checkbox").checked = true;
    } else {
    }
  }

  destinationSubAccountSelectChanged(e) {
    var item = e.currentTarget;
    if (item.value) {
      $("#migrate_user_accounts_step1").prop("disabled", false);
      $("#migrate-user-additional-options").show();
    } else {
      $("#migrate_user_accounts_step1").prop("disabled", true);
      $("#migrate-user-additional-options").hide();
    }

  }

  questionSelectChanged(e) {
    var item = e.currentTarget;

    var from_question_id = $('#from_question_id').val();
    var to_question_id = $('#to_question_id').val();
    if (from_question_id != '' && to_question_id != '' && from_question_id != to_question_id) {
      clearJsAlerts();
      $("#map_demographics_continue_step1").prop("disabled", false);
    } else if (from_question_id != '' && to_question_id != '' && from_question_id == to_question_id) {
      doJsAlert('<div class="alert alert-danger">Source and destination questions must be different</div>');
      $("#map_demographics_continue_step1").prop("disabled", true);
    } else {
      $("#map_demographics_continue_step1").prop("disabled", true);
    }
  }

  startDataMigration(e) {
    $("#confirmDataMigrationStartButton").prop('disabled', true);
    $("#map_demographics_continue_step_final").prop('disabled', true);
    $("#map_demographics_step_final_form").submit();
  }

  startUserDataMigration (e) {
    $("#confirmDataMigrationStartButton").prop('disabled', true);
    $("#migrate_users_continue_step_final").prop('disabled', true);
    $("#migrate_users_step_final_form").submit();
  }

  subAccountSelectChanged(e) {
    var item = e.currentTarget;
    var form_id = item.form.id;

    if (form_id == 'map_demographic_answers_form') {
      $("#from-question-div").hide();
      $("#to-question-div").hide();
      $('#from_question_id').val('');
      $('#to_question_id').val('');
      $("#map_demographics_continue_step1").prop("disabled", true);

      $.ajax({
        url: "/tools/get_question_list?customer_sub_account_id="+item.value,
        type: "GET",
        success: function(returnData) {
          if(returnData.status == 200  && returnData.question_list) {
            var data = '<option value>Select...</option>'
            $(returnData.question_list).each(function(index, item){
              var additional_info = '';
              if (item.kind == 'standard') {
                additional_info += ' - Standard Question';
              } else if (item.kind == 'custom') {
                additional_info += ' - Custom Question';
              }
              if (item.archived) {
                additional_info += ' - Archived';
              }

              data += '<option value="' + item.id + '">' + item.name + additional_info + '</option>';
            });

            $('#from_question_id').html(data);
            $('#to_question_id').html(data);
            $("#from-question-div").show();
            $("#to-question-div").show();
          }
        }
      });
    } else if (form_id == 'migrate_user_accounts_form') {
      $("#migrate_user_accounts_step1").prop("disabled", true);
      $('#customer-id-new-div').show();
    }


  }

  validateSendTestEmailForm(e) {
    if ($("#email").val() == "" || $("#subject").val() == "" || $("#content").val() == "") {
      e.preventDefault();
    }
  }
}
