import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contracts"
export default class extends Controller {
  
  static targets = ["customer"]


  calculateInvoiceTotal(e) {
    let wrapper = e.currentTarget.closest('.nested-fields');

    let license_total             = parseFloat(wrapper.querySelector("input[id$='license_total']").value)
    let setup_total               = parseFloat(wrapper.querySelector("input[id$='setup_total']").value)
    let one_time_total            = parseFloat(wrapper.querySelector("input[id$='one_time_total']").value)
    let leadership_license_total  = parseFloat(wrapper.querySelector("input[id$='leadership_license_total']").value)
    let pharmacist_total          = parseFloat(wrapper.querySelector("input[id$='pharmacist_total']").value)
    let dentist_total             = parseFloat(wrapper.querySelector("input[id$='dentist_total']").value)

    wrapper.querySelectorAll("input[class*='invoice_total']").forEach(el => { 
      el.value = (license_total + setup_total + leadership_license_total + one_time_total + pharmacist_total + dentist_total).toFixed(2)
    })
  } 

  calculateRoyalties(e) {
    e.preventDefault()
    let wrapper = e.currentTarget.closest('.nested-fields')

    const date = new Date(wrapper.querySelector("input[id$='invoice_date']").value)

    let license_total             = wrapper.querySelector("input[id$='license_total']").value
    let setup_total               = wrapper.querySelector("input[id$='setup_total']").value
    let one_time_total            = wrapper.querySelector("input[id$='one_time_total']").value
    let leadership_license_total  = wrapper.querySelector("input[id$='leadership_license_total']").value
    let pharmacist_total          = wrapper.querySelector("input[id$='pharmacist_total']").value
    let dentist_total             = wrapper.querySelector("input[id$='dentist_total']").value


    wrapper.querySelectorAll("input[class*='license_royalty']").forEach(el => {
      var percentage = ''
      if(date < new Date('2023-01-01')) {
        el.value = (parseFloat(license_total) * 0.55).toFixed(2)
        percentage = '(55%)'
      } else {
        el.value = (parseFloat(license_total) * 0.45).toFixed(2)
        percentage = '(45%)'
      }

      wrapper.querySelectorAll("small[class*='license-royalty-label']").forEach(el2 => {
        el2.innerText = percentage
      })
    })

    wrapper.querySelectorAll("input[class*='one_time_royalty']").forEach(el => { 
      el.value = (parseFloat(one_time_total) * 0.05).toFixed(2)
    });

    wrapper.querySelectorAll("input[class*='setup_royalty']").forEach(el => {
      el.value = (parseFloat(setup_total) * 0.05).toFixed(2)
    })

    wrapper.querySelectorAll("input[class*='one_time_royalty']").forEach(el => { 
      el.value = (parseFloat(one_time_total) * 0.05).toFixed(2)
    });

    wrapper.querySelectorAll("input[class*='leadership_license_impact_royalty']").forEach(el => { 
      el.value = (parseFloat(leadership_license_total) * 0.45).toFixed(2) 
    })
    
    wrapper.querySelectorAll("input[class*='logo_license_royalty']").forEach(el => {
      el.value = (parseFloat(license_total) * 0.02).toFixed(2)
    })

    wrapper.querySelectorAll("input[class*='logo_setup_royalty']").forEach(el => {
      el.value = (parseFloat(setup_total) * 0.02).toFixed(2)
    })

    wrapper.querySelectorAll("input[class*='one_time_logo_field_royalty']").forEach(el => {
      el.value = (parseFloat(one_time_total) * 0.02).toFixed(2)
    })

    wrapper.querySelectorAll("input[class*='pharmacist_royalty']").forEach(el => {
      var percentage = ''
      if(date <= new Date('2021-07-09')) {
        // no fee
        percentage = '(0%)'
      } else if(date >= new Date('2021-07-10') && date <= new Date("2022-07-09")) {
        el.value = (parseFloat(pharmacist_total) * 0.10).toFixed(2)
        percentage = '(10%)'
      } else if(date >= new Date('2022-07-10') && date <= new Date("2022-12-31")) {
        el.value = (parseFloat(pharmacist_total) * 0.15).toFixed(2)
        percentage = '(15%)'
      }

      wrapper.querySelectorAll("small[class*='pharmacist-royalty-label']").forEach(el2 => {
        el2.innerText = percentage
      })
    })

    wrapper.querySelectorAll("input[class*='dentist_royalty']").forEach(el => {
      var percentage = ''
      if(date <= new Date('2022-11-01')) {
        el.value = (parseFloat(dentist_total) * 0.30).toFixed(2)
        percentage = '(30%)'
      } else if(date >= new Date('2022-11-02') && date <= new Date("2022-12-31")) {
        el.value = (parseFloat(dentist_total) * 0.55).toFixed(2)
        percentage = '(55%)'
      }

      wrapper.querySelectorAll("small[class*='dentist-royalty-label']").forEach(el2 => {
        el2.innerText = percentage
      })
    })

    wrapper.querySelectorAll("input[class*='royalty_total']").forEach(el => {
      el.value = ( parseFloat(wrapper.querySelector("input[id$='license_royalty']").value) + 
                   parseFloat(wrapper.querySelector("input[id$='setup_royalty']").value) + 
                   parseFloat(wrapper.querySelector("input[id$='logo_license_royalty']").value) + 
                   parseFloat(wrapper.querySelector("input[id$='logo_setup_royalty']").value) + 
                   parseFloat(wrapper.querySelector("input[id='one_time_logo_royalty_field']").value) + 
                   parseFloat(wrapper.querySelector("input[id$='one_time_royalty']").value) + 
                   parseFloat(wrapper.querySelector("input[id$='leadership_license_royalty']").value) +
                   parseFloat(wrapper.querySelector("input[id$='pharmacist_royalty']").value) + 
                   parseFloat(wrapper.querySelector("input[id$='dentist_royalty']").value)).toFixed(2)
    })

  }

  changeContractDate(e) {
    let start_date = document.getElementById('contract_start_date').value
    let years = document.getElementById('contract_years').value
    if(start_date != '' && years != '') {
      const date = new Date(start_date)
      let new_date = new Date(date.setFullYear(date.getFullYear() + parseInt(years))).toISOString().split('T')[0];
      Array.from(document.getElementsByClassName("contract-end-date")).forEach(
        function(element, index, array) {
            element.value = new_date
        }
      );
    }
  }



  changeInvoiceDate(e) {
    const date = new Date(e.currentTarget.value)
    let wrapper = e.currentTarget.closest('.nested-fields')
    let net_terms = wrapper.querySelector("select[id$='net_days']").value 
    if(net_terms != '') {      
      wrapper.querySelector("input[id$='due_date']").value = new Date(date.setDate(date.getDate() + parseInt(net_terms))).toISOString().split('T')[0]
    }

    if(date < new Date("2023-01-01")){
      wrapper.querySelectorAll("div[class*='additional-license-fees']").forEach(el => { 
        el.classList.remove('hidden')
      })
    } else {
      wrapper.querySelectorAll("div[class*='additional-license-fees']").forEach(el => { 
        el.classList.add('hidden')
      })
      wrapper.querySelector("input[id$='pharmacist_total']").value = 0.00
      wrapper.querySelector("input[id$='dentist_total']").value = 0.00
      
      wrapper.querySelectorAll("input[class*='pharmacist_royalty']").forEach(el => {
        el.value = 0.00
      })

      wrapper.querySelectorAll("input[class*='dentist_royalty']").forEach(el => {
        el.value = 0.00
      })
    }

    this.calculateInvoiceTotal(e)
    this.calculateRoyalties(e)
  }


  changeNetTerms(e) {
    let wrapper = e.currentTarget.closest('.nested-fields')
    let invoice_date = wrapper.querySelector("input[id$='invoice_date']").value
    if(invoice_date != '') {
      const date = new Date(invoice_date)
      wrapper.querySelector("input[id$='due_date']").value = new Date(date.setDate(date.getDate() + parseInt(e.currentTarget.value))).toISOString().split('T')[0]
    }
  }

  clearTooltips() {
    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })
  }


  configureSelect2Fields(selector, options){
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }


  connect() {
    console.log("CONTRACTS: connect")
    if( document.getElementById('contract_form') ){
      // https://psmy.medium.com/rails-6-stimulus-and-select2-de4a4d2b59e4

      this.configureSelect2Fields('.select2', {})

      $('#contract_customer_id').on('select2:select', function(){
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event)
      })
    }

    if( document.getElementById('contracts_table_holder') ){
      $('#account_management_table').bootstrapTable({
        search: "true",
        searchable: "true"
      })
    }
  }


  delete(e) {
    e.preventDefault()
    var ans = confirm('Are you sure you want to delete this contract?')

    if(ans) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: e.currentTarget.href,
        method: 'PATCH',
        success: function (e) {
          if(e.status == 200) {
            var frame = document.querySelector('turbo-frame#customer_contracts')
            frame.reload();
          } else {
            alert("Issue deleteing contract. Please try again.")
          }
        }
      });
    }

    this.clearTooltips()
  }

  fulfilled(e) {
    e.preventDefault()
    var ans = confirm('Are you sure you want to mark this contract fulfilled? Fulfilling the contract will remove it from ongoing ARR.')
    if(ans) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: e.currentTarget.href,
        method: 'PATCH',
        success: function (e) {
          if(e.status == 200) {
            var frame = document.querySelector('turbo-frame#customer_contracts')
            frame.reload();
          } else {
            alert("Issue marking contract fulfilled. Please try again.")
          }
        }
      });
    }
  }



  save(event) {
    event.preventDefault()

    var formData = $('#contract_form').serializeArray();

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: event.currentTarget.action,
      method: 'POST',
      data: formData,
      success: function (e) {
        if(e.status == 200) {
          if(document.querySelector('turbo-frame#customer_contracts') != undefined) {
            var frame = document.querySelector('turbo-frame#customer_contracts')
            frame.reload();
          } else if (document.getElementById('arr_submit_button') != undefined) {
            document.getElementById('arr_submit_button').click()
          }

          $('#system_modal_popup').modal('hide')
        } else {
          alert("Issue saving contract. Please try again.")
        }
      }
    });

    this.clearTooltips()
  }



  selectCustomer(event) {
    document.getElementById('contract_customer_documents_attributes_0_customer_id').value = event.currentTarget.value    
  }

  
  

}
