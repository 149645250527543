import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap";

// Connects to data-controller="resources"
export default class extends Controller {
  ckeditorInstance = undefined;

  connect() {
    const controllerRef = this
    this.initializeCkEditor()
    this.initializeSortables()
  }

  allowCopy(event) {
    event.preventDefault();
    var item = event.target;
    var id = item.parentNode.dataset.id
    $('#resource_' + id + '_do_not_copy').val(false);
    $('#resource_content_' + item.parentNode.dataset.id).removeClass('do-not-copy');
    $("#collapse_" + item.parentNode.dataset.id).removeClass("hidden");
    $("#do_not_copy_btn_" + item.parentNode.dataset.id).removeClass("hidden");
    $('#collapse_toggle_btn_' + item.parentNode.dataset.id).removeClass('hidden');
    item.parentNode.classList.add('hidden');
  }

  changeResourceSection(event) {
    if ( event.target.value != "" ) {

      $.get("/resources/find_resource_categories_for_section", { id: event.target.value }, function(data){
        if(data.length > 0) {
          $(".resource-category").each(function () {
            this.checked = false;
          });

          $(data).each(function(){
            $('#resource_resource_display_resource_category_id_' + $(this)[0]['resource_category_id']).prop("checked", true);
          });
        }
      });
    }
  }

  deleteResource(event) {
    const controllerRef = this
    event.preventDefault();
    var item = event.currentTarget

    var ans = confirm(i18n_string("resources.are_you_sure_you_want_to_delete_this_resource", "Are you sure you want to delete this resource?"));
    if(ans) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: item.href, 
        type: 'DELETE', 
        success: function(e){
          if(e.status == 200) {
            if(item.name == "individual_resource") {
              location.href = "/resources/manage";
            } else {
              $('#' + item.name).remove();
            }
          }
        }
      });
    }
  }

  deleteImage(event) {
    var ans = confirm(i18n_string("resources.are_you_sure_you_want_to_delete_this_image", "Are you sure you want to delete this image?"));
    if(!ans) {
      event.preventDefault()
    }
  }

  deselectAllNational(event){
    event.preventDefault()
    var item = event.currentTarget
    $(item).closest('form').find('.card').each(function() {
      $(this).children('.card-body').removeClass('disabled')
    })

    $(item).closest('form').find('input[type=checkbox]').each(function(){
      $(this).prop('checked', true)
      $(this).click()
    })
  }

  doNotCopy(event) {
    event.preventDefault();
    var item = event.target;
    var id = item.parentNode.dataset.id
    $('#resource_' + id + '_do_not_copy').val(true);
    $('#resource_content_' + item.parentNode.dataset.id).addClass('do-not-copy');
    $("#collapse_" + item.parentNode.dataset.id).addClass("hidden");
    $("#allow_copy_btn_" + item.parentNode.dataset.id).removeClass("hidden");
    $('#collapse_toggle_btn_' + item.parentNode.dataset.id).addClass('hidden');
    item.parentNode.classList.add('hidden')
  }

  showResourceForm(event) {
    this.modal = new Modal(document.getElementById('resourceModal'), {
      keyboard: false
    });

    this.modal.show()
  }

  toggleTab(event) {
    $('a.nav-link').removeClass('active');
    $(event.currentTarget).addClass("active")
  }


  initializeCkEditor() {
    // Initialize CKEditor    
    if($("#resource_content").length) {
      setTimeout(() => {
        if(!$("#resource_content").data("ckeditor_initialized")) {
          $("#resource_content").data("ckeditor_initialized", 1);
          this.ckeditorInstance = CKEDITOR.replace("resource_content", {
            height: '300px',
            toolbar: [
              {
                name: 'basicstyles',
                groups: ['basicstyles', 'cleanup'],
                items: ['Bold', 'Italic','RemoveFormat','-']
              },
              { name: 'links', items: ['Link','Unlink'] },
              {
                name: 'paragraph',
                groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
                items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
              },
              {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
              {name: 'colors', items: ['TextColor', 'BGColor']},
              {name: 'other', items: ['Image', 'Table']}
            ],
            filebrowserBrowseUrl: '/ckeditor/browse?type=files',
            filebrowserImageBrowseUrl: '/ckeditor/browse?type=images'
          });

          // http://handsomedogstudio.com/ckeditor-set-default-target-blank
          /* Here we are latching on an event ... in this case, the dialog open event */
          CKEDITOR.on('dialogDefinition', function(ev) {
            try {
              /* this just gets the name of the dialog */
        
              var dialogName = ev.data.name;
              /* this just gets the contents of the opened dialog */
        
              var dialogDefinition = ev.data.definition;
              /* Make sure that the dialog opened is the link plugin ... otherwise do nothing */

              if(dialogName == 'link') {
                
                /* Getting the contents of the Target tab */
                var informationTab = dialogDefinition.getContents('target');
              
                /* Getting the contents of the dropdown field "Target" so we can set it */
                var targetField = informationTab.get('linkTargetType');
              
                /* Now that we have the field, we just set the default to _blank. A good modification would be to check the value of the URL field and if the field does not start with "mailto:" or a relative path, then set the value to "_blank" */
                targetField['default'] = '_blank';
              
              }
            } catch(exception) {
              alert('Error ' + ev.message);
            }
          });
        }
      }, 500)
    }
    if($(".at-risk-ckeditor").length) {
      setTimeout(() => {
        $(".at-risk-ckeditor").each(function(){
          if(!$(this).data("ckeditor_initialized")) {
            $(this).data("ckeditor_initialized", 1);
            this.ckeditorInstance = CKEDITOR.replace(this.id, {
              height: '300px',
              toolbar: [
                {
                  name: 'basicstyles',
                  groups: ['basicstyles', 'cleanup'],
                  items: ['Bold', 'Italic','RemoveFormat','-']
                },
                { name: 'links', items: ['Link','Unlink'] },
                {
                  name: 'paragraph',
                  groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
                  items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                },
                {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
                {name: 'colors', items: ['TextColor', 'BGColor']},
                {name: 'other', items: ['Image', 'Table']}
              ],
              filebrowserBrowseUrl: '/ckeditor/browse?type=files',
              filebrowserImageBrowseUrl: '/ckeditor/browse?type=images'
            });
          }
        });
      }, 500)
    }
  }

  initializeSortables(e) {
    $("#sortable-resources").sortable({
      placeholder: "sortable-resources-ui-state-highlight",
      update: function (event, ui) {
        if (!document.is_promoter) {
          var data = $(this).sortable('toArray', {attribute: 'data-display_id'});
          var id = $(this).data('id')
          //console.dir(data);
          $.ajax({
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            data: {display_order: data},
            type: 'PATCH',
            url: '/resources/' + id + '/update_sub_account_order',
            success: function (returndata) {
              if (returndata != "") {
                var alert = $('<div class="alert alert-sm alert-success" style="display:none;">' + returndata + '</div>');
                $("#sort-save-msg-container").append(alert);
                alert.fadeIn(400, function () {
                  $(this).delay(800).fadeOut();
                });
              }
            }
          });
        }
      }
    });
    $("#sortable-resources").disableSelection();
  }

  // data-action="click->resources#logResourceClick" data-resid="[resource id]"
  logResourceClick(e) {
    let item = event.currentTarget;
    let id = $(item).data("resid");
    if (id) {
      log_event(window.location.pathname, "clicked_resource_id="+id);
    }
  }

  selectAllNational(event){
    event.preventDefault()
    var item = event.currentTarget
    $(item).closest('form').find('.card').each(function() {
      $(this).children('.card-body').addClass('disabled')
    })

    $(item).closest('form').find('input[type=checkbox]').each(function(){
      $(this).prop('checked', false)
      $(this).click()
    })
  }


  toggleConditional(event) {
    var item = event.currentTarget;
    var value = item.value
    
    document.getElementById('signup_custom_question_div_' + value).classList.toggle("hidden");
  }


  toggleCountryList(event) {
    var item = event.currentTarget
    if(item.value == 'national_resources') {
      document.getElementById('national_resource_select').classList.remove('hidden')
    } else {
      document.getElementById('national_resource_select').classList.add('hidden')
    }
  }

  toggleNationalResourceDisplay(event) {
    let item = event.currentTarget
    var elem = $(item).closest('.card').children('.card-body')
    var kind = ''

    if($(elem).hasClass('disabled')){
      $(elem).removeClass("disabled")
    } else {
      $(elem).addClass("disabled")
    }
  }

  validate_admin_resource_form(event) {
    let num_categories_checked = 0;
    $(".resource-category").each(function () {
      if (this.checked){
        num_categories_checked++;
      }
    });
    if (num_categories_checked == 0) {
      event.preventDefault();
      let error_text = i18n_string("resources.you_must_select_at_least_one_resource_category", "You must select at least one resource category.");
      doJsAlert('<div class="alert alert-danger">'+error_text+'</div>');
    }
  }
  
}
