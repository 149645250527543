import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="conditional-question"
export default class extends Controller {
  initialize() {
  }

  connect() {
    this.element[this.identifier] = this;

    let $element = $(this.element);
    $element.data("last_value", $element.val());
  }

  process_input_change (e){
    let $element = $(e.currentTarget);
    let last_value = $element.data("last_value");
    let current_value = $element.val();
    if (last_value != current_value) {
      $element.data("last_value", current_value);
      let question_id = $element.data("questionId");
      // Check for "Other Selection"
      let other_options = $element.data("other");
      let have_other_selected = false;
      if (other_options && other_options.length > 0) {
        for(let i=0;i<other_options.length;i++){
          if (current_value == other_options[i][0]){
            have_other_selected = true;
            let other_name = "Other";
            if (other_options[i][1]) {
              other_name = other_options[i][1];
            }
            $("#label-other-"+question_id).html(other_name);
            $("#other-text-container-"+question_id).removeClass("hidden");
            break;
          }
        }
      }
      if (have_other_selected) {
        // If we have an "Other" option selected we should remove any children questions for this question since we can not have both
        if ($(".child-question-"+question_id).length) {
          $(".child-question-" + question_id).remove();
        }
      } else {
        $("#other-text-container-"+question_id+" input[type=text]").val("");
        $("#other-text-container-"+question_id).addClass("hidden");
        // If not then check if we need to load conditional
        if ($element.data("subQuestions")) {
          this.load_conditional_question (e);
        }
      }
    }
  }

  async load_conditional_question (e){
    let $element = $(e.currentTarget);
    let last_value = $element.data("last_value");
    let current_value = $element.val();
    let question_id = $element.data("questionId");
    // console.log("load_conditiona_question -- last_value:"+last_value+" -- question_id="+question_id);

    // Make sure value actually changed
    // if (last_value != current_value) {
    //   $element.data("last_value", current_value);
      // Remove old dropdown
      let postData = {
        question_id: question_id, option_id: current_value
      }
      $(".child-question-"+question_id).remove();
      if ($element.closest(".customer-sub-account-container").length) {
        postData.load_for_customer_sub_account_id = $element.closest(".customer-sub-account-container").data("id");
      }
      // Load dependent question from server
      if (Number(current_value) > 0) {
        const request = new FetchRequest("post", "/signup-questions/sub_question",
            {
              responseKind: "html",
              body: postData
            });
        const response = await request.perform();
        // console.dir(response);
        if (response.ok) {
          // console.log("OK="+response.json);
          // console.dir(response.json);
          response.html.then(function(formData){
            // formData.options
            //console.log(formData);
            $element.closest(".form-control-outer-container").after(formData);
          });
        }
      }
    //}
  }
}
