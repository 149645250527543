import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="two-factor-authentication"
export default class extends Controller {
  connect() {
  }

  setup_backup_code_download(e) {
    e.preventDefault();
    let html_content = $("#backup-code-div").html();
    $("#download-backup-code-content").val(html_content);
    $("#download-backup-code-form").submit();
  }

  setup_backup_code_print(e) {
    e.preventDefault();
    $("body").addClass("print-backup-codes");
    window.print();
  }

  setup_backup_code_verify(e) {
    let button = $(e.currentTarget)
    let message = button.data("verify-message");
    if (!confirm(message)) {
      e.preventDefault();
    }
  }

  setup_sms_resend_code(e) {
    e.preventDefault();
    this.setup_sms_step1_continue(e, true);
  }

  setup_sms_step1_continue(e,resend_code) {
    e.preventDefault();
    if ($("#error-message-2fa").length) {
      $("#error-message-2fa").remove();
    }
    if ($("#success-message-2fa").length) {
      $("#success-message-2fa").remove();
    }
    let button_ref = $(e.currentTarget);
    button_ref.prop('disabled', true);

    // Verify form filled out
    let phoneCountryCode = $('.selected-dial-code').html();
    let phoneNumber = $('#user_sms_phone').val();
    if (phoneCountryCode) {
      phoneCountryCode = phoneCountryCode.replace(/[^0-9]/g, '');
    }
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    }
    let phoneToVerify = phoneCountryCode+phoneNumber;
    if (phoneCountryCode && phoneNumber) {
      // Send via ajax to send sms
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/account/2fa/send_2fa_code_to_phone',
        data: {phone: phoneToVerify},
        type: 'post',
        success: function(e) {
          // console.log(e);
          if(e.status == 500) {
            if (resend_code) {
              $('#2fa_sms_step2_div').prepend("<p class='text-danger' id='error-message-2fa'>" + e.message + "</p>");
            } else {
              $('#2fa_sms_step1_div').prepend("<p class='text-danger' id='error-message-2fa'>" + e.message + "</p>");
            }
          } else {
            if (resend_code) {
              $("#2fa_sms_step2_div").prepend("<p class='text-success' id='success-message-2fa'>" + e.message + "</p>");
            } else {
              // Switch div to step 2
              $("#2fa_sms_step1_div").hide();
              $("#2fa_sms_step2_div").show();
            }
          }
          button_ref.prop('disabled', false);
        }
      });

    }
  }

  setup_sms_step2_back(e) {
    e.preventDefault();
    $("#sms_code").val("");
    $("#2fa_sms_step2_div").hide();
    $("#2fa_sms_step1_div").show();
  }

  setup_sms_step2_verify(e) {
    e.preventDefault();
    let code = $("#sms_code").val();
    // console.log("CODE="+code);
    if ($("#error-message-2fa").length) {
      $("#error-message-2fa").remove();
    }
    let button_ref = $(e.currentTarget);
    button_ref.prop('disabled', true);
    if (code) {
      // Send via ajax to verify code
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/account/2fa/verify_sms_2fa_code',
        data: {code: code},
        type: 'post',
        success: function(e) {
          console.log(e)
          if(e.status == 500) {
            $('#2fa_sms_step2_div').prepend("<p class='text-danger' id='error-message-2fa'>" + e.message + "</p>");
            button_ref.prop('disabled', false);
          } else {
            // Submit Form
            $("#new_two_factor_authentication").submit();
          }
        }
      });
    }
  }

  setup_totp_show_key(e) {
    e.preventDefault();
    $("#totp_qr_key_wrapper").show();
  }

  setup_totp_verify(e) {
    e.preventDefault();
    let code = $("#totp_code").val();
    if ($("#error-message-2fa").length) {
      $("#error-message-2fa").remove();
    }
    let button_ref = $(e.currentTarget);
    button_ref.prop('disabled', true);
    if (code) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/account/2fa/verify_totp_2fa_code',
        data: {code: code},
        type: 'post',
        success: function(e) {
          console.log(e)
          if(e.status == 500) {
            $('#setup_totp_message_div').prepend("<p class='text-danger' id='error-message-2fa'>" + e.message + "</p>");
            button_ref.prop('disabled', false);
          } else {
            // Submit Form
            $("#new_two_factor_authentication").submit();
          }
        }
      });
    }
  }

  verify_delete(e) {
    let button = $(e.currentTarget)
    let message = button.data("verify-message");
    if (!confirm(message)) {
      e.preventDefault();
    }
  }
}
