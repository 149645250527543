import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="account-switcher"
export default class extends Controller {
  initialize() {
    let title = i18n_string("general.switch_accounts","Switch Accounts")
    let close = i18n_string("general.close","Close")
    setupBootstrapModal("switch-account-modal", title, '', '<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">'+close+'</button>', 'body', '', '');
  }
  connect() {
    this.element[this.identifier] = this;
    console.log("connect");
  }
  loadingHTML(){
    let loading_html = "";
    loading_html += '';
    loading_html += '  <p>'+i18n_string("general.select_the_account_you_want_to_switch_to","Select the account you want to switch to.")+'</p>';
    loading_html += '  <div class="list-group account-switch-list placeholder-glow">';
    loading_html += '    <a class="list-group-item list-group-item-action disabled">';
    loading_html += '      <span class="switch-icon">&nbsp;</span>';
    loading_html += '      <span class="switch-type"><span class="placeholder w-50"></span></span>';
    loading_html += '      <span class="switch-info"><span class="placeholder w-100"></span></span>';
    loading_html += '    </a>';
    loading_html += '    <a class="list-group-item list-group-item-action disabled">';
    loading_html += '      <span class="switch-icon">&nbsp;</span>';
    loading_html += '      <span class="switch-type"><span class="placeholder w-50"></span></span>';
    loading_html += '     <span class="switch-info"><span class="placeholder w-100"></span></span>';
    loading_html += '    </a>';
    loading_html += '    <a class="list-group-item list-group-item-action disabled">';
    loading_html += '      <span class="switch-icon">&nbsp;</span>';
    loading_html += '      <span class="switch-type"><span class="placeholder w-50"></span></span>';
    loading_html += '      <span class="switch-info"><span class="placeholder w-100"></span></span>';
    loading_html += '    </a>';
    loading_html += '  </div>';
    return loading_html
  }
  async loadModalContent() {
    $("#switch-account-modal .modal-body").html(this.loadingHTML());
    console.log("loadModalContent");

    const request = new FetchRequest("get", "/users/switch_account_modal",
        {
          responseKind: "html"
        });
    const response = await request.perform();
    if (response.ok) {
      response.html.then(function(modalHTML){
        $("#switch-account-modal .modal-body").html(modalHTML);
      });
    }
  }
}
