import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="advanced-report-folders"
export default class extends Controller {
  static targets = [ "folderList", "unassignedReportList" ]

  confirm_delete_folder (e) {
    let confirm_message = i18n_string("advanced_reports.are_you_sure_you_want_to_delete_this_folder", "Are you sure you want to delete this report folder? Any sub-folders will be re-assigned to the top level. Your reports will NOT be deleted.")
    if (!confirm(confirm_message)) {
      e.preventDefault();
    }
  }

  confirm_delete_report (e) {
    let confirm_message = i18n_string("advanced_reports.are_you_sure_you_want_to_delete_this_report", "Are you sure you want to delete this report?");
    if (!confirm(confirm_message)) {
      e.preventDefault();
    }
  }

  connect() { 

    if($('#wellness_snapshot_folders').length) {
      this.edit_folders()
    }

  }

  edit_folders(event = null) {
    if(event !== null){
      event.preventDefault();

      let $elem = $(event.currentTarget);
      $elem.toggleClass("opened");
      this.toggle_folder_status($elem);
    }

    $('#folder-list ol').nestedSortable({
      handle: '.dd-content',
      items: 'li',
      toleranceElement: '.dd-content',
      maxLevels: 3,
      relocate: this.update_nested_order,

      forcePlaceholderSize: true,

      // opacity: .6,
      // placeholder: 'placeholder',
      revert: 250,
      tabSize: 25,
      tolerance: 'pointer',
      isTree: true,
      expandOnHover: 700,
      // startCollapsed: false
    });

    $('#create_report_folder').attr('disabled') == 'false' ? 'true' : 'false'
  }

  folder_click(event) {
    let elem = event.currentTarget;
    $(elem).children('em').toggleClass('fa-folder fa-folder-open');
    // console.log($('#parent_' + $(elem).data('id')));
    // $('#parent_' + $(elem).data('id')).toggleClass('hidden');
    $(".parent-folder-" + $(elem).data('id')).toggleClass('hidden');
  }

  mouseleave_dd_content(event) {
    let elem = event.currentTarget;
    $(elem).children('.folder-options').hide();
  }
  mouseover_dd_content(event) {
    let elem = event.currentTarget;
    $(elem).children('.folder-options').show();
  }

  new_report_folder(event){

  }

  toggle_folder_status(btn) {

    if($(btn).hasClass('opened')){
      $(btn).html('<em class="fa fa-check"></em> '+i18n_string("advanced_reports.finish_editing_folders", "Finish Editing Folders")).removeClass('btn-outline-primary').addClass('btn-success');
      $('.sub-level').removeClass("hidden");
      $('.handle-holder').removeClass("hidden");
      $('#create_report_folder').attr('disabled', 'disabled');
      $('.assign-folders').attr('disabled', 'disabled');
      //$('.dd-item').addClass("ps-5");
      // reloadSorting();

    } else {
      $(btn).html('<em class="fa fa-pencil"></em> '+i18n_string("advanced_reports.edit_report_folders", "Edit Report Folders")).addClass('btn-outline-primary').removeClass('btn-success');
      $('.sub-level').addClass('hidden');
      $('.handle-holder').addClass("hidden");
      $('#create_report_folder').removeAttr('disabled');
      $('.assign-folders').removeAttr('disabled');
      //$('.dd-item').removeClass("ps-5");
    }

    $.each($('button[data-bs-toggle="collapse"]'), function(){

      $(this).children('em').removeClass('fa-folder-open').addClass('fa-folder');

      if($(this).attr('disabled')) {
        $(this).removeAttr('disabled');
      } else {
        $(this).attr("disabled", "disabled");
      }

      $(this).attr('aria-expanded', false);
      var id = $(this).attr('aria-controls');
      $('#' + id).removeClass('show');
      $('#' + id).attr('aria-expanded', false);
    });
  }

  update_nested_order(){
    let data = $('#folder-list ol').nestedSortable('toArray', {startDepthCount: 0});
    let postData = {}; // folder[folder_id] = parent_id / position[folder_id] = i
    for(let i=0; i<data.length; i++) {
      if (data[i]['id']) {
        postData['folder['+data[i]['id']+']'] = data[i]['parent_id']
        postData['position['+data[i]['id']+']'] = i
      }
    }
    let update_folder_order_url = $('#folder-list').data('folder-order-url');

    $.ajax({
      url: update_folder_order_url,
      type: 'post',
      data: postData,
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'json',
      success: function (data) {
        window.reloadFolders = true;
        if(data.status != "success") {
          alert(i18n_string("advanced_reports.error_saving_folder_order", "Error saving folder order."));
        }
      }
    });

  }
}
