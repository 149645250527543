import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="validate-form"
export default class extends Controller {
  connect() {
    // console.log("Validate Form Controller:connect");
    this.element.addEventListener('turbo:submit-start', this.validateForm);
    this.element.addEventListener('submit', this.validateForm);
    this.element.noValidate = false;
    // change event listener
    this.element.addEventListener('change', this.checkChangedInputs);
  }

  checkChangedInputs(event){
    if (event && event.target) {
      if (event.target.type == 'radio') {
        let oneChecked = false;
        if (event.target.name) {
          let radioFields = document.getElementsByName(event.target.name);
          radioFields.forEach((rField) => {
            if (rField.checked) {
              oneChecked = true;
            }
          });
        }
        let parentContainer = event.target.closest(".radio-group")
        if (oneChecked && parentContainer && parentContainer.classList && parentContainer.classList.contains('is-invalid') && event.target.classList && (event.target.classList.contains('required') || event.target.required)) {
          parentContainer.classList.remove('is-invalid');
        }
      } else {
        if (event.target.classList && event.target.classList.contains('is-invalid') && (event.target.classList.contains('required') || event.target.required)) {
          if ($(event.target).val()) {
            event.target.classList.remove('is-invalid');
          }
        }
      }
    }
  }

  validateForm(event, container = undefined){
    let formElement = event.target;
    let alertContainerId = 'message-container';
    console.dir(formElement.dataset);
    if (formElement.dataset && formElement.dataset.alert_container) {
      alertContainerId =  formElement.dataset.alert_container;
    } else if (formElement.dataset && formElement.dataset.alertContainer) {
      alertContainerId =  formElement.dataset.alertContainer;
    } else if(container !== undefined) {
      alertContainerId = container
    }
    if (formElement.dataset && formElement.dataset.disableSubmitButtons) {
      let disabled_submit_button_list = formElement.querySelectorAll('button[type="submit"], input[type="submit"]');
      disabled_submit_button_list.forEach((button) => {
        button.disabled = true;
      });
    }
    // console.log("Validate Form Controller:validateForm");

    let isValid = true;

    let requiredFieldSelectors = 'textarea:required, input:required, select:required, textarea.required, input.required, select.required';
    let requiredFields = formElement.querySelectorAll(requiredFieldSelectors);

    let errors = [];
    let requiredMissingWithoutError = 0;
    requiredFields.forEach((field) => {
      if(field.type == 'radio') {
        let oneChecked = false;
        if (!field.disabled && isVisible(field) && field.name) {
          let radioFields = document.getElementsByName(field.name);
          radioFields.forEach((rField) => {
            if (rField.checked) {
              oneChecked = true;
            }
          });
        }
        let parentContainer = field.closest(".radio-group")
        if (oneChecked) {
          if (parentContainer) {
            parentContainer.classList.remove('is-invalid');
          }
        } else {
          requiredMissingWithoutError++;
          if (parentContainer) {
            parentContainer.classList.add('is-invalid');
          }
          isValid = false;
        }
      } else {
        if (!field.disabled && !field.value.trim() && isVisible(field)) {
          // field.focus();
          if (field.dataset && field.dataset.error) {
            errors.push(field.dataset.error)
          } else {
            requiredMissingWithoutError++;
          }
          field.classList.add('is-invalid');
          isValid = false;
        } else {
          field.classList.remove('is-invalid');
        }
      }
    });

    let conditionalRequires = 'textarea.conditional-required, input.conditional-required, select.conditional-required';
    let conditionalRequiredFields = formElement.querySelectorAll(conditionalRequires);

    conditionalRequiredFields.forEach((field) => {
      if (field.dataset && (field.dataset.conditionalId || field.dataset.conditional_id)) {
        let conditionalField = document.getElementById(field.dataset.conditionalId ?? field.dataset.conditional_id);

        if (conditionalField && !conditionalField.disabled && conditionalField.value.trim() && isVisible(conditionalField)) {
          if(field.type == 'radio') {
            let oneChecked = false;
            if (!field.disabled && isVisible(field) && field.name) {
              let radioFields = document.getElementsByName(field.name);
              radioFields.forEach((rField) => {
                if (rField.checked) {
                  oneChecked = true;
                }
              });
            }
            let parentContainer = field.closest(".radio-group")
            if (oneChecked) {
              if (parentContainer) {
                parentContainer.classList.remove('is-invalid');
              }
            } else {
              requiredMissingWithoutError++;
              if (parentContainer) {
                parentContainer.classList.add('is-invalid');
              }
              isValid = false;
            }
          } else {
            if (!field.disabled && !field.value.trim() && isVisible(field)) {
              if (field.dataset && field.dataset.error) {
                errors.push(field.dataset.error)
              } else {
                requiredMissingWithoutError++;
              }
              field.classList.add('is-invalid');
              isValid = false;
            } else {
              field.classList.remove('is-invalid');
            }
          }
        }
      }
    });
    formElement.querySelectorAll("input.confirm-password").forEach((field) => {
      if (field.dataset && (field.dataset.conditionalId || field.dataset.conditional_id)) {
        let conditionalField = document.getElementById(field.dataset.conditionalId ?? field.dataset.conditional_id);

        if (conditionalField && !conditionalField.disabled && conditionalField.value.trim() && isVisible(conditionalField)) {
          if (!field.disabled && field.value != conditionalField.value && isVisible(field)) {
            errors.push(i18n_string("users.account.error_password_confirmation_does_not_match","Password confirmation does not match."));
            field.classList.add('is-invalid');
            isValid = false;
          } else {
            field.classList.remove('is-invalid');
          }
        }
      }
    });


    if (!isValid){
      event.preventDefault();
      // Re-enabled submit buttons
      if (formElement.dataset && formElement.dataset.disableSubmitButtons) {
        let disabled_submit_button_list = formElement.querySelectorAll('button[type="submit"], input[type="submit"]');
        disabled_submit_button_list.forEach((button) => {
          button.disabled = false;
        });
      }
      if (errors.length == 0 || requiredMissingWithoutError > 0) {
        errors.unshift(i18n_string("general.make_sure_all_required_fields_are_completed","Make sure all required fields are completed."));
      }
      doJsAlert('<div class="alert alert-danger">'+removeDuplicates(errors).join('<br />')+'</div>', alertContainerId);
    }

    return isValid;
  }
}