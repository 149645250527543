import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer-sub-account-search"
export default class extends Controller {
  connect() {
    $('.main-choose-location').on('shown.bs.dropdown', function () {
      $("#main-choose-location-search").focus();
    });
    document.account_search_input_id = "main-choose-location-search";
  }
  refreshSearch(event){
    // console.log("refreshSearch");
    let searchStr = $("#"+document.account_search_input_id).val();
    let numShown = 0;
    // console.log("searchStr='"+searchStr+"'");
    if(searchStr && searchStr.length > 0)
    {
      searchStr = searchStr.toLowerCase();
      searchStr = searchStr.replace(/[^a-z0-9]+/g, '');
      $(".choose-location-search-item").each(function(){
        let this_searchable_val = $(this).data("searchstr");
        if(this_searchable_val)
        {
          if(this_searchable_val.indexOf(searchStr) != -1)
          {
            // $(this).show();
            $(this).removeClass("d-none");
            numShown++;
          }
          else
          {
            // $(this).hide();
            $(this).addClass("d-none");
          }
        }
        else
        {
          // $(this).hide();
          $(this).addClass("d-none");
        }
      });
    }
    else
    {
      // Show all
      $(".choose-location-search-item").each(function(){
        $(this).show();
        numShown++;
      });
    }
    if(numShown == 0)
    {
      $("#choose-location-no-items").show();
    }
    else
    {
      $("#choose-location-no-items").hide();
    }
  }
  clearSearch(event){
    event.preventDefault();
    event.stopImmediatePropagation();
    // console.log("clearSearch");
    $("#"+document.account_search_input_id).val("");
    this.refreshSearch(event);
    $("#"+document.account_search_input_id).focus();
  }
}
