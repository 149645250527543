import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="password"
export default class extends Controller {
  initialize() {
    document.validatePasswordTimeout = undefined;
    document.validatePasswordLastValue = '';
    document.password_ok = false;
    document.password_confirm_ok = false;
    super.initialize();
  }

  connect() {
    if ($("#user_reset_password_token").length ){
      setInterval((function(){
        if (document.password_ok && document.password_confirm_ok) {
          // Enable submit button
          $(".login-submit-button").prop('disabled', false);
        } else {
          // Disable submit button
          $(".login-submit-button").prop('disabled', true);
        }
      }), 200);
    }
  }

  validate_password_change(e) {

    // Reset Password Confirmation Fields
    $('#user_password_confirmation').val('')
    $("#password_confirm_valid").addClass('hidden');
    $("#password_confirm_fail").addClass('hidden');

    // console.log(e.currentTarget.value);
    if (document.validatePasswordTimeout && document.validatePasswordLastValue != e.currentTarget.value) {
      clearTimeout(document.validatePasswordTimeout);
    }
    if (document.validatePasswordLastValue == e.currentTarget.value) {
      return;
    }
    document.password_ok = false;
    document.validatePasswordLastValue = e.currentTarget.value;
    if (document.validatePasswordLastValue == "") {
      //console.log("validatePasswordLastValue")
      $("#password_valid").addClass('hidden');
      $("#password_fail").addClass('hidden');
      $("#password_requirement_length em").removeClass("fa-check text-success").addClass("fa-times text-danger");
      if ($("#password_requirement_complex").length) {
        $("#password_requirement_complex em").removeClass("fa-check text-success").addClass("fa-times text-danger");
      }
      // Clear requirements for new requirements
      if ($("#password_requirement_number").length) {
        $("#password_requirement_number em").removeClass("fa-check text-success").addClass("fa-times text-danger");
      }
      if ($("#password_requirement_case").length) {
        $("#password_requirement_case em").removeClass("fa-check text-success").addClass("fa-times text-danger");
      }
      if ($("#password_requirement_special").length) {
        $("#password_requirement_special em").removeClass("fa-check text-success").addClass("fa-times text-danger");
      }
      return;
    }
    if (document.validatePasswordLastValue.length < 8) {
      //console.log("validatePasswordLastValue Length")
      $("#password_valid").addClass('hidden');
      $("#password_fail").removeClass('hidden');
      $("#password_requirement_length em").removeClass("fa-check text-success").addClass("fa-times text-danger");
      // Do not continue until password is >= 8 characters
      return;
    }
    document.validatePasswordTimeout = setTimeout(async function(){
      const request = new FetchRequest("post", "/sessions/validate_password",
          { responseKind: "json",
                    body: {
                      password: document.validatePasswordLastValue
                  }});
      const response = await request.perform();
      // console.dir(response);
      if (response.ok) {
        // console.log("OK="+response.json);
        // console.dir(response.json);
        response.json.then(function(statusData){
          // console.dir(statusData);
          if (statusData.valid_password) {
            document.password_ok = true;
            $("#password_fail").addClass('hidden');
            $("#password_valid").removeClass('hidden');
          } else {
            $("#password_valid").addClass('hidden');
            $("#password_fail").removeClass('hidden');
          }
          if (statusData.valid_length) {
            $("#password_requirement_length em").removeClass("fa-times text-danger").addClass("fa-check text-success");
          } else {
            $("#password_requirement_length em").removeClass("fa-check text-success").addClass("fa-times text-danger");
          }
          if ($("#password_requirement_complex").length) {
            if (statusData.not_common) {
              $("#password_requirement_complex em").removeClass("fa-times text-danger").addClass("fa-check text-success");
            } else {
              $("#password_requirement_complex em").removeClass("fa-check text-success").addClass("fa-times text-danger");
            }
          }
          // Handle other requirements::: valid_number: false, valid_case: false, valid_special: false
          if ($("#password_requirement_number").length) {
            if (statusData.valid_number) {
              $("#password_requirement_number em").removeClass("fa-times text-danger").addClass("fa-check text-success");
            } else {
              $("#password_requirement_number em").removeClass("fa-check text-success").addClass("fa-times text-danger");
            }
          }
          if ($("#password_requirement_case").length) {
            if (statusData.valid_case) {
              $("#password_requirement_case em").removeClass("fa-times text-danger").addClass("fa-check text-success");
            } else {
              $("#password_requirement_case em").removeClass("fa-check text-success").addClass("fa-times text-danger");
            }
          }
          if ($("#password_requirement_special").length) {
            if (statusData.valid_special) {
              $("#password_requirement_special em").removeClass("fa-times text-danger").addClass("fa-check text-success");
            } else {
              $("#password_requirement_special em").removeClass("fa-check text-success").addClass("fa-times text-danger");
            }
          }

        });

      }
    }, 250);

  }
  validate_confirm_password_change(e) {
    const current_confirm_value = e.currentTarget.value;
    document.password_confirm_ok = false;
    if (current_confirm_value.length > 0 && $('#password_fail').hasClass('hidden') && !$('#password_valid').hasClass('hidden')) {
      if ($("#user_password").val() == current_confirm_value) {
        document.password_confirm_ok = true;
        $("#password_confirm_fail").addClass('hidden');
        $("#password_confirm_valid").removeClass('hidden');
      } else {
        $("#password_confirm_valid").addClass('hidden');
        $("#password_confirm_fail").removeClass('hidden');
      }
// password_confirm_valid/password_confirm_fail
    } else {
      $("#password_confirm_valid").addClass('hidden');
      $("#password_confirm_fail").addClass('hidden');
    }
  }

}
