import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="wbi-version-selector"
export default class extends Controller {
  static targets = [ "input", "button" ]
  connect() {
    if (this.hasInputTarget && this.hasButtonTarget) {

    }
  }
  select_item(event){
    event.preventDefault();
    if (event.params && event.params.version) {
      this.inputTarget.value = event.params.version;
      for (let i=0; i<this.buttonTargets.length;i++) {
        this.buttonTargets[i].classList.remove("active");
      }
      event.currentTarget.classList.add("active");
    }
  }
}
