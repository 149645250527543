import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { Offcanvas } from "bootstrap"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
  }

  clearTooltips() {
    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })
  }

  loadModal(event) {
    let focus_modal = true;
    // Change for data-focus="no"
    if (event.currentTarget.dataset && event.currentTarget.dataset.focus && (event.currentTarget.dataset.focus == "no")) {
      focus_modal = false;
    }

    if (event.currentTarget.dataset && event.currentTarget.dataset.bsToggle && event.currentTarget.dataset.bsToggle == 'tooltip') {
      // Tooltips on buttons that open modals do not always cleanly close -- remove with force :)
      $(event.currentTarget).blur();
      setTimeout(function(){
        $(".tooltip[role='tooltip']").remove();
      },100);
      // $(event.currentTarget).tooltip('dispose');
    }

    this.modal = new Modal(document.getElementById('system_modal_popup'), {
      keyboard: false,
      focus: focus_modal
    });

    let modal_container = document.querySelector('#system_modal_popup');
    this.removeBootstrapModalSizes(modal_container);
    if (event.currentTarget.dataset && event.currentTarget.dataset.modalSize) {
      if (modal_container) {
        let dialog_container = modal_container.querySelector('.modal-dialog');
        if (dialog_container) {
          dialog_container.className = "modal-dialog " + event.currentTarget.dataset.modalSize;
        }
      }
    } else {
      this.setDefaultBootstrapModalSize(modal_container);
    }

    this.modal.show();
    this.clearTooltips();
  }

  loadInnerModal(event) {
    let focus_modal = true;
    // Change for data-focus="no"
    if (event.currentTarget.dataset && event.currentTarget.dataset.focus && (event.currentTarget.dataset.focus == "no")) {
      focus_modal = false;
    }
    this.modal = new Modal(document.getElementById('inner_modal_popup'), {
      keyboard: false,
      focus: focus_modal
    });

    let modal_container = document.querySelector('#inner_modal_popup');
    this.removeBootstrapModalSizes(modal_container);
    if (event.currentTarget.dataset && event.currentTarget.dataset.modalSize) {
      if (modal_container) {
        let dialog_container = modal_container.querySelector('.modal-dialog');
        if (dialog_container) {
          dialog_container.className = "modal-dialog " + event.currentTarget.dataset.modalSize;
        }
      }
    } else {
      this.setDefaultBootstrapModalSize(modal_container);
    }

    this.modal.show();
    this.clearTooltips();
  }

  loadLoginAsModal(event) {
    $("#recent-customers-holder").hide();
    $("#organization-search-holder").hide();
    this.modal = new Modal(document.getElementById('login_as_modal_popup'), {
      keyboard: false
    });

    if (event.currentTarget.dataset && event.currentTarget.dataset.modalSize) {
      let modal_container = document.querySelector('#login_as_modal_popup');
      if (modal_container) {
        let dialog_container = modal_container.querySelector('.modal-dialog');
        if (dialog_container) {
          dialog_container.className = "modal-dialog " + event.currentTarget.dataset.modalSize;
        }
      }
    }

    this.modal.show();
    this.clearTooltips();
  }

  removeBootstrapModalSizes(modal_container) {
    let class_list = ["modal-sm","modal-md","modal-lg","modal-xl"]
    if (modal_container) {
      let dialog_container = modal_container.querySelector('.modal-dialog');
      if (dialog_container) {
        class_list.forEach(function (class_str, index) {
          if (dialog_container.classList.contains(class_str)) {
            dialog_container.classList.remove(class_str);
          }
        });
      }
    }
  }

  setDefaultBootstrapModalSize(modal_container) {
    if (modal_container) {
      let dialog_container = modal_container.querySelector('.modal-dialog');
      // data-default-size = dialog_container.dataset.defaultSize;
      if (dialog_container && dialog_container.dataset && dialog_container.dataset.defaultSize) {
        dialog_container.classList.add(dialog_container.dataset.defaultSize);
      }
    }
  }

  verify_2fa_enable(event) {
    event.preventDefault();
    $('#error-message-2fa').remove();

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: '/sessions/verify_mfa_account',
      type: 'post',
      data: { code: $('#verify_code').val() },
      success: function(e) {
        if(e.status == 200) {
          $('#enable-2fa-modal').modal('hide');
          $('#button-2fa-holder').html('<p class="alert alert-success">2-Factor Authentication is enabled.</p><p><button class="btn btn-danger" id="disable_2fa_button" data-action="click->account-setup-verification#disable2fa">Disable 2-Factor Authentication</button></p>');
          $('#sms_phone_label').removeClass('hidden');
          $('#user_sms_phone').attr("disabled", "disabled");
          $('#enable-2fa-modal').modal('hide');
        }
        if(e.status == 500) {
          $('#enable-2fa-modal .modal-body').prepend("<p class='text-danger' id='error-message-2fa'>" + e.message + "</p>")
        }
      }
    });
  }
}
