import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customers"
export default class extends Controller {

  cust_hubspot_vid = undefined;
  hs_data_cust = {}
  current_modal_sync_type = ''
  hubspot_vid = ""
  wbi_data = {}
  hs_data = {}
  field_common_billing = ["fname","lname","email","company","phone","renewal","instadmin","brand"]
  field_common_ro = ["fname","lname","email","rolloutcontact","brand"]

  connect() {
    this.initializeSelect2(".select2", {})

    this.initializeCkEditor();

    if ($(".ckeditor-landing-page-custom-message").length) {
      $(".ckeditor-landing-page-custom-message").each(function(){
        CKEDITOR.replace(this, {
          allowedContent:true, // Need this to allow attributes on elements
          height: '300px',
          toolbar: [
            {
              name: 'basicstyles',
              groups: ['basicstyles', 'cleanup'],
              items: ['Source', '-', 'Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat', '-', 'Link', 'Unlink']
            },
            {
              name: 'paragraph',
              groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
              items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Link', 'Unlink']
            },
            {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
            {name: 'colors', items: ['TextColor', 'BGColor']}
          ]
        });
      });
    }
  }

  addLandingPageOption(event) {
    event.preventDefault()
    var item = event.currentTarget;
    var count = parseInt(item.dataset.count)
    $('#dropdown_list_' + item.dataset.form + ' > table > tbody').append('<tr><td><input type="text" name="option[][option_name]" class="form-control"></td><td><input type="text" name="option[][option_code]" class="form-control"></td><td><input type="text" name="option[][option_order]" value="' + item.dataset.count + '" class="form-control"></td><td><input type="checkbox" name="option[][option_delete]" id="option_delete_" value="true"></td></tr>');
    document.getElementById("add_row_btn_" + item.dataset.form).dataset.count = count + 1
    document.getElementById("auto_populate_btn_" + item.dataset.form).dataset.count = count
  }

  autoFillHubspotData(event) {
    event.preventDefault();
    $("#customer_name").val(this.hs_data_cust["company"]);
    $("#customer_contact_fname").val(this.hs_data_cust["fname"]);
    $("#customer_contact_lname").val(this.hs_data_cust["lname"]);
    $("#customer_contact_email").val(this.hs_data_cust["email"]);
    $("#customer_contact_phone").val(this.hs_data_cust["phone"]);
  }

  autoFillLandingPage(event) {
    event.preventDefault();
    var data = event.currentTarget.dataset;
    document.getElementById('redirect_path').value = slugify_string(data.path);
    document.getElementById('redirect_redirect').value = "landing-page/" + encodeURIComponent(data.path);
  }

  autoFillSignup(event) {
    event.preventDefault();
    var data = event.currentTarget.dataset;
    document.getElementById('redirect_path').value = slugify_string(data.code);
    document.getElementById('redirect_redirect').value = "signup?code=" + encodeURIComponent(data.code);
  }

  autoPopulateLandingPage(event) {
    event.preventDefault()
    var item = event.target

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: "/customers/" + item.dataset.id + "/landing_page_options",
      data: { start: parseInt(item.dataset.count) + 1 },
      type: "GET",
      success: function(e) {
        if(e.status == 200) {
          $('#dropdown_list_' + item.dataset.form + ' > table > tbody').append(e.fields)
          document.getElementById("add_row_btn_" + item.dataset.form).dataset.count = e.count + 1
          document.getElementById("auto_populate_btn_" + item.dataset.form).dataset.count = e.count
        }
      }
    })
  }

  cancelAutoFillHubspotData(event) {
    event.preventDefault();
    this.hs_data_cust = {};
    this.cust_hubspot_vid = undefined;
    $("#hubspot_vid_customer_form").val(0);
    $(".hubspot-select-contact-button-cust").each(function () {
      $(this).remove();
    });
    $("#hubspot-sync-search-results-none-cust").hide();
    $("#hubspot-sync-search-results-cust-selected").hide();
    $("#hubspot-sync-search-results-cust").show();
    $("#hubspot-sync-search-group-cust").show();
  }

  cancelEditSetting(event) {
    event.preventDefault();

    var item = event.target;
    if(!item.classList.contains('cancel-setting')){
      item = event.target.parentNode;
    }

    var id = item.dataset.id;
    var type = item.dataset.type;
    var $row = $("#settings-" + type + "-row-" + id);
    $row.find(".save-setting").addClass('hidden');
    $row.find(".edit-setting").removeClass('hidden');
    $(item).addClass('hidden');
    $("#settings_" + type + "s_value_" + id).addClass('hidden');
    $row.find(".setting-value-display").removeClass('hidden');
  }

  cancelHiddenSignupQuestion(event) {
    event.preventDefault()

    var item = event.currentTarget
    var id = item.dataset.id
    var type = item.dataset.type

    item.classList.add('hidden')

    var row = document.getElementById("settings-" + type + '-row-' + id)
    row.getElementsByClassName('edit-setting')[0].classList.remove('hidden')
    row.getElementsByClassName('save-setting')[0].classList.add('hidden')
    row.getElementsByClassName('setting-value-display')[0].classList.remove('hidden')
    
    document.getElementById("settings_" + type + 's_value_' + id).classList.add('hidden')
  }

  certificateReportFrequencyChange(event) {
    let $item = $(event.target);
    if ($item.val() == "custom") {
      $item.closest(".auto_send_cert_report_frequency_custom_wrapper_div").find(".auto_send_cert_report_frequency_custom_div").show();
    } else {
      $item.closest(".auto_send_cert_report_frequency_custom_wrapper_div").find(".auto_send_cert_report_frequency_custom_div").hide();
    }
  }

  checkCancelledStatus(event) {
    var item = event.currentTarget

    $('#customer_cancellation_date').attr("disabled", true)
    
    if(item.value != 1){
      $('#customer-account-status-email-alert').removeClass("hidden");
      $('#customer_send_status_change_alert_yes').prop('checked', true)
    } else {
      $('#customer-account-status-email-alert').addClass("hidden");
      $('#customer_send_status_change_alert_yes').prop('checked', false)
      $('#customer_send_status_change_alert_no').prop('checked', false)
    }

    if(item.value == 4) {
      $('#customer_cancellation_date').attr("disabled", false)
    }
  }

  clearSearchFilter(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    var type = event.target.dataset.type
    $("#filter-" + type + "-settings-table-input").val("");
    $("#filter-" + type + "-settings-table-clear-button").hide();
    $("#filter-" + type + "-settings-table tbody tr").each(function(index, item){
      $(item).attr('style', '');
    })
  }

  clearTestAccounts(event) {
    event.currentTarget.querySelector('button[type="submit"]').innerHTML = '<em class="fa fa-spin fa-spinner"></em> Clearing Test Accounts'
    event.currentTarget.querySelector('button[type="submit"]').setAttribute('disabled', 'disabled')
    event.currentTarget.submit()
  }


  confirmChangeStarred (e) {
    if (!confirm("Are you sure you want to change the last reminder dates for starred admins? This action is not reversible.")) {
      e.preventDefault();
    }
  }

  confirmChangeAny (e) {
    if (!confirm("Are you sure you want to change the last reminder dates for any admin type? This action is not reversible.")) {
      e.preventDefault();
    }
  }

  default_country_change(event) {
    let new_val = $(event.target).val();
    let closest_form = $(event.target).closest('form');
    if (new_val == "") {
      closest_form.find(".input-state-question-id").val("");
      closest_form.find(".input-state").val("");
      closest_form.find(".default-state-div").addClass("hidden");
    } else {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: "/customers/get_state_options?country="+new_val,
        type: "GET",
        success: function(data) {
          if(data.status == 200) {
            // console.log(data.state_question_id);
            if (data.state_question_id && data.state_question_id > 0 && data.option_list) {
              closest_form.find(".input-state-question-id").val(data.state_question_id);
              closest_form.find(".input-state").html("");
              for (let i = 0; i<data.option_list.length; i++){
                let opt = document.createElement('option');
                opt.value = data.option_list[i][1];
                opt.innerHTML = data.option_list[i][0];
                closest_form.find(".input-state").append(opt);
              }
              closest_form.find(".default-state-div").removeClass("hidden");
            } else {
              closest_form.find(".input-state-question-id").val("");
              closest_form.find(".input-state").val("");
              closest_form.find(".default-state-div").addClass("hidden");
            }
          }
        }
      });
    }
  }

  deleteHiddenSignupQuestion(event) {
    event.preventDefault()
    var item = event.currentTarget
    var type = item.dataset.type
    var obj_id = item.dataset.id.split('-')

    console.log(obj_id)

    var ans = confirm("Are you sure you want to remove this setting?")
    if(ans) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "DELETE",
        url: "/customers/delete_hidden_question",
        data: {
          customer_sub_account_id: obj_id[0],
          question_id: obj_id[1]
        },
        success: function (e) {
          if(e.status == 200) {
            document.getElementById('settings-' + type + '-row-' + item.dataset.id).remove()
          }
        }
      })
    }
  }

  deleteRedirect(event) {
    event.preventDefault();
    var item = event.target;
    var url = item.href
    var id = item.dataset.id
    var ans = confirm("Are you sure you want to delete this redirect?")
    if(ans) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: url,
        type: "DELETE",
        success: function(e) {
          if(e.status == 200) {
            $('#redirect_' + id).remove();
          }
        }
      })
    }
  }

  deleteUpload(event) {
    event.preventDefault();
    var item = event.currentTarget;
    var url = item.href;
    var id = item.dataset.id;
    var hidden_id = item.dataset.hidden;
    var ans = confirm('Are you sure you want to delete this file?');
    if(ans) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: url, 
        type: 'DELETE', 
        success: function(e){
          if(e.status == 200) {
            $('#upload_' + id).remove();
            $('#' + hidden_id).remove();
          }
        }
      });
    }
  }

  document_selected(event) {
    var input_field = event.target;
    if (input_field && input_field.value && input_field.id) {
      var name_id = input_field.id.replace('_file','_name');
      var name_field = document.getElementById(name_id);
      if (name_field) {
        var filename = input_field.value.replace(/^.*\\/, "");
        name_field.value = filename;
      }
    }
  }

  editHiddenSignupQuestion(event) {
    event.preventDefault()
    var item = event.currentTarget
    var id = item.dataset.id
    var type = item.dataset.type

    item.classList.add('hidden')

    var row = document.getElementById("settings-" + type + '-row-' + id)
    row.getElementsByClassName('save-setting')[0].classList.remove('hidden')
    row.getElementsByClassName('cancel-setting')[0].classList.remove('hidden')
    row.getElementsByClassName('setting-value-display')[0].classList.add('hidden')

    document.getElementById("settings_" + type + 's_value_' + id).classList.remove('hidden')
    document.getElementById("settings_" + type + 's_value_' + id).focus()
  }


  filterHiddenSignupQuestions(event) {
    var item = event.target
    var searchStr = item.value
    var type = item.dataset.type

    var numShown = 0

    if (searchStr && searchStr.length > 0) {
      document.getElementById("filter-" + type + "-settings-table-clear-button").classList.remove('hidden')
      searchStr = searchStr.toLowerCase();
      searchStr = searchStr.replace(/[^a-z0-9]+/g, '');
      $("#filter-" + type + "-settings-table tbody tr.searchable-row").each(function () {
        var this_searchable_val = $(this).data("searchstr");
        if (this_searchable_val) {
          if (this_searchable_val.indexOf(searchStr) != -1) {
            $(this).show();
            numShown++;
          } else {
            $(this).hide();
          }
        } else {
          $(this).hide();
        }
      });
    } else {
      document.getElementById("filter-" + type + "-settings-table-clear-button").classList.add('hidden')
      // Show all
      $("#filter-" + type + "-settings-table tbody tr.searchable-row").each(function () {
        $(this).show();
        numShown++;
      });
    }
    if (numShown == 0) {
      document.getElementById("no-items-match").classList.remove('hidden')
    } else {
      document.getElementById("no-items-match").classList.add('hidden')
    }
  }


  initializeCkEditor() {

    $('.ckeditor').each(function(){
      CKEDITOR.replace($(this).prop('id'), {
        allowedContent:true, // Need this to allow attributes on elements
        height: '300px',
        toolbar: [
          {
            name: 'basicstyles',
            groups: ['basicstyles', 'cleanup'],
            items: ['Source', '-', 'Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat']
          },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
            items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Link', 'Unlink']
          },
          {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
          {name: 'colors', items: ['TextColor', 'BGColor']}
        ]
      });

        // http://handsomedogstudio.com/ckeditor-set-default-target-blank
        /* Here we are latching on an event ... in this case, the dialog open event */
        CKEDITOR.on('dialogDefinition', function(ev) {
          try {
            /* this just gets the name of the dialog */
      
            var dialogName = ev.data.name;
            /* this just gets the contents of the opened dialog */
      
            var dialogDefinition = ev.data.definition;
            /* Make sure that the dialog opened is the link plugin ... otherwise do nothing */

            if(dialogName == 'link') {
              
              /* Getting the contents of the Target tab */
              var informationTab = dialogDefinition.getContents('target');
            
              /* Getting the contents of the dropdown field "Target" so we can set it */
              var targetField = informationTab.get('linkTargetType');
            
              /* Now that we have the field, we just set the default to _blank. A good modification would be to check the value of the URL field and if the field does not start with "mailto:" or a relative path, then set the value to "_blank" */
              targetField['default'] = '_blank';
            
            }
          } catch(exception) {
            alert('Error ' + ev.message);
          }
        });
    })
  }

  initializeSelect2(selector, options) {
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }

  landingPageCodeChanged(event) {
    let item = event.target;
    let $form = $(item).closest("form");
    let $landing_page_url_field = $form.find(".landing-page-url");
    if ($landing_page_url_field.val() == "") {
      let generated_url = slugify_string(item.value);
      $landing_page_url_field.val(generated_url);
    }
  }

  participationThreshold(event) {
    if( $('#participation_amount_holder_' + event.target.dataset.id).hasClass('hidden')) {
      $('#participation_amount_holder_' + event.target.dataset.id).removeClass('hidden');
    } else {
      $('#participation_amount_holder_' + event.target.dataset.id).addClass('hidden');
    }

    $('#participation_amount_holder_' + event.target.dataset.id).children('input').val('');
  }


  removeHubspotSync(event) {
    event.preventDefault();
    var item = event.target;
    if(confirm("Are you sure you want to remove the Hubspot sync for this account?"))
    {
      var customer_id = item.dataset.customerId;
      this.current_modal_sync_type = item.dataset.syncType;
      if(!this.current_modal_sync_type)
      {
        this.current_modal_sync_type = "billing";
      }
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "PATCH",
        url: "/customers/" + customer_id + "/remove_hubspot_sync",
        data: { sync_type: this.current_modal_sync_type },
        success: function (e) {
          if( e.status == 200) {
            window.location.reload(true);
          } 
          else if(e && e.error)
          {
            alert(e.error);
          }
          else
          {
            alert("Unexpected error, please try again");
          }
        }
      });
    }
  }

  saveHiddenSignupQuestion(event) {
    event.preventDefault()

    var item = event.currentTarget
    var id = item.dataset.id
    var type = item.dataset.type
    var obj_id = id.split('-')

    var row = document.getElementById("settings-" + type + '-row-' + id)
    var option = document.getElementById("settings_" + type + "s_value_" + id)

    row.getElementsByClassName('saving-progress')[0].classList.remove('hidden');

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "PATCH",
      url: "/customers/update_hidden_question",
      data: {
        customer_sub_account_id: obj_id[0],
        question_id: obj_id[1],
        option_id: option.value
      },
      success: function (e) {
        if(e.status == 200) {
          row.getElementsByClassName('setting-value-display')[0].innerHTML = option.options[option.selectedIndex].innerHTML;
          row.getElementsByClassName('cancel-setting')[0].click()
          row.getElementsByClassName('saving-progress')[0].classList.add('hidden');
        } else {
          row.getElementsByClassName('saving-progress')[0].classList.add('hidden');
        }
      }

    });
  }

  searchHubspotAccounts(event) {
    event.preventDefault()
    var item = event.target;
    var search_val = $("#hubspot-sync-search-input-cust").val();
    var customer_id = item.dataset.customerId;
    
    $.ajax({
      type: "GET",
      url: "/customers/search_hubspot_sync_data",
      data: { form: 'cust', search: encodeURI(search_val), customer_id: customer_id },
      success: function (results_html) {
        $(".hubspot-select-contact-button-cust").each(function () {
          $(this).remove();
        });
        if (results_html) {
          $("#hubspot-sync-search-results-cust").html('');
          $("#hubspot-sync-search-results-none-cust").hide();
          $.each(results_html, function(index, item){
            $("#hubspot-sync-search-results-cust").append(item);
          });
        } else {
          $("#hubspot-sync-search-results-none-cust").show();
        }
      }
    });
  }

  searchHubspotAccountsSync(event) {
    console.log("searchHubspotAccountsSync")
    event.preventDefault()
    var item = event.target;
    var search_val = $("#hubspot-sync-search-input-cust").val();
    var customer_id = item.dataset.customerId;

    this.current_modal_sync_type = "billing"
    
    $.ajax({
      type: "GET",
      url: "/customers/search_hubspot_sync_data",
      data: { search: encodeURI(search_val), customer_id: customer_id },
      success: function (results_html) {
        $(".hubspot-select-contact-button-cust").each(function () {
          $(this).remove();
        });
        if (results_html) {
          $("#hubspot-sync-search-results-none-cust").hide();
          $("#hubspot-sync-search-results-cust").html('');
          $.each(results_html, function(index, item){
            $("#hubspot-sync-search-results-cust").append(item);
          });
        } else {
          $("#hubspot-sync-search-results-none-cust").show();
        }
      }
    });
  }

  select_all_sub_accounts(event) {
    event.preventDefault();
    $("#sub_accounts > option").prop("selected", true);
    $("#sub_accounts").trigger("change");
  }

  selectHubspotAccount(event) {
    console.log("selectHubspotAccount")
    event.preventDefault();
    var item = event.target

    this.hubspot_vid = item.dataset.hubspot_vid
    var field_common = this.field_common_billing
    if(this.current_modal_sync_type == "rollout")
    {
      field_common = this.field_common_ro;
      $(".sync-type-rollout-row").show();
      $(".sync-type-billing-row").hide();
    }
    else
    {
      $(".sync-type-rollout-row").hide();
      $(".sync-type-billing-row").show();
    }

    for(var i=0;i<field_common.length;i++)
    {

      console.log(field_common[i])

      if(this.current_modal_sync_type == "rollout" && (field_common[i] == "fname" || field_common[i] == "lname" || field_common[i] == "email"))
      {
        this.wbi_data[field_common[i]] = item.getAttribute("data-wbi_"+field_common[i] + "_ro")
      }
      else
      {
        this.wbi_data[field_common[i]] = item.getAttribute("data-wbi_"+field_common[i])
      }


      this.hs_data[field_common[i]] = item.getAttribute("data-" + field_common[i])

      if(this.wbi_data[field_common[i]] == this.hs_data[field_common[i]])
      {
        $("input[name='hs_sync_val_"+field_common[i]+"']").hide();
      }
      else
      {
        $("input[name='hs_sync_val_"+field_common[i]+"']").show();
      }
      $("#hs-sync-display-"+field_common[i]+"-wbi").html(this.wbi_data[field_common[i]]);
      $("#hs-sync-display-"+field_common[i]+"-hubspot").html(this.hs_data[field_common[i]]);
      $("#hubspot-sync-modal-setup-step1").hide();
      $("#hubspot-sync-modal-setup-step2").show();
    }
  }

  selectHubspotAccountCustomer(event) {
    console.log("selectHubspotAccountCustomer")
    event.preventDefault();
    var item = event.target
    var field_common = ["fname", "lname", "email", "company", "phone"];
    var field_name = ["First Name", "Last Name", "Email", "Organization", "Phone"];
    this.cust_hubspot_vid = item.dataset.hubspot_vid

    $("#hubspot_vid_customer_form").val(this.cust_hubspot_vid);
    var display_html = '<div class="row">';
    for (var i = 0; i < field_common.length; i++) {
      this.hs_data_cust[field_common[i]] = item.dataset[field_common[i]]

      display_html += '<div class="col-6">';
      display_html += '<strong>' + field_name[i] + ':</strong> ' + this.hs_data_cust[field_common[i]];
      display_html += '</div>';

    }
    display_html += '<div class="col-12"><a href="#" data-action="click->customers#autoFillHubspotData">Autofill Primary Contact/Company Information <em class="fa fa-chevron-down"></em></a>';
    display_html += ' | <a href="#" data-action="click->customers#cancelAutoFillHubspotData" >New Search / Cancel Sync</a></div>';
    display_html += '</div>';
    $("#hubspot-sync-search-results-cust-selected").html(display_html);
    $("#hubspot-sync-search-results-cust").hide();
    $("#hubspot-sync-search-group-cust").hide();
    $("#hubspot-sync-search-results-cust-selected").show();
  }

  selectQuestionsByCustomerSubAccount(event) {
    var item = event.currentTarget
    var id = item.value

    let frame = document.querySelector('turbo-frame#csa_signup_questions')
    if(id === '') {
      frame.innerHTML = ''
    } else {
      frame.src = '/customers/signup_questions?id=' + id
      frame.reload() 
    }

    let frame2 = document.querySelector('turbo-frame#csa_signup_question_options')
    frame2.innerHTML = ''

    $('#save-new-hidden-question').attr('disabled', true)
  }

  selectQuestionOptionsByCustomerSubAccount(event) {
    var item = event.currentTarget
    var id = item.value

    let frame = document.querySelector('turbo-frame#csa_signup_question_options')
    frame.src = '/customers/signup_question_options?id=' + id
    frame.reload() 
    $('#save-new-hidden-question').attr('disabled', false)
  }

  // Currently only used for account search: /users/account_search
  selectSubAccounts(event) {
    var item = event.currentTarget
    $.ajax({
      url: '/customers/' + item.value + '/customer_sub_account_list', 
      type: 'GET', 
      success: function(e){
        if(e.status == 200) {
          if(item.dataset.container) {
            var select = '<select name="customer_sub_account_id[]" id="customer_sub_account_id" multiple="" class="form-select form-select-sm required select2-hidden-accessible" data-controller="select2" style="width: 100%;" tabindex="-1" aria-hidden="true"><option value=""></option>'
            e.data.forEach( function(element) {
              select += '<option value="' + element.id + '">' + element.name + ' (' + element.wbi_version + ')</option>'
            })
            select += '</select>'
            $('#' + item.dataset.container).html(select)
          }
        }
      }
    });
  }

  // toggleAccountOwner(event) {
  //   event.preventDefault();
  //   var item = event.currentTarget;
  //   var icon = item.firstChild;
  //
  //   if( icon.classList.contains("fas")) {
  //     var ans = confirm("Are you sure you want to unmark this account?")
  //     if(ans) {
  //       $.ajax({
  //         headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
  //         url: item.href,
  //         data: { toggle: false },
  //         type: 'PATCH',
  //         success: function(e){
  //           if(e.status == 200) {
  //             icon.classList.remove('fas', 'text-success');
  //             icon.classList.add('far', 'text-black-50');
  //             item.title = 'Mark as master account';
  //             item.dataset.bsOriginalTitle = 'Mark as master account';
  //             item.ariaLabel = 'Mark as master account';
  //           }
  //         }
  //       });
  //     }
  //   }
  // }

  slugify_url_path(event) {
    let input = event.currentTarget;
    input.value = slugify_string(input.value);
  }

  toggleAccountOwner(event) {
    event.preventDefault();
    var item = event.currentTarget;
    var icon = item.firstChild;
    if( icon.classList.contains("far") ){
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: item.href,
        data: { owner: true },
        type: 'PATCH', 
        success: function(e){
          if(e.status == 200) {
            icon.classList.add('fas', 'text-success');
            icon.classList.remove('far', 'text-black-50');
            item.title = 'Unmark as owner account';
            item.dataset.bsOriginalTitle = 'Unmark as owner account';
            item.ariaLabel = 'Unmark as owner account';
          }
        }
      });   
    } else {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: item.href,
        data: { owner: false },
        type: 'PATCH', 
        success: function(e){
          if(e.status == 200) {
            icon.classList.remove('fas', 'text-success');
            icon.classList.add('far', 'text-black-50');
            item.title = 'Mark as owner account';
            item.dataset.bsOriginalTitle = 'Mark as owner account';
            item.ariaLabel = 'Mark as owner account';
          }
        }
      }); 
    }
  }

  toggleAssignSubAccount(event) {
    if(event.currentTarget.value != '') {
      $("#assign_sub_account_button").attr('disabled', false);
    } else {
      $("#assign_sub_account_button").attr('disabled', true);
    }
  }

  toggleAutoAlertReassessSpecificDate(event) {
    event.preventDefault();
    var item = event.target

    if(item.value == 'date') {
      $('#auto_alert_reassess_specific_date_' + item.dataset.subaccount + '_holder').removeClass('hidden');
    } else {
      $('#auto_alert_reassess_specific_date_' + item.dataset.subaccount + '_holder').addClass('hidden');
      $('#auto_alert_reassess_specific_date_' + item.dataset.subaccount).val('')
    }
    
  }

  toggleLandingPageOptions(event) {
    event.preventDefault();
    var item = event.target;

    if(item.value == "true") {
      document.getElementById("dropdown_list_" + event.target.dataset.id).classList.add("hidden")
      document.getElementById("custom_html_" + event.target.dataset.id).classList.remove("hidden")
    } else {
      document.getElementById("dropdown_list_" + event.target.dataset.id).classList.remove("hidden")
      document.getElementById("custom_html_" + event.target.dataset.id).classList.add("hidden")
    }
  }

  toggleMasterAccount(event) {
    event.preventDefault();
    var item = event.target;

    if( item.classList.contains("fas")){
      var ans = confirm("Are you sure you want to unmark this sub-account as Master?")
      if(ans) {
        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          url: event.target.parentNode.href, 
          data: { toggle: false },
          type: 'PATCH', 
          success: function(e){
            if(e.status == 200) {
              item.classList.remove('fas', 'text-success');
              item.classList.add('far', 'text-black-50');
              item.parentNode.title = 'Mark as master account';
              item.parentNode.dataset.bsOriginalTitle = 'Mark as master account';
              item.parentNode.ariaLabel = 'Mark as master account';
            }
          }
        });
      }
    } else {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: event.target.parentNode.href,
        data: { toggle: true }, 
        type: 'PATCH', 
        success: function(e){
          if(e.status == 200) {
            item.classList.remove('far', 'text-black-50');
            item.classList.add('fas', 'text-success');
            item.parentNode.title = 'Unmark as master account';
            item.parentNode.dataset.bsOriginalTitle = 'Unmark as master account';
            item.parentNode.ariaLabel = 'Unmark as master account';
          }
        }
      });
    }
  }


  toggleUnlimitedLicenses(event) {
    if(event.currentTarget.checked == 1) {
      document.getElementById('customer_user_limit').disabled = true
    } else {
      document.getElementById('customer_user_limit').disabled = false
    }
  }

  unassignAccount(event) {
    event.preventDefault();
    var ans = confirm("Are you sure you want to un-assign this account?");
    if(ans) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: event.target.parentNode.href, 
        type: 'PATCH', 
        success: function(e){
          if(e.status == 200) {
            $('#account_' + event.target.parentNode.dataset.id).remove();
          }
        }
      });
    }
  }


  updateHubspotSync(event) {
    event.preventDefault();
    $("#hubspot-sync-modal-setup-error-div").hide();
    var missing_radio = false;
    var final_data = {};
    var field_common = this.field_common_billing;
    var customer_id = event.target.dataset.customer_id
    if(this.current_modal_sync_type == "rollout")
    {
      field_common = this.field_common_ro;
    }
    for(var i=0;i<field_common.length;i++)
    {
      if(field_common[i] != "instadmin" && field_common[i] != "rolloutcontact" && field_common[i] != "brand")
      {
        if(this.wbi_data[field_common[i]] != this.hs_data[field_common[i]])
        {
          if($("input[name='hs_sync_val_"+field_common[i]+"']:checked").length)
          {
            if($("input[name='hs_sync_val_"+field_common[i]+"']:checked").val() == "wbi")
            {
              final_data[field_common[i]] = this.wbi_data[field_common[i]];
            }
            else
            {
              final_data[field_common[i]] = this.hs_data[field_common[i]];
            }
          }
          else
          {
            missing_radio = true;
            break;
          }
        }
        else
        {
          final_data[field_common[i]] = this.hs_data[field_common[i]];
        }
      }
    }
    if(!missing_radio)
    {
      // Not missing any data -- finish this up by setting up the sync!
      $.ajax({
        type: "PATCH",
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: "/customers/" + customer_id + "/update_hubspot_sync",
        data: { 
          setup_sync: '1', 
          sync_data: final_data, 
          hubspot_vid: this.hubspot_vid, 
          sync_type: this.current_modal_sync_type 
        },
        success: function (results_json) {          
          if(results_json && results_json.success)
          {
            $("#hubspot-sync-modal").modal("hide");
            window.location.reload(true);
          }
          else if(results_json && results_json.error)
          {
            alert(results_json.error);
          }
          else
          {
            alert("Unexpected error, please try again");
          }
        }
      });

    }
    else
    {
      $("#hubspot-sync-modal-setup-error-div").html('Please select a value to use for each field.').show();
    }
  }




  validateSubmittedDocument(e) {
    if($('#customer_customer_documents_attributes_0_name').val() != '' && $("#customer_customer_documents_attributes_0_file").val() == ''){
      e.preventDefault();
      alert("You must choose a file or clear the name / label field.");
    }

    if($('#customer_customer_documents_attributes_0_name').val() == '' && $("#customer_customer_documents_attributes_0_file").val() != ''){
      e.preventDefault();
      alert("You must enter a name / label or file field");
    }
  }

  
  verifyDelete(e) {
    
    var ans = confirm("Are you sure you want to delete this customer?")
    if(!ans) {
      e.preventDefault()
    }
  }

  

  

  

  

}
