import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-form"
export default class extends Controller {
  
  static targets = ["links", "template"]
  

  addAssociation(event) {
    event.preventDefault();

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  connect() {
  }


  removeAssociation(event) {
    event.preventDefault();
    
    let wrapper = event.currentTarget.closest('.nested-fields')
    if(wrapper.dataset.newRecord == 'true') {
      wrapper.remove()
    } else {
      var ans = confirm('Are you sure you want to remove this item?')
      if(ans) {
        wrapper.querySelector("input[name*='_destroy']").value = 1
        wrapper.style.display = 'none'
      }
    }
  }
}
