import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disassociate-organization"
export default class extends Controller {

  initialize() {
    $("#disassociate-organization-modal-form").on("submit", function (e) {
      if ($("#disassociate-organization-new-primary-div").is(":visible")) {
        if (!$("input[name='new_primary_customer_sub_account_id']:checked").length) {
          e.preventDefault();
        }
      }
    });
  }
  connect() {
  }
  disassociate_confirm(event){
    //console.log("func: disassociate_confirm");
    event.preventDefault();
    let $button = $(event.currentTarget);

    // Clear hidden inputs
    $("#disassociate-organization-assoc-account-id-input").val("");
    $("#disassociate-organization-is-asssoc-input").val("");
    $("#disassociate-organization-hospital-assoc-account-id-input").val("");
    $("#disassociate-organization-is-hospital-asssoc-input").val("");
    $("#disassociate-organization-customer-sub-account-id-input").val("");
    if ($("input[name='new_primary_customer_sub_account_id']:checked").length) {
      $("input[name='new_primary_customer_sub_account_id']:checked").prop('checked', false);
    }

    var is_primary = $button.data("is_primary");
    var customer_sub_account_id = $button.data("customer_sub_account_id");
    var display_name = $button.data("display_name");
    var is_association = $button.data("association");
    var is_hospital_association = $button.data("hospital_association");
    $("#disassociate-organization-display-name").html(display_name);
    if(is_association == "yes") {
      var association_account_id = $button.data("id");
      $("#disassociate-organization-assoc-account-id-input").val(association_account_id);
      $("#disassociate-organization-is-asssoc-input").val("yes");
    } else if(is_hospital_association == "yes") {
      var hospital_account_id = $button.data("id");
      $("#disassociate-organization-hospital-assoc-account-id-input").val(hospital_account_id);
      $("#disassociate-organization-is-hospital-asssoc-input").val("yes");
    } else {
      $("#disassociate-organization-customer-sub-account-id-input").val(customer_sub_account_id);
    }
    if (is_primary == 1 && $("input[name='new_primary_customer_sub_account_id']").length) {
      $("#disassociate-organization-new-primary-div").show();
    } else {
      $("#disassociate-organization-new-primary-div").hide();
    }
    $("#disassociate-organization-modal").modal("show");

  }

  change_version(event){
    event.preventDefault();
    let $button = $(event.currentTarget);

    var customer_sub_account_id = $button.data("customer_sub_account_id");
    $("#change-version-customer-sub-account-id-input").val(customer_sub_account_id);
    $("#change-version-modal").find("input[type='radio']").prop('checked', false);
    $("#change-version-modal").find(".change-version-type").show();
    var $chkObjDiv = $("#change-version-modal").find("#change-version-type-" + customer_sub_account_id);
    $chkObjDiv.find("input").prop("checked",true);
    $("#change-version-modal").modal("show");
  }
}
