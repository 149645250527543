import { Controller } from "@hotwired/stimulus"
import VerifyAccountController from './verify_account_controller.js'
import {FetchRequest} from "@rails/request.js";

// Connects to data-controller="validate-email-change"
export default class extends VerifyAccountController {
  static targets = [ "checkbox_open_modal", "email", "send_button", "message", "code_holder" ]
  connect() {
    super.connect();
    this.verify_code_callback = this.process_verify_code_for_convert;
  }

  checkbox_click(event) {
    let checkBoxElement = event.currentTarget
    var is_checked = checkBoxElement.checked;
    console.log("Is Checked="+is_checked);
    event.preventDefault();
    $(checkBoxElement).blur();
    if (is_checked) {
      // Open modal
      $("#verify-new-email-modal-email-input").prop("readonly", false);
      $("#verify-new-email-modal-email-input").val("");
      $('#verify-new-email-modal-message1').html("");
      $('#verify-new-email-modal-code-holder').html("");
      $("#new-email-input").val("");
      $("#new-email-after-verification-div").hide();
      $("#verify-new-email-modal").modal('show');
    } else {
      // Hide email display
      $("#change_email_checkbox").prop("checked", false);
      $("#new-email-input").val("");
      $("#new-email-after-verification-div").hide();
    }
  }
  async send_verification(event){
    event.preventDefault();
    var email = $("#verify-new-email-modal-email-input").val();
    $("#verify-new-email-modal-email-input").prop("readonly", true);
    if (email) {
      let requestBody = {
        kind: "email",
        email: email
      };
      // let modalDiv = document.getElementById("verify-modal");
      // if (!modalDiv){ return; }
      // console.dir("doLoadVerification:1");
      // modalDiv.querySelector(".modal-title").innerHTML = "Loading";
      // modalDiv.querySelector(".modal-body").innerHTML = '<div class="text-center mt-5 mb-5"><em class="fa fa-spinner fa-spin fa-3x"></em></div>';
      // $("#verify-modal").modal("show");
      // console.dir("doLoadVerification:2");
      $('#verify-new-email-modal-message1').html('<div class="text-center mt-5 mb-5"><em class="fal fa-circle-notch fa-spin fa-2x"></em></div>');

      const request = new FetchRequest("post", '/account/load-verification', {responseKind: "json", body: requestBody});
      const response = await request.perform();
      if (response.ok) {
        response.json.then(function (data) {
          if (data.form) {
            $('#verify-new-email-modal-message1').html("");
            $('#verify-new-email-modal-code-holder').html(data.form);
            $("#new-email-input").val(email);
          } else if (data.error) {
            $("#verify-new-email-modal-email-input").prop("readonly", false);
            $('#verify-new-email-modal-message1').html(json.error);
          } else {
            $("#verify-new-email-modal-email-input").prop("readonly", false);
            $('#verify-new-email-modal-message1').html('<div class="text-danger">'+i18n_string("general.unknown_error_please_try_again","Unknown error occurred. Please try again")+'</div>');
          }
        });
      }
    }
  }

  // This is called from the super class VerifyAccountController -> and assigned in the connect function
  process_verify_code_for_convert(valid_code) {
    if (valid_code) {
      $("#change_email_checkbox").prop("checked", true);
      $("#new-email-after-verification-div").show();
      $("#verify-new-email-modal").modal('hide');
    } else {
      let errorDiv = document.getElementById("verify-modal-error-message");
      if (errorDiv){
        errorDiv.innerHTML = '<div class="alert alert-danger">'+i18n_string("sessions.verify_login.incorrect_verification_code","Incorrect verification code")+'</div>';
      }
    }
  }
}
