import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="signup"
export default class extends Controller {
  initialize() {
    this.invite_button_processing = false;
  }

  inviteInputChange() {
    if($('#invite_code').val().length > 0) {
      $('button[type="submit"]').removeAttr("disabled");
    } else {
      $('button[type="submit"]').attr("disabled", "disabled");
    }
  }
}
