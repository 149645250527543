import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="add-organization"
export default class extends Controller {

  static targets = [ "error", "invite_code", "landing_page" ]

  connect() {
    console.dir(this.element.dataset.url);
    if (this.element.dataset && this.element.dataset.url) {
      this.invite_code_validate_url = this.element.dataset.url;
    }
    this.upgrade_account_request = false;
    if (this.element.dataset && this.element.dataset.upgrade && this.element.dataset.upgrade == "yes") {
      this.upgrade_account_request = true;
    }
  }

  check_invite_code(){
    let check_code = this.invite_codeTarget.value;
    this.perform_check_invite_code(check_code);
    /*
    if (!this.invite_code_validate_url) {
      return;
    }
    // console.log(this.errorTarget);
    // console.log(this.invite_codeTarget);
    // console.log(this.landing_pageTarget);
    this.errorTarget.innerHTML = "";
    this.errorTarget.style.display = 'none';
    let check_code = this.invite_codeTarget.value;
    let errorTargetRef = this.errorTarget;
    let landingPageTargetRef = this.landing_pageTarget;
    if (check_code && check_code != "") {
      let requestBody = {
        invite_code: check_code
      };
      if (this.upgrade_account_request) {
        requestBody.upgrade_request = 1;
      }
      const request = new FetchRequest("post", this.invite_code_validate_url,
          { responseKind: "json",
            body: requestBody
          });
      const response = await request.perform();
      if (response.ok) {
        response.json.then(function (statusData) {
          if (statusData.status == 'valid_code' && statusData.invite_code && statusData.redirect_url) {
            document.location = statusData.redirect_url;
          } else if (statusData.status == 'landing_page' && statusData.landing_page_options && landingPageTargetRef) {

            // let selectorHTML = '<p class="mt-3">Multiple options are available for this invite code.  Please select one below.</p>';
            // selectorHTML += '<div id="" class="list-group">';
            // for (let [display_name, invite_code] of Object.entries(statusData.landing_page_options)) {
            //   selectorHTML += '  <button type="button" class="list-group-item list-group-item-action" data-code="'+invite_code+'">'+display_name+'</button>';
            // }
            // selectorHTML += '</div>';
            // landingPageTargetRef.innerHTML = selectorHTML;
          } else {
            errorTargetRef.innerHTML = "The invite code provided is not valid";
            errorTargetRef.style.display = '';
          }
        });
      }
    } else {
      this.errorTarget.innerHTML = "Please enter an invite code";
      this.errorTarget.style.display = '';
    }
    */
  }

  async perform_check_invite_code(check_code){
    if (!this.invite_code_validate_url) {
      return;
    }
    // console.log(this.errorTarget);
    // console.log(this.invite_codeTarget);
    // console.log(this.landing_pageTarget);
    this.errorTarget.innerHTML = "";
    this.errorTarget.style.display = 'none';
    let errorTargetRef = this.errorTarget;
    let landingPageTargetRef = this.landing_pageTarget;
    let addOrgControllerRef = this;
    if (check_code && check_code != "") {
      let requestBody = {
        invite_code: check_code
      };
      if (this.upgrade_account_request) {
        requestBody.upgrade_request = 1;
      }
      const request = new FetchRequest("post", this.invite_code_validate_url,
          { responseKind: "json",
            body: requestBody
          });
      const response = await request.perform();
      if (response.ok) {
        response.json.then(function (statusData) {
          if (statusData.status == 'valid_code' && statusData.invite_code && statusData.redirect_url) {
            document.location = statusData.redirect_url;
          } else if (statusData.status == 'landing_page' && statusData.landing_page_options && landingPageTargetRef) {
            const p = document.createElement('p');
            p.className = "mt-3";
            p.innerHTML = i18n_string("users.account.multiple_options_for_invite_code","Multiple options are available for this invite code.  Please select one below.");
            const list_group_div = document.createElement('div');
            list_group_div.className = 'list-group';
            for (let [display_name, invite_code] of Object.entries(statusData.landing_page_options)) {
              let selector_button = document.createElement('button');
              selector_button.type = "button"
              selector_button.className = "list-group-item list-group-item-action"
              selector_button.setAttribute("data-code", invite_code);
              selector_button.innerText = display_name;
              // Event handler
              selector_button.addEventListener('click', (event) => {
                event.preventDefault();
                addOrgControllerRef.perform_check_invite_code(event.currentTarget.getAttribute("data-code"));
              });
              // Append to list
              list_group_div.appendChild(selector_button);
            }
            landingPageTargetRef.appendChild(p);
            landingPageTargetRef.appendChild(list_group_div);
            // let selectorHTML = '<p class="mt-3">Multiple options are available for this invite code.  Please select one below.</p>';
            // selectorHTML += '<div id="" class="list-group">';
            // for (let [display_name, invite_code] of Object.entries(statusData.landing_page_options)) {
            //   selectorHTML += '  <button type="button" class="list-group-item list-group-item-action" data-code="'+invite_code+'">'+display_name+'</button>';
            // }
            // selectorHTML += '</div>';
            // landingPageTargetRef.innerHTML = selectorHTML;
          } else {
            errorTargetRef.innerHTML = i18n_string("users.account.invite_code_provided_is_not_valid","The invite code provided is not valid");
            errorTargetRef.style.display = '';
          }
        });
      }
    } else {
      this.errorTarget.innerHTML = i18n_string("users.account.please_enter_an_invite_code","Please enter an invite code");
      this.errorTarget.style.display = '';
    }
  }


}
