import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="account-login-tracking"
export default class extends Controller {
  connect() {
    this.initializeTableSearch()
    this.resizeChartHolder()
  }


  initializeTableSearch() {
    $.tablesorter.addParser({
      id: 'data',
      is: function(s, table, cell, $cell) {
        return false;
      },
      format: function(s, table, cell, cellIndex) {
        var $cell = $(cell);
        if (cellIndex === 1) {
          return $cell.attr('data-date') || s;
        }
        return s;
      },
      // flag for filter widget (true = ALWAYS search parsed values; false = search cell text)
      parsed: true,
      // set type, either numeric or text
      type: 'numeric'
    });

    
    $('#sortable-table').tablesorter({
      headers: {
        1: { sorter: 'data' }
      }
    });

    $('#sortable-sub-account-table').tablesorter({
      headers: {
        2: { sorter: 'data' }
      }
    });

    $('#sortable-table-engagement').tablesorter({
      headers: {
        1: { sorter: 'data' },
        2: { sorter: 'data' },
        3: { sorter: 'data' },
      }
    });
  }

  resetTable(event) {
    event.preventDefault();
    $('#search_filter').val('');
    if($('#sortable-table').length){
      $('#sortable-table tbody tr').each(function(){
        $(this).show();
      });
    }

    if($('#sortable-table-engagement').length){
      $('#sortable-table-engagement tbody tr').each(function(){
        $(this).show();
      });
    }
  }

  resizeChartHolder() {
    if( $('#participation_chart_holder').length ){
      if($('#participant_chart').width() > $('#content-body').width()) {
        $('#participation_chart_holder').css('width', ($('#content-body').width() - $('#navigation-col').width()) + 'px');
      } else {
        $('#participation_chart_holder').css('width', '100%');
      }
    }
  }

  searchTable(event) {
    var f = event.target.value;
    if($('#sortable-table').length) {
      $('#sortable-table tbody tr').each(function(){
        if($(this).text().search(new RegExp(f, "i")) < 0){
          $(this).fadeOut();
        } else {
          $(this).show();
        }
      });
    }

    if($('#sortable-table-engagement').length) {
      $('#sortable-table-engagement tbody tr').each(function(){
        if($(this).text().search(new RegExp(f, "i")) < 0){
          $(this).fadeOut();
        } else {
          $(this).show();
        }
      });
    }
  }

  searchTableSubmit(event) {
    event.preventDefault()
  }

  setCustomerStatus(event) {
    event.preventDefault()

    // Clear existing selected options
    $("#customer_status option:selected").prop("selected", false);
    
    $('.change-column-filter-modal-type-select-checkbox').each(function(i, obj){
      if(obj.checked) {
        // Check option if item is selected
        $('#customer_status option[value="' + obj.value + '"]').prop('selected', true);
      }
    })

    $("#change-column-filter-modal").modal("hide");
    $("#filter_customers").submit()

  }
}
