import { Controller } from "@hotwired/stimulus"
import WellnessCommonController from "./wellness_common_controller.js"

// Connects to data-controller="wellness-snapshot"
export default class extends WellnessCommonController {
  connect() {
    console.log("Wellness Snapshot Controller")

    document.wsControllerRef = this
    const controllerRef = this

    // // Custom callback created - https://www.betterstimulus.com/interaction/callbacks.html
    // $(document).on('wellness_snapshot_controller.state', function(event, callback) {
    //     callback(this)
    //   }.bind(this)
    // )

    // Determine if we have the snapshot updates modal to show
    if($('#well-being-snapshot-revisions-modal').length){
      $("#well-being-snapshot-revisions-modal").modal()
      $('#well-being-snapshot-revisions-modal').modal('show')
    }

    // Prevents duplicate loading of connect events. (used in generating option lists)
    if(!$('#configure_options').length){


      // Determine if we have the terms modal to show
      if($('#well-being-snapshot-terms-modal').length){
        $("#well-being-snapshot-terms-modal").modal({backdrop: 'static', keyboard: false})
        $('#well-being-snapshot-terms-modal').modal('show')
      }


      document.offcanvas_snapshot = document.getElementById('configureReportOptions');
      document.bsOffcanvas_snapshot = new bootstrap.Offcanvas( document.offcanvas_snapshot );

      super.showLoader()

      // Default Check if Data Exists
      if($('#i_can_create_snapshot_folders').val() == 'true' || ($('#saved_report_settings_last').length && $('#default_loaded_report'.length))) {

        controllerRef.setInitialSettings()

        

        if(document.initial_report_settings !== undefined) {

          console.log("WE ARE LOADING DEFAULT WELLNESS SNAPSHOT STUFF")
          console.log("Initial Settings:", document.initial_report_settings)


          // Enable PopOvers
          var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
          var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
          })

          // document.offcanvas = document.getElementById('configureReportOptions');
          // document.bsOffcanvas = new bootstrap.Offcanvas( document.offcanvas );


          document.saved_filter_loaded = undefined;
          document.edit_saved_filter_loaded = undefined;
          document.initial_report_load_complete = false
          

          // Inital Load
          super.loadQuestion('primary_question')
          controllerRef.resetFilterOptions()
          controllerRef.loadSavedReportSettings(controllerRef)
          super.setReportPanelSize()

          // super.initialReportLoad()
          add_google_chart_onload_callback(function(){
            controllerRef.processReportSettingsForm();
          });
          
        //   controllerRef.showNationalComparisons()

        // } else {
        //   $('#configuration-panel').addClass("hidden");
        //   $('#ranges-explanation-all').addClass('hidden')
        //   $("<p>" + I18n.wellness_snapshot.admin_no_reports_assigned + "</p>").insertBefore('#ranges-explanation-all')
        }
      } else {
        $('#configuration-panel').addClass("hidden");
        $('#ranges-explanation-all').addClass('hidden')
        if(document.getElementById('snapshot_no_reports_assigned') == undefined) {
          $("<p id='snapshot_no_reports_assigned'>" + I18n.wellness_snapshot.admin_no_reports_assigned + "</p>").insertBefore('#ranges-explanation-all')
        }
      }
    }

    console.log("  Initial - default settings:", document.saved_report_settings_default)
    console.log("  Initial - last settings:", document.saved_report_settings_last)
  } 




  //         modalEl.addEventListener('hidden.bs.modal', function(event){
  //           modalEl.remove()
  //         });

  //         $('#submit').click()
  //       }
  //     }
  //   });
  // }

  /* **************************************************************************
  function: arrayDiffAssoc
  * Internal triggered
  * Used to compare the differences between two arrays
  ************************************************************************** */
  arrayDiffAssoc (arr1) {
    var retArr = {};
    var argl = arguments.length;
    var k1 = '';
    var i = 1;
    var k = '';
    var arr = {};
  
    arr1keys: for (k1 in arr1) {
      for (i = 1; i < argl; i++) {
        arr = arguments[i];
        for (k in arr) {
          // console.log(k+" -- "+k1);
          if (arr[k] === arr1[k1] && k === k1) {
            continue arr1keys;
          }
        }
        retArr[k1] = arr1[k1];
      }
    }
  
    return retArr;
  }


  /* **************************************************************************
  function: changePrimaryOptionKinds
  * Internal triggered
  * MAY NOT BE USED
  ************************************************************************** */
  // changePrimaryOptionKinds(event) {
  //   console.log("WS: changePrimaryOptionKinds")
  //   var option_kind = event.currentTarget.value
  //   if(option_kind == 'default') {
  //     $('#primary_question_options_filter').removeClass("hidden")
  //   } else {
  //     $("#filter_by_primary_option").parent().click()
  //     $('#primary_question_options_filter').addClass("hidden")
  //   }
  // }

  /* **************************************************************************
  function: changePrimaryQuestion
  * User and Internal triggered
  * Gets the newly selected question, clears secondary and tertiary questions, loads secondary question builder
  ************************************************************************** */
  changePrimaryQuestion(event) {
    console.log("WS: changePrimaryQuestion")
    this.resetQuestionPrimary();
    this.resetQuestionSecondary();
    this.resetQuestionTertiary();

    var question_id = event.currentTarget.value

    // const controllerRef = this
    if( document.initial_report_load_complete == true) {

      if(question_id == '') {
        $('#primary_question_customize_options').attr('href', '#');

        $('#dashboard-filter-options').addClass("hidden");
        $('#chart-sort-by').hide();

        $('#secondary_question').html('')
        $('#secondary_options').html('')

        if($('#tertiary_question_holder').length){
          $("#tertiary_question").html('');
          $('#tertiary_options').html('');
        }
      } else {
        $('#primary_question_customize_options').attr('href', '/wellness_snapshot/' + question_id.split("-")[0] + '/configure_options')
        $('#dashboard-filter-options').removeClass("hidden");
        $('#chart-sort-by').show();
        this.showNationalComparisons()

        this.changePrimaryQuestionProcess(question_id)

        if($('#tertiary_question_holder').length){
          $('#tertiary_question_holder').hide();
          $("#tertiary_question").html('');
          $('#tertiary_options').html('');
        }
      }
    }
  }

  /* **************************************************************************
  function: changePrimaryQuestionProcess
  * Internal triggered
  * Loads the secondary questions and calls function to load primary question options
  ************************************************************************** */
  changePrimaryQuestionProcess(question_id, chosen_option_list_id = undefined) {
    console.log("WS: changePrimaryQuestionProcess")
    const controllerRef = this

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/wellness_snapshot/get_signup_questions",
      data: { selected_question: question_id },
      async: false,
      success: function (data) {
        if(data.status == 200) {
          $('#secondary_question').html( controllerRef.processQuestionList(data.questions) )
          $('#secondary_options').html('')
        }
      }
    });

    controllerRef.getSignUpQuestionOptionList(question_id, chosen_option_list_id, 'primary', 'primary_option_kinds')
  }

  /* **************************************************************************
  function: changeQuestionOptionKinds
  * User and Internal triggered
  * Loads the question option kids (standard list and custom lists)
  * MAY NOT BE USED
  ************************************************************************** */
  // changeQuestionOptionKinds(event, question_id = undefined, option_id = undefined, question_level = undefined) {
  //   console.log("WS: changeQuestionOptionKinds")
  //   const controllerRef = this

  //   if(event !== undefined) {
  //     var item = event.currentTarget
  //     question_id = item.dataset.questionId
  //     option_id = item.value
  //     question_level = item.dataset.questionLevel
  //   }

  //   $.ajax({
  //           headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
  //           type: "POST",
  //           url: "/wellness_snapshot/get_signup_question_options",
  //           data: { selected_question: question_id, option_group_id: option_id },
  //           async: false,
  //           success: function (data) {
  //             if(data.status == 200) {
  //               $('#' + question_level + '_options').val(null).trigger('change'); // Clear chosen settings (if any)
  //               $('#' + question_level + '_options').html( controllerRef.processOptionList(data.options) )
  //             }
  //           }
  //         });
  // }


  /* **************************************************************************
  function: changeSecondaryOptionKinds
  * User and Internal triggered
  * MAY NOT BE USED
  ************************************************************************** */
  // changeSecondaryOptionKinds(event) {
  //   console.log("WS: changeSecondaryOptionKinds")
  //   var option_kind = event.currentTarget.value
  //   if(option_kind == 'default') {
  //     $('#secondary_question_options_filter').removeClass("hidden")
  //   } else {
  //     $("#filter_by_secondary_option").parent().click()
  //     $('#secondary_question_options_filter').addClass("hidden")
  //   }
  // }

  /* **************************************************************************
  function: changeSecondaryQuestion
  * User and Internal triggered
  * Gets the newly selected question, clears tertiary questions, loads tertiary question builder if available
  ************************************************************************** */
  changeSecondaryQuestion(event) {
    console.log("WS: changeSecondaryQuestion")
    this.resetQuestionSecondary();
    this.resetQuestionTertiary();

    this.showNationalComparisons()
    if(document.initial_report_load_complete == true) {

      if(event.currentTarget.value == '') {

        $('#secondary_question_customize_options').attr('href', '#');

        $('#secondary_questions').hide();
        $("#tertiary_question_holder").hide();
        $('#tertiary_question').html('');
      } else {
        $('#secondary_questions').show();

        $('#secondary_question_customize_options').attr('href', '/wellness_snapshot/' + event.currentTarget.value.split("-")[0] + '/configure_options')

        this.changeSecondaryQuestionProcess(event.currentTarget.value)
      }
    }
  }

  /* **************************************************************************
  function: changeSecondaryQuestionProcess
  * Internal triggered
  * Loads the tertiary questions and calls function to load secondary question options
  ************************************************************************** */
  changeSecondaryQuestionProcess(question_id, chosen_option_list_id = undefined) {
    console.log("WS: changeSecondaryQuestionProcess")
    const controllerRef = this

    if($('#tertiary_question_holder').length){
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        url: "/wellness_snapshot/get_signup_questions",
        data: { selected_question: question_id, primary_question: $('#primary_question').val() },
        async: false,
        success: function (data) {
          if(data.status == 200) {
            $("#tertiary_question_holder").show();
            $('#tertiary_question').html( controllerRef.processQuestionList(data.questions) )
          }
        }
      }); 
    }

    controllerRef.getSignUpQuestionOptionList(question_id, chosen_option_list_id, 'secondary', 'secondary_option_kinds')
  }

  /* **************************************************************************
  function: changeTertiaryQuestion
  * User and Internal triggered
  * Gets the newly selected question, loads tertiary question builder if available
  ************************************************************************** */
  changeTertiaryQuestion(event) {
    console.log("WS: changeTertiaryQuestion")
    this.resetQuestionTertiary();
    this.showNationalComparisons()
    if( document.initial_report_load_complete == true) {
      if(event.currentTarget.value == '') {
        $('#tertiary_questions').hide();

        $('#tertiary_question_customize_options').attr('href', '#')
      } else {
        $('#tertiary_questions').show();

        $('#tertiary_question_customize_options').attr('href', '/wellness_snapshot/' + event.currentTarget.value.split("-")[0] + '/configure_options')
        this.changeTertiaryQuestionProcess(event.currentTarget.value)
      }
    }
  }

  /* **************************************************************************
  function: changeTertiaryOptionKinds
  * User and Internal triggered
  * MAY NOT BE USED
  ************************************************************************** */
  // changeTertiaryOptionKinds(event) {
  //   console.log("WS: changeTertiaryOptionKinds")
  //   var option_kind = event.currentTarget.value
  //   if(option_kind == 'default') {
  //     $('#tertiary_question_options_filter').removeClass("hidden")
  //   } else {
  //     $("#filter_by_tertiary_option").parent().click()
  //     $('#tertiary_question_options_filter').addClass("hidden")
  //   }
  // }


  /* **************************************************************************
  function: changeTertiaryQuestionProcess
  * Internal triggered
  * Loads the tertiary question options
  ************************************************************************** */
  changeTertiaryQuestionProcess(question_id, chosen_option_list_id = undefined) {
    console.log("WS: changeTertiaryQuestionProcess")
    const controllerRef = this


    controllerRef.getSignUpQuestionOptionList(question_id, chosen_option_list_id, 'tertiary', 'tertiary_option_kinds')

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/wellness_snapshot/get_signup_question_options",
      data: { selected_question:  question_id},
      async: false,
      success: function (data) {
        if(data.status == 200) {
          $('#tertiary_options').html( controllerRef.processOptionList(data.options) )
        }
      }
    });
  }

  
  /* **************************************************************************
  function: checkForChangeFromDefault
  * Internal triggered
  * Determines if the options selected have changed from the originally loaded settings
  * This is where most of the JS issues arise when trying to reset the options.
  ************************************************************************** */
  checkForChangeFromDefault() {
    console.log("WS: checkForChangeFromDefault")

    if(document.resetFilter == true){
      document.saved_report_settings_last = document.saved_report_settings_default
    }

    var array1 = JSON.parse(document.saved_report_settings_default);
    var array2 = JSON.parse(document.saved_report_settings_last);

    var comparison =  super.arrayDiffAssoc(array1, array2);
    var comparison2 =  super.arrayDiffAssoc(array2, array1);

    console.log("document.saved_report_settings_default", JSON.parse(document.saved_report_settings_default))
    // console.log("document.initial_report_settings", document.initial_report_settings)
    console.log("document.saved_report_settings_last", JSON.parse(document.saved_report_settings_last))
    console.log(comparison)
    console.log(comparison2)

    if(Object.keys(comparison).length > 0 || Object.keys(comparison2).length > 0){
      // Notice on "Configure Report" button
      // Notice in header area to reset
      // Notice in settings panel to reset
      // Notice in settings panel to save as "Saved Configuration"
      $(".report-settings-filter-applied-notice").removeClass('hidden');
      if(document.saved_filter_loaded && document.saved_filter_loaded > 0)
      {
        $(".report-settings-filter-save-btn").addClass('hidden');
        $(".report-settings-filter-save-as-new-btn").removeClass('hidden');
        // Check to see if the saved_filter_loaded differs from the current last filter
        $(".report-settings-filter-update-btn").removeClass('hidden');
      }
      else
      {
        $(".report-settings-filter-update-btn").addClass('hidden');
        $(".report-settings-filter-save-as-new-btn").addClass('hidden');
        $(".report-settings-filter-save-btn").removeClass('hidden');
      }
    } else {
      $(".report-settings-filter-applied-notice").addClass('hidden');
      $(".report-settings-filter-save-btn").addClass('hidden');
      $(".report-settings-filter-update-btn").addClass('hidden');
      $(".report-settings-filter-save-as-new-btn").addClass('hidden');
    }

    super.setReportPanelSize()
  } 



  /* **************************************************************************
  function: clearSnapshotLabel
  * Internal triggered
  * Resets and hides the snapshot label description gray box.
  ************************************************************************** */
  clearSnapshotLabel() {
    console.log("WS: clearSnapshotLabel")
    $('#report-name-holder').addClass('hidden')
    $('#report-name').html('')
    $('#report-description').html('')
    $('#report-created-by').html('')
  }




  /* **************************************************************************
  function: closeSettings
  * User triggered
  * Closes the off-canvas settings panel
  ************************************************************************** */
  closeSettings(event) {
    console.log("WS: closeSettings")
    if( $("#configureReportOptions").hasClass("show")){
      $("#configureReportOptions").removeClass("show")
    }
  }



  /* **************************************************************************
  function: closeSnapshotUpdates
  * User triggered
  * Saves when the user has closed the notification
  ************************************************************************** */
  closeSnapshotUpdates(e) {
    console.log("WS: closeSnapshotUpdates")
    var formData = "setting_name=viewed_snapshot_updates&setting_value=yes";
    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/users/viewed_snapshot_updates",
      data: { setting_name: 'viewed_snapshot_updates', setting_value: 'yes' },
      success: function (data) {
        if(data.status == 200) {
          var modalEl = document.getElementById('well-being-snapshot-revisions-modal')

          // $("#well-being-snapshot-outer-div").removeClass("blur-element");
          $("#well-being-snapshot-revisions-modal").modal('hide');


          modalEl.addEventListener('hidden.bs.modal', function(event){
            modalEl.remove()
          });

          $('#submit').click()
        }
      }
    });
  }

  /* **************************************************************************
  function: closeSnapshotUpdates
  * User triggered
  * Saves when the user has closed the notification
  ************************************************************************** */
  closeSnapshotUpdatesDashboard(e) {
    console.log("WS: closeSnapshotUpdatesDashboard")
    var formData = "setting_name=viewed_snapshot_updates_dashboard&setting_value=yes";
    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/users/viewed_snapshot_updates_dashboard",
      data: { setting_name: 'viewed_snapshot_updates', setting_value: 'yes' },
      success: function (data) {
        if(data.status == 200) {
          var modalEl = document.getElementById('well-being-snapshot-revisions-modal')

          // $("#well-being-snapshot-outer-div").removeClass("blur-element");
          $("#well-being-snapshot-revisions-modal").modal('hide');


          modalEl.addEventListener('hidden.bs.modal', function(event){
            modalEl.remove()
          });

          $('#submit').click()
        }
      }
    });
  }

  /* **************************************************************************
  function: configureSelect2Fields
  * Internal triggered
  * Sets up the select2 options
  ************************************************************************** */
  configureSelect2Fields(selector, options){
    console.log("WS: configureSelect2Fields")
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }

  /* **************************************************************************
  function: dateRangeSelect
  * User triggered
  * Determines which date select options to show and resets other options if changed.
  ************************************************************************** */
  dateRangeSelect(event) {
    console.log("WS: dateRangeSelect")
    this.toggleActiveClass(event)

    $('#assess_filter_holder').show()

    if(event.currentTarget.children[0].value == 0) {
      $('#report-date-range-custom-div').attr("style", "display:block");
      $('#report-campaign-select-div').attr("style", "display:none");
    } else if(event.currentTarget.children[0].value == 'campaign') {
      $('#report-campaign-select-div').attr("style", "display:block");
      $('#report-date-range-custom-div').attr("style", "display:none");
      $('#assess_filter_holder').hide()
      $('#assess_filter').val('last_in_period')
    } else {
      $('#report-date-range-custom-div').attr("style", "display:none");
      $('#report-campaign-select-div').attr("style", "display:none");
      $('#start_date').val( $('#default_start_date').val() );
      $('#end_date').val( $('#default_end_date').val() );
    }
  }

  /* **************************************************************************
  function: determineReload
  * Internal triggered
  * Determines if we need to reload the snapshot report after the user closes the custom list modal
  ************************************************************************** */
  determineReload(event) {
    console.log("WS: determineReload")
    const controllerRef = this
    if(window.reloadSettings == true){
      window.reloadSettings = false
      controllerRef.resetSettings(event)
    }
  }

  /* **************************************************************************
  function: determineReloadFolders
  * Internal triggered
  * Determines if we need to reload the snapshot folders after the user closes the custom list modal
  ************************************************************************** */
  determineReloadFolders(event) {
    console.log("WS: determineReloadFolders")
    if(window.reloadFolders == true){
      window.reloadFolders = false
      document.getElementById('load_saved_snapshots').reload()
    }
  }

  /* **************************************************************************
  function: displayComparisonDates
  * User triggered
  * Determines if we need to reload the snapshot folders after the user closes the custom list modal
  ************************************************************************** */
  displayComparisonDates(event) {
    console.log("WS: displayComparisonDates")
    this.toggleActiveClass(event)
    if(event.currentTarget.children[0].value == 'custom') {
      $('#comparison-dates').removeClass('hidden');
    } else {
      $('#comparison-dates').addClass('hidden');
    }

    this.showNationalComparisons()
  }


  /* **************************************************************************
  function: displayResponseAs
  * User triggered
  * Displays the data as either participants or percentages
  ************************************************************************** */
  displayResponseAs(event) {
    console.log("WS: displayResponseAs")
    super.toggleActiveClass(event)

    document.wsControllerRef.displayResponseAsProcess(event.currentTarget.children[0].value)
    this.showNationalComparisons()
  }


  displayResponseAsProcess(kind, prefix = '') {
    console.log("WS: displayResponseAsProcess")
    if(kind == 'participants') {
      $('input[name="sort_distress_by"][value="thriving"]').parent().addClass('hidden');
      $('input[name="sort_distress_by"][value="distressed"]').parent().click();
      $('input[name="show_group_total"][value="1"]').parent().addClass("hidden");
      $('input[name="show_group_total"][value="0"]').parent().click();
      $('input[name="show_organization_total"][value="1"]').parent().addClass("hidden");
      $('input[name="show_organization_total"][value="0"]').parent().click();
      $('input[name="show_national_total"][value="1"]').parent().addClass("hidden");
      $('input[name="show_national_total"][value="0"]').parent().click();
      $('#national-overall').addClass("hidden");
      $('#national-primary').addClass('hidden');
      $('#secondary-primary').addClass('hidden');
      $('#tertiary-primary').addClass('hidden');
    } else {
      $('#' + prefix + 'display_responses_as').parent().click();
      $('input[name="sort_distress_by"][value="distressed"]').parent().click();
      $('input[name="sort_distress_by"][value="thriving"]').parent().removeClass('hidden');
      $('input[name="show_group_total"][value="1"]').parent().removeClass("hidden");
      $('input[name="show_organization_total"][value="1"]').parent().removeClass("hidden");
      $('input[name="show_national_total"][value="1"]').parent().removeClass("hidden");
    }
  }




  /* **************************************************************************
  function: loadSavedReportSettings
  * User and Internal triggered
  * Loads settings from a saved snapshot report, resets all settings and processes new list.
  * Main function to handle all resetting and loading of new data
  ************************************************************************** */
  loadSavedReportSettings(controllerRef, settings){
    console.log("WS: loadSavedReportSettings")

    // This loads the default (last saved report)
    $('#tertiary_question_holder').addClass('hidden');

    var primary_question_id = ''
    var secondary_question_id = ''
    var tertiary_question_id = ''

    var loaded_settings = false

    // Fully resetting options
    if(document.resetFilter == true){
      document.loadSettingsFromSerializedString = true;
      var dataParsed = document.initial_report_settings;
      var dataKeys = Object.keys(dataParsed);
    } else {
      // Load the current panel for settings
      document.saved_report_settings_default = JSON.stringify(document.initial_report_settings)
      if( ($("#saved_report_settings_last").length && $("#saved_report_settings_last").val() != "") || settings !== undefined) {
        // Check if we changed any settings and if so set the current settings to that.
        if(settings !== undefined) {
          loaded_settings = true
          document.saved_report_settings_last = settings;
        } else {
          document.saved_report_settings_last = $("#saved_report_settings_last").val();
        }
        
        // Check if we have the same wbi version. If not set current settings to default.
        if( JSON.parse(document.saved_report_settings_default).wbi_version != JSON.parse(document.saved_report_settings_last).wbi_version) {
          document.saved_report_settings_last = document.saved_report_settings_default;
        }

        if($("#saved_report_settings_last_save_id").length) {
          document.saved_filter_loaded = $("#saved_report_settings_last_save_id").val();
        }
      } else {
        document.saved_report_settings_last = document.saved_report_settings_default;
      }

      document.loadSettingsFromSerializedString = true;
      var dataParsed = JSON.parse(document.saved_report_settings_last);
      var dataKeys = Object.keys(dataParsed);
    }
  
    // UNCHECK CHECKBOXES/MULTI-SELECTs
    for(var i=0;i<dataKeys.length;i++){
      var name = dataKeys[i];
      var value = dataParsed[name];

      if(name.indexOf('[') == -1 || $("[name='"+name+"']").length > 0)
      {

        if(name == 'report_id') { 
          // do nothing
        }

        if(name == 'primary_question' && dataParsed[name] != '') {
          // Originally used for generating the custom option lists. may not be needed ER 8/10/23
          primary_question_id = dataParsed[name]

          // $('#primary_option_list_btn').attr('href', '/wellness_common/' + primary_question_id.split('-')[0] + '/configure_options')

          $('#dashboard-filter-options').removeClass('hidden');
          $('#chart-sort-by').removeClass('hidden');
          $('#primary_question').val(primary_question_id).trigger('change');
          // this.changePrimaryQuestionProcess(primary_question_id)
        }
  
        if(name == 'primary_option_kinds' && dataParsed[name] == "default"){
          $('#primary_question_options_filter').removeClass("hidden");
        }

        if(name == 'filter_by_primary_option' && dataParsed[name] == 1){
          $('#primary_question_options').removeClass("hidden");
        }
        
        if(name == 'secondary_question' && dataParsed[name] != '') {

          console.log("-=-=-=-=-=----- SECONDARY QUESTION ----=-=-=-=-=-=")

          secondary_question_id = dataParsed[name]

          if($('#tertiary_question').length){
            // Load Tertiary Question
            $.ajax({
              headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
              url: "/wellness_common/get_signup_questions",
              method: "post",
              data: { 'selected_question': secondary_question_id, 'primary_question': primary_question_id },
              async: false
            }).done(function( data){
              if(data.status == 200){
                $('#tertiary_question_holder').removeClass('hidden');
                $('#tertiary_question').html( controllerRef.processQuestionList(data.questions) );
              }
            });
          }

          $.ajax({
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            type: "POST",
            url: "/wellness_common/get_signup_question_option_list",
            data: { selected_question: secondary_question_id },
            async: false,
            success: function (data) {
              if(data.status == 200) {
                $('#secondary_option_kinds').html( controllerRef.processOptionKind(data.options) )
              }
            }
          });
  
          $.ajax({
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            url: "/wellness_common/get_signup_question_options",
            method: "post",
            data: { "selected_question": secondary_question_id },
            async: false
          }).done(function(data){
            if(data.status == 200) {
              $('#secondary_questions').removeClass('hidden')
              $('#secondary_options').html( controllerRef.processOptionList(data.options) );
            }
          });
        }
  
        if(name == 'filter_by_secondary_option' && dataParsed[name] == 1){
          $('#secondary_question_options').removeClass("hidden");
        }
  
        if(name == 'tertiary_question' && dataParsed[name] != '') {
          $('#tertiary_question_holder').removeClass('hidden');
          $('#tertiary_questions').removeClass('hidden');
  
          $.ajax({
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            url: "/wellness_common/get_signup_question_options",
            method: "post",
            data: { "selected_question": dataParsed[name] },
            async: false
          }).done(function(data){
            if(data.status == 200){
              $('#tertiary_options').html( controllerRef.processOptionList(data.options) );
            }
          });
        }
  
        if(name == 'filter_by_tertiary_option' && dataParsed[name] == 1){
          $('#tertiary_question_options').removeClass("hidden");
        }
  
        // Choose Time Period
        if(name == 'date_range_select') {
          $('#assess_filter_holder').removeClass('hidden')
          if(dataParsed[name] == '0') {
            $('.report-date-range-custom-div').removeClass('hidden');
          }
          if(dataParsed[name] == 'campaign') {
            $('#report-campaign-select-div').removeClass('hidden');
            $('#assess_filter_holder').addClass('hidden')
            $('#assess_filter').val('last_in_period')
            // $('#assess_filter').attr("disabled", true);
          }
        }
  
        if(name == 'display_response_as') {
          // Specifically filter options for participant display.
          if(dataParsed[name] == 'participants') {
            $('input[name="sort_distress_by"][value="thriving"]').parent().addClass('hidden');
            $('input[name="sort_distress_by"][value="distressed"]').click();
            $('input[name="show_group_total"][value="1"]').parent().addClass("hidden");
            $('input[name="show_group_total"][value="0"]').click();
            $('input[name="show_organization_total"][value="1"]').parent().addClass("hidden");
            $('input[name="show_organization_total"][value="0"]').click();
          }
        }
  
        var $element = $("[name='"+name+"']");
        var inputType = $element.prop("type");
        if(inputType == "checkbox") {
          $element.prop("checked",false);
        } else if(inputType == "select-multiple") {
          $element.children("option").prop("selected", false);
        }
      }
      else if(name.indexOf('[') != -1)
      {
        // Multi-select selectbox or checkbox array
        var nameParts = name.split("[");
        name = nameParts[0]+"[]";
        var $element = $("[name='"+name+"']");
        if($element.length > 0)
        {
          var inputType = $element.prop("type");
          if(inputType == "checkbox") {
            $element.prop("checked",false);
          } else if(inputType == "select-multiple") {
            $element.children("option").prop("selected", false);
          }
  
        }
      }
    }
    // SELECT NEW VALUES
    var nonExistingIndexes = [];
    for(var i=0;i<dataKeys.length;i++){
      var name = dataKeys[i];
      var value = dataParsed[name];

      if(name.indexOf('[') == -1 || $("[name='"+name+"']").length > 0)
      {
        if(name == 'report_id') { 
          // do nothing - we set this above when selecting the saved report
        } 

        if(name == 'primary_question' && dataParsed[name] != '') {
          // Originally used for generating the custom option lists. may not be needed ER 8/10/23
          primary_question_id = dataParsed[name]
          $('#primary_question_customize_options').attr('href', '/wellness_common/' + primary_question_id + '/configure_options')

          $('#dashboard-filter-options').removeClass('hidden');
          $('#chart-sort-by').removeClass('hidden');
  
          var obj = ["", primary_question_id, 'primary']
          super.changeQuestion(obj, dataParsed['primary_option_kinds'])
          // this.changePrimaryQuestionProcess(primary_question_id, dataParsed['primary_option_kinds'])
        }
  

        if(name == 'customize_primary_option_group' && dataParsed[name] == 1){
          $('#primary_question_option_groups').removeClass('hidden');
        }

        if(name == 'filter_by_primary_option' && dataParsed[name] == 1){
          $('#primary_question_options').removeClass("hidden");
        }

        
        
        if(name == 'secondary_question' && dataParsed[name] != '') {

          secondary_question_id = dataParsed[name]

          // TODO: Look at reloading this farther down instead of here.
          // if($('#tertiary_question').length){
          //   // Load Tertiary Question
          //   $.ajax({
          //     headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          //     url: "/wellness_snapshot/get_signup_questions",
          //     method: "post",
          //     data: { 'selected_question': secondary_question_id, 'primary_question': primary_question_id },
          //     async: false
          //   }).done(function( data){
          //     if(data.status == 200){
          //       $('#tertiary_question_holder').removeClass('hidden');
          //       $('#tertiary_question').html( controllerRef.processQuestionList(data.questions) );
          //     }
          //   });
          // }

          $('#secondary_questions').removeClass('hidden')
          var obj = ["", secondary_question_id, 'secondary']
          super.changeQuestion(obj, dataParsed['secondary_option_kinds'])
          // this.changeSecondaryQuestionProcess(secondary_question_id, dataParsed['secondary_option_kinds'])
        }
  
        if(name == 'customize_secondary_option_group' && dataParsed[name] == 1){
          $('#secondary_question_option_groups').removeClass('hidden');
        }

        if(name == 'filter_by_secondary_option' && dataParsed[name] == 1){
          $('#secondary_question_options').removeClass("hidden");
        }

  
        if(name == 'tertiary_question' && dataParsed[name] != '') {
          $('#tertiary_question_holder').removeClass('hidden');
          $('#tertiary_questions').removeClass('hidden');

          tertiary_question_id = dataParsed[name]
          var obj = ["", tertiary_question_id, 'tertiary']
          super.changeQuestion(obj, dataParsed['tertiary_option_kinds'])
          // this.changeTertiaryQuestionProcess(tertiary_question_id)
        }
  
        if(name == 'tertiary_option_kinds' && dataParsed[name] == "default"){
          $('#tertiary_question_options_filter').removeClass("hidden");
        }

        if(name == 'filter_by_tertiary_option' && dataParsed[name] == 1){
          $('#tertiary_question_options').removeClass("hidden");
        }
  
        // Choose Time Period
        if(name == 'date_range_select') {
          if(dataParsed[name] == '0') {
            $('.report-date-range-custom-div').removeClass('hidden');
          }
          if(dataParsed[name] == 'campaign') {
            $('#report-campaign-select-div').removeClass('hidden');
            // $('#assess_filter').attr("disabled", true);
          }
        }
  
        if(name == 'display_response_as') {
          // Specifically filter options for participant display.
          if(dataParsed[name] == 'participants') {
            $('input[name="sort_distress_by"][value="thriving"]').parent().addClass('hidden');
            $('input[name="sort_distress_by"][value="distressed"]').click();
            $('input[name="show_group_total"][value="1"]').parent().addClass("hidden");
            $('input[name="show_group_total"][value="0"]').click();
            $('input[name="show_organization_total"][value="1"]').parent().addClass("hidden");
            $('input[name="show_organization_total"][value="0"]').click();
          }
        }

        if(name == 'campaign_select') {
          if(dataParsed[name] != '') {
            // dataParsed[name] is an ID number or "none"
            $('#campaign_select').val( dataParsed[name] )

            super.selectCampaign()
            $('#report-campaign-select-comparison-div').removeClass('hidden');
          }
        }

        if(name == 'campaign_select_comparison') {
          if(dataParsed[name] != '') {
            // dataParsed[name] is an ID number or "none"
            $('#campaign_select_comparison').val( dataParsed[name] )
          }
        }

        var $element = $("[name='"+name+"']");
        var inputType = $element.prop("type");

        if(inputType == "radio") {
          $("[name='"+name+"'][value='"+value+"']").parent().click();
        } else if(inputType == "checkbox") {
          $element.each(function(){
            $(this).prop("checked",true);
          });
        } else {
          if(name != 'report_id') { 
            $element.val(value);
          }
        }
  
        if($element.hasClass("select2-hidden-accessible")){
          $element.trigger('change.select2');
        }
      }
      else if(name.indexOf('[') != -1)
      {
        // Multi-select selectbox or checkbox array
        var nameParts = name.split("[");
        name = nameParts[0]+"[]";
        var $element = $("[name='"+name+"']");
        if($element.length > 0)
        {
          var inputType = $element.prop("type");
          if(inputType == "radio") {
            $("[name='"+name+"'][value='"+value+"']").click();
          } else if(inputType == "checkbox") {
            $element.each(function(){
              if($(this).val() == value) {
                $(this).prop("checked", true);
              }
            });
          } else if(inputType == "select-multiple") {
            value = value.replaceAll("'", "\\'")
            $element.children("option[value='" + value + "']").prop("selected", true);
          } else if ($element.length == 1){
            $element.val(value);
          }
  
          if($element.hasClass("select2-hidden-accessible")){
            $element.trigger('change.select2');
          }
        }
        else
        {
          nonExistingIndexes.push(i);
        }
      }
    }

    console.log("==-=-=-=-=-== LOAADED SETTINGS: " + loaded_settings + " -=-=-=-=-=-=-====")

    if(loaded_settings == true) {
      // This happens when you choose a report
      $('#submit').click()
    }
  }




  /* **************************************************************************
  function: loadSavedSettings
  * User triggered
  * Gathers the data from a saved report setting to load
  ************************************************************************** */
  loadSavedSettings(e) {
    console.log("WS: loadSavedSettings")
    const controllerRef = this

    e.preventDefault()
    controllerRef.resetFilterOptions()

    var item = e.currentTarget

    var parentContainer = $(item).closest(".report-settings-saved-filter");
    var save_id = parentContainer.data("save_id");

    if(save_id) {
      document.saved_filter_loaded = save_id;

      $(".report-settings-saved-filter.list-group-item-success").removeClass("list-group-item-success");
      parentContainer.addClass("list-group-item-success");

      $('#report_id').val(item.dataset.id)

      if($('#i_can_create_snapshot_folders').val() == 'false') {
        // Reset the default report settings to clicked report if limited admin
        var loaded_filter = JSON.parse(item.dataset.filter)
        loaded_filter.report_id = item.dataset.id
        document.initial_report_settings = loaded_filter
      }

      window.leavePanelOpen = true
      window.reportName = item.dataset.name
      window.reportDescription = item.dataset.description
      window.reportCreatedBy = item.dataset.createdby

      controllerRef.loadSavedReportSettings(controllerRef, item.dataset.filter)
      controllerRef.saveLastReportSettings(controllerRef)
    }
  }





  /* **************************************************************************
  function: processReportSettingsForm
  * Internal triggered
  * Handles what the user sees on the settings panel, and send the data in the form to the Rails processing file.
  ************************************************************************** */
  processReportSettingsForm(prefix = '') {
    console.log("WS: processReportSettingsForm", prefix)
    const controllerRef = this

    if($('#i_can_create_snapshot_folders').val() == 'false'){
      $('#primary_question_option_text_list').html('')
      $('#secondary_question_option_text_list').html('')
      $('#tertiary_question_option_text_list').html('')

      if($('#primary_question').val() != '') {
        $('#primary_question_option_text_list').removeClass('hidden')
        $('#primary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.question + '</strong> ' + $("#primary_question option:selected").text() + '</li>')

        if( $("#primary_option_kinds").val() != 0){
          $('#primary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.custom_list + ':</strong> ' + $("#primary_option_kinds option:selected").text() + '</li>')
        }

        if( $("#primary_options").val() != '') {
          var data = $("#primary_options :selected").map(function(i, el) {
            return $(el).text()
          }).get();
          $('#primary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.filter + ':</strong> ' + data.join(', ') + '</li>')
        }
      }

      if($('#secondary_question').val() != '') {
        $('#secondary_question_holder').removeClass('hidden')
        $('#secondary_question_option_text_list').removeClass('hidden')
        $('#secondary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.question + '</strong> ' + $("#secondary_question option:selected").text() + '</li>')

        if( $("#secondary_option_kinds").val() != 0){
          $('#secondary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.custom_list + ':</strong> ' + $("#secondary_option_kinds option:selected").text() + '</li>')
        }

        if( $("#secondary_options").val() != '') {
          var data = $("#secondary_options :selected").map(function(i, el) {
            return $(el).text()
          }).get();
          $('#secondary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.filter + ':</strong> ' + data.join(', ') + '</li>')
        }
      } else {
        $('#secondary_question_holder').addClass('hidden')
      }

      if($('#tertiary_question').val() != '' && $('#tertiary_question').val() != null) {

        $('#tertiary_question_holder').removeClass('hidden')
        $('#tertiary_question_option_text_list').removeClass('hidden')
        $('#tertiary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.question + ':</strong> ' + $("#tertiary_question option:selected").text() + '</li>')

        if( $("#tertiary_option_kinds").val() != 0){
          $('#tertiary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.custom_list + ':</strong> ' + $("#tertiary_option_kinds option:selected").text() + '</li>')
        }

        if( $("#tertiary_options").val() != '') {
          var data = $("#tertiary_options :selected").map(function(i, el) {
            return $(el).text()
          }).get();
          $('#tertiary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.filter + ':</strong> ' + data.join(', ') + '</li>')
        }
      } else {
        $('#tertiary_question_holder').addClass('hidden')
      }
    }
    // TODO: This is where we need to display the selected options in list format.


    // Verify campaign has at least one selected
    if( $("input[name='date_range_select']:checked").val() == 'campaign' && ($('#campaign_select').val() == '' || !$('#campaign_select').val())) {
      alert(I18n.wellness_snapshot.choose_campaign)
    } else {

      if(window.leavePanelOpen === true) {
        window.leavePanelOpen = false
        $('#report-settings-tab').click()
      } else {
        // if(document.bsOffcanvas_journey !== undefined) {
        //   document.bsOffcanvas_journey.hide()
        // }
        // if(document.bsOffcanvas_snapshot !== undefined) {
          document.bsOffcanvas_snapshot.hide()
        // }
      }


      if(window.reportName !== undefined ) {
        $('#report-name-holder').removeClass('hidden');
        $('#report-name').html("<h4 class='m-0 p-0'>" + window.reportName + "</h4>")
        window.reportName = undefined
      }
      if(window.reportDescription !== undefined) {
        $('#report-description').html('<p>' + window.reportDescription + '</p>')
        window.reportDescription = undefined
      }

      
      if(window.reportCreatedBy !== undefined) {
        var name = '<a href="mailto:' + window.reportCreatedBy + '">' + window.reportCreatedBy + '</a>'
        if(window.reportCreatedBy == 'default-wbi-admin@cws.net') {
          name = 'Well-Being Index Team'
        }
        $('#report-created-by').html('<p><small>' + I18n.wellness_snapshot.created_by + ': ' + name + '</small></p>')
        window.reportCreatedBy = undefined
      }

      super.showLoader()

      var formObj = $("#report-settings-panel-form");
      setTimeout(function() {
        var formData = formObj.serializeArray();
        formData.push({name: "ajax_call", value: "report"});

        var chart_type = "horizontal_bar";
        // var date_range_val = parseInt(formObj.find("[name='date_range_select']:checked").val()); 

        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          type: "POST",
          url: "/wellness_snapshot/process_report",
          data: formData,
          success: function (data) {
            if(data.status == 200) {

              $('#table-data-holder').removeClass('hidden')

              if(data.chart_data == '') {
                $('#' + prefix + 'chart-data-holder').html("<p class='text-center'>" + I18n.wellness_snapshot.to_begin + "</p>");
              } else if(data.chart_data.chart_data == 'no data' || data.chart_data == 'no data') { 
                $('#chart-holder').html("<p class='text-center'>" + I18n.wellness_snapshot.no_data_found + "</p>");
                $('#table-dates').html('')
                $('#chart-holder').removeClass("hidden")
                $('#loader-holder').addClass("hidden")
                $("#table-data").html('');
              } else {
                if(data.chart_data.length == 0 && (data.chart_data.sample_size_above_threshold == null || data.chart_data.sample_size_above_threshold == 0) ) {
                  $('#chart-holder').html("<p>" + I18n.wellness_snapshot.not_enough_participants + "</p>");
                  $('#chart-holder').removeClass("hidden")
                  $('#loader-holder').addClass("hidden")
                  $('#table-dates').html('')
                  $("#table-data").html('');
                } else {
                  // Do chart update
                  document.wsControllerRef.replaceChartData(data, chart_type)
                  // Save as last settings -- Only after the inital load is complete
                  if(document.initial_report_load_complete == true) {
                    controllerRef.saveLastReportSettings(controllerRef);
                  } else {
                    document.initial_report_load_complete = true;
                    document.wsControllerRef.checkForChangeFromDefault();
                  }
                }
              }
            }
          }
        });

      },300);
    }
  }




  /* **************************************************************************
  function: replaceChartData
  * Internal triggered
  * Displays the data returned from the ruby processing file to the user
  ************************************************************************** */
  replaceChartData(json_data, chart_type) {

    console.log("WS: replaceChartData")
    var last_json_data = json_data;
    var last_chart_type = chart_type;

    $('#table-dates').html("");

    if(json_data.chart_data.report_table_data) {
      $("#table-data").html(json_data.chart_data.report_table_data);
    }

    if(json_data.date){

      // console.log("JSON DATA: ", json_data.date)

      if(json_data.date.length == 1 && json_data.date[0][2] != 'All Time'){
        $('#table-dates').html(json_data.date[0][2]);
      } else if(json_data.date.length == 2) {
        if(json_data.date[1] == null) {
          $('#table-dates').html(json_data.date[0][2]);
        } else {
          $('#table-dates').html("Comparing <em>" + json_data.date[0][2].substring(7) + "</em> to <em>" + json_data.date[1][2].substring(7) + "</em>");
        }
      } else if(json_data.date.length == 3) {
        $('#table-dates').html("<strong>" + I18n.wellness_snapshot.comparing_campaigns + "</strong><br />" + json_data.date[0][2].substring(9) + " " + I18n.advanced_reports.to + " " + json_data.date[1][2].substring(9) + "");
      }
    }

    
    console.log("----- Chart Data: " + json_data.chart_data.chart_data.length)

    if( json_data.chart_data.chart_data.length > 0){
      var data = new google.visualization.DataTable();
      /*
      Create Data Columns
        We loop through the json object to create each option
      */
      $.each(json_data.chart_data.chart_columns, function(key, val){
        var size = Object.keys(val).length;

        if(size == 1)
        {
          $.each(val, function(key2,val2){
            data.addColumn(key2, val2);
          });
        }

        if(size == 2)
        {
          var arr = [];
          $.each(val, function(key2,val2) {
            arr.push([key2, val2] );
          });

          data.addColumn({ type: arr[0][1], role: arr[1][1] });
        }

        if(size == 3)
        {
          var arr = [];
          $.each(val, function(key2,val2) {
            arr.push([key2, val2] );
          });
          data.addColumn({ type: arr[0][1], role: arr[1][1], p: { 'html': true }});
        }
      });

      /*********************************************
      Create Options Object
        We create the 'options' object from the json object returned from our php script(s)
      *********************************************/
      var options = {}
      $.each(json_data.chart_data.chart_options, function(key, val) {
        if($.isPlainObject(val))
        {
          var sub_options = {}
          $.each(val, function(key_2, val_2){
            sub_options[key_2] = val_2;
          });
          options[key] = sub_options;
        }
        else
        {
          options[key] = val;
        }
      });
      
      var arr = [];

      $.each(json_data.chart_data.chart_data, function(key, val){

        if(val.length > 0){ 
          // We need to remove the first item in the val array ["First Label", "Full Item Label"]
          var list = Array.from(val)
          if(Array.isArray(list[0])) {
            list.shift()
          }
          var temp_arr = $.map(list, function(el) { return el });
          for (var i=0;i<temp_arr.length;i++) {
            if (temp_arr[i] === 0){
              temp_arr[i] = null;
            }
          }
          arr.push(temp_arr);
        }
      });

      console.log("\n\n\nData:", data)
      console.log("Data Row:", arr)
      data.addRows(arr);

      

      var explanation = $('#range-explanation-icons').html(); 
      $("#explanation_data").val(explanation)

      var chart = new google.visualization.BarChart(document.getElementById("chart-holder"));

      google.visualization.events.addListener(chart, 'ready', function() {
        // Download report
        $("#image_data").val(chart.getImageURI());
        $('#export_chart_total_text').val(json_data.chart_data.report_table_data);
      });

      super.hideLoader()

      chart.draw(data, options);
    } else {
      super.hideLoader()
      $('#chart-holder').html("<p class='text-center'>" + I18n.wellness_snapshot.not_enough_assessments + "</p>")
      $('#chart-holder').removeClass('hidden')
    }

    // If the selected question's list grouping is not set to 0 AND selected question is present then open the panel for it.
    if( $('#primary_option_kinds').val() != 0 && $('#primary_question').val() != ''){
      $('#customize_primary_option_group').parent().parent().children().first().click()
      $('#primary_question_option_groups').removeClass('hidden')
    }

    if( $('#secondary_option_kinds').val() != 0 && $('#secondary_question').val() != '' && $('#secondary_question').val() != null){
      $('#customize_secondary_option_group').parent().parent().children().first().click()
      $('#secondary_question_option_groups').removeClass('hidden')
    }

    if( $('#tertiary_option_kinds').val() != 0 && $('#tertiary_question').val() != '' && $('#tertiary_question').val() != null){
      $('#customize_tertiary_option_group').parent().parent().children().first().click()
      $('#tertiary_question_option_groups').removeClass('hidden')
    }

  }



  /* **************************************************************************
  function: resetFilterOptions
  * Internal triggered
  * Handles resetting of the form to "factory defaults"
  ************************************************************************** */
  resetFilterOptions() {
    console.log("WS: resetFilterOptions")
    var date = new Date();

    // $('#report_id').val('')

    if($("#primary_question").length) { $('#primary_question').prop('selectedIndex', 0).trigger("change"); }
    if($("#secondary_question").length) { $('#secondary_question').html(''); }
    if($("#tertiary_question").length) { $('#tertiary_question').html(''); }

    super.resetQuestionPrimary();
    super.resetQuestionSecondary();
    super.resetQuestionTertiary();

    // Reset Other Buttons
    if($('#show_group_total').length) { $('#show_group_total').closest('.btn').click(); }
    if($('#show_organization_total').length) { $('#show_organization_total').closest('.btn').click(); }
    if($('#show_national_total').length) { $('#show_national_total').closest('.btn').click(); }
    if($('#sort_distress_by').length) { $('#sort_distress_by').closest('.btn').click(); }
    if($('#display_responses_as').length) { $('#display_responses_as').closest('.btn').click(); }
    if($('#spectrum-date-compare').length) { $('#spectrum-date-compare').closest(".btn").click(); }
    if($('#choose_time_period').length) { $('#choose_time_period').closest('.btn').click(); }
    if($('#campaign_select').length) { $('#campaign_select').val(''); }
    if($('#campaign_select_comparison').length) { $('#campaign_select_comparison').val('none'); }
    if($('#end_date').length) { $('#end_date').val( date.toISOString().split('T')[0] ); }
    if($('#start_date').length) { $('#start_date').val( $('#default_start_date').val() ); }
    if($('#show_national_overall').length){ $('#show_national_overall').closest('.btn').click(); }
    if($('#show_national_primary').length){ $('#show_national_primary').closest('.btn').click(); }
    if($('#show_national_secondary').length){ $('#show_national_secondary').closest('.btn').click(); }
    if($('#show_national_tertiary').length){ $('#show_national_tertiary').closest('.btn').click(); }
    if($('#customize_primary_option_group').length){ $('#customize_primary_option_group').closest('.btn').click(); console.log("customize primary option group") }
    if($('#customize_secondary_option_group').length){ $('#customize_secondary_option_group').closest('.btn').click(); console.log("customize secondary option group") }
    if($('#customize_tertiary_option_group').length){ $('#customize_tertiary_option_group').closest('.btn').click(); console.log("customize tertiary option group") }
    // if($('#assess_filter').length){ $('#assess_filter')}

    // Reset hidden values from limited by report users
    $('#report_id').val('')
    if(document.saved_report_settings_default !== undefined) {
      var saved_data = JSON.parse(document.saved_report_settings_default)
      $('#report_id').val(saved_data.report_id)

      document.saved_report_settings_last = document.saved_report_settings_default
    }


    this.showNationalComparisons();
  }

  /* **************************************************************************
  function: resetQuestionPrimary
  * Internal triggered
  * Resets the primary question
  ************************************************************************** */
  resetQuestionPrimary() {
    console.log("WS: resetQuestionPrimary")
    $('#primary_question_customize_options').attr('href', '#');
    if($('#dashboard-filter-options').length) { $('#dashboard-filter-options').addClass("hidden"); }
    if($('#primary_question_options_filter').length) { $('#primary_question_options_filter').addClass("hidden"); }
    if($('#customize_primary_option_group').length) { 
      $('#customize_primary_option_group').closest('.btn').trigger('click'); 
      $('#customize_primary_option_group').parent().parent().children().removeClass('active')
      $('#customize_primary_option_group').parent().addClass('active')
    }
    if($('#filter_by_primary_option').length) { $('#filter_by_primary_option').closest('.btn').click(); }
    if($('#primary_options').length) { $('#primary_options').html(''); }
    if($('#primary_option_kinds').length) { $('#primary_option_kinds').html(''); }
  }


  /* **************************************************************************
  function: resetSettings
  * User triggered
  * User action to call the reset scripts, reloads default data, saves resetted settings as current settings
  ************************************************************************** */
  resetSettings(e) {
    console.log("WS: resetSettings")

    document.resetFilter = true

    this.clearSnapshotLabel()

    const controllerRef = this
    e.preventDefault()
    // $("#report-settings-panel").removeClass("open");
    $('#dashboard-filter-options').addClass('hidden');
    $('#saved_report_settings_last').remove();
    $('#chart-sort-by').addClass('hidden');
    $('#report-settings-tab').click()
    
    console.log(document.initial_report_settings)
  
    controllerRef.resetFilterOptions()
    if(document.initial_report_settings){
      document.saved_filter_loaded = undefined;
      $(".report-settings-saved-filter.list-group-item-success").removeClass("list-group-item-success");
      controllerRef.loadSavedReportSettings(controllerRef, JSON.stringify(document.initial_report_settings));
    }
    
    controllerRef.saveLastReportSettings(controllerRef, "reset_settings");
  }




  /* **************************************************************************
  function: saveLastReportSettings
  * Internal triggered
  * Saves the current settings on the settings form to the "_last_" settings for the user
  ************************************************************************** */
  saveLastReportSettings(controllerRef, location = '') {
    console.log("WC: saveLastReportSettings")
    var settings_last_data = $("#report-settings-panel-form").serializeArray();


    console.log("--- Last Saved Data: ", settings_last_data)

    if($('#i_can_create_snapshot_folders').val() == 'false'){
      var report_id = $('#report_id').val()
    } else {
      report_id = null
    }
    var formData = {settings_data: settings_last_data, save_for: "last", report_id: report_id, report_type: 'wellness_snapshot' };

    $.post("/reports/save_settings", formData, function(returnDataStr) {
      document.saved_report_settings_last = controllerRef.getJSONLoadSettingsString($("#report-settings-panel-form"));
      document.wsControllerRef.checkForChangeFromDefault();
  

      // TODO: This is where the reset is getting hosed up
      if(location == 'reset_settings' || document.resetFilter == true) {
        controllerRef.processReportSettingsForm();
        document.resetFilter = null
      }
    });
  }


  //   var valid = true
  //   // if($('#i_can_create_snapshot_folders').val() == 'false' && $('#created_from').val() == 'new_snapshot'){
  //   //   if( $('[name="saved_report_setting[folder_ids][]"]').filter(':checked').length < 1 ) {
  //   //     alert("Please choose at least one folder.");
  //   //     valid = false
  //   //   }
  //   // } 

  //   if(valid == true) {
  //     $.ajax({
  //       headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
  //       type: "POST",
  //       url: $(item).attr('action'),
  //       data: $(item).serializeArray(),
  //       async: false,
  //       success: function (data) {
  //         if(data.status == 200) {
  //           $('#system_modal_popup').modal('hide')
  //           if($('#load_saved_snapshots').length) {
  //             document.getElementById('load_saved_snapshots').reload()
  //           } else {
  //             document.getElementById('load_manage_advanced_filters').reload()
  //             document.getElementById('load_advanced_filters_list').reload()
  //           }

  //           $('#report-name-holder').removeClass('hidden')

  //           if(!$('#report-name-holder').hasClass('hidden')){
  //             $('#report-name').html('<h4 class="m-0 p-0">' + data.name + '</h4>')
  //             if(data.description !== null) {
  //               $('#report-description').html('<p>' + data.description + '</p>')
  //             }
  //             $('#report-created-by').html('<p><small>' + I18n.wellness_snapshot.created_by + ': <a href="mailto:' + data.createdby + '">' + data.createdby + '</a></h4>')
  //           }
  //         } else {
  //           alert(I18n.wellness_snapshot.error_saving)
  //         }
  //       }
  //     })
  //   }
  // }

  /* **************************************************************************
  function: selectAll
  * User triggered
  * Selects all options for the question
  ************************************************************************** */
  selectAll(event) {
    console.log("WS: selectAll")
    event.preventDefault()
    var item = event.currentTarget

    $("#"+ item.dataset.field + " > option").prop("selected","selected");
    $("#"+ item.dataset.field ).trigger("change");
  }

  /* **************************************************************************
  function: setInitialSettings
  * Internal triggered
  * Loads initial settings for the snapshot report. Settings get updated and reset based on user interaction
  ************************************************************************** */
  setInitialSettings() {

    console.log("WS: setInitialSettings")

    if($('#i_can_create_snapshot_folders').val() == 'true') {
      var initial_settings = {
        "wbi_version":$('#wbi_version').val(),
        "country":$('#country').val(),
        "report_id":"",
        "report_type":"wellness_snapshot",
        "display_data":"spectrum",
        "chart_type":"bar",
        "compare":"none",
        "compare_start_date":$("#default_start_date").val(),
        "compare_end_date":$("#default_end_date").val(),
        "primary_question":$('#default_primary_question').val(),
        // "primary_option_kinds": "",
        "filter_by_primary_option":"0",
        "customize_primary_option_group":"0",
        "customize_secondary_option_group":"0",
        // "secondary_question":"",
        "filter_by_secondary_option":"0",
        "display_response_as":"percent",
        "sort_distress_by":"distressed",
        "date_range_select":"-1",
        "start_date":$("#default_start_date").val(),
        "end_date":$("#default_end_date").val(),
        "assess_filter":"last_in_period",
        "show_group_total":"0",
        "show_organization_total":"0",
        "show_national_total":"0",
        "show_national_overall":"0",
        "show_national_primary":"1",
        "show_national_secondary":"1"
      }

      if($('#default_primary_question').val() != '') {
        initial_settings["show_organization_total"] = "1"
        initial_settings["primary_option_kinds"] = "0"
        initial_settings["secondary_question"] = ""
      }

      if($("#campaign_select").length) {
        initial_settings = Object.assign({campaign_select:$("#campaign_select").val()}, initial_settings)
      }

      if($("#campaign_select_comparison").length) {
        initial_settings = Object.assign({campaign_select_comparison:$("#campaign_select_comparison").val()}, initial_settings)
      }

      if($('#show_national_tertiary').length) {
        initial_settings = Object.assign({show_national_tertiary:"1"}, initial_settings)
        initial_settings = Object.assign({filter_by_tertiary_option:"0"}, initial_settings)
      }

      if($("#tertiary_question_holder").length) {
        initial_settings = Object.assign({customize_tertiary_option_group:"0"}, initial_settings)
      }
    } else {

      // LIMITED ACCESS USERS
      if( $('#saved_report_settings_last').length && $('#default_loaded_report'.length)) {
        var saved_report_settings_last = JSON.parse($('#saved_report_settings_last').val())
        var initial_settings = JSON.parse($('#default_loaded_report').val())
        initial_settings.report_id = saved_report_settings_last['report_id']
      }
    }

    document.initial_report_settings = initial_settings
  }



  /* **************************************************************************
  function: showCustomizeOptionGroup
  * User triggered
  * Shows or hides the "Customize Question # List Grouping" options
  ************************************************************************** */
  showCustomizeOptionGroup(event) {
    console.log("WS: showCustomizeOptionGroup")
    const controllerRef = this
    this.toggleActiveClass(event);

    var item = event.currentTarget.dataset.item;
    var field = event.currentTarget.dataset.field;

    if(event.currentTarget.querySelector('input').value == 1) {
      $('#' + item).removeClass("hidden");
    } else {
      // Hide and reset the option data
      $('#' + item).addClass('hidden');
      $('#' + field).val(0).trigger('change');

      var question_level = item.split("_")[0]
      if( $("#" + question_level + "_question").val() !== null){
        var question_id = $("#" + question_level + "_question").val().split('-')[0]
        controllerRef.changeQuestionOptionKinds(undefined, question_id, 0, question_level)
      }

      this.setReportPanelSize();
    }
  }

  /* **************************************************************************
  function: showLoader
  * Internal triggered
  * Clears the report visualization data and displays a loading ring
  ************************************************************************** */
  showLoader() {
    console.log("WS: showLoader")
    $('#chart-loader-holder').html('<div class="chart-loader"></div>');
    $('#chart-data-holder').html('<div id="ajax_chart_div"></div>');
    $('#table-data').html("");
    $('#table-dates').html("");
  }

  /* **************************************************************************
  function: showLoader
  * Internal triggered
  * Determines if we should show the national comparison by question options at the bottom of the settings tab
  ************************************************************************** */
  showNationalComparisons(elem) {
    console.log("WC: showNationalComparisons")
    var checked_val = 0;

    if(elem) {
      checked_val = $('#' + elem).children().val();
    } else {
      checked_val = $("input[name=show_national_total]:checked").val();
    }

    $('#national-overall').addClass("hidden");
    $('#national-primary').addClass("hidden");
    $('#national-secondary').addClass("hidden");
    if($('#national-tertiary').length) {
      $('#national-tertiary').addClass("hidden");
    }

    if(checked_val != 1) {
      $('#national-comparison').addClass("hidden");
    }
    
    if(checked_val == 1 && $('#primary_question').val() != ''){
      $('#national-comparison').removeClass("hidden");
      $('#national-overall').removeClass("hidden");
      $('#national-primary').removeClass("hidden");
    }

    if(checked_val == 1 && ( $('#secondary_question').val() != '' && $('#secondary_question').val() != null )){
      $('#national-secondary').removeClass("hidden");
    }

    if($('#tertiary_question').length) {
      if(checked_val == 1 && ($('#tertiary_question').val() != '' && $('#tertiary_question').val() != null )){
        $('#national-tertiary').removeClass("hidden");
      }
    }
  }




  /* **************************************************************************
  function: showNationalTotal
  * User triggered
  * Determines if we should show the "Show National Comparison" data
  ************************************************************************** */
  showNationalTotal(event) {
    console.log("WS: showNationalTotal")
    super.toggleActiveClass(event);
    this.showNationalComparisons(event.currentTarget.id);
  }


  /* **************************************************************************
  function: submitForm
  * User and Internal triggered
  * Handles when the user submits the settings form.
  ************************************************************************** */
  submitForm(e) {
    console.log("WS: submitForm")
    e.preventDefault()

    if($('#i_can_create_snapshot_folders').val() == 'true') {
      this.clearSnapshotLabel()
    }

    // const controllerRef = this
    this.processReportSettingsForm()
  }
}