import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin-import"
export default class extends Controller {
  connect() {
  }

  bulk_action_apply_click(e) {
    e.preventDefault();
    var action = $("#import-bulk-action-select").val();
    if ($(".import-bulk-action-checkbox:checked").length && action != "") {
      $(".import-bulk-action-checkbox:checked").each(function () {
        var rowId = $(this).val();
        if (action == "admin") {
          $("#import-bulk-action-permission-type-row-" + rowId).val("admin").change().triggerNativeEvent('change');
        } else if (action == "reports") {
          $("#import-bulk-action-permission-type-row-" + rowId).val("reports").change().triggerNativeEvent('change');
          // Select options
          $("#import-bulk-action-data-type-row-" + rowId + " select").val("").change().triggerNativeEvent('change');
          $("#import-bulk-action-leadership-row-" + rowId + " select").val("").change().triggerNativeEvent('change');
          var curOptions = $("#import-bulk-action-data-type-select").val();
          if (curOptions && curOptions.length > 0) {
            $("#import-bulk-action-data-type-row-" + rowId + " select").val(curOptions).change().triggerNativeEvent('change');
          }
        } else if (action == "leadership") {
          $("#import-bulk-action-permission-type-row-" + rowId).val("leadership").change().triggerNativeEvent('change');
          // Select options
          $("#import-bulk-action-data-type-row-" + rowId + " select").val("").change().triggerNativeEvent('change');
          $("#import-bulk-action-leadership-row-" + rowId + " select").val("").change().triggerNativeEvent('change');
          var curOptions = $("#import-bulk-action-leadership-select").val();
          if (curOptions && curOptions.length > 0) {
            $("#import-bulk-action-leadership-row-" + rowId + " select").val(curOptions).change().triggerNativeEvent('change');
          }
        } else if (action == "remove") {
          $("#import-bulk-action-permission-type-row-" + rowId).val("none").change().triggerNativeEvent('change');
        } else if (action == "reset") {
          $("#import-bulk-action-permission-type-row-" + rowId).val("none").change().triggerNativeEvent('change');
        }

      });
    }
  }

  bulk_action_checkall(e) {
    if (e.currentTarget.checked) {
      $(".import-bulk-action-checkbox").each(function () {
        this.checked = true;
      });
    } else {
      $(".import-bulk-action-checkbox").each(function () {
        this.checked = false;
      });
    }
  }

  bulk_action_select_change(e) {
    let curVal = $(e.currentTarget).val();
    if (curVal == "reports") {
      $("#import-bulk-action-leadership-div").find("select").each(function () {
        $(this).val("").change().triggerNativeEvent('change');
      });
      $("#import-bulk-action-leadership-div").hide();
      $("#import-bulk-action-data-type-div").removeClass("d-none");
    } else if (curVal == "leadership") {
      $("#import-bulk-action-data-type-div").find("select").each(function () {
        $(this).val("").change().triggerNativeEvent('change');
      });
      $("#import-bulk-action-data-type-div").hide();
      $("#import-bulk-action-leadership-div").show();
    } else {
      $("#import-bulk-action-data-type-div").find("select").each(function () {
        $(this).val("").change().triggerNativeEvent('change');
      });
      $("#import-bulk-action-leadership-div").find("select").each(function () {
        $(this).val("").change().triggerNativeEvent('change');
      });
      $("#import-bulk-action-data-type-div").addClass("d-none");
      $("#import-bulk-action-leadership-div").hide();
    }
  }

  permission_row_select(e){
    let newVal = $(e.currentTarget).val();
    let rowId = $(e.currentTarget).data("row_id");
    if (newVal == "none" || newVal == "admin") {
      $("#import-bulk-action-data-type-row-" + rowId).find("select").each(function () {
        $(this).val("").change().triggerNativeEvent('change');
      });
      $("#import-bulk-action-leadership-row-" + rowId).find("select").each(function () {
        $(this).val("").change().triggerNativeEvent('change');
      });
      $("#import-bulk-action-data-type-row-" + rowId).hide();
      $("#import-bulk-action-leadership-row-" + rowId).hide();
    } else if (newVal == "reports") {
      $("#import-bulk-action-leadership-row-" + rowId).find("select").each(function () {
        $(this).val("").change().triggerNativeEvent('change');
      });
      $("#import-bulk-action-leadership-row-" + rowId).hide();
      $("#import-bulk-action-data-type-row-" + rowId).show();
    } else if (newVal == "leadership") {
      $("#import-bulk-action-data-type-row-" + rowId).find("select").each(function () {
        $(this).val("").change().triggerNativeEvent('change');
      });
      $("#import-bulk-action-data-type-row-" + rowId).hide();
      $("#import-bulk-action-leadership-row-" + rowId).show();
      if($("#import-bulk-action-participant-checkbox-row-" + rowId).length) {
        $("#import-bulk-action-participant-checkbox-row-" + rowId).show();
      }
    }
  }
}
