import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="combined-select"
export default class extends Controller {
    initialize() {
    }

    connect() {
        this.element[this.identifier] = this;

        let $element = $(this.element);
        //$element.data("last_value", $element.val());
        // Sent last_value to blank -- that way if we are editing it can trigger the change event right away to init the hidden fields
        $element.data("last_value", "");

        var options = {
            // placeholder: "Please Select",
            theme: "bootstrap-5",
            containerCssClass: "select2--small",
            dropdownCssClass: "select2--small",
            // Needed to fix IOS/fastclick issue - 6/23/2017 neil
            templateResult: function (result, container) {
                if (!result.id) {
                    return result.text;
                }
                container.className += ' needsclick';
                //console.dir(result);
                if (result && result.element && result.element.parentElement && result.element.parentElement.tagName == 'OPTGROUP' && result.element.parentElement.label) {
                    var parentLabel = result.element.parentElement.label;
                    if (parentLabel.indexOf("Internal Medicine") != -1) {
                        parentLabel = "Internal Med.";
                    } else if (parentLabel.indexOf("Pediatric") != -1) {
                        parentLabel = "Pediatrics";
                    } else if (parentLabel.indexOf("Surgical") != -1) {
                        parentLabel = "Surgical";
                    }
                    return $('<div style="padding-left:10px;"><div style="font-size:80%;color:#787878;margin-bottom:-2px;">' + parentLabel + ' &gt;</div>' + result.text + '</div>');
                }
                return result.text;
            },
            templateSelection: function (item) {
                if (!item || !item.element || !item.element.parentElement) {
                    return "";
                }
                if ((item.element.parentElement.tagName != 'OPTGROUP') || !item.element.parentElement.label) {
                    return item.text;
                } else {
                    var parentLabel = item.element.parentElement.label;
                    if (parentLabel.indexOf("Internal Medicine") != -1) {
                        parentLabel = "Internal Med.";
                    } else if (parentLabel.indexOf("Pediatric") != -1) {
                        parentLabel = "Pediatrics";
                    } else if (parentLabel.indexOf("Surgical") != -1) {
                        parentLabel = "Surgical";
                    }
                    return parentLabel + " > " + item.text;
                }
            },
            matcher: function (params, data) {
                // If there are no search terms, return all of the data
                if ($.trim(params.term) === '') {
                    return data;
                }

                var textUC = data.text.toUpperCase();
                var termUC = params.term.toUpperCase();

                //console.log(textUC);
                if (textUC.indexOf("OTHER") > -1) {
                    return data;
                }

                // `params.term` should be the term that is used for searching
                // `data.text` is the text that is displayed for the data object
                if (textUC.indexOf(termUC) > -1) {
                    return data;
                }

                var filteredChildren = [];
                if (data.children && data.children.length > 0) {
                    $.each(data.children, function (idx, child) {
                        if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
                            filteredChildren.push(child);
                        } else if (child.text.toUpperCase().indexOf("OTHER") > -1) {
                            filteredChildren.push(child);
                        }
                    });
                }

                if (filteredChildren.length) {
                    var modifiedData = $.extend({}, data, true);
                    modifiedData.children = filteredChildren;

                    // You can return modified objects from here
                    // This includes matching the `children` how you want in nested data sets
                    return modifiedData;
                }

                // Return `null` if the term should not be displayed
                return null;
            }

        };
        var parentElement = $element.closest(".select2-wrapper-div");
        if (parentElement.length) {
            options.dropdownParent = parentElement;
        }

        $element.select2(options);
        // Needed to fix IOS/fastclick issue - 6/23/2017 neil
        var sel2Data = $element.data('select2');
        if (sel2Data && sel2Data.$container) {
            sel2Data.$container.find('*').addClass('needsclick');
        }
        if (parentElement.length) {
            // Fix missing placeholder text
            var selectObj = $element;
            setTimeout(function () {
                if (selectObj.data("placeholder")) {
                    if (parentElement.find(".select2-selection__placeholder").html() == "") {
                        parentElement.find(".select2-selection__placeholder").html(selectObj.data("placeholder"));
                    }
                }
            }, 50);
        }
        $element.on('select2:open', function(e){
            const select2 = $(e.target).data('select2');
            if (select2 && select2.dropdown && select2.dropdown.$search && select2.dropdown.$search.get(0)) {
                if (!mobileCheck()) {
                    select2.dropdown.$search.get(0).focus();
                }
            }
        });

        // Need to fire native event to trigger stimulus action
        $element.on('select2:select', function () {
          let event = new Event('change', { bubbles: true }) // fire a native event
          this.dispatchEvent(event);
        });

        if ($element.val() != "") {
            // Fire change event
            let event = new Event('change', { bubbles: true });
            $element[0].dispatchEvent(event);
        }
    }

    process_input_change(e) {
      // console.log("process_input_change");
        let $element = $(e.currentTarget);
        let last_value = $element.data("last_value");
        let current_value = $element.val();
        if (last_value != current_value) {
            //console.log("New val: " + current_value);
            $element.data("last_value", current_value);

            var mainSelectObj = $element;
            var selValue = current_value;
            var $selOption = $element.find("option:selected");

            // dont update if they are "hidden" inputs
            $("input.combined-select-hidden").each(function () {
                var $thisObj = $(this);
                if (!$thisObj.data("dontupdate")) {
                    $thisObj.val("");
                }
            });

            var quest_id = $element.data("question_id");
            var option_other = $selOption.data("option_other");
            var quest_id_for_other = 0;
            if (selValue.indexOf("!") > -1) {
                var valParts = selValue.split("!");
                $("#combined_select_hidden_field_" + quest_id).val(valParts[0]);
                $("#combined_select_hidden_field_" + quest_id).change();
                var sub_quest_id = $selOption.data("question_id");
                var $subInput = $("#combined_select_hidden_field_" + sub_quest_id);
                if ($subInput.length > 0 && !$subInput.data("dontupdate")) {
                    $subInput.val(valParts[1]);
                    $subInput.change();
                }
                quest_id_for_other = sub_quest_id;
            } else {
                $("#combined_select_hidden_field_" + quest_id).val(selValue);
                $("#combined_select_hidden_field_" + quest_id).change();
                quest_id_for_other = quest_id;
            }
            if (quest_id_for_other > 0 && option_other == "yes") {
                $(".combined-select-other-text-container").each(function () {
                    if ("other-text-container-"+quest_id_for_other == this.id) {
                        $(this).removeClass("hidden");
                    } else {
                        $(this).find("input").val("");
                        $(this).addClass("hidden");
                    }
                });
            } else {
                $(".combined-select-other-text-container").each(function () {
                    $(this).find("input").val("");
                    $(this).addClass("hidden");
                });
            }
            // if (quest_id_for_other > 0 && option_other == "yes") {
            //   var option_label_name = $selOption.data("option_other_name");
            //   if (option_label_name) {
            //     $("#label-other-combined-select").html(option_label_name);
            //   } else {
            //     $("#label-other-combined-select").html("Other");
            //   }
            //   $("#other-text-container-combined-select").removeClass("hidden");
            // } else {
            //   $("#other-text-container-combined-select").addClass("hidden");
            //   $("#question_other_combined_select").val("");
            // }

        }
    }
}
