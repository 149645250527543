import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="arr"
export default class extends Controller {
  
  buildTable(element, columns = 0) {
    console.log("ARR: buildTable")
    $(element).bootstrapTable({
      fixedColumns: true,
      fixedNumber: columns
    })
  }
  
  connect() {
    console.log("ARR: connect")
    const controllerRef = this 

    $(window).resize(function() {
      if(this.resizeTO) clearTimeout(this.resizeTO);
      this.resizeTO = setTimeout(function() {
        $(this).trigger('resizeEnd');
      }, 200);
    });
    $(window).bind('resizeEnd', function() {
      controllerRef.resizeTable()
    })

    if(document.getElementById('clv_submit_button') != undefined) {
      setTimeout(()=>{
        document.getElementById('clv_submit_button').click()
      }, 250)
    } else if(document.getElementById('arr_submit_button') != undefined) {
      document.getElementById('arr_submit_button').click()
    } else if(document.getElementById('arr_changes') != undefined) {
      this.buildTable('#arr_changes', 1)
      this.resizeTable()
    }
  }


  loadPulseData() {
    console.log("ARR: loadPulseData")
    var chart = new google.visualization.BarChart(document.getElementById("ajax_chart_div"));

    google.visualization.events.addListener(chart, 'ready', function() {
      // Download report
      $("#image_data").val(chart.getImageURI());
      $('#export_chart_total_text').val(json_data.chart_data.report_table_data);
    });
  }

  reloadTooltip(){
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }


  resizeTable(kind = '') {
    console.log("ARR: resizeTable")
    if(kind == 'modal') {
      // Set the default width of the container
      var myModalEl = document.getElementById('system_modal_popup')
      myModalEl.addEventListener('shown.bs.modal', function (event) {
        var width = document.getElementById('customer_lifetime_value_holder').offsetWidth;
        $('#customer_lifetime_value_holder > .bootstrap5').css("width", width);

      })

    } else {
      // Set the default width of the container
      var width = $(window).width();
      var w = 0;
      // We subtract the width of the nav bar (80 or 250) plus the padding of 48 on each size (96)

      if($('body').hasClass("nav-toggled")) {
          w = width - 80 - 96;
      } else {
          w = width - 250 - 96;
      }

      $('.bootstrap5').css("width", w);
    }
  }

  toggleGrouping(e) {
    var item = e.currentTarget
    document.getElementById('selected_grouping').innerHTML = item.dataset.label
    document.getElementById('clv_grouping').value = item.dataset.value
  }


  toggleReportType(e) {
    var item = e.currentTarget
    document.getElementById('report_type').innerHTML = item.dataset.label
    document.getElementById('arr_type').value = item.dataset.kind
  }

  toggleRevenueType(e){
    var item = e.currentTarget
    document.getElementById('selected_revenue').innerHTML = item.dataset.label
    document.getElementById('clv_revenue_type').value = item.dataset.value
  }

  toggleYear(e) {
    var item = e.currentTarget
    document.getElementById('selected_year').innerHTML = item.dataset.year
    document.getElementById('arr_year').value = item.dataset.year
  }


  updateArr(e) {
    console.log("ARR: updateArr")
    const controllerRef = this
    e.preventDefault()

    document.getElementById('account_management_table_holder').innerHTML = ''
    document.getElementById('arr_loader').classList.remove('hidden')

    var formData = $('#arr_form').serializeArray();

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: e.currentTarget.action,
      method: 'POST',
      data: formData,
      success: function (e) {
        if(e.status == 200) {
          document.getElementById('arr_loader').classList.add('hidden')

          document.getElementById('gross_prof_services').innerHTML = e.gross_revenue.prof_services
          document.getElementById('gross_licensing').innerHTML = e.gross_revenue.licensing
          document.getElementById('gross_arr').innerHTML = e.gross_revenue.arr

          document.getElementById('net_prof_services').innerHTML = e.net_revenue.prof_services
          document.getElementById('net_licensing').innerHTML = e.net_revenue.licensing
          document.getElementById('net_arr').innerHTML = e.net_revenue.arr

          document.getElementById('account_management_table_holder').innerHTML = e.content

          controllerRef.buildTable('#account_management_table', 2)
          controllerRef.resizeTable()

          controllerRef.reloadTooltip()

        } else {
          alert("Issue loading data. Please try again.")
        }
      }
    });
  }


  updateChanges(e) {
    console.log("ARR: updateChanges")
    const controllerRef = this
    e.preventDefault()

    document.getElementById('account_management_table_holder').innerHTML = ''
    document.getElementById('arr_loader').classList.remove('hidden')

    var formData = $('#arr_form').serializeArray();

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: e.currentTarget.action,
      method: 'POST',
      data: formData,
      success: function (e) {
        if(e.status == 200) {
          document.getElementById('arr_loader').classList.add('hidden')
          document.getElementById('account_management_table_holder').innerHTML = e.content

          controllerRef.buildTable('#arr_changes', 1)
          controllerRef.resizeTable()

          controllerRef.reloadTooltip()
        } else {
          document.getElementById('arr_loader').classList.add('hidden')
          document.getElementById('account_management_table_holder').innerHTML = '<p class="py-5 my-5 text-center">No results found. Please try again.</p>';
        }
      }
    });
  }

  updateCustomerLifetimeValue(e) {
    console.log("ARR: updateCustomerLifetimeValue")
    e.preventDefault()

    document.getElementById('customer_lifetime_value_holder').innerHTML = ''
    document.getElementById('clv_loader').classList.remove('hidden')

    const controllerRef = this

    var formData = $('#clv_form').serializeArray();

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: e.currentTarget.action,
      method: 'POST',
      data: formData,
      success: function (e) {
        if(e.status == 200) {
          document.getElementById('clv_loader').classList.add('hidden')
          document.getElementById('customer_lifetime_value_holder').innerHTML = e.content
          controllerRef.buildTable('#customer_lifetime_value', 4)
          controllerRef.reloadTooltip()
        } else {
          alert("Issue loading data. Please try again.")
        }
      }
    });
    
  }


  updatePulse(e) {
    console.log("ARR: updateChanges")
    const controllerRef = this
    e.preventDefault()

    document.getElementById('pulse_data_holder').innerHTML = ''
    document.getElementById('pulse_loader').classList.remove('hidden')

    var formData = $('#pulse_form').serializeArray();

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: e.currentTarget.action,
      method: 'POST',
      data: formData,
      success: function (e) {
        if(e.status == 200) {
          document.getElementById('pulse_loader').classList.add('hidden')
          document.getElementById('pulse_data_holder').innerHTML = e.content

          controllerRef.reloadTooltip()
        } else {
          document.getElementById('pulse_loader').classList.add('hidden')
          document.getElementById('pulse_data_holder').innerHTML = '<p class="py-5 my-5 text-center">No results found. Please try again.</p>';
        }
      }
    });
  }

}
