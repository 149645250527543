import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="wellness-common-folders"
export default class extends Controller {

  connect() {
    console.log("WCF: connect")
  }


  confirm_delete_folder (e) {
    console.log("WSF: confirm_delete_folder")
    e.preventDefault();
    if (confirm(I18n.wellness_snapshot.delete_folder)) {
      var item = e.currentTarget
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        url: $(item).attr('href'),
        async: false,
        success: function (data) {
          if(data.status == 200) {
            $('[data-bs-toggle="tooltip"]').each(function(){
              $(this).tooltip('hide')
            })

            if(data.kind == 'wellness_snapshot') {
              if($('#load_saved_snapshots').length) {
                document.getElementById('load_saved_snapshots').reload()
              }
            } else if(data.kind == 'wellness_journey') {
              if($('#wj_load_saved_snapshots').length) {
                document.getElementById('wj_load_saved_snapshots').reload()
              }
            }
          } else {
            alert(I18n.wellness_snapshot.cannot_delete_folder)
          }
        }
      });
    }
  }

  confirm_delete_saved_snapshot (e) {
    console.log("WSF: confirm_delete_saved_snapshot")
    e.preventDefault();
    if (confirm(I18n.wellness_snapshot.confirm_delete_snapshot)) {
      var item = e.currentTarget
      $.ajax({
        url: item.closest('form').action,
        type: 'post',
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        success: function (data) {
          if(data.status == 200) {

            $('[data-bs-toggle="tooltip"]').each(function(){
              $(this).tooltip('hide')
            })

            if(data.kind == 'wellness_snapshot'){

              if($('#load_saved_snapshots').length) {
                document.getElementById('load_saved_snapshots').reload()
              }
            } else if(data.kind == 'wellness_journey') {
              if($('#wj_load_saved_snapshots').length) {
                document.getElementById('wj_load_saved_snapshots').reload()
              }
            }
          } else {
            alert(I18n.wellness_snapshot.cannot_delete_filter);
          }
        }
      });

    }
  }


  createSnapshotFilter(event) {
    console.log("WSF: createSnapshotFilter")
    event.preventDefault()
    var item = event.currentTarget

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: $(item).attr('action'),
      data: $(item).serializeArray(),
      async: false,
      success: function (data) {
        if(data.status == 200) {
          $('#system_modal_popup').modal('hide')

          if($('#' + data.prefix + 'load_saved_snapshots').length) {
            document.getElementById(data.prefix + 'load_saved_snapshots').reload()
          }
        } else {
          alert("Error saving snapshot folder")
        }
      }
    })

  }
  
  edit_folders(event) {
    console.log("WSF: edit_folders")
    event.preventDefault();

    let $elem = $(event.currentTarget);
    $elem.toggleClass("opened");
    this.toggle_folder_status($elem);

    $('#folder-list ol').nestedSortable({
      handle: '.dd-content',
      items: 'li',
      toleranceElement: '.dd-content',
      maxLevels: 3,
      relocate: this.update_nested_order,

      forcePlaceholderSize: true,

      // opacity: .6,
      // placeholder: 'placeholder',
      revert: 250,
      tabSize: 25,
      tolerance: 'pointer',
      isTree: true,
      expandOnHover: 700,
      // startCollapsed: false
    });

    $('#create_report_folder').attr('disabled') == 'false' ? 'true' : 'false'
  }


  editFolderModal(e) {
    console.log("WSF: editFolderModal")
    e.preventDefault()
    var item = e.currentTarget

    $.ajax({
      url: item.closest('form').action,
      type: 'post',
      data: $(item).serializeArray(),
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      success: function (data) {
        if(data.status == 200) {
          $('#system_modal_popup').modal('hide')

          if(data.kind == 'wellness_snapshot') {
            if($('#load_saved_snapshots').length) {
              document.getElementById('load_saved_snapshots').reload()
            }
          } else if(data.kind == 'wellness_journey') {
            if($('#wj_load_saved_snapshots').length) {
              document.getElementById('wj_load_saved_snapshots').reload()
            }
          }
        } else {
          alert(I18n.wellness_snapshot.cannot_update_filter);
        }
      }
    });
  }


  folder_click(event) {
    console.log("WSF: folder_click")
    let elem = event.currentTarget;
    $(elem).children('em').toggleClass('fa-folder fa-folder-open');
    // console.log($('#parent_' + $(elem).data('id')));
    // $('#parent_' + $(elem).data('id')).toggleClass('hidden');
    $(".parent-folder-" + $(elem).data('id')).toggleClass('hidden');
  }

  mouseleave_dd_content(event) {
    let elem = event.currentTarget;
    $(elem).children('.folder-options').hide();
  }
  mouseover_dd_content(event) {
    let elem = event.currentTarget;
    $(elem).children('.folder-options').show();
  }




  /* **************************************************************************
  function: saveSnapshotFilter
  * User triggered
  * Saves the current options in the settings panel to a custom snapshot name in the db
  ************************************************************************** */
  saveSnapshotFilter(event) {
    console.log("WCF: saveSnapshotFilter")
    event.preventDefault()
    var item = event.currentTarget

    var prefix = item.dataset.prefix

    console.log( $(item).serializeArray() )

    var valid = true
    // if($('#i_can_create_snapshot_folders').val() == 'false' && $('#created_from').val() == 'new_snapshot'){
    //   if( $('[name="saved_report_setting[folder_ids][]"]').filter(':checked').length < 1 ) {
    //     alert("Please choose at least one folder.");
    //     valid = false
    //   }
    // } 

    if(valid == true) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        url: $(item).attr('action'),
        data: $(item).serializeArray(),
        async: false,
        success: function (data) {
          if(data.status == 200) {
            $('#system_modal_popup').modal('hide')

            Array.from(document.getElementsByClassName('load-saved-snapshots')).forEach(function(item){
              item.reload()
            });

            var name = '<a href="mailto:' + data.createdby + '">' + data.createdby + '</a>'
            if(data.createdby == 'default-wbi-admin@cws.net') {
              name = 'Well-Being Index Team'
            }
            if($('#report-name-holder').length && !$('#report-name-holder').hasClass('hidden')){
              $('#report-name').html('<h4 class="m-0 p-0">' + data.name + '</h4>')
              $('#report-description').html('<p>' + data.description + '</p>')
              $('#report-created-by').html('<p><small>' + I18n.wellness_snapshot.created_by + ': ' + name + '</h4>')
            } else if($('#wj_report-name-holder').length && !$('#wj_report-name-holder').hasClass('hidden')) {
              $('#wj_report-name').html('<h4 class="m-0 p-0">' + data.name + '</h4>')
              $('#wj_report-description').html('<p>' + data.description + '</p>')
              $('#wj_report-created-by').html('<p><small>' + I18n.wellness_snapshot.created_by + ': ' + name + '</h4>')
            }
          } else {
            alert(I18n.wellness_snapshot.error_saving)
          }
        }
      })
    }
  }




  toggle_folder_status(btn) {
    console.log("WSF: toggle_folder_status")
    if($(btn).hasClass('opened')){
      $(btn).html('<em class="fa fa-check"></em> ' + I18n.wellness_snapshot.finish_editing_folders).removeClass('btn-outline-primary').addClass('btn-success');
      $('.sub-level').removeClass("hidden");
      $('.handle-holder').removeClass("hidden");
      $('#create_report_folder').attr('disabled', 'disabled');
      $('.assign-folders').attr('disabled', 'disabled');
      //$('.dd-item').addClass("ps-5");
      // reloadSorting();

    } else {
      $(btn).html('<em class="fa fa-pencil"></em> ' + I18n.wellness_snapshot.edit_report_folders).addClass('btn-outline-primary').removeClass('btn-success');
      $('.sub-level').addClass('hidden');
      $('.handle-holder').addClass("hidden");
      $('#create_report_folder').removeAttr('disabled');
      $('.assign-folders').removeAttr('disabled');
      //$('.dd-item').removeClass("ps-5");
    }

    $.each($('button[data-bs-toggle="collapse"]'), function(){

      $(this).children('em').removeClass('fa-folder-open').addClass('fa-folder');

      if($(this).attr('disabled')) {
        $(this).removeAttr('disabled');
      } else {
        $(this).attr("disabled", "disabled");
      }

      $(this).attr('aria-expanded', false);
      var id = $(this).attr('aria-controls');
      $('#' + id).removeClass('show');
      $('#' + id).attr('aria-expanded', false);
    });
  }


  update_nested_order(){
    console.log("WSF: update_nested_order")
    let data = $('#folder-list ol').nestedSortable('toArray', {startDepthCount: 0});
    let postData = {}; // folder[folder_id] = parent_id / position[folder_id] = i
    for(let i=0; i<data.length; i++) {
      if (data[i]['id']) {
        postData['folder['+data[i]['id']+']'] = data[i]['parent_id']
        postData['position['+data[i]['id']+']'] = i
      }
    }
    console.dir(postData);
    let update_folder_order_url = $('#folder-list').data('folder-order-url');

    $.ajax({
      url: update_folder_order_url,
      type: 'post',
      data: postData,
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'json',
      success: function (data) {
        if(data.status != "success") {
          alert(I18n.wellness_snapshot.cannot_update_order);
        }
      }
    });
  }


  updateSnapshotFilter(event) {
    console.log("WSF: updateSnapshotFilter")
    event.preventDefault()
    var item = event.currentTarget
 
    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "PATCH",
      url: $(item).attr('action'),
      data: $(item).serializeArray(),
      async: false,
      success: function (data) {
        if(data.status == 200) {
          
          $('#system_modal_popup').modal('hide')
          if(data.kind == 'wellness_snapshot') {

            if($('#load_saved_snapshots').length) {
              document.getElementById('load_saved_snapshots').reload()
            }
          } else if(data.kind == 'wellness_journey') {
            if($('#wj_load_saved_snapshots').length) {
              document.getElementById('wj_load_saved_snapshots').reload()
            }
          }
        } else {
          alert(I18n.wellness_snapshot.cannot_save_filter)
        }
      }
    })
  }

}
