import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="participants"
export default class extends Controller {
  connect() {
  }

  cancelParticipantsUpdate(event) {
    event.preventDefault()
    $("#number_participants_display_div").show()
    $("#number_participants_change_div").hide()
  }

  changeParticipants(event) {
    event.preventDefault()
    $("#number_participants_display_div").hide()
    $("#number_participants_change_div").show()
  }

  clean_invite_code(event) {
    let input = event.currentTarget;
    input.value = clean_invite_code_string(input.value);
    if (input.value != '') {
      $("#custom_invitation_code").removeClass("is-invalid");
      $("#custom_invitation_code_error").html("").hide();
    }
    if (document.getElementById('custom_url') && document.getElementById('custom_url').value == '') {
      document.getElementById('custom_url').value = slugify_string(input.value);
      if (document.getElementById('custom_url').value != '') {
        $("#custom_url").removeClass("is-invalid");
        $("#custom_url_error").html("").hide();
      }
    }
  }

  clean_url_path(event) {
    let input = event.currentTarget;
    input.value = slugify_string(input.value);
    if (input.value != '') {
      $("#custom_url").removeClass("is-invalid");
      $("#custom_url_error").html("").hide();
    }
  }

  clipboardHTML(event) {
    var item = event.currentTarget
    var html_content = $("#invite_email_content_input").val();

    var copy_success = copy_to_clipboard(html_content);
    $(item).blur();
    if(copy_success) {
      $("#copy_msg_example_email_body_all").show().delay(5000).fadeOut('fast');
    } else {
      $('#copy_error_msg_example_email_body_all').show().delay(5000).fadeOut('fast');
    }
  }

  copySignupUrl(event) {
    var item = event.currentTarget
    var html_content = $("#signup_url").html();
    if ($(item).data("linktarget")) {
      html_content = $("#"+$(item).data("linktarget")).html();
    }

    var copy_success = copy_to_clipboard(html_content);
    $(item).blur();
    if(copy_success) {
      $("#signup_url_success").show().delay(5000).fadeOut('fast');
    }
  }

  saveParticipants(event) {
    $("#number_participants_display_div").show()
    $("#number_participants_change_div").hide()
    $("#participation_out_of_display").html( $("#customer_sub_account_participation_outof").val())
  }

  save_customize_invite_code_and_url(event) {
    // Reset
    $("#custom_invitation_code").removeClass("is-invalid");
    $("#custom_url").removeClass("is-invalid");
    $("#custom_invitation_code_error").html("").hide();
    $("#custom_url_error").html("").hide();
    // Submit for processing
    let invite_code = $("#custom_invitation_code").val();
    let custom_url = $("#custom_url").val();
    let error = false;
    if (invite_code == "" ) {
      $("#custom_invitation_code").addClass("is-invalid");
      error = true;
      $("#custom_invitation_code_error").html(i18n_string("general.this_field_is_required","This field is required")).show();
    }
    if (custom_url == "") {
      $("#custom_url").addClass("is-invalid");
      error = true;
      $("#custom_url_error").html(i18n_string("general.this_field_is_required","This field is required")).show();
    }
    if (error == false) {
      $.ajax({
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
        url: '/participants/customize_invite_code',
        data: {invite_code: invite_code, custom_url: custom_url},
        type: 'POST',
        success: function (data) {
          if (data) {
            if (data.invite_code_status && data.custom_url_status) {
              // Everything is good -- refresh page
              document.location = '/participants?tab=invitation_code';
            } else if (data.invite_code_status) {
              // First field is ok, but url is not
              $("#custom_invitation_code").removeClass("is-invalid");
              $("#custom_invitation_code_error").html("").hide();
              $("#custom_url").addClass("is-invalid");
              // Make invite_code field read-only
              $("#custom_invitation_code").prop('readonly','readonly');
            }
            // Get errors - display
            if (data.invite_code_error) {
              $("#custom_invitation_code").addClass("is-invalid");
              $("#custom_invitation_code_error").html(data.invite_code_error).show();
            }
            if (data.custom_url_error) {
              $("#custom_url").addClass("is-invalid");
              $("#custom_url_error").html(data.custom_url_error).show();
            }
          }
          // {invite_code_status: false, custom_url_status: false, invite_code_error: '', custom_url_error: ''}
        }
      });
    } else {

    }
  }

  send_test_invite_email(event) {
    event.preventDefault();
    let email = $("#send-test-invite-email-input").val();
    if (email && email.indexOf("@") != -1) {
      $.ajax({
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
        url: '/participants/send_test_invite_email',
        data: {email: email},
        type: 'POST',
        success: function (data) {
          if (data && data.message) {
            alert(data.message);
          }
        }
      });
    }
  }

  show_invite_upload_form(event) {
    event.preventDefault();
    let form_element = document.getElementById("invite_multiple_users_form");
    if (form_element) {
      form_element.style.display = '';
    }
  }

  submit_invite_multiple_users(event) {
    let submit_button = document.getElementById("send-invite-emails");
    if (!submit_button.disabled) {
      submit_button.disabled = true;
      let file_input = document.getElementById("file");
      if (file_input && file_input.value != "") {
        document.getElementById("submit-spinner").style.display = '';
        return true;
      } else {
        submit_button.disabled = false;
        event.preventDefault();
        return false;
      }
    }
    event.preventDefault();
    return false;
  }

  toggleMultiSmsInvite(event) {
    if(event.target.checked == true) {
      $("#send_sms_invite_btn_multi").prop("disabled", false);
    } else {
      $("#send_sms_invite_btn_multi").prop("disabled", true);
    }
  }

  toggleSmsCountry(event) {
    if(event.target.checked == true) {
      $("#sms_upload_set_country_div").show();
    } else {
      $("#sms_upload_set_country_div").hide();
    }
  }

  toggleSmsInvite(event) {
    if(event.target.checked == true) {
      $("#send_sms_invite_btn").prop("disabled", false);
    } else {
      $("#send_sms_invite_btn").prop("disabled", true);
    }
  }

  validateRemoval(event) {
    var ans = confirm(i18n_string("participants.are_you_sure_you_want_to_remove_this_test_account","Are you sure you want to remove this test account?"));
    if(ans) {}
    else {
      event.preventDefault()
    }
  }
  
}
