import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sa-dashboard"
export default class extends Controller {

  start_date = ''
  today = ''


  connect() {
    // this.getDates()
    // this.loadAtRiskChart()
    // this.loadNewUserChart()
    // this.loadTotalSurveysChart()
  }

  buildOptions() {

  }

  getDates() {
    this.today = new Date().toISOString().slice(0, 10);
    var start_date = new Date()
    start_date.setDate(start_date.getDate() - 90)
    this.start_date = start_date.toISOString().slice(0,10)
  }

  // drawLineChart()

  loadAtRiskChart() {

    $.get("/reports/sa_surveys_taken_trend.json", { start_date: this.start_date, end_date: this.today, period: 'week' }, function(data){
      console.log(data)

      var dates = []
      for( let row in data) {

        var display_parts = data[row].year_week.split("-")

        let date = new Date(display_parts[0], 0, display_parts[1] * 7)

        dates.push([date, data[row].total])

      }
      console.log(dates)
      drawAtRiskChart(dates)
    })
  }

  loadNewUserChart() {

  }

  loadTotalSurveysChart() {

  }






}


