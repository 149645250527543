import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="support"
export default class extends Controller {
  static targets = ["supportTicketModalBody"]
  ckeditorInstance = undefined;

  connect() {

    if($(".browser_panel").length) { $(".browser_panel").val( navigator.userAgent ); }
    if($(".link_panel").length) { $(".link_panel").val(document.location.href); }

    this.initializeCkEditor()
    this.initializeSelect2($(this.element).find(".select2"), {})

    $(this.element).find(".select2").on('change', function(e){
      $.get("/customers/" + $(this).val() + "/all_admin_users", {}, function(data){
        $('#support_ticket_created_by_id').html('')

        $('#support_ticket_created_by_id').append('<option></option>');
        $.each(data, function(index, user){
          if( user["first_name"] != '' && user["first_name"].trim() != '') {
            var text = user['email'] + ' - ' + user["first_name"] + ' ' + user['last_name'];
          } else {
            var text = user['email']
          }
          $('#support_ticket_created_by_id').append('<option value="' + user["id"] + '">' + text + '</option>');
        });

        $("#customer_users").removeClass("hidden");
      });
    });
  }

  confirmDelete(e) {
    var ans = confirm("Are you sure you want to delete this ticket?")
    if(ans) {
      
    } else {
      e.preventDefault()
    }
  }

  editMessage(event) {
    event.preventDefault();
    this.modal = new Modal(document.getElementById('editTicketModal'), {
      keyboard: false
    });

    var item = event.currentTarget
    this.modal.show()
    $.get(item.href, {} , function(data){
      $('#editTicketForm').attr('action', "/support/" + item.dataset.id + "/update_message");
      $('#editTicketForm').attr('data-turbo-frame', "support_ticket_message_"+item.dataset.id);
      $('#editTicketForm').attr('data-id', item.dataset.id);

      if (!$('#editTicketContent').data("ckeditor_initialized")){
        $('#editTicketContent').data("ckeditor_initialized",1);
        $('#editTicketContent').html(data.message.content);

        CKEDITOR.replace("editTicketContent", {
          height: '300px',
          toolbar: [
            {
              name: 'basicstyles',
              groups: ['basicstyles', 'cleanup'],
              items: ['Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat', '-', 'Link', 'Unlink']
            },
            {
              name: 'paragraph',
              groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
              items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
            },
            {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
            {name: 'colors', items: ['TextColor', 'BGColor']}
          ]
        });
      } else if (CKEDITOR && CKEDITOR.instances['editTicketContent']) {
        CKEDITOR.instances['editTicketContent'].setData(data.message.content);
      }
    });
  }

  initializeCkEditor() {
    // Initialize CKEditor    
    if($("#ticket_content").length) {
      setTimeout(() => {
      if(!$("#ticket_content").data("ckeditor_initialized")) {
        $("#ticket_content").data("ckeditor_initialized", 1);
        this.ckeditorInstance = CKEDITOR.replace("ticket_content", {
          height: '300px',
          toolbar: [
            {
              name: 'basicstyles',
              groups: ['basicstyles', 'cleanup'],
              items: ['Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat', '-', 'Link', 'Unlink']
            },
            {
              name: 'paragraph',
              groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
              items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
            },
            {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
            {name: 'colors', items: ['TextColor', 'BGColor']}
          ]
        });


        // http://handsomedogstudio.com/ckeditor-set-default-target-blank
        /* Here we are latching on an event ... in this case, the dialog open event */
        CKEDITOR.on('dialogDefinition', function(ev) {
          try {
            /* this just gets the name of the dialog */
      
            var dialogName = ev.data.name;
            /* this just gets the contents of the opened dialog */
      
            var dialogDefinition = ev.data.definition;
            /* Make sure that the dialog opened is the link plugin ... otherwise do nothing */

            if(dialogName == 'link') {
              
              /* Getting the contents of the Target tab */
              var informationTab = dialogDefinition.getContents('target');
            
              /* Getting the contents of the dropdown field "Target" so we can set it */
              var targetField = informationTab.get('linkTargetType');
            
              /* Now that we have the field, we just set the default to _blank. A good modification would be to check the value of the URL field and if the field does not start with "mailto:" or a relative path, then set the value to "_blank" */
              targetField['default'] = '_blank';
            
            }
          } catch(exception) {
            alert('Error ' + ev.message);
          }
        });
      }
      }, 500);
    }
  }

  initializeSelect2(selector, options) {
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }

  markAllRead(event) {
    var ans = confirm(i18n_string("support.mark_all_read_confirm"))
    if(!ans) {
      event.preventDefault()
    }
  }

  setStatus(event) {
    var user_changed_status = event.target.getAttribute("data-user_changed");
    if(user_changed_status == "no")
    {
      var msg_kind = event.target.value;
      var note_status = $("#tick_status_also").data("note_status");
      var message_status = $("#tick_status_also").data("message_status");
      if(note_status != message_status)
      {
        if(msg_kind == "message")
        {
          $("#tick_status_also").val(message_status);
        }
        else if(msg_kind == "note")
        {
          $("#tick_status_also").val(note_status);
        }
      }
    }

    var msg_kind2 = event.target.value;
    if(msg_kind2 == "message")
    {
      if(this.ckeditorInstance)
      {
        this.ckeditorInstance.document.getBody().setStyle('background-color', '#ffffff');
      }
    }
    else if(msg_kind2 == "note")
    {
      if(this.ckeditorInstance)
      {
        this.ckeditorInstance.document.getBody().setStyle('background-color', '#fff9d9');
      }
    }
  }


  setCreatedById(event) {
    var user_id = event.target.value
    $('#created_by_user_id').val(user_id);
  }

  

  savedMessage(event) {
    $('.modal').modal('hide');
  }
}
