import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  connect() {
    this.check_reassess_enabled_checkbox();
    // this.initializeSelect2(".select2", {});

    $("#allowed_reminder_periods").select2({width:'100%'});
    $("#auto_alert_custom_send_dow").select2({width:'100%'});
  }

  check_reassess_enabled_checkbox()
  {
    if(!$("#reassess_enabled_checkbox").prop("checked"))
    {
      $("#reassess_enabled_div").hide();
    }
    else
    {
      $("#reassess_enabled_div").show();
    }
  }

  togglePreferences(e) {
    var cur_val = e.target.value
    $("input[name='auto_alert_reassess_preference']").each(function(){
      if(!this.checked)
      {
        var radio_val = $(this).val();
        $("#auto_alert_reassess_preference_extra_options_"+radio_val).hide();
      }
    });
    $("#auto_alert_reassess_preference_extra_options_"+cur_val).show(); 
  }

  toggleReassessEmails(e) {
    this.check_reassess_enabled_checkbox();
  }


  // $(".show-upgrade-messages").on("click",function(e){
  //   // e.preventDefault();
  //   $("#upgrade-message").fadeIn();
  // });
}
