import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="automated-report-editor"
export default class extends Controller {
  connect() {
  }

  delete_automated_report(e) {
    e.preventDefault();
    if (confirm(i18n_string("advanced_reports.automated_report_delete_confirmation", "Are you sure you sure you want to delete this configuration?"))) {
      var adv_report_id = $("#adv_report_id").val();
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: "/advanced-report/" + adv_report_id + "/delete_automated_report",
        data: "automated_report_id=" + $(e.currentTarget).data("id"),
        type: "DELETE",
        success(dataArr) {
          if (dataArr && dataArr.deleted_automated_report_id) {
            var $table = $("#automated-report-config-table-body");
            $table.find("#automated-report-config-row-" + dataArr.deleted_automated_report_id).remove();
            $("#inner_modal_popup").modal("hide");
          }
        }
      });

    }
  }

  delivery_method_change(e) {
    if ($("#ar_delivery_method").val() == "email") {
      $("#ar_email_delivery_input_div").show();
      if ($("#ar_send_to").val() == "sftp") {
        $("#ar_send_to").val("");
      }
    } else {
      $("#ar_email_delivery_input_div").hide();
      if ($("#ar_delivery_method").val() == "sftp") {
        $("#ar_send_to").val("sftp");
      }
    }
  }

  getJSONLoadSettingsString($formObj) {
    var settings_data = $formObj.serializeArray();
    var final_data_array = {};
    var index_array_counter = {};
    for(var i=0;i<settings_data.length;i++){
      var name = settings_data[i].name;
      if(name != 'authenticity_token') {
        if(name.indexOf("[]") != -1){
          if(!index_array_counter[name]){
            index_array_counter[name] = 0;
          }
          var new_temp_name = name.replace("[]","["+index_array_counter[name]+"]");
          index_array_counter[name]++;
          name = new_temp_name;
        }
        final_data_array[name] = settings_data[i].value;
      }
    }
    return JSON.stringify(final_data_array);
  }

  save_automated_report(e) {
    e.preventDefault();
    clearJsAlerts("automated-report-jsalert");
    var jsonSettings = this.getJSONLoadSettingsString($("#automated-report-settings-include").find("select, textarea, input"));
    var errorString = "";
    if (jsonSettings.length > 2) {
      var postData = {
        automated_report_id: $("#automated_report_id").val(),
        saved_report_settings: jsonSettings
      };
      // Get the rest of the data and validate
      var ar_name = $("#ar_name").val();
      var ar_frequency = $("#ar_frequency").val();
      var ar_next_run_date = $("#ar_next_run_date").val();
      var ar_timeframe = $("#ar_timeframe").val();
      var ar_send_to = $("#ar_send_to").val();
      var adv_report_id = $("#adv_report_id").val();
      var user_id = 0;
      if ($("#ar_user_id").length) {
        user_id = $("#ar_user_id").val();
      }
      if (ar_name == "") {
        errorString = i18n_string("advanced_reports.automated_report_error_enter_name","Please enter a name");
      } else if (ar_frequency == "") {
        errorString = i18n_string("advanced_reports.automated_report_error_select_frequency","Please select a Frequency");
      } else if (ar_next_run_date == "") {
        errorString = i18n_string("advanced_reports.automated_report_error_select_next_report_date","Please select the next reporting date");
      } else if (ar_timeframe == "") {
        errorString = i18n_string("advanced_reports.automated_report_error_select_timeframe","Please select data pull timeframe");
      } else if (ar_send_to == "") {
        errorString = i18n_string("advanced_reports.automated_report_error_enter_recipients","Please enter one or more recipients");
      } else if ($("#ar_account_id").length && (user_id == 0 || user_id == "")) {
        errorString = i18n_string("advanced_reports.automated_report_error_select_report_as","Please select user to run report as");
      }
      if (errorString == "") {
        if($("#ar_output_format").length) {
          postData.output_format = $("#ar_output_format").val();
          postData.date_column = $("#ar_date_column").val();
          postData.date_column_format = $("#ar_date_column_format").val();
          postData.split_demographics = $("#ar_split_demographics").val();
          postData.exclude_filter_info = $("#ar_exclude_filter_info").val();
        }
        postData.name = ar_name;
        postData.frequency = ar_frequency;
        postData.next_run_date = ar_next_run_date;
        postData.timeframe = ar_timeframe;
        postData.send_to = ar_send_to;
        postData.advanced_report_id = adv_report_id;
        postData.user_id = user_id;
        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          url: "/advanced-report/" + adv_report_id + "/save_automated_report",
          data: postData,
          type: "POST",
          success(dataArr) {
            if (dataArr && dataArr.success) {
              let frame = document.getElementById("automated_report_list");
              frame.reload();
              clearJsAlerts("automated-report-jsalert");
              $("#inner_modal_popup").modal("hide");
            } else if (dataArr && dataArr.error) {
              doJsAlert('<div class="alert alert-danger">'+dataArr.error+'</div>', "automated-report-jsalert");
            } else {
              // Display generic saving error
              doJsAlert('<div class="alert alert-danger">'+i18n_string("advanced_reports.automated_report_error_unknown","Unknown error occurred while saving automated report configuration.")+'</div>', "automated-report-jsalert");
            }
          }
        });

      }
    } else {
      errorString = i18n_string("advanced_reports.automated_report_error_failed_to_get_settings","Failed to get chart settings, please try again.");
    }

    if (errorString) {
      doJsAlert('<div class="alert alert-danger">'+errorString+'</div>', "automated-report-jsalert");
    }
  }

}
