import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="export-question"
export default class extends Controller {
  connect() {
  }

  deselectAll(e) {
    e.preventDefault()
    var item = e.currentTarget

    $("#" + item.dataset.option).find(':checkbox').each(function(){
      this.checked = false
    })
  }

  selectAll(e) {
    e.preventDefault()
    var item = e.currentTarget

    $("#" + item.dataset.option).find(':checkbox').each(function(){
      this.checked = true
    })
  }
  
}
