import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-survey"
export default class extends Controller {
  static targets = [ 'answer8_output', 'answer9_output' ]
  connect() {
  }

  process_auto_send_checkbox(event) {
    var item = event.currentTarget
    if ($(item).is("select")) {
      if($(item).val() == 1) {
        $("#auto_send_cert_name_div").removeClass('hidden');
      } else {
        $("#auto_send_cert_name_div").addClass('hidden');
      }
    } else {
      if(item.checked) {
        $("#auto_send_cert_name_div").removeClass('hidden');
      } else {
        $("#auto_send_cert_name_div").addClass('hidden');
      }
    }
  }

  update_range_slider_output(event) {
    var input_changed = $(event.currentTarget);
    var newVal = input_changed.val();
    var id = input_changed.attr('id');
    if (id == "answer_8") {
      this.answer8_outputTarget.innerText = newVal;
    } else if (id == "answer_9") {
      this.answer9_outputTarget.innerText = newVal;
    }
  }

  validate_form(event) {
    // Hide any alerts
    clearJsAlerts();
    if ($("#auto_send_cert_errors").length) {
      $("#auto_send_cert_errors").html('');
    }

    // Go through all the questions and make sure they are answered
    var notanswered = 0;
    var totalQuestions = 7;
    var qlist = [];
    for(var i=1;i<=totalQuestions;i++){
      // $('input[name="survey[survey_detail_attributes][answer_3]"][value=1]').length
      if(!$('input[name="survey[survey_detail_attributes][answer_'+i+']"][value=1]').is(':checked') && !$('input[name="survey[survey_detail_attributes][answer_'+i+']"][value=0]').is(':checked')){
        notanswered++;
        qlist.push(i);
      }
    }
    if($('input[name="survey[survey_detail_attributes][answer_'+8+']"]').length && !$('input[name="survey[survey_detail_attributes][answer_'+8+']"]').val()){
      notanswered++;
      qlist.push(8);
    }
    if($('input[name="survey[survey_detail_attributes][answer_'+9+']"]').length && !$('input[name="survey[survey_detail_attributes][answer_'+9+']"]').val()){
      notanswered++;
      qlist.push(9);
    }
    if(notanswered > 0){
      var msghtml = '<strong>'+i18n_string("survey.new.please_answer_all_of_the_questions","Please answer all of the questions")+'</strong><br />';
      msghtml += i18n_string("survey.new.questions","Questions")+' #'+qlist.join(', #')+' '+i18n_string("survey.new.not_answered","not answered");

      doJsAlert('<div class="alert alert-danger">'+msghtml+'</div>');
      event.preventDefault();
      return false;
    }

    var auto_send_errors = [];
    if ($("#auto_send_cert").is("select") && $("#auto_send_cert").val() === '') {
      auto_send_errors.push(i18n_string("survey.new.please_select_an_answer","Please select an answer"));
    }
    else if ($("#auto_send_cert").length && ($("#auto_send_cert").is(":checked") || ($("#auto_send_cert").is("select") && $("#auto_send_cert").val() === '1')))
    {
      
      if ($("#auto_send_cert_name").val() == ""){
        auto_send_errors.push(i18n_string("survey.new.please_fill_in_name","Please fill in Name"));
      }
      if ($("#auto_send_cert_employee_id").length && $("#auto_send_cert_employee_id").val() == ""){
        auto_send_errors.push(i18n_string("survey.new.please_fill_in_employee_id","Please fill in Employee ID"));
      }
    }

    if (auto_send_errors.length > 0) {
      $("#auto_send_cert_errors").html('<div class="alert alert-danger">'+auto_send_errors.join('<br />')+'</div>');
      event.preventDefault();
      return false;
    }



    if($("#assessment-step1-button").length == 0) {
      $("#submit-index-button").val(i18n_string("survey.new.submitting","Submitting")+"...");
      $("#submit-index-button").prop("disabled", true);
    }
    return true;

    // event.preventDefault();
  }

  validate_form_with_validation_questions_step1(event) {
    if (this.validate_form(event)) {
      $("#assessment-step2-button").hide();
      $("#assessment-step1").slideUp("fast");
      $("#assessment-step2").slideDown("fast",function (){
        $("#assessment_title_step").html("2");
        var offset = $("#assessment_title_step").offset();
        var gotoPos = offset.top - 40;
        $("html,body").animate({ scrollTop: gotoPos }, "fast", function(){
          $("#assessment-step2-button").show();
        });
      });
    }
  }

  validate_form_with_validation_questions_step2(event) {
    let missing_validation_question = false;
    $(".validation-question-select").each(function(){
      if($(this).val() == "")
      {
        missing_validation_question = true;
      }
    });
    $("#assessment-step2 input").each(function(){
      var name = $(this).attr("name");
      if($(this).attr("type") == "radio")
      {
        if($("input[name='"+name+"']").length)
        {
          if($("input[name='"+name+"']:checked").length == 0)
          {
            missing_validation_question = true;
          }
        }
      }
      if($(this).attr("type") == "checkbox")
      {
        if($("input[name='"+name+"']").length)
        {
          if($("input[name='"+name+"']:checked").length == 0)
          {
            missing_validation_question = true;
          }
        }
      }
    });
    if(missing_validation_question)
    {
      var msghtml = '<strong>'+i18n_string("survey.new.please_answer_all_of_the_questions","Please answer all of the questions")+'</strong>';

      doJsAlert('<div class="alert alert-danger">'+msghtml+'</div>');
      event.preventDefault();
      return false;
    }
    // Everything is ok
    $("#wbi-form button[type='submit']").text("Submitting...");
    $("#wbi-form button[type='submit']").prop("disabled", true);
  }

  validation_questions_back_to_step1(e){
    $("#assessment-step2").slideUp("fast");
    $("#assessment-step1").slideDown("fast",function (){
      $("#assessment_title_step").html("1");
      var offset = $("#assessment_title_step").offset();
      var gotoPos = offset.top - 40;
      $("html,body").animate({ scrollTop: gotoPos }, "fast", function(){

      });
    });
  }
}
