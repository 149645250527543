import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="account-launchpad"
export default class extends Controller {
  connect() {
    setTimeout(function(){
      $("#location-search").focus();
    },250);
  }


  locationSearch(event) {
    var searchStr = event.target.value
    var numShown = 0
   
    if(searchStr && searchStr.length > 0)
    {
      searchStr = searchStr.toLowerCase();
      searchStr = searchStr.replace(/[^a-z0-9]+/g, '');
      $(".choose-location-search-item").each(function(){
        var this_searchable_val = $(this).data("searchstr");
        if(this_searchable_val)
        {
          console.log(this_searchable_val.indexOf(searchStr), searchStr)

          if(this_searchable_val.indexOf(searchStr) != -1)
          {
            $(this).show();
            numShown++;
          }
          else
          {
            $(this).hide();
          }
        }
        else
        {
          $(this).hide();
        }
      });
    }
    else
    {
      // Show all
      $(".choose-location-search-item").each(function(){
        $(this).show();
        numShown++;
      });
    }
    if(numShown == 0)
    {
      $("#choose-location-no-items").show();
    }
    else
    {
      $("#choose-location-no-items").hide();
    }
    var menu_width = $("#search-location-menu-item").parent().width();
    $("#search-location-menu-item").css("width",menu_width+"px");
  }

  clearSearch(event){
    event.preventDefault()
    $("#location-search").val("")
    
    $(".choose-location-search-item").each(function(){
      $(this).show();
    })    
  }  
}
