import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ckeditor-browser"
export default class extends Controller {
  connect() {
  }

  file_clicked(e) {
    let file_path = e.currentTarget.dataset.file;
    let ckeditor_func = e.currentTarget.dataset.func;
    if (file_path && ckeditor_func) {
      window.opener.CKEDITOR.tools.callFunction(ckeditor_func, file_path);
      window.close();
    }
  }

  file_upload_form_changed(e) {
    let file_field = document.getElementById("file_upload_field");
    if (file_field && file_field.files.length > 0) {
      let upload_container_overlay = document.getElementById("uploading-container");
      if (upload_container_overlay) {
        upload_container_overlay.style.display = '';
        document.body.classList.add("noscroll");
      }
      e.currentTarget.submit();
    }
  }
}
