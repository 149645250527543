import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="plans"
export default class extends Controller {
  connect() {
  }

  deletePlan(event) {
    var item = event.target

    if(item.checked) {
      $('#delete_holder_' + item.dataset.id).removeClass("hidden");

      if(item.dataset.total > 0) {
        $("#save_plan_" + item.dataset.id).attr('disabled', 'disabled');
      }
    } else {
      $('#delete_holder_' + item.dataset.id).addClass("hidden");
      $("#save_plan_" + item.dataset.id).removeAttr('disabled');
      $("#select_plan_" + item.dataset.id).prop('selectedIndex', 0);
    }
  }

  selectNewPlan(event) {
    var item = event.target
    if(item.value != '') {
      $("#save_plan_" + item.dataset.id).removeAttr('disabled');
    } else {
      $("#save_plan_" + item.dataset.id).attr('disabled', 'disabled');
    }
  }
}
