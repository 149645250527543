import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="dismiss-alert"
export default class extends Controller {
  connect() {
    this.element.addEventListener('closed.bs.alert', async function () {
      if (this.dataset && this.dataset.dismiss_path) {
        let notice_id = this.dataset.notice_id;
        // let formData = new FormData();
        // formData.append('id', notice_id);
        // const request = new FetchRequest("post", this.dataset.dismiss_path, { responseKind: "json", body: formData});
        const request = new FetchRequest("post", this.dataset.dismiss_path, { responseKind: "json"});
        const response = await request.perform();
      }
    });
  }
}
