import { Controller } from "@hotwired/stimulus"
import AccountSetupVerificationController from './account_setup_verification_controller'

// Connects to data-controller="free-assessment"
export default class extends AccountSetupVerificationController {
  connect() {
    if (this.element.dataset && this.element.dataset.step) {
      switch (this.element.dataset.step){
        case 1:
        case '1':
          setupTermsScrollBox();
          this.element.addEventListener('turbo:submit-start', this.validateVersionSelection.bind(this));
          this.element.addEventListener('submit', this.validateVersionSelection.bind(this));
          this.element.noValidate = false;
          break;
        case 3:
        case '3':
          this.element.addEventListener('turbo:submit-start', this.validateDemographics.bind(this));
          this.element.addEventListener('submit', this.validateDemographics.bind(this));
          this.element.noValidate = false;
          this.element.addEventListener('change', this.checkChangedInputs.bind(this));
          // Country Change
            let country_select = document.getElementById("question_22");
            if (country_select) {
              country_select.addEventListener('change', this.countrySelectChanged.bind(this));
            }
          break;
        case 4:
        case '4':
          this.element.addEventListener('turbo:submit-start', this.validateEmailPassword.bind(this));
          this.element.addEventListener('submit', this.validateEmailPassword.bind(this));
          this.element.noValidate = false;
          this.element.addEventListener('change', this.checkChangedInputs.bind(this));
          break;
      }
    }
    this.emailDebouncer = basicDebouncer( 250 );
  }
  countrySelectChanged(event) {
    let sel = event.target;
    if (sel.options[sel.selectedIndex].text == "United States") {
      $("#use_state_in_search_container").show();
    } else {
      $("#use_state_in_search").prop("checked",false);
      $("#use_state_in_search_container").hide();
    }
  }
  validateDemographics(event) {
    let isValid = true;
    let errors = [];

    let passedStandardValidation = super.validateForm(event);

    if (passedStandardValidation) {
      // Make sure organization is selected/entered
      if ($("#institution").val() == "") {
        isValid = false;
        errors.push(i18n_string("free_assess.please_enter_a_hospital_institution","Please enter a hospital/institution"));
      }
      if (!isValid){
        event.preventDefault();
        doJsAlert('<div class="alert alert-danger">'+removeDuplicates(errors).join('<br />')+'</div>');
      }
    }

    return isValid && passedStandardValidation;
  }
  validateEmailPassword(event){
    if($(event.target).attr("submit_pressed") == "yes") {
      return false;
    }
    let passedStandardValidation = super.validateForm(event);
    if (passedStandardValidation) {
      let passOtherValidation = true;
      let errors = [];
      if ($("#user_email").data("email_valid") != "yes") {
        passOtherValidation = false;
        errors.push(i18n_string("home.account_setup.please_enter_a_valid_email", "Please enter a valid email."));
      }
      if ($("#user_sms_phone").val() != "" && $("#invalid_phone").length)
      {
        passOtherValidation = false;
        errors.push(i18n_string("home.account_setup.the_phone_number_is_not_available", "The entered phone number is used by another account."));
      }
      if (passOtherValidation) {
        $(event.target).attr("submit_pressed", "yes");
        return true;
      } else {
        doJsAlert('<div class="alert alert-danger">'+removeDuplicates(errors).join('<br />')+'</div>');
      }
    }
    event.preventDefault();
    return false;
  }
  validateVersionSelection(event) {
    let isValid = true;
    let errors = [];
    // Make sure version is selected
    if ($("#wbi_version_id").val() == "" || parseInt($("#wbi_version_id").val()) < 1) {
      errors.push(i18n_string("free_assess.please_select_your_group","Please select your group"));
      isValid = false;
    }
    // Make sure agree_terms is checked
    if (!$("#agree_terms").prop("checked")) {
      errors.push(i18n_string("home.account_setup.please_finish_reading_the_eula","Please finish reading the End User License Agreement before continuing."));
      isValid = false;
    }

    if (!isValid){
      event.preventDefault();
      doJsAlert('<div class="alert alert-danger">'+removeDuplicates(errors).join('<br />')+'</div>');
    }

    return isValid;
  }
}
