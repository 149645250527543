import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dev-test-tools"
export default class extends Controller {
  connect() {
  }

  autofill(e) {
    e.preventDefault();
    if (e.currentTarget.dataset.kind == "random") {
      for (var i = 1; i <= 7; i++) {
        var ans = "0";
        if (Math.random() * 100 < 45) {
          ans = "1"
        }
        document.getElementById("survey_survey_detail_attributes_answer_" + i + "_" + ans).checked = true;
      }
      if (document.getElementById("answer_8")) {
        let range_input = document.getElementById("answer_8")
        range_input.value = ~~(Math.random() * 7)
        let event = new Event('change', { bubbles: true }) // fire a native event
        range_input.dispatchEvent(event);
      }
      if (document.getElementById("answer_9")) {
        let range_input = document.getElementById("answer_9")
        range_input.value = ~~(Math.random() * 5)
        let event = new Event('change', { bubbles: true }) // fire a native event
        range_input.dispatchEvent(event);
      }
    } else if (e.currentTarget.dataset.kind == "positive") {
      for (var i = 1; i <= 7; i++) {
        var ans = "0";
        document.getElementById("survey_survey_detail_attributes_answer_" + i + "_" + ans).checked = true;
      }
      if (document.getElementById("answer_8")) {
        let range_input = document.getElementById("answer_8")
        range_input.value = 7
        let event = new Event('change', { bubbles: true }) // fire a native event
        range_input.dispatchEvent(event);
      }
      if (document.getElementById("answer_9")) {
        let range_input = document.getElementById("answer_9")
        range_input.value = 5
        let event = new Event('change', { bubbles: true }) // fire a native event
        range_input.dispatchEvent(event);
      }
    } else if (e.currentTarget.dataset.kind == "negative") {
      for (var i = 1; i <= 7; i++) {
        var ans = "1";
        document.getElementById("survey_survey_detail_attributes_answer_" + i + "_" + ans).checked = true;
      }
      if (document.getElementById("answer_8")) {
        let range_input = document.getElementById("answer_8")
        range_input.value = 1
        let event = new Event('change', { bubbles: true }) // fire a native event
        range_input.dispatchEvent(event);
      }
      if (document.getElementById("answer_9")) {
        let range_input = document.getElementById("answer_9")
        range_input.value = 1
        let event = new Event('change', { bubbles: true }) // fire a native event
        range_input.dispatchEvent(event);
      }
    }
  }
  autofill_inputs(e) {
    e.preventDefault();
    var controllerRef = this;
    $("select:visible").each(function(){
      if (!$(this).val()) {
        var $options = $(this).find('option'),
            random = ~~(Math.random() * $options.length);

        $options.eq(random).prop('selected', true);

        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      }
    });
    $("input:visible").each(function(){
      if (!$(this).val()) {
        if (this.type == "email") {
          $(this).val("youremail+"+controllerRef.random_string(25)+"@cws.net");

          let event = new Event('input', { bubbles: true }) // fire a native event
          this.dispatchEvent(event);
        } else if (this.type == "tel") {
          // Skip for now
        } else if (this.type == "password") {
          $(this).val("P@ssw0rd1");
        } else {
          var rand_string = controllerRef.random_string(20);
          $(this).val(rand_string);
        }
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      }
    });
  }

  process_modal_info_date(e){
    e.preventDefault();
    var date_val = $("#dev_test_tool_demographics_date").val();
    $.ajax({
      url: '/dev_test_tools/change_demographic_date',
      type: 'post',
      data: {date: date_val},
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'json',
      success: function (data) {
        $("#devTestToolsModal").modal("hide");
      }
    });
  }

  process_reminder_send(e) {
    e.preventDefault();
    var date_val = $("#dev_test_tool_survey_date").val();
    $.ajax({
      url: '/dev_test_tools/process_reminder_send',
      type: 'post',
      data: {},
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'json',
      success: function (data) {
        alert("Reminder sending is running in the background.  This can take a while to process through everything.");
      }
    });
  }

  process_modal_report_dates(e) {
    e.preventDefault();
    setCookie('dev_test_tool_report_date_start', $("#dev_test_tool_report_date_start").val(), 100);
    setCookie('dev_test_tool_report_date_end', $("#dev_test_tool_report_date_end").val(), 100);
    $("#devTestToolsModal").modal("hide");
    setTimeout(function(){document.location=document.location;}, 25);
  }

  process_modal_report_dates_clear(e) {
    e.preventDefault();
    setCookie('dev_test_tool_report_date_start', '', -100);
    setCookie('dev_test_tool_report_date_end', '', -100);
    $("#devTestToolsModal").modal("hide");
    setTimeout(function(){document.location=document.location;}, 25);
  }

  process_modal_survey_date(e){
    e.preventDefault();
    var date_val = $("#dev_test_tool_survey_date").val();
    $.ajax({
      url: '/dev_test_tools/change_survey_date',
      type: 'post',
      data: {date: date_val},
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'json',
      success: function (data) {
        $("#devTestToolsModal").modal("hide");
        setTimeout(function(){document.location=document.location;}, 25);
      }
    });
  }

  modal_info_date(e) {
    e.preventDefault();
    $("#dev-test-tool-survey-date").hide();
    $("#dev-test-tool-report-dates").hide();
    $("#dev-test-tool-demographic-date").show();
    $("#devTestToolsModal").modal("show");
  }
  modal_report_dates(e) {
    e.preventDefault();
    $("#dev-test-tool-survey-date").hide();
    $("#dev-test-tool-demographic-date").hide();
    $("#dev-test-tool-report-dates").show();
    $("#devTestToolsModal").modal("show");
  }
  modal_survey_date(e) {
    e.preventDefault();
    $("#dev-test-tool-demographic-date").hide();
    $("#dev-test-tool-report-dates").hide();
    $("#dev-test-tool-survey-date").show();
    $("#devTestToolsModal").modal("show");
  }

  random_string(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  reload_permissions(e) {
    e.preventDefault();
    $.ajax({
      url: '/dev_test_tools/reload_permissions',
      type: 'post',
      data: {},
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'json',
      success: function (data) {

      }
    });
  }

}
