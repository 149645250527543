import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-toggle"
export default class extends Controller {
  connect() {
    this.element[this.identifier] = this

    if (!this.element.dataset || !this.element.dataset.toggletarget) {
      //console.log("I'm missing something");
      return;
    }

    let toggleTarget = this.element.dataset.toggletarget;
    this.element.addEventListener('click', function (event) {
      if (this.checked) {
        $(toggleTarget).show();
      } else {
        $(toggleTarget).hide();
      }
    }, false);
  }
}
