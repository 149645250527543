import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diy-checklist"
export default class extends Controller {
  connect() {
  }

  itemCheckboxClicked(event) {
    let element = event.currentTarget;
    let status_val = 0;
    if (element.checked) {
      status_val = 1;
    }

    $.ajax({
      headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
      url: '/dashboard/diy_checklist_item_update',
      data: {item_tag: element.value, status: status_val},
      type: 'POST',
      success: function (data) {
        // {percent: 00, steps_completed: 00, steps_total: 00}
        let progressPieElement = document.getElementById("diy_progress_pie_wrapper");
        let progressStepsDiv = document.getElementById("diy_progress_step_count");
        if (progressPieElement && progressStepsDiv && data && data.steps_total) {
          var prefix = "progress-";
          var classes = progressPieElement.className.split(" ").filter(function(c) {
            return c.lastIndexOf(prefix, 0) !== 0;
          });
          classes.push("progress-"+data.percent);
          progressPieElement.className = classes.join(" ").trim();

          progressStepsDiv.innerHTML = data.steps_completed+"/"+data.steps_total;
        }
      }
    });
  }

  scrollTo(e) {
    e.preventDefault()
    var item = e.currentTarget.dataset.href
    document.getElementById(item).scrollIntoView()
  }
}
