import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-app-signup"
export default class extends Controller {
  connect() {
    $('.accordion-vh-100 .collapse').on('show.bs.collapse', function (e) {
      $(this).parents('.accordion-item').addClass('show');
    });

    $('.accordion-vh-100 .collapse').on('hide.bs.collapse', function (e) {
      if ($('.accordion-item.show').length <= 1) {
        e.preventDefault();
        return false;
      }
      $(this).parents('.accordion-item').removeClass('show');

    });

    $(window).resize(function() {
      var headerHeights = $(".no-auth-header").outerHeight();;
      $(".accordion-header").each(function(){
        headerHeights += $(this).outerHeight();
      });
      headerHeights += 20;
      var windowHeight = $(window).height();
      var heightLeftOver = windowHeight - headerHeights;
      // $(".collapse").height($(window).height() - headerHeights);
      $(".collapse").each(function(){
        $(this).height(heightLeftOver);
        $(this).css({
          "overflow": "auto",
          "max-height": heightLeftOver
        });
      });

    }).resize();
    $(document).on("change","#country_search_select",function(e){
      let selected_option = $(this).find('option:selected');
      let country_code = selected_option.data("code");
      $("#country_code").val(country_code);
      $("#country_option_id").val(selected_option.val());
      //console.log("change event: code="+country_code+"/option_id="+selected_option.val());
      if (country_code == "US" || country_code == "CA") {
        $("#institution-other-container").hide();
        $("#institution-search-container").show();
      } else {
        $("#institution-search-container").hide();
        $("#institution-other-container").show();
      }
    });
  }

  no_invite_continue(e){
    clearJsAlerts('message-container2');
    if ($("#wbi_version_id").val() != "") {
      $("#no_invite_step1").hide();
      $("#no_invite_step2").show();
    } else {
      doJsAlert('<div class="alert alert-danger px-2">'+i18n_string("mobile_app.please_select_your_profession","Please select your profession")+'</div>','message-container2');
    }
  }
  no_invite_back(e){
    $("#no_invite_step2").hide();
    $("#no_invite_step1").show();
  }
}
