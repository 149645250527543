import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="wellness-common-custom-list"
export default class extends Controller {
  connect() {
    const controllerRef = this
    this.element[this.identifier] = this
  }


  /* **************************************************************************
  function: buildOptionGroupList
  * Internal triggered
  * Gathers list of option ids that were dragged into the middle column (Selected Options), returns array object
  ************************************************************************** */
  buildOptionGroupList(option_kind) {
    console.log("WSCL: buildOptionGroupList")

    var id = ''
    if(option_kind == 'new') {
      id = "snapshot-list-2"
    } else if(option_kind == 'edit') {
      id = "snapshot-list-4"
    }

    var options = []
    $("#" + id).children().each(function(index, obj) {
      options.push(obj.dataset.id)
    });
    return options;
  }


  /* **************************************************************************
  function: buildOptionGroupRow
  * Internal triggered
  * Returns the final html to be displayed in the Option Groups column.
  ************************************************************************** */
  buildOptionGroupRow(object_id, options, label, kind) {
    console.log("WSCL: buildOptionGroupRow")
    return "<td><input id='options_" + object_id +"' type='hidden' name='wellness_snapshot_group[option_groups][]' value='" + options.join() + ":" + label.replaceAll("'", "&#39;") + "' />" + label + "</td><td style='width:75px'><div class='input-group'><button class='btn btn-info btn-xs' data-kind='" + kind + "' data-id='" + object_id + "' data-action='click->wellness-common-custom-list#editOptionGroup'><em class='fa fa-edit'></em></button><button class='btn btn-danger btn-xs' data-kind='" + kind + "' data-id='" + object_id + "' data-action='click->wellness-common-custom-list#removeOptionGroup'><em class='fa fa-trash float-end'></em></button></td>"
  }


  /* **************************************************************************
  function: cancelUpdateGroupOption
  * User triggered
  * Cancels and resets the "Selected Options" column when editing a saved list
  ************************************************************************** */
  cancelUpdateGroupOption(event) {
    console.log("WSCL: cancelUpdateGroupOption")
    var item = event.currentTarget
    const controllerRef = this

    var kind = ''
    if(item.dataset.kind == 'edit') { kind = '_edit' }

    $('#group_label_combinations' + kind).find('button').attr('disabled', false);
    $('#question_update_group_btn' + kind).removeClass("disabled")
    controllerRef.clearOptionGroup(item.dataset.kind)
    controllerRef.rebuildOptions(item.dataset.kind)
  }

  /* **************************************************************************
  function: checkUsedOptions
  * Internal triggered
  * Cancels and resets the "Selected Options" column when editing a saved list
  ************************************************************************** */
  checkUsedOptions(kind) {
    console.log("WSCL: checkUsedOptions")
    if(kind == 'new') {
      $('#save_list').attr('disabled', 'disabled')
      if($("#snapshot-list-1").children('li').length == 0) {
        $('#save_list').removeAttr('disabled')
        
      }
    } else if(kind == 'edit') {
      $('#save_list_edit').attr('disabled', 'disabled')
      if($("#snapshot-list-3").children('li').length == 0) {
        $('#save_list_edit').removeAttr('disabled')
      }
    }
  }

  /* **************************************************************************
  function: clearSearch
  * User triggered
  * Clears the search field and resets all unselected options to show
  ************************************************************************** */
  clearSearch(e) {
    console.log("WSCL: clearSearch")
    e.preventDefault()

    var value = ''
    $("#snapshot-list-1 li").show().filter(function() {
      return $(this).text().toLowerCase().trim().indexOf(value) == -1;
    }).hide();
    $('#search_option_field').val('')
  }

  /* **************************************************************************
  function: clearOptionGroup
  * Internal triggered
  * Clears the options that were selected and saved, resets button status to accept new options
  ************************************************************************** */
  clearOptionGroup(option_kind) {
    console.log("WSCL: clearOptionGroup")
    var kind = ''
    if(option_kind == 'new') {
      $('#snapshot-list-2').html('')
    }
    if(option_kind == 'edit') { 
      kind = '_edit' 
      $('#snapshot-list-4').html('')
    }
    
    $('#group_label_field' + kind).val('')
    $('#update_group_label_field' + kind).val('')
    $('#group_label' + kind).collapse('hide')
    $('#update_group_label' + kind).hide()
    $('#question_add_group_btn' + kind).removeClass('hidden').addClass('disabled')
    $('#group_label_holder' + kind).removeClass('hidden')
  }

  /* **************************************************************************
  function: createGroupLabel
  * User triggered
  * Gets called when the user clicks "Save" under the Selected Options section.
  ************************************************************************** */
  createGroupLabel(e) {
    console.log("WSCL: createGroupLabel")
    e.preventDefault()

    if((document.getElementById('group_label_field') && document.getElementById('group_label_field').value == '') && (document.getElementById('group_label_field_edit') && document.getElementById('group_label_field_edit').value == '')){
      alert("Please enter a group name")
      return false;
    } else {
      const controllerRef = this 
      var item = e.currentTarget

      var kind = ''
      if(item.dataset.kind == 'edit') {
        kind = '_edit'
      }

      var options = controllerRef.buildOptionGroupList(item.dataset.kind)
      var label = $('#group_label_field' + kind).val()
      var now = Math.floor(Date.now() / 1000)

      $('#group_label_combinations' + kind).append("<tr id='item_" + now + "' class='ui-sortable-handle'>" + controllerRef.buildOptionGroupRow(now, options, label, item.dataset.kind) + "</tr>")
      
      controllerRef.clearOptionGroup(item.dataset.kind)

      controllerRef.checkUsedOptions(item.dataset.kind)
    }
  }

  /* **************************************************************************
  function: deleteOptionList
  * User triggered
  * Deletes the custom list from the database.
  ************************************************************************** */
  deleteOptionList(event) {
    console.log("WSCL: deleteOptionList")
    event.preventDefault()
    const controllerRef = this
    var item = event.currentTarget

    if(confirm(I18n.wellness_snapshot.delete_option_group)){
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: "/wellness_common/" + item.dataset.id + "/delete_option_group",
        method: "post",
        success: function (data) {
          if(data.status == 200) {
            
            $('[data-bs-toggle="tooltip"]').each(function(){
              $(this).tooltip('hide')
            })

            document.getElementById('saved_option_list').reload() // Reloads the turbo-frame
            if(document.getElementById('load_custom_lists') !== null) {
              document.getElementById('load_custom_lists').reload() // Reloads the turbo-frame
            }

            // Need to reload the existing option group IF its show on the main page, while maintaining the currently selected item.
            var fields = ['primary_option_kinds', 'secondary_option_kinds', 'tertiary_option_kinds']
            fields.forEach(function(item){ 
              controllerRef.reloadListGrouping(item)
            });
          } else {
            alert(I18n.wellness_snapshot.cannot_delete_option_group)
          }
        }
      })
    }
  }

  /* **************************************************************************
  function: editOptionGroup
  * User triggered
  * Loads the chosen option into the "Selected Options" panel. Sets button statuses
  ************************************************************************** */
  editOptionGroup(event) {
    console.log("WSCL: editOptionGroup")
    event.preventDefault()
    var item = event.currentTarget
    var item_id = event.currentTarget.dataset.id
    var group_option = $('#options_' + item_id).val().split(':')
    var options = group_option[0]

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: "/wellness_common/find_specific_options",
      method: "post",
      data: { 'options': options },
      success: function (data) {
        if(data.status == 200) {

          if(item.dataset.kind == 'new') {
            $('#snapshot-list-2').html(data.option_list);
            $('#group_label_combinations').find('button').attr('disabled', true)
            $('#update_group_label').show()
            $('#question_add_group_btn').addClass('hidden')
            $('#update_group_label_field').val(group_option[1])
            $('#update_group_label_field').data('id', item_id)
          } 
          else if(item.dataset.kind == 'edit') {
            $('#snapshot-list-4').html(data.option_list);
            $('#group_label_combinations_edit').find('button').attr('disabled', true)
            $('#update_group_label_edit').show()
            $('#question_add_group_btn_edit').addClass('hidden')
            $('#update_group_label_field_edit').val(group_option[1])
            $('#update_group_label_field_edit').data('id', item_id)
            $('#save_list_edit').attr('disabled', true)
          }
          else if(item.dataset.kind == 'view') {
            $('#snapshot-list-6').html(data.option_list);
            // $('#group_label_combinations_edit').find('button').attr('disabled', true)
            $('#update_group_label_view').show()
            // $('#question_add_group_btn_edit').addClass('hidden')
            $('#update_group_label_field_view').val(group_option[1])
            // $('#update_group_label_field_edit').data('id', item_id)
            // $('#save_list_edit').attr('disabled', true)
          }
        }
      }
    })
  }

  /* **************************************************************************
  function: editOptionList
  * User triggered
  * Loads the edit panel for the specific saved grouping
  ************************************************************************** */
  editOptionList(event) {
    console.log("WSCL: editOptionList")
    event.preventDefault()
    const controllerRef = this
    var item = event.currentTarget

    if(item.dataset.delete) {
      if(confirm(I18n.wellness_snapshot.confirm_edit_list)){
        controllerRef.editOptionListShow(item.dataset.id)
      }
    } else {
      controllerRef.editOptionListShow(item.dataset.id)
    }
  }

  /* **************************************************************************
  function: editOptionListShow
  * Internal triggered
  * Loads the selected option list into the "Edit List Item" panel
  ************************************************************************** */
  editOptionListShow(item) {
    console.log("WSCL: editOptionListShow")
    $('#edit_option_group').attr('src', '/wellness_common/' + item + '/edit_option_group' )
    $('#nav-edit-tab').removeClass('hidden')
    var tab = new bootstrap.Tab($('#nav-edit-tab'))
    tab.show()
  }

  /* **************************************************************************
  function: rebuildOptions
  * Internal triggered
  * Reloads the list of options the user can select in "Available Options"
  ************************************************************************** */
  rebuildOptions(option_kind){
    console.log("WSCL: rebuildOptions")
    const controllerRef = this
    var kind = ''
    if(option_kind == 'edit') {
      kind = "_edit"
    }

    // Gather the current list of options selected by the user
    var options = []
    $("#group_label_combinations" + kind + " input[name='wellness_snapshot_group[option_groups][]']").each(function(index, obj){
      obj = $(obj).val().split(':')
      obj = obj[0].split(',')
      $(obj).each(function(i, o){
        options.push(parseInt(o))
      });
    })

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/wellness_common/get_signup_question_options",
      data: { selected_question: $("#question_id").val() },
      success: function (data) {
        if(data.status == 200) {

          // Check if the list of options (data.options) is included in the original list of options (options[]) and if not display it.
          // Displayed options are items that are available for the user to choose
          var display_options = '';
          $(data.options).each(function(index, element){
            if(!options.includes(element.id)) {
              display_options += '<li class="ui-state-default ui-sortable-handle" data-id="' + element.id + '">' + element.name + '</li>';
            }
          });

          if(option_kind == 'new') {
            $('#snapshot-list-1').html(display_options);
          } else if(option_kind == 'edit') {
            $('#snapshot-list-3').html(display_options);
          }

          controllerRef.checkUsedOptions(option_kind)
        }
      }
    });
  }

  /* **************************************************************************
  function: reloadListGrouping
  * Internal triggered
  * Reloads the list of saved lists on the settings panel.
  ************************************************************************** */
  reloadListGrouping(item, reset_chosen_list = false, prefix = '') {
    console.log("WSCL: reloadListGrouping")
    const controllerRef = this
    console.log("      Item:", item)
    console.log("      Reset Chosen List:", reset_chosen_list)
    console.log("      Prefix:", prefix)

    if($("select[name='" + item + "']").length > 0){
      var id = $("#" + prefix + item).attr('data-question-id')
      if(prefix == '' && id === undefined) {
        prefix = "wj_"
        var id = $("#wj_" + item).attr('data-question-id')
      }

      console.log("    ID: ", id)
      
      if(parseInt(id) > 0) {
        var question_level = item.split('_')[0]

        console.log("   QUESTION LEVEL: ", question_level)
        if(reset_chosen_list == true) {
          var chosen_option_list_id = undefined
        } else {
          var chosen_option_list_id = $("#" + prefix + item).val()
        }

        console.log("    Chosen Option List ID:", chosen_option_list_id)

        // Custom callback created - https://www.betterstimulus.com/interaction/callbacks.html
        $(document).trigger('wellness_common_controller.state', function(wellnessCommon) {
          console.log("----- CUSTOM CALLBACK: - WSCL: reloadListGrouping (common) --------------")
          wellnessCommon.getSignUpQuestionOptionList(id, chosen_option_list_id, question_level, item, prefix)
        })
      }
    }
  }

  /* **************************************************************************
  function: removeOptionGroup
  * User triggered
  * Removes the selected option group and reloads those options into the Available Options column.
  ************************************************************************** */
  removeOptionGroup(e) {
    console.log("WSCL: removeOptionGroup")
    const controllerRef = this
    var item = e.currentTarget
    e.preventDefault()

    var ans = confirm(I18n.wellness_snapshot.remove_option_group);
    if(ans) {
      $('#item_' + item.dataset.id).remove()

      // Need to reload the option list with the missing items in place.
      controllerRef.rebuildOptions(item.dataset.kind)
      var kind = ''
      if(item.dataset.kind == 'edit') {
        kind = '_edit'
      }

      if($('#group_label_combinations' + kind).children().length == 0){
        $('#group_label_holder' + kind).addClass('hidden')
      }
    }
  }

  /* **************************************************************************
  function: saveOptionGroup
  * User triggered
  * Saves the option group to the database. Reloads the list of option groups on main screens.
  ************************************************************************** */
  saveOptionGroup(event) {
    console.log("WSCL: saveOptionGroup")
    event.preventDefault()

    // var item = event.currentTarget
    // var prefix = item.dataset.prefix
    // console.log("      Item Prefix:", prefix)

    // // Collects all options and saves it into an array that can be sent through ajax request
    // var values = $("input[name^='wellness_snapshot_group']").serializeArray();
    if(document.getElementById('wellness_snapshot_group_name').value == '') {
      alert("Please enter a group name")
      return false;
    } else {
      const controllerRef = this

      // Collects all options and saves it into an array that can be sent through ajax request
      var values = $("input[name^='wellness_snapshot_group']").serializeArray();

      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        url: $('#new_wellness_snapshot_group').attr('action'),
        data: values,
        success: function (data) {
          if(data.status == 200) {          
            // Reload the turbo frame of saved option list.
            document.getElementById('saved_option_list').reload()
            if(document.getElementById('load_custom_lists') !== null){
              document.getElementById('load_custom_lists').reload()
            }

            $('#group_label_combinations').html('');
            $('#wellness_snapshot_group_name').val('')
            $('#group_label_holder').addClass('hidden')
            $('#nav-existing-tab').click()

            // Need to reload the list of available options.
            document.getElementById('available_options').reload()

            // Need to reload the existing option group IF its show on the main page, while maintaining the currently selected item.
            var fields = ['primary_option_kinds', 'secondary_option_kinds', 'tertiary_option_kinds']
            fields.forEach(function(item){ 
              controllerRef.reloadListGrouping(item)
            });

          } else {
            alert(I18n.wellness_snapshot.cannot_save_option_group)
          }

        //   $('#group_label_combinations').html('');
        //   $('#wellness_snapshot_group_name').val('')
        //   $('#group_label_holder').addClass('hidden')
        //   $('#nav-existing-tab').click()

        //   // Need to reload the list of available options.
        //   document.getElementById('available_options').reload()

        //   // Need to reload the existing option group IF its show on the main page, while maintaining the currently selected item.
        //   var fields = ['primary_option_kinds', 'secondary_option_kinds', 'tertiary_option_kinds']
        //   fields.forEach(function(item){ 
        //     controllerRef.reloadListGrouping(item, false, prefix)
        //   });

        // } else {
        //   alert(I18n.wellness_snapshot.cannot_save_option_group)
        }
      });
    }
  }


  /* **************************************************************************
  function: searchList
  * User triggered
  * Hides the list of available options that do not match the query
  ************************************************************************** */
  searchList(e) {
    console.log("WSCL: searchList")
    e.preventDefault()
    var item = e.currentTarget
    
    var value = item.value.toLowerCase().trim();
    $("#snapshot-list-1 li").show().filter(function() {
      return $(this).text().toLowerCase().trim().indexOf(value) == -1;
    }).hide();
  }

  /* **************************************************************************
  function: setDefaultOptionGroup
  * User triggered
  * Updates the default option group list on the Saved Options List and sets this option on the settings panel
  ************************************************************************** */
  setDefaultOptionGroup(e) {
    console.log("WSCL: setDefaultOptionGroup")
    const controllerRef = this
    var item = e.currentTarget
    e.preventDefault()
    
    var ans = confirm(I18n.wellness_snapshot.change_default_option)
    if(ans) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        url: '/wellness_common/' + item.dataset.questionId + '/update_default_option_group',
        data: { option_group_id: item.dataset.optionGroupId },
        success: function (data) {
          if(data.status == 200) {

            // Need to reload the existing option group IF its show on the main page, while maintaining the currently selected item.
            var fields = ['primary_option_kinds', 'secondary_option_kinds', 'tertiary_option_kinds']
            fields.forEach(function(item){ 
              controllerRef.reloadListGrouping(item, true)
            });

            $('#system_modal_popup').modal('hide')
          }
        }
      })
    }
  }

  /* **************************************************************************
  function: updateGroupLabel
  * User triggered
  * Updates the label of the selected options group in either new panel or edit panel
  ************************************************************************** */
  updateGroupLabel(e) {
    console.log("WSCL: updateGroupLabel")
    e.preventDefault()

    if((document.getElementById('update_group_label_field_edit') && document.getElementById('update_group_label_field_edit').value == '') && (document.getElementById('update_group_label_field') && document.getElementById('update_group_label_field').value == '')){
      alert("Please enter a group name")
      return false;
    } else {
      const controllerRef = this

      var item = e.currentTarget

      var kind = ''
      if(item.dataset.kind == 'edit') {
        kind = '_edit'
      }

      var options = controllerRef.buildOptionGroupList(item.dataset.kind)

      console.log("Options:", options)
      var label = $('#update_group_label_field' + kind).val()
      var id = $('#update_group_label_field' + kind).data('id')

      $('#item_' + id).html(controllerRef.buildOptionGroupRow(id, options, label, item.dataset.kind))

      $('#group_label_combinations' + kind).find('button').attr('disabled', false);
      
      controllerRef.clearOptionGroup(item.dataset.kind)
      controllerRef.checkUsedOptions(item.dataset.kind)
    }
  }

  /* **************************************************************************
  function: updateOptionGroup
  * User triggered
  * Updates the saved options and name for the specific option list item under the "Edit List Item" panel
  ************************************************************************** */
  updateOptionGroup(event) {
    console.log("WSCL: updateOptionGroup")
    event.preventDefault()

    let wrapper = event.currentTarget.closest('form');
    if(wrapper.querySelector("input[id$='wellness_snapshot_group_name']").value == ''){
      alert("Please enter a grouping name")
      return false;
    } else {

      const controllerRef = this
      var item = event.currentTarget

      // Collects all options and saves it into an array that can be sent through ajax request
      var values = $("#group_label_holder_edit input[name^='wellness_snapshot_group']").serializeArray();

      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        url: $(item).attr('action'),
        data: values,
        success: function (data) {
          if(data.status == 200) {
            controllerRef.rebuildOptions('edit')

            // Reload the turbo frame of saved option list.
            document.getElementById('saved_option_list').reload()

            $('#group_label_combinations_edit').html('');
            $('#wellness_snapshot_group_name_edit').val('')
            $('#group_label_holder_edit').addClass('hidden')
            $('#snapshot-list-3').html('')

            $('#nav-edit-tab').addClass('hidden')
            var tab = new bootstrap.Tab($('#nav-existing-tab'))
            tab.show()

            window.reloadSettings = true

          } else {
            alert(I18n.wellness_snapshot.cannot_save_option_group)
          }
        }
      });

      controllerRef.checkUsedOptions()
    }
  }


  /* **************************************************************************
  function: viewOptionList
  * User triggered
  * Loads the edit panel for the specific saved grouping (full admin only)
  ************************************************************************** */
  viewOptionList(event) {
    console.log("WCLS:viewOptionList")
    event.preventDefault()
    const controllerRef = this
    var item = event.currentTarget
    controllerRef.viewOptionListShow(item.dataset.id)
  }


  /* **************************************************************************
  function: viewOptionListShow
  * Internal triggered
  * Loads the selected option list into the "View List Item" panel
  ************************************************************************** */
  viewOptionListShow(item) {
    console.log("WSCL: viewOptionListShow")
    $('#view_option_group').attr('src', '/wellness_common/' + item + '/view_option_group' )
    $('#nav-view-tab').removeClass('hidden')
    var tab = new bootstrap.Tab($('#nav-view-tab'))
    tab.show()
  }


  /* **************************************************************************
  function: verifyTab
  * User triggered
  * Determines if the edit tab should be shown or not.
  ************************************************************************** */
  verifyTab(e) {
    console.log("WSCL: verifyTab")
    var item = e.currentTarget

    if(item.id == 'nav-create-tab' || item.id == 'nav-existing-tab') {
      $('#nav-edit-tab').addClass('hidden');
      $('#nav-view-tab').addClass('hidden');
    }
    else if(item.id = 'nav-edit-tab') {
      $('#nav-view-tab').addClass('hidden');
    }
    else if(item.id = 'nav-view-tab') {
      $('#nav-edit-tab').addClass('hidden');
    }
  }
}
