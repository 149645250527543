import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="peer-reviewed-resources"
export default class extends Controller {
  connect() {
    $('#system_modal_popup').attr('data-bs-focus', "false");

    this.initializeCkEditor()
  }

  initializeCkEditor() {  

    if($("#content").length) {
      var editor = CKEDITOR.instances.content;
      var ckeditorInstance = CKEDITOR.replace("content", {
        height: '300px',
        toolbar: [
          {
            name: 'basicstyles',
            groups: ['basicstyles', 'cleanup'],
            items: ['Source', '-', 'Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat']
          },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
            items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Link', 'Unlink']
          },
          {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
          {name: 'colors', items: ['TextColor', 'BGColor']}
        ]
      });
      
      // http://handsomedogstudio.com/ckeditor-set-default-target-blank
      /* Here we are latching on an event ... in this case, the dialog open event */
      CKEDITOR.on('dialogDefinition', function(ev) {
        try {
          /* this just gets the name of the dialog */
    
          var dialogName = ev.data.name;
          /* this just gets the contents of the opened dialog */
    
          var dialogDefinition = ev.data.definition;
          /* Make sure that the dialog opened is the link plugin ... otherwise do nothing */

          if(dialogName == 'link') {
            
            /* Getting the contents of the Target tab */
            var informationTab = dialogDefinition.getContents('target');
          
            /* Getting the contents of the dropdown field "Target" so we can set it */
            var targetField = informationTab.get('linkTargetType');
          
            /* Now that we have the field, we just set the default to _blank. A good modification would be to check the value of the URL field and if the field does not start with "mailto:" or a relative path, then set the value to "_blank" */
            targetField['default'] = '_blank';
          
          }
        } catch(exception) {
          alert('Error ' + ev.message);
        }
      });
    }
  }
}
