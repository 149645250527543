import { Controller } from "@hotwired/stimulus"
import ValidateFormController from './validate_form_controller.js'

// Connects to data-controller="certificate"
export default class extends ValidateFormController {
  formElement = undefined;
  processingCertificateDownload = false;

  connect() {
    this.element.addEventListener('turbo:submit-start', this.validateCertificateForm.bind(this));
    this.element.addEventListener('submit', this.validateCertificateForm.bind(this));
    this.element.noValidate = false;
    this.element.addEventListener('change', this.checkChangedInputs.bind(this));

    this.formElement = this.element;
  }

  hidePreviewConfirmation(event) {
    event.preventDefault();
    $("#email-transcript-organization-preview-div").slideUp();
    $("#email-transcript-organization-form-div").slideDown();
  }

  showPreviewConfirmation(event) {
    event.preventDefault();
    let valid = true;
    if (!$("#name").val()) {
      valid = false;
    }
    if (!$(".transcript-checkbox:checked").length) {
      valid = false;
    }
    if(valid) {
      clearJsAlerts();

      var versionSelectedImage = $("input[name='wbi_version_id']:checked").data('image');
      $('.transcript-preview-holder').css('background-image', 'url(' + versionSelectedImage + ')');
      var nameStr = $("#name").val();
      var dateStr = '';
      $(".transcript-checkbox:checked").each(function(){
        if(dateStr != '')
        {
          dateStr += ", ";
        }
        dateStr += $(this).data("preview");
      });
      $("#transcript-preview-name").html(nameStr);
      $("#transcript-preview-dates").html(dateStr);
      $("#email-transcript-organization-form-div").slideUp();
      $("#email-transcript-organization-preview-div").slideDown();
    } else {
      doJsAlert('<div class="alert alert-danger">'+i18n_string("certificate.enter_your_name_and_select_date", "Enter your name and select at least one date to continue.")+'</div>');
    }
  }

  submitClick(event) {
    let kind = $(event.currentTarget).data("kind");
    if (kind) {
      $("#delivery_kind").val(kind);
    }
  }

  validateCertificateForm(event) {
    let isValid = true;
    let errors = [];

    $('#message-container').html('')

    if (this.processingCertificateDownload){
      event.preventDefault();
      return false;
    }

    this.processingCertificateDownload = true;

    let passedStandardValidation = super.validateForm(event);

    if (passedStandardValidation) {
      // Make sure at least one date is selected
      if ($(".transcript-checkbox:checked").length == 0) {
        isValid = false;
        errors.push(i18n_string("certificate.please_select_at_least_one_date","Please select at least one date"));
      }
      if (!isValid){
        event.preventDefault();
        doJsAlert('<div class="alert alert-danger">'+removeDuplicates(errors).join('<br />')+'</div>');
      }
    }

    if (isValid && passedStandardValidation) {
      let delivery_kind = $("#delivery_kind").val();
      // Disable buttons
      $(this.formElement).find("button").each(function(){
        this.disabled = true;
      });
      // Show processing message
      $("#certificate_processing_message_download").hide();
      $("#certificate_processing_message_email").hide();
      if (delivery_kind == "download") {
        $("#certificate_processing_message_download").show();
      } else {
        $("#certificate_processing_message_email").show();
      }
      $("#certificate_processing_message_box").show();
      let controllerRef = this;
      if (delivery_kind == "download") {
        setTimeout(function () {
          // Enable buttons
          $(controllerRef.formElement).find("button").each(function(){
            this.disabled = false;
          });
          // hide processing messages
          $("#certificate_processing_message_box").hide();
          controllerRef.processingCertificateDownload = false;
        }, 1800);
      }
    } else {
      this.processingCertificateDownload = false;
    }

    return isValid && passedStandardValidation;
  }

  versionClick(event){
    let wbi_version_id = $(event.currentTarget).data("wbi_version_id");
    // ' %>" id="transcript-perm-checkboxes-
    let $thisContainer = $("#transcript-perm-checkboxes-"+wbi_version_id);
    if ($thisContainer.hasClass("faded-container")) {
      // Changed
      $(".transcript-perm-checkboxes").not(".faded-container").each(function(){
        $(this).find("input").each(function(){
          this.checked = false;
          this.disabled = true;
        });
        $(this).addClass("faded-container");
      });
      $thisContainer.find("input").each(function(){
        this.disabled = false;
      });
      $thisContainer.removeClass("faded-container");

    }

  }
}
