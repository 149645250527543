import { Controller } from "@hotwired/stimulus"
import Clipboard from "../../../public/js/clipboard.min"

// Connects to data-controller="admin-toolkit"
export default class extends Controller {
  connect() {
  }

  copySource(e) {
    e.preventDefault()
    var html_content = $(".email-content").html();
    var copy_success = copy_to_clipboard(html_content);
    if(copy_success) {
      $(".copied-message").show().delay(3000).fadeOut('fast');
    }
  }

  copySourceLoad(e) {
    e.preventDefault()
    var item = e.currentTarget;

    $.ajax({
      url: "/admin_toolkit/" + item.dataset.template + "/load_email", 
      type: 'GET', 
      success(data) {
        if(data.status == 200) {
          $('body').prepend('<div id="copy-element" class="hidden">' + data.source_code + '</div>');

          var content = $('#copy-element').html();
          var copy_success = copy_to_clipboard(content);
          if(copy_success) {
            $(".copied-message").show().delay(3000).fadeOut('fast');
          }

          $('#copy-element').remove();

          alert("Source code copied")
        } else {
          alert("Cannot copy source code.")
        }
      }
    });
  }

  copyText(e) {
    e.preventDefault()
    var item = e.currentTarget;

    $.ajax({
      url: "/admin_toolkit/" + item.dataset.template + "/load_email", 
      type: 'GET', 
      success(data) {
        if(data.status == 200) {
          $('body').prepend('<div id="copy-element" class="hidden">' + data.source_code + '</div>');

          var content = $('#copy-element').text();
          var copy_success = copy_to_clipboard(content);
          if(copy_success) {
            $(".copied-message").show().delay(3000).fadeOut('fast');
          }

          $('#copy-element').remove();

          alert("Email text copied")
        } else {
          alert("Cannot copy email")
        }
      }
    });
  }

  downloadQRCode(event) {
    console.log("Download QR code")
    event.preventDefault();
    var url = document.getElementById('custom_signup_url_qr_code').value
    var kind = event.currentTarget.getAttribute('data-kind')

    document.location = '/administrator/generate_qr_code?signup_url=' + encodeURIComponent(url) + '&download=1&kind=' + kind
  }

  loadPreview(e) {
    e.preventDefault()
  }


  scrollTo(e) {
    e.preventDefault()
    var item = e.currentTarget.dataset.href
    document.getElementById(item).scrollIntoView()
  }
}
