import { Controller } from "@hotwired/stimulus"
import ValidateFormController from "./validate_form_controller"

// Connects to data-controller="signup-questions"
export default class extends ValidateFormController {

  static targets = ["options", "template"]
  connect() {
    setTimeout(function(){
      this.init_option_sortable();
    }.bind(this),750);
    this.init_question_sortable();

    let edit_form = document.getElementById("edit_custom_question_form");
    if (edit_form) {
      edit_form.addEventListener('change', this.checkChangedInputs);
    }
    if ($("#question_display_control").length) {
      this.display_control_change_process($("#question_display_control").val());
    }
    if ($(".mapping-select").length) {
      $(".mapping-select").each(function () {
        $(this).css('width', '100%');
        var options = {};
        var parentElement = $(this).closest(".select2-wrapper-div");
        if (parentElement.length) {
          options.dropdownParent = parentElement;
        }
        options.theme = "bootstrap-5",
        $(this).select2(options);
      });
    }
    this.diyInit();
  }

  add_bulk_options(event) {
    event.preventDefault();
    var data = $('#bulk_options_textarea').val().split(/\r?\n/);
    for (let i = 0; i < data.length; i++) {
      var new_option_text = data[i].trim();
      if (new_option_text != "") {
        this.add_option_to_table(new_option_text);
      }
    }

    $('#bulk_options_textarea').val('');
    $('#bulk_question_option_modal').modal("hide");
  }

  add_option_click(event) {
    event.preventDefault();
    this.add_option_to_table('');
  }

  add_option_to_table(option_name) {
    option_name = (typeof option_name !== 'undefined') ?  option_name : "";
    var next_order = $('#option_table_body tr').length + 2;
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime());
    content = content.replace("TEMPLATE_NAME", option_name);
    content = content.replace("999999", next_order);
    this.optionsTarget.insertAdjacentHTML('beforeend', content);
    this.fix_table_widths();
  }

  add_diy_mapped_option_to_table(option_name, option_id) {
    option_name = (typeof option_name !== 'undefined') ?  option_name : "";
    var next_order = $('#option_table_body tr').length + 2;
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime());
    content = content.replace("TEMPLATE_NAME", option_name);
    content = content.replace("999999", next_order);

    content = content.replace('form-control form-control-sm option-name-input', 'form-control form-control-sm option-name-input');
    this.optionsTarget.insertAdjacentHTML('beforeend', content);
    this.fix_table_widths();
  }

  at_least_one_unchecked(e) {
    // Make sure at least one is unchecked
    var at_least_one = false;
    $(".hide-option-checkbox").each(function () {
      if (!this.checked) {
        at_least_one = true;
      }
    });
    if (!at_least_one) {
      e.preventDefault();
      alert(i18n_string("signup_questions.you_must_have_at_least_one_option", "You must leave at least one option."));
      return false;
    }
    return true;
  }

  confirm_delete (event){
    let confirm_text = i18n_string("process_improvement.are_you_sure_you_want_to_delete_this_question", "Are you sure you want to delete this question?");
    confirm_text += " "+i18n_string("signup_questions.you_will_not_be_able_to_access_the_report", "You will not be able to access the report data after you delete this question.");
    if (!confirm(confirm_text)) {
      event.preventDefault();
    }
  }

  default_country_change(event) {
    let new_val = $(event.target).val();
    let closest_form = $(event.target).closest('form');
    if (new_val == "") {
      closest_form.find(".input-state-question-id").val("");
      closest_form.find(".input-state").val("");
      closest_form.find(".default-state-div").hide();
    } else {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: "/signup-questions/diy_get_state_options?country="+new_val,
        type: "GET",
        success: function(data) {
          if(data.status == 200) {
            // console.log(data.state_question_id);
            if (data.state_question_id && data.state_question_id > 0 && data.option_list) {
              closest_form.find(".input-state-question-id").val(data.state_question_id);
              closest_form.find(".input-state").html("");
              for (let i = 0; i<data.option_list.length; i++){
                let opt = document.createElement('option');
                opt.value = data.option_list[i][1];
                opt.innerHTML = data.option_list[i][0];
                closest_form.find(".input-state").append(opt);
              }
              closest_form.find(".default-state-div").show();
            } else {
              closest_form.find(".input-state-question-id").val("");
              closest_form.find(".input-state").val("");
              closest_form.find(".default-state-div").hide();
            }
          }
        }
      });
    }
  }

  display_control_change(event) {
    this.display_control_change_process($(event.currentTarget).val());
  }

  display_control_change_process(controlValue) {
    if(controlValue == "heading") {
      $(".hide-for-heading-type").hide();
      $("#question_report_name").val("Heading");
      $("#question_archived").val(0);
    } else {
      $(".hide-for-heading-type").show();
    }
  }

  diyInit() {
    if (document.getElementById('demographic_question_list')) {
      let dql = document.getElementById('demographic_question_list');
      if (!dql.dataset.attachedModalCloseListener) {
        const myModalEl = document.getElementById('system_modal_popup')
        myModalEl.addEventListener('hidden.bs.modal', event => {
          let turboElement = document.getElementById('demographic_question_list');
          turboElement.src = turboElement.dataset.src;
        });
        dql.dataset.attachedModalCloseListener = 'yes';
      }
    }
    if (document.getElementById('close_modal_after_turbo_frame_load')) {
      setTimeout(function(){
        $("#system_modal_popup").modal("hide");
      },75)

    }
  }

  diyToggleStatus(event) {
    let $element = $(event.currentTarget);
    let clickedValue = event.currentTarget.value;
    let name = event.currentTarget.name;
    let matches = name.match(/\[(.*?)\]/);
    let id = matches[1];
    if (clickedValue != $("#status_group_"+id).data("last_status")) {
      console.log("new value="+clickedValue);
      // Do ajax call to change status
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        url: "/signup-questions/"+id+"/diy-status",
        data: { new_status: clickedValue },
        success: function (data) {
          if (data && data.new_status) {
            if (data.new_status == 'hide') {
              // Nothing right now
            } else if (data.new_status == 'display') {
              // Nothing right now
            }

          }
          if (data && data.load_options_for_question_id) {
            $("#question_row_"+id).find(".edit-custom-options").attr("href", "/signup-questions/"+data.load_options_for_question_id+"/diy-edit-options-modal");
            $("#question_row_"+id).find(".edit-custom-options")[0].click();
          }
        }
      });
      $("#status_group_"+id).data("last_status",clickedValue)
    }
    $element.blur();
  }

  do_question_order_auto_save(quest_order) {
    document.auto_save_question_order_timeout = undefined;
    $("#autosave_quest_order_message").html('<strong class="text-info"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> '+i18n_string("signup_questions.saving_sort_order", "Saving sort order...")+'</strong>');
    $("#autosave_quest_order_message").fadeIn();
    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/signup-questions/save-question-order",
      data: {question_order: quest_order},
      success: function (data) {
        $("#autosave_quest_order_message").html('<strong class="text-success"><i class="fa fa-check" aria-hidden="true"></i> '+i18n_string("signup_questions.sort_order_saved", "Sort order saved")+'</strong>');
        setTimeout(function () {
          $("#autosave_quest_order_message").fadeOut(function () {
            $(this).html("");
          });
        }, 3500);
      }
    });
  }

  fix_table_widths() {
    $("#option_table_body td").each(function () {
      if (!$(this).data("fixed-width") && !$(this).hasClass("no-fixed-size")) {
        $(this).css("width", $(this).width());
        $(this).data("fixed-width", 1);
      }
    });
  }

  init_option_sortable(){
    if ($("#option_table_body").length) {
      this.fix_table_widths();
      $("#option_table_body").sortable({
        update: function (event, ui) {
          $('#option_table_body tr').each(function (idx) {
            var inputField = $(this).find(".option-order-field");
            $(inputField).val(idx + 1);
          });
        }
      });
    }
  }

  init_question_sortable() {
    let controllerRef = this;
    document.auto_save_question_order_timeout = undefined;
    $("#question_table_body").sortable({
      update: function (event, ui) {
        var quest_order = {};
        $('#question_table_body tr.allow-sort').each(function (idx) {
          quest_order[idx + 1] = $(this).data("quest_id");
        });
        if (document.auto_save_question_order_timeout) {
          clearTimeout(document.auto_save_question_order_timeout);
        }
        document.auto_save_question_order_timeout = setTimeout(function () {
          controllerRef.do_question_order_auto_save(quest_order);
        }, 100);
      },
      items: 'tr.allow-sort'
    });
    $("#question_table_body td").each(function () {
      if (!$(this).data("fixed-width")) {
        $(this).css("width", $(this).width());
        $(this).data("fixed-width", 1);
      }
    });

    $("#question_table_body_assess").sortable({
      update: function (event, ui) {
        var quest_order = {};
        $('#question_table_body_assess tr.allow-sort').each(function (idx) {
          quest_order[idx + 1] = $(this).data("quest_id");
        });
        if (document.auto_save_question_order_timeout) {
          clearTimeout(document.auto_save_question_order_timeout);
        }
        document.auto_save_question_order_timeout = setTimeout(function () {
          controllerRef.do_question_order_auto_save(quest_order);
        }, 100);
      },
      items: 'tr.allow-sort'
    });
    $("#question_table_body_assess td").each(function () {
      if (!$(this).data("fixed-width")) {
        $(this).css("width", $(this).width());
        $(this).data("fixed-width", 1);
      }
    });
  }

  map_checkbox_clicked(event) {
    if (event.currentTarget.checked) {
      $("#quest_mapping_questions").removeClass("hidden");
    } else {
      $("#quest_mapping_questions").addClass("hidden");
      $("#mapped_question_id").val("");
    }
  }

  question_location_change(event) {
    var current_value = $(event.currentTarget).val();
    if (current_value == "signup") {
      $("#quest_display_control_div").hide();
      $("#question_display_control").val("select");
    } else {
      $("#quest_display_control_div").show();
    }
  }

  row_checkbox_click(e) {
    var target = $(e.target);
    if (target.is("input")) {
      return;
    }
    if (this.at_least_one_unchecked(e)) {
      $(e.target).closest('tr').find('input').click();
    }
  }

  validate_question_form(event) {
    let passed_normal_validation = super.validateForm(event);
    if (passed_normal_validation) {
      let errormsg = "";
      let question_type = $("#question_display_control").val();
      if(question_type == "select" || question_type == "radio") {
        // option_name_new/option_name
        let opt_count = 0;
        $(".option-name-input").each(function () {
          if ($(this).val() != "") {
            opt_count++;
          }
        });
        if (opt_count <= 1) {
          errormsg += i18n_string("process_improvement.you_must_have_at_least_two_options","You must have at least two options")+"<br />";
        }
      }
      if (errormsg != "") {
        event.preventDefault();
        errormsg = errormsg.substring(0, errormsg.length - 6);
        doJsAlert('<div class="alert alert-danger">'+errormsg+'</div>', 'message-container');
        return false;
      }
    }
  }
}
