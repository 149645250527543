import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="verify-account"
export default class extends Controller {
  initialize() {
    document.requireEmailVerification = false;
    document.requirePhoneVerification = false;
    document.emailVerificationPassed = false;
    document.phoneVerificationPassed = false;
    document.emailToVerify = '';
    document.phoneToVerify = '';
    // This is called once per controller
    // function(modalId, title, body, footer, placementSelector, modalClasses, modalDialogClasses)
    let close_text = i18n_string("general.close", "Close");
    let loading_text = i18n_string("general.loading", "Loading");
    setupBootstrapModal("verify-modal", loading_text, "", '<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">'+close_text+'</button>', 'body', '');
  }
  connect() {
    this.verify_code_callback = undefined; // Classes that extend this can set this callback
    const controllerRef = this;
    let datakind = '';
    if (this.element.dataset && this.element.dataset.kind) {
      datakind = this.element.dataset.kind
    }
    // Handle single instance like:  data-kind="email" data-controller="verify-account"
    if (datakind == "email" || datakind == "phone") {
      this.element.addEventListener('click', async function (event) {
        event.preventDefault();

        let requestBody = {kind: datakind};
        controllerRef.doLoadVerification(requestBody);

      });
    }

    // Handle verification during email/sms change: data-kind="formchange" data-controller="verify-account" -- Would attach to form.submit and check for email/sms changes
    //console.log("datakind="+datakind);
    if (datakind == "formchange") {
      //console.log("Adding submit listener="+this.element.tagName);
      //this.element.addEventListener('turbo:submit-start', this.checkEmailPhoneChanges);
      this.element.addEventListener('submit', this.checkEmailPhoneChanges.bind(this));
    }

    // Handler for verification
    document.addEventListener( "click", async function(event){
      if (event.target.id == "verify-button") {
        event.preventDefault();
        event.stopImmediatePropagation();
        let verifyCodeInput = document.getElementById("verify-code");
        let verifyKindInput = document.getElementById("verify-kind");
        if (verifyCodeInput && verifyKindInput && verifyCodeInput.value != "") {
          let requestBody = {kind: verifyKindInput.value, code: verifyCodeInput.value};
          const request = new FetchRequest("post", '/account/process-verification', { responseKind: "json", body: requestBody});
          const response = await request.perform();
          if (response.ok) {
            response.json.then(function(data){
              if (typeof controllerRef.verify_code_callback === 'function') {
                controllerRef.verify_code_callback(data.status == 'success');
              }
              if (data.status == 'success') {
                if(data.kind == 'phone'){
                  $('#verify-phone-holder').html('<p class="alert alert-sm alert-success">'+data.message+'</p>');
                  $("#verify-account-alert a[data-kind='phone']").hide();
                  if (document.requirePhoneVerification) {
                    document.phoneVerificationPassed = true;
                  }
                } else if(data.kind == 'email'){
                  $('#verify-email-holder').html('<p class="alert alert-sm alert-success">'+data.message+'</p>');
                  $("#verify-account-alert a[data-kind='email']").hide();
                  if (document.requireEmailVerification) {
                    document.emailVerificationPassed = true;
                  }
                }
                if(!$("#verify-account-alert a[data-kind='email']").is(":visible") && !$("#verify-account-alert a[data-kind='phone']").is(":visible")) {
                  $("#verify-account-alert").hide();
                  $("#verify-account-alert-why").removeClass("d-block").hide();
                }
                $('#verify-modal').modal('hide');
                if (document.requirePhoneVerification && !document.phoneVerificationPassed) {
                  //console.log("--------------------Do phone verification :)))))");
                  // Delay to allow closing of old modal
                  setTimeout(function(){
                    controllerRef.doChangeVerification("phone");
                  }, 300);
                } else if ((!document.requirePhoneVerification || document.phoneVerificationPassed) && (!document.requireEmailVerification || document.emailVerificationPassed)) {
                  // Submit account update form
                  $("#account-security-form").submit();
                }
              } else {
                let errorDiv = document.getElementById("verify-modal-error-message");
                if (errorDiv){
                  errorDiv.innerHTML = '<div class="alert alert-danger">'+i18n_string("sessions.verify_login.incorrect_verification_code","Incorrect verification code")+'</div>'
                }
              }
            });
          }

        }
      }

    } );
  }

  checkEmailPhoneChanges(event) {
    //console.log("Processing1")
    //event.preventDefault();
    if ((document.requirePhoneVerification && !document.phoneVerificationPassed) || (document.requireEmailVerification && !document.emailVerificationPassed) || (!document.requirePhoneVerification && !document.requireEmailVerification)) {
      //console.log("Processing2");
      // Do verification if email or phone number changed
      document.requireEmailVerification = false;
      document.requirePhoneVerification = false;
      document.emailVerificationPassed = false;
      document.phoneVerificationPassed = false;
      document.emailToVerify = '';
      document.phoneToVerify = '';
      var emailToCheck = $("#account_email_address").val();
      var phoneCountryCode = $('.selected-dial-code').html();
      var phoneNumber = $('#user_sms_phone').val();
      if (phoneCountryCode) {
        phoneCountryCode = phoneCountryCode.replace(/[^0-9]/g, '');
      }
      if (phoneNumber) {
        phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
      }
      var phoneToCheck = phoneCountryCode+phoneNumber;
      //console.log("Processing3a:emailToCheck="+emailToCheck+"--original_email_address="+$("#original_email_address").val());
      if (emailToCheck != $("#original_email_address").val()) {
        //console.log("Processing3:emailchange");
        document.requireEmailVerification = true;
        document.emailToVerify = emailToCheck;
      }
      if (phoneToCheck != $("#original_phone_number").val() && "+"+phoneToCheck != $("#original_phone_number").val() && phoneNumber.length > 0) {
        //console.log("Processing3:phonechange");
        document.requirePhoneVerification = true;
        document.phoneToVerify = phoneToCheck;
      }

      if (document.requireEmailVerification) {
        this.doChangeVerification('email');
        event.preventDefault();
        return false;
      } else if (document.requirePhoneVerification) {
        this.doChangeVerification('phone');
        event.preventDefault();
        return false;
      }
    }
  }

  doChangeVerification(kind) {
    // Verify on email/phone number change
    var post_data = {
      kind: kind
    };
    if (kind == "email") {
      post_data.email = document.emailToVerify;
    } else if (kind == "phone") {
      post_data.phone = document.phoneToVerify;
    }

    this.doLoadVerification(post_data);
  }

  async doLoadVerification(requestBody) {
    //console.dir("doLoadVerification:"+JSON.stringify(requestBody));
    let modalDiv = document.getElementById("verify-modal");
    if (!modalDiv){ return; }
    //console.dir("doLoadVerification:1");
    modalDiv.querySelector(".modal-title").innerHTML = i18n_string("general.loading", "Loading");
    modalDiv.querySelector(".modal-body").innerHTML = '<div class="text-center mt-5 mb-5"><em class="fal fa-circle-notch fa-spin fa-3x"></em></div>';
    $("#verify-modal").modal("show");
    //console.dir("doLoadVerification:2");

    const request = new FetchRequest("post", '/account/load-verification', {responseKind: "json", body: requestBody});
    const response = await request.perform();
    if (response.ok) {
      response.json.then(function (data) {
        modalDiv.querySelector(".modal-title").innerHTML = data.title;
        if (data.form) {
          modalDiv.querySelector(".modal-body").innerHTML = data.form;
        } else if (data.error) {
          modalDiv.querySelector(".modal-body").innerHTML = data.error;
        } else {
          modalDiv.querySelector(".modal-body").innerHTML = i18n_string("users.account.response_did_not_include_form","Response did not include form. Please try again.");
        }
      });
    }
  }

  updatePasswordCheckbox(event){
    if(event.currentTarget.checked) {
      $("#update-password-div").removeClass("hidden");
    } else {
      $("#update-password-div").addClass("hidden");
      $("#current_password").val("");
      $("#user_password").val("");
      $("#user_password_confirmation").val("");
    }
  }
}
