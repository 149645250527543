import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="campaign-manager"
export default class extends Controller {
  ckeditorInstance = undefined;
  
  assessBySegment(e) {

    $('#assessments_by_segment').html('<p class="text-center py-5 my-5"><em class="far fa-spin fa-spinner fa-5x"></em></p>')
    $('#assessments_by_segment_chart').html('')

    var kind = ''
    var html = ''
    var data = new google.visualization.DataTable();

    if(e.currentTarget.name == 'commit'){
      if($('#label-assessors').hasClass('btn-primary')) {
        kind = 'assessors'
      } else {
        kind = 'alphabetical'
      }
    } else {

      var item = e.currentTarget
    
      if(item.dataset.kind == 'assessors') {
        $('#label-assessors').addClass('btn-primary').removeClass('btn-outline-primary')
        $('#label-alphabetical').addClass('btn-outline-primary').removeClass('btn-primary')
      } else {
        $('#label-assessors').addClass('btn-outline-primary').removeClass('btn-primary')
        $('#label-alphabetical').addClass('btn-primary').removeClass('btn-outline-primary')
      }

      kind = item.dataset.kind
    }

    data.addColumn("string", i18n_string("signup_questions.option", 'Option'))
    data.addColumn("number", i18n_string("campaign_manager.total", 'Total'))
    data.addColumn({ type: "string", role: "annotation" })
    data.addColumn({role: "tooltip", type: "string", p: { html: true, role: "tooltip"}})

    var arr = [];

    $.get("/campaigns/" + $('#campaign_id').val() + "/get_assess_by_segment_options", { kind: kind, primary_option_id: $('#option_list_primary').val(),  secondary_option_id: $('#option_list_secondary').val()}, function(content){
      if(content.status == 200) {
        
        var total = 0
        $.each(content.data, function(k, v){
          total += v[1]
          html += '<tr><td>' + v[0] + '</td><td>' + v[1] + '</td></tr>'
          arr.push([v[0], v[1], v[1] + " ", "<strong>" + v[0] + "</strong><br />Total: " + v[1]])
        })

        data.addRows(arr);

        var options = {
          annotations: {
            alwaysOutside: false,
            textStyle: {
              fontSize: 12
            }
          },
          bar: {
            groupWidth: "70%"
          },
          chartArea: {
            height: arr.length * 40,
            left: 175,
            top: 40,
            width: "100%",
          },
          height: arr.length * 40 + 75,
          legend: 'none',
          colors: ['#4285f4'],
          hAxis: {
            textStyle: {
              fontSize: 12
            }
          },
          vAxis: {
            textStyle: {
              fontSize: 12
            },
            viewWindowMode: "pretty"
          },
          tooltip: {
            isHtml: true
          }
        };
        
        if(total > 0) {
          var chart = new google.visualization.BarChart(document.getElementById('assessments_by_segment'));
          chart.draw(data, options);
        } else {
          $('#assessments_by_segment').html("")
        }

        let total_participants_text = i18n_string("campaign_manager.total_participants", "Total Participants")
        html += '<tr><td><strong>'+total_participants_text+'</strong></td><td><strong>' + total + '</strong></td></tr>'
        $('#total_participants').html('<strong>'+total_participants_text+': ' + total + '</strong>');
        $('#assessments_by_segment_chart').html('<thead><th width="75%">Group</th><th width="25%">'+i18n_string("campaign_manager.number_of_participants", "# of Participants")+'</th></thead><tbody>' + html + '</tbody>')
        
      } else {
        alert(i18n_string("general.error_loading_data_please_try_again", "Error loading data. Please try again."))
      }
    });
  }

  connect() {
    const controllerRef = this

    if( $('.ckeditor').length ){
      this.initializeCkEditor()
    }

    if( document.getElementById('comparison_group_id') !== null ){

      var item = document.getElementById('comparison_group_id')
      this.loadComparisonGroups( item.dataset.id, item.value)
    }

    this.initializeSelect2(".select2", {})
    let placeholder_text = i18n_string("campaign_manager.select_options","Select Options")
    let search_text = i18n_string("campaign_manager.search_options","Search Options")
    this.initializeMultiselect(".multiselect", {columns: 2, search: true, selectAll: true, texts: { placeholder: placeholder_text, search: search_text}})

    // this.showModal()
  }

  calculateDate() {
    $.get('/campaigns/calculate_end_date', { start_date: $('#campaign_start_date').val(), weeks: $('#campaign_number_of_weeks').val()}, function(data){
      $('#campaign_end_date').attr('min', $('#campaign_start_date').val());
      $('#campaign_end_date').val(data.date);
    })
  }

  calculateTotal() {
    var total = 0;
    $.each( $('.option-list-input'), function(k, v){
      if(v.value != '') {
        total = total + parseInt(v.value);
      }
    });

    $('#invite_total').val(total);
  }

  chooseQuestion1(event) {
    var val = event.target.value
    if(val != ""){
      $.get("/campaigns/get_question_options", { id: val, question: 1 }, function(data){
        if(data.status == 200) {
          $('#question_1_options').html(data.html)
          $("#question_1_1").select2({width:'100%'})

          $.each($("#question_2 option"), function(){
            $(this).removeClass("hidden");
          });

          $('#question_2 option[value="' + val + '"]').addClass("hidden");
          $('#question_2').prop('selectedIndex', 0);

          $('#question_2_options').html('');
        }
      });
      $('#question_2_category, #question_1_options').removeClass('hidden');
      $('#new-comparative-groups-options').collapse('show');
    } else {
      $('#question_2_category, #question_1_options').addClass('hidden');
      $('#new-comparative-groups-options').collapse('hide');
      $('#question_2_options').html('');
      $('#question_1_options').html('')
    }
  }

  chooseQuestion2(event) {
    if(event.target.value == '') {
      $('#question_2_options').addClass('hidden')
      $('#question_2_options').html('');
    } else {
      $.get("/campaigns/get_question_options", { id: event.target.value, question: 2 }, function(data){
        if(data.status == 200) {
          $('#question_2_options').html(data.html);
          $("#question_2_1").select2({width:'100%'});
          $('#question_2_options').removeClass('hidden');
        }
      })
    }
    $('#new-comparative-groups-options').collapse('show');
  }

  clearAll(e) {
    var item = e.currentTarget
    $("#" + $(item).data('field') + " > option").prop("selected", false);
    $("#" + $(item).data('field') ).trigger("change");
  }

  selectAll(e) {
    var item = e.currentTarget
    $("#" + $(item).data('field') + " > option").prop("selected", true);
    $("#" + $(item).data('field') ).trigger("change");
  }

  closeNewOption() {
    $('#add_new_option').collapse("hide");
    $('#question_1_1_new').prop("selectedIndex", 0);
    $('#question_2_1_new').prop("selectedIndex", 0);
    $('#question_invites_new').val('');
  }

  createComparisonGroups(event) {
    event.preventDefault()
    $('#saved-comparative-groups').collapse("show");
    $('#new-comparative-groups').collapse('hide');

    $.get("/campaigns/create_comparison_groups", { "question_1_id": $('#question_1').val(), "question_1": $('#question_1_1').val(), "question_2_id": $('#question_2').val(), "question_2": $("#question_2_1").val() }, function(data) {
      if(data.status == 200) {
        $('#set-invitees').html(data.html);
      }
    });
  }

  deleteCampaign(event) {
    var ans = confirm(i18n_string("campaign_manager.are_you_sure_you_want_to_delete_this_campaign", "Are you sure you want to delete this campaign?"))
    if(!ans) {
      event.preventDefault()
    }
  }

  deleteNewRow(event) {
    let controllerRef = this
    event.preventDefault();
    controllerRef.closeNewOption();
  }

  deleteRow(event) {
    let controllerRef = this

    event.preventDefault();
    var item = event.target
    var ans = confirm(i18n_string("campaign_manager.are_you_sure_you_want_to_delete_this_row", "Are you sure you want to delete this row?"));
    if(ans) {
      if(item.classList.contains("fa-trash")){
        item = item.parentElement
      }

      var elem = document.getElementById(item.dataset.id)
      $(elem).attr('style', 'display: none !important');

      elem = document.getElementById('deleted_' + item.dataset.id)
      $(elem).val(1)

      elem = document.getElementById('invitees_' + item.dataset.id)
      $(elem).val(0)

      controllerRef.calculateTotal();
    }
  }

  initializeMultiselect(selector, options) {
    $(selector).each(function () {
      $(this).multiselect(options);
    });
  }

  initializeSelect2(selector, options) {
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }
  
  initializeCkEditor() {
    // Initialize CKEditor    
    var editor = CKEDITOR.instances.resource_content;
    if(!editor) {
      this.ckeditorInstance = CKEDITOR.replace("customer_sub_account_campaign_help", {
        height: '300px',
        toolbar: [
          {
            name: 'basicstyles',
            groups: ['basicstyles', 'cleanup'],
            items: ['Bold', 'Italic','RemoveFormat','-']
          },
          { name: 'links', items: ['Link','Unlink'] },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
            items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
          },
          {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
          {name: 'colors', items: ['TextColor', 'BGColor']}
        ]
      });

      // http://handsomedogstudio.com/ckeditor-set-default-target-blank
      /* Here we are latching on an event ... in this case, the dialog open event */
      CKEDITOR.on('dialogDefinition', function(ev) {
        try {
          /* this just gets the name of the dialog */
    
          var dialogName = ev.data.name;
          /* this just gets the contents of the opened dialog */
    
          var dialogDefinition = ev.data.definition;
          /* Make sure that the dialog opened is the link plugin ... otherwise do nothing */

          if(dialogName == 'link') {
            
            /* Getting the contents of the Target tab */
            var informationTab = dialogDefinition.getContents('target');
          
            /* Getting the contents of the dropdown field "Target" so we can set it */
            var targetField = informationTab.get('linkTargetType');
          
            /* Now that we have the field, we just set the default to _blank. A good modification would be to check the value of the URL field and if the field does not start with "mailto:" or a relative path, then set the value to "_blank" */
            targetField['default'] = '_blank';
          
          }
        } catch(exception) {
          alert('Error ' + ev.message);
        }
      });
    }
  }

  insertNewRow(event) {

    let controllerRef = this

    event.preventDefault();
    var grouping_id = $("#all_option_list > li").first().attr('id').split("_")[1];
    var d = new Date();
    var now = d.getTime();
    var name = $('#question_1_1_new option:selected').text();
    var id = "grouping_" + grouping_id + "_" + $('#question_1_1_new').val();
    if( $('#question_2_1_new').val() ) {
      id += "-" + $('#question_2_1_new').val();
      name += " - " + $('#question_2_1_new option:selected').text();
    }

    var li = '<li class="list-group-item d-flex justify-content-between border" id="item_' + now + '" >';
    li += '<input type="hidden" name="leadership_group_id[]" value="" />';
    li += '<input type="hidden" name="question_slug[]" value="' + id + '" />';
    li += '<input type="hidden" name="new[]" value="true" />';
    li += '<input type="hidden" name="delete[]" id="deleted_item_' + now + '" value="0" class="delete">';
    li += '<input type="hidden" name="option_1[]" value="' + $('#question_1_1_new').val() + '">';
    if( $('#question_2_1_new').val() ) {
      li += '<input type="hidden" name="option_2[]" value="' + $('#question_2_1_new').val() + '">';
    }
    li += '<input type="hidden" name="name[]" value="' + name + '">';
    li += '<label class="mt-1"><input type="checkbox" data-id="item_' + now + '" class="mass-delete me-2"><small>' + name + '</small></label>';
    li += '<span class="d-flex justify-content-end w-25"><input type="hidden" id="invitees_item_' + now + '" name="comparison_group_invites[]"><button class="btn btn-link delete-row" data-action="campaign-manager#deleteRow" data-id="item_' + now + '"><em class="fa fa-trash text-danger"></em></button></span>';
    li += '</li>';
    $('#all_option_list').append(li);

    controllerRef.calculateTotal();
    controllerRef.closeNewOption();
  }

  loadComparisonGroups(campaign_id, comparison_group_id) {
    let controllerRef = this

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: "/campaigns/load_comparison_group_options",
      data: { campaign_id: campaign_id, comparison_group_id: comparison_group_id },
      type: "GET",
      success: function(e) {
        if(e.status == 200) {
          $('#new_groups').val(false);
          $('#set-invitees').html(e.html);
          $('#new-comparative-groups').removeClass('show')
          controllerRef.calculateTotal();
        } else {
          $('#set-invitees').html("<p class='text-danger'>"+i18n_string("campaign_manager.error_loading_comparison_groups","Error loading comparison groups.")+"</p>")
        }
      }
    })
  }

  massDeleteRows(event) {
    let controllerRef = this

    event.preventDefault();
    var ans = confirm(i18n_string("campaign_manager.are_you_sure_you_want_to_delete_these_rows","Are you sure you want to delete these rows?"));
    if(ans) {
      $.each( $('input.mass-delete'), function(k, v){
        if( $(v).prop("checked") ){
          $('#deleted_' + $(v).data("id")).val(1);
          $('#invitees_' + $(v).data("id")).val(0);
          $('#' + $(v).data("id")).attr('style', 'display: none !important');
        }
      });

      controllerRef.calculateTotal();
    }
  }

  resetComparisonGroups(event) {
    var ans = confirm(i18n_string("campaign_manager.are_you_sure_you_want_to_reset_your_groups","Are you sure you want to reset your invited groups and start over?"));
    if(ans) {
      $('#set-invitees').html('<p class="text-center py-5"><em class="fa fa-spinner fa-spin fa-4x text-light"></em></p>');
      $('#invitee_total').val('');
      $('#saved-comparative-groups').collapse('hide');
      $('#new-comparative-groups').collapse('show');
  
      $('#question_1').prop("selectedIndex", 0);
      $('#question_2').prop("selectedIndex", 0);
      $('#question_2_category').addClass("hidden");
      $('#new-comparative-groups-options').collapse('hide');
      $("#question_1_options").html('');
      $("#question_2_options").html('');
  
      $('#invite_total').val('');
  
      $('#new_groups').val('true');
      $('#comparison_group_id').remove();
  
      $.each( $('#saved-comparative-groups-list > a'), function(key){
        $(this).removeClass("active");
      });
    }
  }

  sendCampaignNotification(event) {
    var ans = confirm("Are you sure you want to send a notification to your particpants about the assessment period being open?")
    if(ans) {

    } else {
      event.preventDefault();
    }    
  }


  showModal(event) {
    event.preventDefault()
    var myModal = new bootstrap.Modal(document.getElementById('campaign_overview_video'), {})

    myModal.show()
    $('#campaign_overview_video_iframe').attr('src', 'https://player.vimeo.com/video/894966568?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')
  }

  toggleLeadershipAdmin(event) {
    event.preventDefault()

    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })

    var item = event.currentTarget
    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: "/campaigns/toggle_admin_status",
      data: { id: item.dataset.id, campaign: item.dataset.campaign },
      type: "POST",
      success: function(e) {
        if(e.status == 200) {
          $('#leadership_status_' + item.dataset.id).html( e.html ) 
        }
      }
    })
  }

  toggleLeadershipOptions(event) {
    var element = document.getElementById('import-bulk-action-leadership-row-' + event.target.dataset.row_id)
    if(event.target.value == "leadership") {
      element.style.display = 'block'
    } else {
      element.style.display = 'none'
    }
  }

  // toggleQuestionOptions(e) {
  //   var item = e.currentTarget


  //   console.log("woot woot")

  //   if(item.id == "option_list_primary" && item.value == "") {
  //     // $('#option_list_secondary').val('none');
  //     $("#option_list_secondary").val(null).trigger("change");
  //   } else if(item.id == "option_list_primary" && item.value == "none") {
  //     $("#option_list_secondary").val(null).trigger("change");
  //   } else if(item.id == "option_list_primary" && item.value > 0 ) {
  //     $("#option_list_secondary").val(null).trigger("change");
  //   }
  //   // } else if(item.id == "option_list_secondary" && item.value > 0) {
  //   //   $('#option_list_primary').val("");
  //   // } else if(item.id == "option_list_secondary" && item.value == "") {
  //   //   $('#option_list_primary').val("none");
  //   // } else if(item.id == "option_list_secondary" && item.value == "none") {
  //   //   $('#option_list_primary').val("");
  //   // }
  // }

  updateParticipationGoal(event) {
    var total = event.target.dataset.invites
    var goal = event.target.value

    document.getElementById('total_respondents').innerHTML = Math.round(total * (goal / 100))
  }

  verifySendingData(event) {
    
    var auto_notification = document.getElementById('campaign_inform_at_start')
    var notification_time = document.getElementById('campaign_inform_at_time')
    var notification_zone = document.getElementById('campaign_inform_at_timezone')
    if(auto_notification.value == 'true' && (notification_time.value == '' || notification_zone.value == '') ) {
      alert("Please select an Automatic Notification Time and Automatic Notification Timezone")
      event.preventDefault()
    }
  }
}
