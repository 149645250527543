import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="wellness-common"
export default class extends Controller {

  connect() {
    console.log("WC: connect")

    this.configureSelect2Fields('.select2', {})

    document.over_time_chart_data = {}
    document.wcControllerRef = this


    // Custom callback created - https://www.betterstimulus.com/interaction/callbacks.html
    $(document).on('wellness_common_controller.state', function(event, callback) {
        callback(this)
      }.bind(this)
    )

    document.addEventListener('determineReload', this.determineReload.bind(this));
    document.addEventListener('determineReloadFolders', this.determineReloadFolders.bind(this));

    // Show / Hide Score Description
    $(this.element).on('show.bs.collapse', (event) => {
      $('#continuum-button').html(I18n.wellness_snapshot.show_less + " <em class='fa fa-chevron-up'></em>");
    });
    $(this.element).on('hide.bs.collapse', (event) => {
      $('#continuum-button').html(I18n.wellness_snapshot.learn_more + " <em class='fa fa-chevron-down'></em>");
    });
  }



  /* **************************************************************************
  function: agreeToTerms
  * User triggered
  * Loads modal for user to agree to terms and sets the page to blurred
  ************************************************************************** */
  agreeToTerms(e) {
    console.log("WS: agreeToTerms")
    var formData = "setting_name=accepted_reporting_terms&setting_value=yes";
    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/users/agree_to_snapshot_terms",
      data: { setting_name: 'accepted_reporting_terms', setting_value: 'yes' },
      success: function (data) {
        if(data.status == 200) {
          $("#well-being-snapshot-outer-div").removeClass("blur-element");
          $("#well-being-snapshot-terms-modal").modal('hide');
        }
      }
    });
  }



  /* **************************************************************************
  function: arrayDiffAssoc
  * Internal triggered
  * Used to compare the differences between two arrays
  ************************************************************************** */
  arrayDiffAssoc (arr1) {
    var retArr = {};
    var argl = arguments.length;
    var k1 = '';
    var i = 1;
    var k = '';
    var arr = {};
  
    arr1keys: for (k1 in arr1) {
      for (i = 1; i < argl; i++) {
        arr = arguments[i];
        for (k in arr) {
          // console.log(k+" -- "+k1);
          if (arr[k] === arr1[k1] && k === k1) {
            continue arr1keys;
          }
        }
        retArr[k1] = arr1[k1];
      }
    }
  
    return retArr;
  }



  /* **************************************************************************
  function: changeQuestion   // also called changePrimaryQuestionProcess
  * User and Internal triggered 
  * Gets the newly selected question, clears secondary and tertiary questions, loads secondary question builder
  ************************************************************************** */
  changeQuestion(event, chosen_option_list_id = undefined) {
    console.log("WC: changeQuestion")

    // TODO: Load in prefix on wellness journey stuff.

    var prefix = ''
    var question_id = ''
    var level = ''

    if(Array.isArray(event)) {
      // event format: [prefix, question_id, level]
      prefix = event[0]
      question_id = event[1]
      level = event[2]
    } else {
      var item = event.currentTarget
      question_id = item.value
      level = item.dataset.level
      prefix = item.dataset.prefix 
    }

    console.log("    Question ID:", question_id)
    console.log("    Level:", level)
    console.log("    Prefix:", prefix)


    if(level == 'primary'){
      this.resetQuestionPrimary(prefix);
      this.resetQuestionSecondary(prefix);
      this.resetQuestionTertiary(prefix);


      console.log("  PREFIX: ", prefix)

      if(prefix != 'wj_') {
        // Set default display response to Percentage : Distressed > Thriving
        document.wsControllerRef.displayResponseAsProcess('percent')
      }


      if(question_id != '') {
        // Load Secondary Question
        this.loadQuestion(prefix + 'secondary_question', question_id)

        // Set Primary Question ID in Secondary Question (allows for clearing of tertiary question)
        $('#' + prefix + 'secondary_question').attr('data-primary', question_id)

        // Display Question Filter Options
        $('#' + prefix + 'dashboard-filter-options').removeClass('hidden')
        $('#' + prefix + 'secondary_question_holder').removeClass('hidden')

        // Load Signup Question Options
        this.getSignUpQuestionOptionList(question_id, chosen_option_list_id, 'primary', 'primary_option_kinds', prefix)

        // Show display responses as sort by
        if(prefix == '') {
          $('#chart-sort-by').removeClass('hidden');
        }

        $('#' + prefix + 'primary_question_customize_options').attr('href', '/wellness_common/' + question_id.split("-")[0] + '/configure_options?prefix=' + prefix)
      }

    } else if(level == 'secondary') {
      this.resetQuestionSecondary(prefix);
      this.resetQuestionTertiary(prefix);

      if(question_id != ''){ 
        let primary = $('#' + prefix + 'primary_question').val()

        // Display Question Filter Options
        $('#' + prefix + 'secondary_questions').removeClass('hidden')

        // Load Signup Question Options
        this.getSignUpQuestionOptionList(question_id, chosen_option_list_id, 'secondary', 'secondary_option_kinds', prefix)

        // Display Tertiary Question (if available)
        $('#' + prefix + 'tertiary_question_holder').removeClass('hidden')
        this.loadQuestion(prefix + 'tertiary_question', [question_id, primary])
        
        $('#' + prefix + 'secondary_question_customize_options').attr('href', '/wellness_common/' + question_id.split("-")[0] + '/configure_options?prefix=' + prefix)
      }
    
    } else if(level == 'tertiary') {
      this.resetQuestionTertiary(prefix);
      
      if(question_id != ''){ 
        // Display Question Filter Options
        $('#' + prefix + 'tertiary_questions').removeClass('hidden')

        // Load Signup Question Options
        this.getSignUpQuestionOptionList(question_id, chosen_option_list_id, 'tertiary', 'tertiary_option_kinds', prefix)

        $('#' + prefix + 'tertiary_question_customize_options').attr('href', '/wellness_common/' + question_id.split("-")[0] + '/configure_options?prefix=' + prefix)
      }
    }
  }


  /* **************************************************************************
  function: changeQuestionOptionKinds
  * User and Internal triggered
  * Loads the question option kids (standard list and custom lists)
  ************************************************************************** */
  changeQuestionOptionKinds(event, question_id = undefined, option_id = undefined, question_level = undefined, prefix = '') {
    console.log("WC: changeQuestionOptionKinds")
    const controllerRef = this

    if(event !== undefined) {
      console.log("    - We have an event")
      var item = event.currentTarget
      question_id = item.dataset.questionId
      option_id = item.value
      question_level = item.dataset.questionLevel
      prefix = item.dataset.prefix
    }

    console.log("    Event", event)
    console.log("    Question ID", question_id)
    console.log("    Option ID", option_id)
    console.log("    Question Level", question_level)
    console.log("    Prefix", prefix)


    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/wellness_common/get_signup_question_options",
      data: { selected_question: question_id, option_group_id: option_id },
      async: false,
      success: function (data) {
        if(data.status == 200) {
          $('#' + prefix + question_level + '_options').val(null).trigger('change'); // Clear chosen settings (if any)
          $('#' + prefix + question_level + '_options').html( controllerRef.processOptionList(data.options) )
        }
      }
    });
  }




  /* **************************************************************************
  function: clearAll
  * User triggered
  * Removes all of the selected options from the question option list.
  ************************************************************************** */
  clearAll(event) {
    console.log("WS: clearAll")
    event.preventDefault()
    var item = event.currentTarget

    $("#"+ item.dataset.field + " > option").prop("selected", "");
    $("#"+ item.dataset.field ).trigger("change");
  }



  /* **************************************************************************
  function: configureSelect2Fields
  * Internal triggered
  * Sets up the select2 options
  ************************************************************************** */
  configureSelect2Fields(selector, options){
    console.log("WC: configureSelect2Fields")
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }




  /* **************************************************************************
  function: dateRangeSelect
  * User triggered
  * Determines which date select options to show and resets other options if changed.
  ************************************************************************** */
  dateRangeSelect(event) {
    console.log("WS: dateRangeSelect")
    this.toggleActiveClass(event) 

    $('#assess_filter_holder').removeClass('hidden')

    if(event.currentTarget.children[0].value == 0) {
      $('#report-date-range-custom-div').attr("style", "display:block");
      $('#report-campaign-select-div').attr("style", "display:none");
    } else if(event.currentTarget.children[0].value == 'campaign') {
      $('#report-campaign-select-div').attr("style", "display:block");
      $('#report-date-range-custom-div').attr("style", "display:none");
      $('#assess_filter_holder').addClass('hidden')
      $('#assess_filter').val('last_in_period')
    } else {
      $('#report-date-range-custom-div').attr("style", "display:none");
      $('#report-campaign-select-div').attr("style", "display:none");
      $('#start_date').val( $('#default_start_date').val() );
      $('#end_date').val( $('#default_end_date').val() );
    }
  }




  /* **************************************************************************
  function: determineReload
  * Internal triggered
  * Determines if we need to reload the snapshot report after the user closes the custom list modal
  ************************************************************************** */
  determineReload(event) {
    console.log("WC: determineReload")
    const controllerRef = this
    if(window.reloadSettings == true){
      window.reloadSettings = false
      controllerRef.resetSettings(event)
    }
  }

  /* **************************************************************************
  function: determineReloadFolders
  * Internal triggered
  * Determines if we need to reload the snapshot folders after the user closes the custom list modal
  ************************************************************************** */
  determineReloadFolders(event) {
    console.log("WC: determineReloadFolders")
    if(window.reloadFolders == true){
      window.reloadFolders = false
      // Get folder ids or just reload by class name
      Array.from(document.getElementsByClassName('load-saved-snapshots')).forEach(function(item){
        item.reload()
      });
    }
  }



  /* **************************************************************************
  function: displayComparisonDates
  * User triggered
  * Determines if we need to reload the snapshot folders after the user closes the custom list modal
  ************************************************************************** */
  displayComparisonDates(event) {
    console.log("WS: displayComparisonDates")
    this.toggleActiveClass(event)
    if(event.currentTarget.children[0].value == 'custom') {
      $('#comparison-dates').removeClass('hidden');
    } else {
      $('#comparison-dates').addClass('hidden');
    }

    if(document.wsControllerRef !== undefined) {
      document.wsControllerRef.showNationalComparisons()
    }
  }





  /* **************************************************************************
  function: filterByOptions
  * User triggered
  * Shows / Hides the Filter Question # Options in Create Your Groups
  ************************************************************************** */
  filterByOptions(event) {
    console.log("WC: filterByOptions")
    document.wcControllerRef.toggleActiveClass(event)

    if( document.initial_report_load_complete == true) {
      // TODO: Need to prevent multiple clicks but check the selected value
      var item = event.currentTarget.dataset.item;
      var field = event.currentTarget.dataset.field;

      if(event.currentTarget.querySelector('input').value == 1) {
        $('#' + item).removeClass("hidden");
      } else {
        $('#' + item).addClass('hidden');
        // Clear all existing selected options when item is hidden
        $("#" + field + " > option").prop("selected",false);
        $("#" + field).trigger("change");
        // TODO: Check if this does anything
        // this.setReportPanelSize();
      }
      
    }
  }





  /* **************************************************************************
  function: getJSONLoadSettingsString
  * Internal triggered
  * Converts an array of data into a JSON string
  ************************************************************************** */
  getJSONLoadSettingsString($formObj) {
    console.log("WC: getJSONLoadSettingsString")
    var settings_data = $formObj.serializeArray();
    var final_data_array = {};
    var index_array_counter = {};
    for(var i=0;i<settings_data.length;i++){
      var name = settings_data[i].name;
      if(name != 'authenticity_token') {
        if(name.indexOf("[]") != -1){
          if(!index_array_counter[name]){
            index_array_counter[name] = 0;
          }
          var new_temp_name = name.replace("[]","["+index_array_counter[name]+"]");
          index_array_counter[name]++;
          name = new_temp_name;
        }
        final_data_array[name] = settings_data[i].value;
      }
    }

    return JSON.stringify(final_data_array);
  }





  /* **************************************************************************
  function: getSignUpQuestionOptionList
  * Internal triggered
  * Get the list of options for a specific question and displays them
  ************************************************************************** */
  getSignUpQuestionOptionList(question_id, chosen_option_list_id, question_level, field_id, prefix = '') {
    
    console.log("WC: getSignUpQuestionOptionList")
    console.log("     Question ID:", question_id)
    console.log("     Chosen Option List ID:", chosen_option_list_id)
    console.log("     Question Level: ", question_level)
    console.log("     Field ID: ", field_id)
    console.log("     Prefix: ", prefix)
    const controllerRef = this

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/wellness_common/get_signup_question_option_list",
      data: { selected_question: question_id },
      async: false,
      success: function (data) {
        if(data.status == 200) {

          $('#' + prefix + field_id).html( controllerRef.processOptionKind(data.options) )
          $('#' + prefix + field_id).attr('data-question-id', question_id.split('-')[0])

          if(chosen_option_list_id === undefined) {
            // If question is changed from user interaction (always pull the default option list)
            $('#' + prefix + field_id).val(data.default_option)
            if(data.default_option > 0) {
              $('label[data-field="' + field_id + '"]').first().click()
              $('select[name="' + field_id + '"]').parent().parent().removeClass('hidden')
            }
            controllerRef.changeQuestionOptionKinds(undefined, question_id, data.default_option, question_level, prefix)
          } else {
            // If question is changed from loading saved settings
            // We need to know what the chosen option list was and change to that, then get the option kinds based on that id.
            $('#' + prefix + field_id).val(chosen_option_list_id)

            $('label[data-field="' + field_id + '"]').first().click()
            $('#' + prefix + field_id).parent().parent().removeClass('hidden')

            controllerRef.changeQuestionOptionKinds(undefined, question_id, chosen_option_list_id, question_level, prefix)
          }
        }

        controllerRef.configureSelect2Fields('.select2', {})
      }
    });  
  }



  /* **************************************************************************
  function: hideLoader
  * System triggered
  * Hides the loader from view
  ************************************************************************** */
  hideLoader(prefix = '') {
    console.log("WC: hideLoader", prefix)
    document.getElementById(prefix + 'loader-holder').classList.add('hidden')
    document.getElementById(prefix + 'chart-holder').classList.remove('hidden') 
  }




  /* **************************************************************************
  function: loadQuestion
  * System triggered
  * Checks if we have saved data and submits the settings form
  ************************************************************************** */
  loadQuestion(element_id, options = ''){
    console.log("WC: loadQuestion")
    console.log("    Element ID:", element_id)
    console.log("    Options:", options)

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      data: { selected_question: options },
      url: "/wellness_common/get_signup_questions",
      async: false,
      success: function (data) {
        if(data.status == 200) {
          $('#' + element_id).html( document.wcControllerRef.processQuestionList(data.questions) )
        }
      }
    });
  }

  /* **************************************************************************
  function: processOptionKind
  * Internal triggered
  * Creates options for "Select Question # List Grouping", assigns Original list options and passed in ones
  ************************************************************************** */
  processOptionKind(json) {
    console.log("WC: processOptionKind")
    console.log("    JSON", json)
    var options = '';
    options += '<option value="0">' + I18n.wellness_snapshot.original_list_options + '</option>';
    $(json).each(function(index, element) {
      options += '<option value="' + element.id + '">' + element.name + '</option>';
    });

    return options;
  }



  /* **************************************************************************
  function: processOptionList
  * Internal triggered
  * Creates options for "Choose Question # Option(s)"
  ************************************************************************** */
  processOptionList(json) {
    console.log("WC: processOptionList")
    console.log("    JSON: ", json)
    var options = '';
    $(json).each(function(index, element){
      if(element.id !== undefined) {
        options += '<option value="' + element.id + '">' + element.name + '</option>';
      } else {
        element.name = element.name.replaceAll("'", "&#39;")
        element.name = element.name.replaceAll('"', '&quot;')
        options += '<option value="' + element.option_ids.join(',') + ':' + element.name + '">' + element.name + '</option>';
      }
    });

    return options;
  }

  /* **************************************************************************
  function: processQuestionList
  * Internal triggered
  * Creates options the passed in data. Contains list of questions to be selected
  ************************************************************************** */
  processQuestionList(json) {
    console.log("WC: processQuestionList")
    var options = '<option value="">' + I18n.general.please_select + '</option>';
    $(json).each(function(index, element) {
      options += '<option value="' + element.id + '-' + element.kind + '">' + element.name + '</option>';
    });
  
    return options;
  }



  /* **************************************************************************
  function: resetQuestionPrimary
  * Internal triggered
  * Resets the primary question options
  ************************************************************************** */
  resetQuestionPrimary(prefix = '') {
    console.log("WC: resetQuestionPrimary")

    // Hide Secondary & Tertiary Question Boxes
    $('#' + prefix + 'secondary_question_holder').addClass("hidden")
    if($('#' + prefix + 'tertiary_question_holder').length) { $('#' + prefix + 'tertiary_question_holder').addClass("hidden") }


    $('#' + prefix + 'primary_question_customize_options').attr('href', '#');

    if($('#' + prefix + 'dashboard-filter-options').length) { $('#' + prefix + 'dashboard-filter-options').addClass('hidden'); }
    if($('#' + prefix + 'customize_primary_option_group').length) { 
      $('#' + prefix + 'customize_primary_option_group').closest('.btn').trigger('click'); 
      $('#' + prefix + 'customize_primary_option_group').parent().parent().children().removeClass('active')
      $('#' + prefix + 'customize_primary_option_group').parent().addClass('active')
    }
    if($('#' + prefix + 'filter_by_primary_option').length) { $('#' + prefix + 'filter_by_primary_option').closest('.btn').click(); }
    if($('#' + prefix + 'primary_options').length) { $('#' + prefix + 'primary_options').html(''); }
    if($('#' + prefix + 'primary_option_kinds').length) { $('#' + prefix + 'primary_option_kinds').html(''); }
  }

  /* **************************************************************************
  function: resetQuestionSecondary
  * Internal triggered
  * Resets the secondary question
  ************************************************************************** */
  resetQuestionSecondary(prefix = '') {
    console.log("WC: resetQuestionSecondary", prefix)

    // Hide Tertiary Question Boxes
    if($('#' + prefix + 'tertiary_question_holder').length) { $('#' + prefix + 'tertiary_question_holder').addClass("hidden") }

    $('#' + prefix + 'secondary_question_customize_options').attr('href', '#');

    if($('#' + prefix + 'secondary_questions').length) { $('#' + prefix + 'secondary_questions').addClass('hidden'); $('#' + prefix + 'secondary_questions').attr('data-primary', ''); }
    if($('#' + prefix + 'secondary_question_options_filter').length) { $('#' + prefix + 'secondary_question_options_filter').addClass("hidden"); }
    if($('#' + prefix + 'filter_by_secondary_option').length) { $('#' + prefix + 'filter_by_secondary_option').closest('.btn').click(); }
    if($('#' + prefix + 'secondary_options').length) { $('#' + prefix + 'secondary_options').html('') }
    if($('#' + prefix + 'secondary_option_kinds').length) { $('#' + prefix + 'secondary_option_kinds').html(''); }
  }

  /* **************************************************************************
  function: resetQuestionTertiary
  * Internal triggered
  * Resets the tertiary question
  ************************************************************************** */
  resetQuestionTertiary(prefix = '') {
    console.log("WC: resetQuestionTertiary", prefix)
    $('#' + prefix + 'tertiary_question_customize_options').attr('href', '#');

    // if($('#' + prefix + 'tertiary_question_holder').length) { $('#' + prefix + 'tertiary_question_holder').addClass('hidden'); }
    if($('#' + prefix + 'tertiary_questions').length) { $('#' + prefix + 'tertiary_questions').addClass('hidden'); }
    if($('#' + prefix + 'tertiary_question_options_filter').length) { $('#' + prefix + 'tertiary_question_options_filter').addClass("hidden"); }
    if($('#' + prefix + 'filter_by_tertiary_option').length) { $('#' + prefix + 'filter_by_tertiary_option').closest('.btn').click(); }
    if($('#' + prefix + 'tertiary_options').length) { $('#' + prefix + 'tertiary_options').html('') }
    if($('#' + prefix + 'tertiary_option_kinds').length) { $('#' + prefix + 'tertiary_option_kinds').html(''); }
  }



  /* **************************************************************************
  function: selectAll
  * User triggered
  * Selects all options for the question
  ************************************************************************** */
  selectAll(event) {
    console.log("WC: selectAll")
    event.preventDefault()
    var item = event.currentTarget

    $("#"+ item.dataset.field + " > option").prop("selected","selected");
    $("#"+ item.dataset.field ).trigger("change");
  }





  /* **************************************************************************
  function: selectCampaign
  * User and Internal triggered
  * Selects all campaigns except for the one the user selected for comparison data
  * Displays the data in the campaign comparison section.
  ************************************************************************** */
  selectCampaign(prefix = '') {
    if(typeof(prefix) == 'object') {
      prefix = prefix.currentTarget.dataset.prefix
    }

    console.log("WC: selectCampaign", prefix)
    var item = document.getElementById(prefix + "campaign_select")
    
    if(item.value == '') {
      $('#' + prefix + 'report-campaign-select-comparison-div').addClass("hidden")
      $("#" + prefix + "campaign_select_comparison").val("none")
    } else {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        url: '/campaigns/find_other_campaigns',
        data: { campaign_ids: [item.options[item.selectedIndex].value] },
        async: false,
        success: function (data) {
          if(data.status == 200) {

            var option_list = '<option value="none">' + I18n.general.none + '</option>';
            data.campaigns.forEach(function(item, index){
              var start_date = item.start_date.split('-')[1] + '/' + item.start_date.split('-')[2] + '/' + item.start_date.split('-')[0]
              var end_date = item.end_date.split('-')[1] + '/' + item.end_date.split('-')[2] + '/' + item.end_date.split('-')[0]
              option_list += '<option data-date="' + item.start_date + ':' + item.end_date + '" value="' + item.id + '" data-name="' + item.name + '">' + item.name + ' (' + start_date + ' ' + I18n.advanced_reports.to + ' ' + end_date + ')</option>';
            })
            
            $('#' + prefix + 'campaign_select_comparison').html(option_list)
            $('#' + prefix + 'report-campaign-select-comparison-div').removeClass("hidden")
          }
        }
      });
      
    }
  }




  /* **************************************************************************
  function: setReportPanelSize
  * Internal triggered
  * Configures the height of the settings panel when data changes
  ************************************************************************** */
  setReportPanelSize(prefix = '') {
    console.log("WC: setReportPanelSize")
    var panelDiv = document.getElementById(prefix + 'report-settings');
    if(panelDiv)
    {
      var combined_element_height = panelDiv.offsetHeight;
      combined_element_height += 40; // Padding

      if(!$("#" + prefix + "report-settings-update-btn-container-follow").is(":visible"))
      {
        combined_element_height += 50; // Hidden button
      }

      if(combined_element_height > panelDiv.offsetHeight)
      {
        if(!$("#" + prefix + "report-settings-update-btn-container-sticky").is(":visible"))
        {
          $("#" + prefix + "report-settings-update-btn-container-follow").fadeOut('fast');
          $("#" + prefix + "report-settings-update-btn-container-sticky").fadeIn('fast');
        }
      }
      else
      {
        if(!$("#" + prefix + "report-settings-update-btn-container-follow").is(":visible"))
        {
          $("#" + prefix + "report-settings-update-btn-container-sticky").fadeOut('fast');
          $("#" + prefix + "report-settings-update-btn-container-follow").fadeIn('fast');
        }
      }

      $(panelDiv).scrollTop(0);
    }
  }







  /* **************************************************************************
  function: showLoader
  * System triggered
  * Shows the loader 
  ************************************************************************** */
  showLoader(prefix = '') {
    console.log("WC: showLoader -- ", prefix)
    document.getElementById(prefix + 'loader-holder').classList.remove('hidden')
    document.getElementById(prefix + 'chart-holder').classList.add('hidden') 
    document.getElementById(prefix + 'table-data-holder').innerHTML = '';
  }



  
  /* **************************************************************************
  function: toggleActiveClass
  * User and Internal triggered
  * Highlights the clicked item. Used to display radio buttons in a "button" format
  ************************************************************************** */
  toggleActiveClass(event){
    // console.log("WC: toggleActiveClass")
    for(let item of event.currentTarget.parentNode.children){
      item.classList.remove('active')
    }
    event.currentTarget.classList.add('active')
  }


  /* **************************************************************************
  function: toggleOptionsDisplay
  * User triggered
  * Toggles the showing and hiding of specific elements. Elements in the data-show-element / data-hide-element attribute
  ************************************************************************** */
  toggleOptionsDisplay(e) {
    // console.log("WC: toggleOptionsDisplay")
    this.toggleActiveClass(e)
    
    // Run prevent default AFTER call to active class so it doesn't trigger twice.
    // e.preventDefault()

    var item = e.currentTarget

    if(item.dataset.showElement !== undefined) { 
      item.dataset.showElement.split(',').forEach((x) =>
        document.getElementById(x).classList.remove('hidden')
      )
    }

    if(item.dataset.hideElement !== undefined) {
      item.dataset.hideElement.split(',').forEach((x) =>
        document.getElementById(x).classList.add('hidden')
      )
    }
  }
}
