import { Controller } from "@hotwired/stimulus"
import intlTelInput from "../../../public/js/intl_tel/intlTelInput.min"

// Connects to data-controller="phone-input"
// Do not attach controller to input itself instead the IntlTelInput script duplicate that input field.
// Usage:
// <div data-controller"phone-input">
//   <input data-phone-input-target="input" name="sms_phone" data-hidden-field="user[sms_full_phone]" />
// </div>
export default class extends Controller {
  static targets = [ "input" ]
  initialize() {
    // This is called once per controller
    document.intlTelInputObject = undefined;
    document.intlTelInputSkipAutoFocus = false;
    if ($("#country").length) {
      $("#country").on("change", function (e) {
        var countryCode = $(this).val();
        if (countryCode && intlTelInputObject) {
          $("#user_sms_phone").intlTelInput('setCountry', countryCode.toLowerCase());
        }
      });
    }
  }
  connect() {
    if (this.hasInputTarget) {
      let hiddenFieldName = this.inputTarget.dataset.hiddenField ?? this.inputTarget.dataset.hidden_field;
      this.setupIntlTelInput(this.inputTarget, hiddenFieldName, document.default_country);
    }
  }

  setupIntlTelInput(inputElement, hiddenFieldName)
  {
    var utilsLocation = "";
    if (document.wbi_cdn_path) {
      utilsLocation = document.wbi_cdn_path;
    }
    
    // Check if we have a default country set in the sub account.
    if(document.getElementById('default_country')) {
      var initalCountryCode = document.getElementById('default_country').value;
    } else {
      var initalCountryCode = "us";
    }
    
    var have_tel_country = getCookie("login_tel_country");
    if(arguments.length > 2 && arguments[2] && arguments[2].length == 2)
    {
      initalCountryCode = arguments[2];
    }
    else if(have_tel_country && have_tel_country.length == 2)
    {
      initalCountryCode = have_tel_country;
    }
    document.intlTelInputObject = $(inputElement).intlTelInput({
      initialCountry: initalCountryCode,
      separateDialCode: true,
      preferredCountries: ["us", "ca", "gb"],
      utilsScript: utilsLocation+"/js/intl_tel/utils.js",
      hiddenInput: hiddenFieldName
    }).done(function(){
      var mask1 = $(inputElement).attr('placeholder');
      //console.log("MASK placeholderL: "+mask1);
      if(mask1)
      {
        mask1 = mask1.replace(/[0-9]/g, 9);
        mask1 = mask1.replace(/_/g, "9");
        // phone number masking causing issues for AU/NZ - disabling for now --
        if (initalCountryCode == "us")
        {
          $(inputElement).mask(mask1);
        }
        else
        {
          $(inputElement).unmask();
        }
        // Update placeholder
        var placeHolder = mask1.replace(/[0-9]/g, "_");
        $(inputElement).attr('placeholder',placeHolder);
      }
    });
    $(inputElement).on("countrychange", function (e, countryData) {
      if(countryData && countryData.iso2) {
        setCookie("login_tel_country", countryData.iso2, 365);
      }
      var mask1 = $(inputElement).attr('placeholder');
      //console.log("MASK placeholderL: "+mask1);
      if(mask1)
      {
        mask1 = mask1.replace(/[0-9]/g, 9);
        mask1 = mask1.replace(/_/g, "9");
        // phone number masking causing issues for AU/NZ - disabling for now
        if (countryData.iso2 == "us")
        {
          $(inputElement).mask(mask1);
        }
        else
        {
          $(inputElement).unmask();
          setTimeout(function(){
            var currentVal = $(inputElement).val();
            if  (currentVal.indexOf("_") > -1) {
              // Clear this -- left over from masking probably
              $(inputElement).val("");
            }
            var placeHolder = mask1.replace(/[0-9]/g, "_");
            $(inputElement).attr('placeholder',placeHolder);
          }, 50);
        }
        // Update placeholder
        var placeHolder = mask1.replace(/[0-9]/g, "_");
        //console.log("placeHolder="+placeHolder);
        $(inputElement).attr('placeholder',placeHolder);
        if (intlTelInputSkipAutoFocus){
          intlTelInputSkipAutoFocus = false;
        } else {
          $(inputElement).blur().focus();
        }
      }
    });
    $(inputElement).on("keyup change", function (e) {
      if ($(this).val() == "") {
        var currentPlaceholder = $(inputElement).attr('placeholder');
        if (currentPlaceholder.match(/[0-9]/g)) {
          var newPlaceHolder = currentPlaceholder.replace(/[0-9]/g, "_");
          $(inputElement).attr('placeholder',newPlaceHolder);
        }
      }
    });
  }
}
