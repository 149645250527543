import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alert"
// Message with this:  data-alert-message-value="Test confirm"
// Action with this: data-action="click->alert#confirm"
export default class extends Controller {
  static values = { message: String };

  alert(e) {
    alert(this.messageValue);
  }
  confirm(e) {
    if (!confirm(this.messageValue)) {
      e.preventDefault();
    }
  }
}
