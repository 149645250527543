import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="super-admin"
export default class extends Controller {
  connect() {
  }

  confirmDisable(e) {
    var ans = confirm("Are you sure you want to disable this super admin? They will no longer be able to login if they are disabled.")
    if(!ans) {
      e.preventDefault()
    }
  }

  confirmEnable(e) {
    var ans = confirm("Are you sure you want to re-enable this super admin? They will have full access to the WBI.")
    if(!ans) {
      e.preventDefault()
    }
  }
}
