import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="organizations"
export default class extends Controller {
  
  connect() {
    this.initializeSelect2(".select2", {})


    $('#customer_sub_account_id').on('select2:select select2:unselect', function(e){
      if($("#selected_sub_accounts").length){
        $("#selected_sub_accounts").html('')

        $('#hide_option_button').attr("disabled", "disabled");

        $('#customer_sub_account_id').find(':selected').each(function(index, item){
          $("#selected_sub_accounts").append("<li>" + item.text + "</li>")
          $('#hide_option_button').removeAttr("disabled");
        })
      }
    });

    $('#customer_id').on('select2:select', function(e){
      $('#sub_account_select2').addClass("hidden")
      $('#customer_sub_account_id').html('')
      $("#selected_sub_accounts").html('')

      $.ajax({
        url: "/customers/" + e.target.value + "/get_customer_sub_accounts",
        type: "GET",
        success: function(e) {
          if(e.status == 200) {
            $('#sub_account_select2').removeClass("hidden")
            var data = ''
            $(e.accounts).each(function(index, item){
              data += '<option value="">' + item.name + ' (' + item.group_content + ')</option>';
            })
            
            $('#customer_sub_account_id').html(data);
          }
        }
      })
    })
  }

  clearEmail(event) {
    $('#email_invalid').addClass("hidden");
    $('#hide_option_button').removeAttr("disabled");
  }

  editOrganization(event) {
    event.preventDefault()

    
  }

  hideOptions(event) {
    event.preventDefault();

    if($("#user_account_email").val() != ''){
      // validate email here
      $('#hide_option_button').attr("disabled", "disabled");
      $.ajax({
        url: "/users/check_email_avail",
        type: "POST",
        data: { email: $("#user_account_email").val() },
        success: function(e){
          console.log(e)
          if(e.available == "no") {
            $('#email_invalid').removeClass("hidden");
        
          }
          else {
            $('#email_invalid').addClass("hidden");
            $('#show_option_button').removeAttr("disabled");

            $("#confirm_conversion_button").removeClass('hidden');
            $("#upgrade_customer_message").removeClass('hidden');

            $("#hide_option_button").addClass("hidden");
            $("#upgrade_customer_holder").addClass("hidden");
          }
        }
      });
    } else {
      $("#show_option_button").removeClass('hidden');
      $("#confirm_conversion_button").removeClass('hidden');
      $("#upgrade_customer_message").removeClass('hidden');

      $("#hide_option_button").addClass("hidden");
      $("#upgrade_customer_holder").addClass("hidden");
    }
  }

  initializeSelect2(selector, options) {
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }
  
  selectCustomerList(event) {
    event.preventDefault()
    var item = event.target

    $('#customer-list').html('<tr><td colspan="3"><p class="text-center p-5"><em class="fal fa-spin fa-circle-notch fa-3x"></em></td></tr>')

    $.ajax({
      url: "/organizations/select_organizations_by_kind",
      data: { kind: item.value },
      type: "GET",
      success: function(e) {
        if(e.status == 200) {
          $('#customer-list').html(e.data);
        }
      }
    })
  }

  showDeleteButton(event) {
    if($("#delete_user_button").attr('disabled')){
      $("#delete_user_button").removeAttr('disabled');
    } else {
      $("#delete_user_button").attr('disabled', 'disabled')
    }
  }

  showOptions(event) {
    event.preventDefault();

    $("#show_option_button").addClass('hidden');
    $("#confirm_conversion_button").addClass('hidden');
    $("#upgrade_customer_message").addClass('hidden');

    $("#hide_option_button").removeClass("hidden");
    $("#upgrade_customer_holder").removeClass("hidden");
  }

  // verifyEmail(event) {
  //   event.preventDefault
  //   var item = event.target

  //   $.ajax({
  //     url:
  //   });
  // }
}
