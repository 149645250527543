import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alerts"
export default class extends Controller {

  connect() {
    this.initializeCkEditor()
  }

  initializeCkEditor() {
    if($("#content").length) {
      var editor = CKEDITOR.instances.content;
      var ckeditorInstance = CKEDITOR.replace("content", {
        allowedContent:true, // Need this to allow attributes on elements
        height: '300px',
        toolbar: [
          {
            name: 'basicstyles',
            groups: ['basicstyles', 'cleanup'],
            items: ['Source', '-', 'Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat', '-', 'Link', 'Unlink']
          },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
            items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Link', 'Unlink']
          },
          {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
          {name: 'colors', items: ['TextColor', 'BGColor']}
        ]
      });

      // http://handsomedogstudio.com/ckeditor-set-default-target-blank
        /* Here we are latching on an event ... in this case, the dialog open event */
        CKEDITOR.on('dialogDefinition', function(ev) {
          try {
            /* this just gets the name of the dialog */
      
            var dialogName = ev.data.name;
            /* this just gets the contents of the opened dialog */
      
            var dialogDefinition = ev.data.definition;
            /* Make sure that the dialog opened is the link plugin ... otherwise do nothing */

            if(dialogName == 'link') {
              
              /* Getting the contents of the Target tab */
              var informationTab = dialogDefinition.getContents('target');
            
              /* Getting the contents of the dropdown field "Target" so we can set it */
              var targetField = informationTab.get('linkTargetType');
            
              /* Now that we have the field, we just set the default to _blank. A good modification would be to check the value of the URL field and if the field does not start with "mailto:" or a relative path, then set the value to "_blank" */
              targetField['default'] = '_blank';
            
            }
          } catch(exception) {
            alert('Error ' + ev.message);
          }
        });
    }
  }

  selectCustomers() {
    $('#customer_holder').html('')
    $.ajax({
      url: '/customers/customer_list_with_select',
      type: 'GET',
      success: function(e) {
        if(e.status == 200) {
          $('#customer_holder').html(e.data)
        } else {
          alert("Could not load customer list")
        }
      }
    })
  }

  selectCustomerSubAccounts(event) {
    $('#version_holder').html('')
    if(event.currentTarget.value != ''){
      $.ajax({
        url: '/customers/' + event.currentTarget.value + '/customer_sub_account_list_with_select',
        type: 'GET',
        success: function(e) {
          if(e.status == 200) {
            $('#version_holder').html(e.data)
          } else {
            alert("Could not load version list")
          }
        }
      })
    }
  }

  verifyDelete(event) {
    event.preventDefault()
    var item = event.currentTarget
    var url = item.href
    var id = item.dataset.id
    var ans = confirm("Are you sure you want to delete this notice?")
    if(ans) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: url,
        type: "DELETE",
        success: function(e) {
          if(e.status == 200) {
            $('#notice_' + id).remove();
          } 
          if( e.status == 400) {
            alert("Notice could not be deleted")
          }
        }
      })
    }
  }
}
