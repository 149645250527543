import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account-search"
export default class extends Controller {
  connect() {
  }


  confirmUserBack(e) {
    e.preventDefault();
    $("#convertUserConfirmModalBackButton").hide();
    $("#convertUserConfirmModalConfirmFinalButton").hide();
    $("#convertUserConfirmModalStep2").html("").hide();

    $("#convertUserConfirmModalStep1").show();
    $("#convertUserConfirmModalConfirmButton").show();
  }

  confirmUserConvert(e) {
    e.preventDefault()
    var display_list = [];
    $("#customer_sub_account_id > option:selected").each(function(){
      display_list.push($(this).text());
    });
    var user_email_message = '';
    var user_email_val = $("#email_for_user_account").val();
    if(user_email_val && user_email_val.indexOf("@") != -1){
      user_email_message = '<strong>The old user account will be switched to the email: '+user_email_val+'</strong>';
    }
    var confirmation_message = '<div class="alert alert-danger"><strong>Confirm Admin Conversion</strong><br />You will be converting this user to be an administrator for these accounts: <ul><li>'+display_list.join("</li><li>")+'</li></ul>'+user_email_message+'</div>';
    $("#convertUserConfirmModalStep2").html(confirmation_message);
    $("#convertUserConfirmModalStep1").hide();
    $("#convertUserConfirmModalConfirmButton").hide();

    $("#convertUserConfirmModalBackButton").show();
    $("#convertUserConfirmModalConfirmFinalButton").show();
    $("#convertUserConfirmModalStep2").show();
  }

  delete(e) {
    var item = e.currentTarget

    if($("#deleteUserConfirmModalConfirmCheckbox").is(":checked"))
    {
      $('#delete-user-button').html('<em class="fa fa-spin fa-spinner"></em> Deleting User');

      $.ajax({
        type: "DELETE",
        url: "/users/" + item.dataset.id + "/delete",
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        success: function (data) {
          if(data.status == 200)
          {
            $("#account-search-item-row-"+item.dataset.id).hide();
            $("#system_modal_popup").modal("hide");
          }
          else
          {
            $('#delete-user-button').html('Confirm User Delete')
            alert(data.message);
          }
        }
      });
    }
  }

}
