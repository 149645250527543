import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="download-reports"
export default class extends Controller {
  connect() {
  }

  downloadCsv(e) {
    e.preventDefault();
    this.downloadReportTableAsCsv()
  }

  downloadJpeg(e) {
    e.preventDefault();
    $("#export-report-file-type-input").val("jpeg");
    $("#export_chart_filter_settings").val( this.getCurrentReportFilterSettingsForDisplayHTML() );
    $("#download-chart-form").submit();
    setTimeout(function(thisRef){
      $(thisRef).blur();
    }.bind(undefined, this),500);
  }

  downloadPdf(e) {
    e.preventDefault()
    $("#export-report-file-type-input").val("pdf");
    $("#export_chart_filter_settings").val( this.getCurrentReportFilterSettingsForDisplayHTML() );
    $("#download-chart-form").submit();
    setTimeout(function(thisRef){
      $(thisRef).blur();
    }.bind(undefined, this),500);
  }

  downloadReportTableAsCsv() {
    var tableData = getReportTableAsArray();
    var dataStr = JSON.stringify(tableData);
    document.report_table_download_count++;
    var formId = "download_report_table" + document.report_table_download_count;
    $('body').append('<form method="POST" id="' + formId + '" action="/reports/download_report_csv"><input type="hidden" name="chart_title" value="" /><input type="hidden" name="data" value="" /></form>');
    $("#" + formId).find("input[name='data']").val(dataStr.replace("&nbsp;", " "));
    $("#" + formId).find("input[name='chart_title']").val($("#export_chart_title").val());
    $("#" + formId).submit();
  }


  getCurrentReportFilterSettingsForDisplay() {
    return window.getCurrentReportFilterSettingsForDisplay();
  }

  getCurrentReportFilterSettingsForDisplayHTML()
  {
    var settings_list = this.getCurrentReportFilterSettingsForDisplay();
    var return_html = "";
    if(settings_list.length > 0)
    {
      return_html += '<h2>Chart Settings</h2>';
      for(var i=0;i<settings_list.length;i++){
        return_html += '<p class="setting-print">';
        return_html += '<strong>'+settings_list[i][0]+'</strong><br />';
        return_html += settings_list[i][1];
        return_html += '</p>';
      }
    }
    return return_html;
  }
}
