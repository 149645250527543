import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invites"
export default class extends Controller {
  connect() {
  }


  deleteInvite(event) {
    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })

    var ans = confirm(i18n_string("administrator.are_you_sure_you_want_do_delete_this_invite", "Are you sure you want to delete this invite?"))
    if(!ans) {
      event.preventDefault();
    }
  }

  resendInvite(event) {
    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })
  }
}
