import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="session-refresh"
export default class extends Controller {
  cancel() {
    $('#session_continue_modal_popup').modal('hide');
  }
  
  connect() {
    // let max_idle_time = document.getElementById('max_idle_time').value
    // setTimeout(this.showRefreshModal, max_idle_time)
  }

  refreshToken() {
    let controllerRef = this;
    let max_idle_time = document.getElementById('max_idle_time').value

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: "/home/session_refresh", 
      type: 'POST', 
      success(data) {
        if(data.status == 200) {
          $('#session_continue_modal_popup').modal('hide');
          setTimeout(controllerRef.showRefreshModal, max_idle_time)
        }
      }
    });
  }

  showRefreshModal() {
    // $('#session_continue_modal_popup').modal('show');
    // $("#session_modal").html('<div class="modal-body text-center p-5"><h3>' +  I18n.session_timeout.timeout_notice + '</h3><div class="row my-3"><div class="col-6"><button class="btn btn-success" data-action="click->session-refresh#refreshToken">' +  I18n.session_timeout.continue + '</button></div><div class="col-6"><a href="/logout" class="btn btn-secondary">' + I18n.session_timeout.log_out + '</a></div></div>');
  }

}
