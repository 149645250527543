import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account-retention"
export default class extends Controller {


  // buildTable() {
  //   console.log("ARR - buildTable")
  //   $("#account_management_table").bootstrapTable({
  //     fixedColumns: true,
  //     fixedNumber: 1,
  //     stickyHeader: true
  //   })

  //   $('.bi-trash').addClass("far fa-trash");
  // }

  /* **************************************************************************
  function: configureSelect2Fields
  * Internal triggered
  * Sets up the select2 options
  ************************************************************************** */
  configureSelect2Fields(selector, options){
    console.log("ARR: configureSelect2Fields")
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }

  connect() {
    const controllerRef = this 

    // $(window).resize(function() {
    //   if(this.resizeTO) clearTimeout(this.resizeTO);
    //   this.resizeTO = setTimeout(function() {
    //     $(this).trigger('resizeEnd');
    //   }, 200);
    // });

    // $(window).bind('resizeEnd', function() {
    //   controllerRef.buildTable()
    //   controllerRef.resizeTable()
    // });
    // controllerRef.configureSelect2Fields('.select2', {})
    // controllerRef.buildTable()
    // controllerRef.resizeTable()

    // document.getElementById('update_chart_button').click()
  }

  // resizeTable(kind = '') {
  //   console.log("ARR - resizeTable")
    
  //   // Set the default width of the container
  //   var width = $(window).width();
  //   var w = 0;
  //   // We subtract the width of the nav bar (80 or 250) plus the padding of 48 on each size (96)

  //   if($('body').hasClass("nav-toggled")) {
  //       w = width - 80 - 96;
  //   } else {
  //       w = width - 250 - 96;
  //   }

  //   $('.bootstrap5').css("width", w);
  // }




  updateSettings(e) {
    console.log("ARR - updateSettings")
    var item = e.currentTarget

    $.ajax({
      type: "PATCH",
      url: "/account_retention/save",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      data: { customer: item.dataset.customer, year: item.dataset.year, retention: item.value },
      success: function (data) {
        if(data.status == 200)
        {
          $("#customer_" + item.dataset.customer + "_settings_retention_settings_" + item.dataset.year ).parent().removeClass().addClass(data.retention.toLowerCase().replace(" ", "_"))
        }
        else
        {
          alert("Error saving. Please try again.");
        }
      }
    });    
  }

  updateTable(e) {
    console.log("ARR - updateTable")

    var year = document.getElementById('retention_year_select').value
    if(year == 'all'){
      $("#arr_changes").bootstrapTable('showAllColumns')
      document.getElementById('retention_status_select_holder').classList.add('hidden')
      document.getElementById('retention_year_select').options.selectedIndex = 0
    } else {
      document.querySelectorAll('th[data-field*="year_"]').forEach((obj) =>{
        if(obj.dataset.field != year) {
          $("#arr_changes").bootstrapTable('hideColumn', obj.dataset.field)
        }
      });

      document.getElementById('retention_status_select_holder').classList.remove('hidden')
    }

    var status = $("#retention_status_select :selected").map(function(i, el) {
      return $(el).val()
    }).get();

    if(status.length == 0 || year == 'all') {
      document.querySelectorAll('tr[data-index] td:last-child').forEach((obj) =>{
        obj.parentElement.classList.remove('hidden')
      });
    } else {
      document.querySelectorAll('tr[data-index] td:last-child').forEach((obj) =>{
        obj.parentElement.classList.add('hidden')
        status.forEach((cssClass) => {
          if(obj.classList.contains(cssClass.toLowerCase().replace(" ", "_"))) {
            obj.parentElement.classList.remove('hidden')
          }
        })
      });
    }



    $.ajax({
      type: "PATCH",
      url: "/account_retention/update_user_settings",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      data: { retention_status: status, retention_year: year },
      success: function (data) {
        if(data.status != 200){
          alert("Error saving. Please try again.");          
        }
      }
    }); 

    this.resizeTable()
  }

  
}
