import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="enable-from-checkbox"
export default class extends Controller {
  static targets = [ "item", "checkbox" ]
  connect() {
    if (this.hasCheckboxTarget && this.hasItemTarget)
    {
      let itemList = this.itemTargets;
      this.checkboxTarget.addEventListener('change', function (event) {
        for(let i=0;i<itemList.length;i++){
          let elemType = itemList[i].tagName;
          if (elemType == "INPUT" || elemType == "SELECT" || elemType == "BUTTON" || elemType == "TEXTAREA") {
            itemList[i].disabled = !event.currentTarget.checked
          } else if (elemType == "DIV") {
            // Hide/show item
            if (event.currentTarget.checked) {
              itemList[i].style.display = '';
            } else {
              itemList[i].style.display = 'none';
            }
          }
        }
        if (this.checked && this.tagName == "INPUT" && this.type == "radio") {
          // Get others with same name and trigger change
          let radio_list = document.getElementsByName(this.name);
          for (let i=0; i<radio_list.length; i++) {
            if (!radio_list[i].checked){
              let newevent = new Event('change');
              radio_list[i].dispatchEvent(newevent);
            }
          }
        }
      });
    }
  }
}
