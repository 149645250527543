import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-feedback"
export default class extends Controller {
  ckeditorInstance = undefined;

  connect() {
    console.log("Connect")
    
    if(document.getElementById('feedback_survey_welcome_text')){
      this.initializeCkEditor()
    }

    if(document.getElementById('create-page')){
      var elem = document.getElementById('create-page')
      this.addNewPage(elem.dataset.id)
    }

    if(document.getElementById('new-question')){ 
      var elem = document.getElementById('new-question')
      this.addNewQuestion(elem.dataset.id, elem.dataset.question) 
    }

    if(document.getElementById('edit-question')){ 
      var elem = document.getElementById('edit-question')
      this.reloadQuestionFrame(elem.dataset.id) 
    }

    if(document.getElementById('feedback-survey')){
      this.setupSortablePages()
    }

    if(document.querySelectorAll('div.feedback_question')){
      this.setupSortableQuestions()
    }

    if(document.getElementById('update-page')){
      var elem = document.getElementById('update-page')
      this.reloadPageFrame(elem.dataset.id)
    }
  }


  addNewPage(id) {
    $('#feedback-survey').append('<turbo-frame id="user_feedback_survey_page_' + id + '" src="/user_feedback_survey/' + id + '/survey_page"></turbo-frame>')
    $('#system_modal_popup').modal('hide')
  }


  addNewQuestion(id, question_id) {
    $('#feedback_question_list_' + id).append('<turbo-frame data-save-id="' + question_id + '" id="survey_question_' + question_id + '" src="/user_feedback_survey/' + question_id + '/survey_question"></turbo-frame>')
    $('#system_modal_popup').modal('hide')
  }


  deletePage(e) {
    var item = e.currentTarget
    if(confirm("Are you sure you want to delete this page? All question will be deleted as well.")) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: "/user_feedback_survey/" + item.dataset.id + "/delete_page",
        type: "POST",
        success(obj) {
          if(obj.status == 200) {
            document.getElementById('feedback-page-' + item.dataset.id).remove()
          } else {
            alert('Cannot delete page. Please try again.');
          }
        }
      });
    }
  }


  deleteQuestion(e) {
    var item = e.currentTarget
    if(confirm("Are you sure you want to delete this question?")) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: "/user_feedback_survey/" + item.dataset.id + "/delete_question",
        type: "POST",
        success(obj) {
          if(obj.status == 200) {
            document.getElementById('survey_question_' + item.dataset.id).remove()
            $('[data-bs-toggle="tooltip"]').each(function(){
              $(this).tooltip('hide')
            })
          } else {
            alert('Cannot delete question. Please try again.');
          }
        }
      });
    }
  }


  deleteSurvey(e) {
    var item = e.currentTarget
    if(confirm("Are you sure you want to delete this survey?")) {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/user_feedback_survey/' + item.dataset.id + '/delete_survey',
        type: "POST",
        success(obj){
          if(obj.status == 200) {
            document.getElementById("survey_" + item.dataset.id).remove()
            $('[data-bs-toggle="tooltip"]').each(function(){
              $(this).tooltip('hide')
            })
          } else {
            alert('Cannot delete survey. Please try again.');
          }
        }
      })
    } else {
      e.preventDefault()
    }
  }


  initializeCkEditor() {
    // Initialize CKEditor    
    if($("#feedback_survey_welcome_text").length) {
      setTimeout(() => {
        if(!$("#feedback_survey_welcome_text").data("ckeditor_initialized")) {
          $("#feedback_survey_welcome_text").data("ckeditor_initialized", 1);
          this.ckeditorInstance = CKEDITOR.replace("feedback_survey_welcome_text", {
            height: '300px',
            toolbar: [
              {
                name: 'basicstyles',
                groups: ['basicstyles', 'cleanup'],
                items: ['Bold', 'Italic','RemoveFormat','-']
              },
              { name: 'links', items: ['Link','Unlink'] },
              {
                name: 'paragraph',
                groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
                items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
              },
              {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
              {name: 'colors', items: ['TextColor', 'BGColor']},
              {name: 'other', items: ['Image', 'Table']}
            ],
            filebrowserBrowseUrl: '/ckeditor/browse?type=files',
            filebrowserImageBrowseUrl: '/ckeditor/browse?type=images'
          });

          // http://handsomedogstudio.com/ckeditor-set-default-target-blank
          /* Here we are latching on an event ... in this case, the dialog open event */
          CKEDITOR.on('dialogDefinition', function(ev) {
            try {
              /* this just gets the name of the dialog */
        
              var dialogName = ev.data.name;
              /* this just gets the contents of the opened dialog */
        
              var dialogDefinition = ev.data.definition;
              /* Make sure that the dialog opened is the link plugin ... otherwise do nothing */

              if(dialogName == 'link') {
                
                /* Getting the contents of the Target tab */
                var informationTab = dialogDefinition.getContents('target');
              
                /* Getting the contents of the dropdown field "Target" so we can set it */
                var targetField = informationTab.get('linkTargetType');
              
                /* Now that we have the field, we just set the default to _blank. A good modification would be to check the value of the URL field and if the field does not start with "mailto:" or a relative path, then set the value to "_blank" */
                targetField['default'] = '_blank';
              
              }
            } catch(exception) {
              alert('Error ' + ev.message);
            }
          });
        }
      }, 500)
    }

    if($("#feedback_survey_complete_text").length) {
      setTimeout(() => {
        if(!$("#feedback_survey_complete_text").data("ckeditor_initialized")) {
          $("#feedback_survey_complete_text").data("ckeditor_initialized", 1);
          this.ckeditorInstance = CKEDITOR.replace("feedback_survey_complete_text", {
            height: '300px',
            toolbar: [
              {
                name: 'basicstyles',
                groups: ['basicstyles', 'cleanup'],
                items: ['Bold', 'Italic','RemoveFormat','-']
              },
              { name: 'links', items: ['Link','Unlink'] },
              {
                name: 'paragraph',
                groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
                items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
              },
              {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
              {name: 'colors', items: ['TextColor', 'BGColor']},
              {name: 'other', items: ['Image', 'Table']}
            ],
            filebrowserBrowseUrl: '/ckeditor/browse?type=files',
            filebrowserImageBrowseUrl: '/ckeditor/browse?type=images'
          });

          // http://handsomedogstudio.com/ckeditor-set-default-target-blank
          /* Here we are latching on an event ... in this case, the dialog open event */
          CKEDITOR.on('dialogDefinition', function(ev) {
            try {
              /* this just gets the name of the dialog */
        
              var dialogName = ev.data.name;
              /* this just gets the contents of the opened dialog */
        
              var dialogDefinition = ev.data.definition;
              /* Make sure that the dialog opened is the link plugin ... otherwise do nothing */

              if(dialogName == 'link') {
                
                /* Getting the contents of the Target tab */
                var informationTab = dialogDefinition.getContents('target');
              
                /* Getting the contents of the dropdown field "Target" so we can set it */
                var targetField = informationTab.get('linkTargetType');
              
                /* Now that we have the field, we just set the default to _blank. A good modification would be to check the value of the URL field and if the field does not start with "mailto:" or a relative path, then set the value to "_blank" */
                targetField['default'] = '_blank';
              
              }
            } catch(exception) {
              alert('Error ' + ev.message);
            }
          });
        }
      }, 500)
    }
  }


  rebuildUsers(e) {
    var ans = confirm("Are you sure you want to rebuild the invite list?")
    if(!ans){
      e.preventDefault()
    }
  }
  

  // Reload a single page with sections and questions
  reloadPageFrame(page_id){
    let frame = document.getElementById('user_feedback_survey_page_' + page_id)
    frame.reload()
    $('#system_modal_popup').modal('hide')
  }

  // Reloads all pages
  reloadPagesFrame() {
    let frame = document.querySelector('turbo-frame#user_feedback_survey_pages')
    frame.reload() 
    $('#system_modal_popup').modal('hide')
  }

  // Reloads question
  reloadQuestionFrame(question_id) {
    let frame = document.getElementById('survey_question_' + question_id)
    frame.reload() 
    $('#system_modal_popup').modal('hide')
  }


  setupSortablePages() {
    console.log("SORTABLE PAGES")
    $( "#feedback-survey").sortable({
      handle: ".drag-handle",
      update: function( event, ui ) {
        var save_id_order = [];
        $('#feedback-survey .feedback-page').each(function(idx, elem) {
          save_id_order.push(elem.dataset.saveId);
        });
        // Save ordering to server
        var formData = {positions: save_id_order};
        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          url: "/user_feedback_survey/save_page_order",
          data: formData,
          type: "POST",
          success(dataObj) {
            if(dataObj.error){
              $("#message-container").html('<div class="alert alert-danger">'+dataObj.error+'</div>');
              setTimeout(function(){
                $("#message-container").html('&nbsp;');
              },5000);
            }
            
          }
        });
      }
    });
  }


  setupSortableQuestions() {
    console.log("SORTABLE QUESTIONS")
    setTimeout(() => {
      $('.feedback-survey-questions').each(function(idx, elem) {
        console.log("ELEM", elem)
        $(elem).sortable({
          handle: ".drag-handle",
          update: function( event, ui ) {
            var elem_id = $(elem).attr("id")
            var save_id_order = [];
            $('#' + elem_id + ' turbo-frame').each(function(idx, e) {
              save_id_order.push(e.dataset.saveId);
            });
            // Save ordering to server
            var formData = {positions: save_id_order};
            $.ajax({
              headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
              url: "/user_feedback_survey/save_question_order",
              data: formData,
              type: "POST",
              success(dataObj) {
                if(dataObj.error){
                  $("#message-container").html('<div class="alert alert-danger">'+dataObj.error+'</div>');
                  setTimeout(function(){
                    $("#message-container").html('&nbsp;');
                  },5000);
                }
              }
            });
          }
        })
      });
    }, 3000)
  }


  toggleOther(e) {
    var item = e.currentTarget
    if(item.dataset.other == 'true') {
      document.getElementById('answer_' + item.dataset.question + '_other').classList.remove('hidden')
      document.getElementById('answer_' + item.dataset.question).disabled = false
    } else {
      document.getElementById('answer_' + item.dataset.question + '_other').classList.add('hidden')
      document.getElementById('answer_' + item.dataset.question).value = ''
      document.getElementById('answer_' + item.dataset.question).disabled = true
    }
  }


}
