import { Controller } from "@hotwired/stimulus"
import Clipboard from "../../../public/js/clipboard.min"

// Connects to data-controller="starter-kit"
export default class extends Controller {
  connect() {
  }

  clipboard(event) {
    var item = event.target
    var clipboard = new Clipboard('#' + item.id);
    clipboard.on('success', function(e) {
      $(item).blur();
      $(".copied-message").show().delay(3000).fadeOut('fast');
    });
  }

  clipboardHTML(event) {
    var item = event.target
    var html_content = $("#invite_email_content_input").val();

    var copy_success = copy_to_clipboard(html_content);
    $(item).blur();
    if(copy_success) {
      $(".copied-message").show().delay(3000).fadeOut('fast');
    }
  }

  downloadQRCode(event) {
    event.preventDefault();
    var url = document.getElementById('custom_signup_url_qr_code').value
    var kind = event.currentTarget.getAttribute('data-kind')

    document.location = '/administrator/generate_qr_code?signup_url=' + encodeURIComponent(url) + '&download=1&kind=' + kind
  }

  selectEmbedCode(event) {
    var item = event.target
    $(item).select();
  }

  toggleEmbedCode(event) {
    event.preventDefault();
    var item = event.currentTarget

    $(item).closest(".media-body").find(".embed-code").slideToggle(400,function(){
      if (!$(this).is(':hidden'))
      {
        $(this).find("input").select();
      }
    });
  }

  updateQRCode(event) {
    var url = document.getElementById('custom_signup_url_qr_code').value
    document.getElementById('custom_signup_url_qr_code_image').src = '/administrator/generate_qr_code?signup_url=' + encodeURIComponent(url);
  }

}

